// @flow
import React from 'react';
import type {Node} from 'react';
import {Alert, Typography} from '@wellstone-solutions/web';
import type {UISessionDocumentType} from '../../documentation/model/types';
import type {UIIntegrationType} from '@wellstone-solutions/common';

type PropsType = {
  documentation: UISessionDocumentType,
  orgIntegration: UIIntegrationType,
};
export const ConnectWarning = ({
  documentation,
  orgIntegration,
}: PropsType): Node => {
  const ehrName = orgIntegration?.name ?? 'the EHR System';
  const needsMemberIntegration =
    documentation.member?.integrations?.length === 0;
  const needsStaffIntegration = documentation.staff?.integrations?.length === 0;
  const needsBoth = needsMemberIntegration && needsStaffIntegration;

  const title = needsBoth
    ? `Connect Bridge Profiles to ${ehrName}`
    : needsMemberIntegration
    ? `Connect the member to ${ehrName}`
    : `Connect the staff to ${ehrName}`;

  const prefixWording =
    'You can save your documentation progress, but you will need to connect';

  const description = needsBoth
    ? `${prefixWording} profiles in Bridge to ${ehrName} in order to submit the member's documentation."`
    : needsMemberIntegration
    ? `${prefixWording} the member's profile in Bridge to ${ehrName} in order to submit the member's documentation.`
    : `${prefixWording} the staff's profile in Bridge to ${ehrName} in order to submit the member's documentation.`;

  return (
    <Alert severity="warning" sx={styles.container}>
      <Typography sx={{fontSize: 16, fontWeight: 'bold'}}>{title}</Typography>
      <Typography sx={{fontSize: 14}}>{description}</Typography>
    </Alert>
  );
};

const styles = {
  container: {
    mb: 2,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
};
