// @flow
import React from 'react';
import type {Node} from 'react';
import {Dialog, DialogContent, DialogActions} from '@mui/material';
import {Button, Stack, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  open: boolean,
  setOpen: (boolean) => void,
  onConfirm: () => Promise<void>,
};

export const ConfirmRevert = ({open, setOpen, onConfirm}: PropsType): Node => {
  const onDialogClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={onDialogClose}
      open={open}
      data-testid="document-revert-confirm">
      <div style={styles.headerBar} />
      <DialogContent sx={styles.content}>
        <Typography sx={styles.text}>
          Are you sure you want to revert the status of this document back to In
          Progress?
        </Typography>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Stack direction="row" sx={{paddingTop: 0}}>
          <Button
            onClick={() => {
              onDialogClose();
              onConfirm();
            }}
            color="primary"
            variant="contained"
            style={styles.button}>
            Revert
          </Button>
          <Button onClick={onDialogClose} color="primary" style={styles.button}>
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  content: {
    paddingLeft: Theme.spacing.small,
    paddingRight: Theme.spacing.small,
    paddingTop: Theme.spacing.mini,
    paddingBottom: Theme.spacing.micro,
    marginTop: Theme.spacing.micro,
  },
  text: {
    fontSize: 18,
    color: Theme.colorPalette.primary,
  },
  actions: {
    paddingLeft: Theme.spacing.small,
    paddingBottom: 6,
    justifyContent: 'start',
  },
  headerBar: {
    position: 'absolute',
    height: Theme.spacing.average,
    top: 0,
    left: 0,
    backgroundColor: Theme.colorPalette.primary,
    width: '100%',
  },
  button: {
    fontSize: 16,
    textTransform: 'none',
    borderRadius: '20px',
    paddingLeft: Theme.spacing.huge,
    paddingRight: Theme.spacing.huge,
    marginRight: Theme.spacing.average,
  },
};
