// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {Box, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {PfCheckinChart} from 'modules/activity/components/CheckinChart';

type PropsType = {
  dateRange: {startDate: Date, endDate: Date},
  format?: 'date' | 'day' | 'hour',
  label: string,
};

export const CheckinChart = ({
  dateRange,
  format = 'date',
  label,
}: PropsType): Node => {
  const [checkinData, setCheckinData] = useState([]);
  const {dashboardStore} = useStores();

  useEffect(() => {
    const getCheckinData = async () => {
      const checkins = await dashboardStore.getCheckins({
        // Dashboard shows last 30 days
        dateRange,
        format,
      });

      setCheckinData(checkins);
    };

    getCheckinData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        marginTop: 10,
      }}>
      <Typography variant="h4" style={{fontColor: Theme.colorPalette.darkest}}>
        Check-Ins
      </Typography>
      <Typography style={{fontColor: Theme.colorPalette.darkest}}>
        {label}
      </Typography>

      <PfCheckinChart
        aggregator={format}
        checkinData={checkinData}
        initialFilter="Safety"
      />
    </Box>
  );
};
