// @flow
import React, {useEffect, useRef} from 'react';
import type {Node} from 'react';
import {PfButton, PfButtonSize} from 'components/shared/PfButton';
import {Stack} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  showConfirm: boolean,
  confirm: () => void | Promise<void>,
  cancelConfirm: () => void,
  color?: string,
  style?: any,
  confirmText?: string,
  confirmTextStyle?: {[key: string]: any},
  buttonPosition?: 'left' | 'right',
};

export const PfConfirmInline = ({
  showConfirm,
  confirm,
  cancelConfirm,
  color,
  style,
  confirmText = '',
  confirmTextStyle,
  buttonPosition = 'right',
}: PropsType): Node => {
  const confirmTextRef = useRef(null);
  useEffect(() => {
    if (confirmTextRef.current) {
      confirmTextRef.current.focus();
    }
  });
  if (!showConfirm) {
    return <></>;
  }

  const hasCustomStyles = !!style;

  return (
    <Stack data-testid="pf-confirm-inline">
      {confirmText.length > 0 && (
        <div
          ref={confirmTextRef}
          tabIndex={0}
          style={{...styles.confirmTextDefault, ...confirmTextStyle}}>
          {confirmText}
        </div>
      )}
      <Stack
        direction="row"
        justifyContent={buttonPosition === 'left' ? 'flex-start' : 'flex-end'}>
        <PfButton
          data-testid="confirm-button"
          color={color || 'primary'}
          onClick={confirm}
          style={hasCustomStyles ? style : {}}
          size={hasCustomStyles ? undefined : PfButtonSize.SMALL}
          aria-label="confirm">
          Confirm
        </PfButton>
        <PfButton
          data-testid="cancel-button"
          color={color || 'primary'}
          onClick={cancelConfirm}
          style={hasCustomStyles ? style : {}}
          size={hasCustomStyles ? undefined : PfButtonSize.SMALL}
          aria-label="cancel"
          outlined>
          Cancel
        </PfButton>
      </Stack>
    </Stack>
  );
};

const styles: {[key: string]: any} = {
  confirmTextDefault: {
    fontWeight: 'bold',
    marginBottom: Theme.spacing.small,
    textAlign: 'end',
  },
};
