import React from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useQueryString} from '@wellstone-solutions/web';

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const query = useQueryString();
    return (
      <Component {...props} router={{location, navigate, params, query}} />
    );
  }

  return ComponentWithRouterProp;
}
