import {Palette} from '../palette';
// data mapping for different data types, determining color and relationship between different data.
// "stack" allows us to determine what type of data should be grouped and stacked together
export const chartProps = {
  // WARNING STACK
  user_marked_message: {
    name: 'Reported Concern',
    key: 'user_marked_message',
    color: Palette.MARKED_MESSAGE,
    selected: true,
    stack: 'warning',
    charts: ['activity', 'engagement'],
    fa: 'flag',
  },
  user_had_message_marked: {
    name: 'Concerning Message',
    key: 'user_had_message_marked',
    color: Palette.MESSAGE_MARKED,
    selected: true,
    stack: 'warning',
    charts: ['activity'],
    fa: 'exclamation',
  },
  user_reported_abusive_user: {
    name: 'Reported Abuse',
    key: 'user_reported_abusive_user',
    color: Palette.REPORT_ABUSE,
    selected: true,
    stack: 'warning',
    charts: ['activity', 'engagement'],
    fa: 'flag',
  },
  user_reported_as_abusive: {
    name: 'Abuse',
    key: 'user_reported_as_abusive',
    color: Palette.ABUSE_REPORTED,
    selected: true,
    stack: 'warning',
    charts: ['activity'],
    fa: 'exclamation',
  },
  user_relapsed: {
    name: 'Relapse',
    key: 'user_relapsed',
    color: Palette.RELAPSE,
    selected: true,
    stack: 'warning',
    charts: ['activity'],
    fa: 'exclamation',
  },
  // ACTIVITY STACK
  user_created_message: {
    name: 'Message Created',
    key: 'user_created_message',
    color: Palette.MESSAGE,
    selected: true,
    stack: 'share',
    charts: ['activity', 'engagement'],
  },
  user_shared_resource: {
    name: 'Shared Resource',
    key: 'user_shared_resource',
    color: Palette.SHARED_RESOURCE,
    selected: true,
    stack: 'share',
    charts: ['activity', 'engagement'],
  },
  user_shared_resource_from_chat: {
    name: 'Shared Resource (chat)',
    key: 'user_shared_resource_from_chat',
    color: Palette.SHARED_RESOURCE_CHAT,
    selected: true,
    stack: 'share',
    charts: ['activity', 'engagement'],
  },
  user_shared_resource_from_resources: {
    name: 'Shared Resource (resources)',
    key: 'user_shared_resource_from_resources',
    color: Palette.SHARED_RESOURCE_RESOURCES,
    selected: true,
    stack: 'share',
    charts: ['activity', 'engagement'],
  },
  user_shared_resource_from_saved_resources: {
    name: 'Shared Resource (saved)',
    key: 'user_shared_resource_from_saved_resources',
    color: Palette.SHARED_RESOURCE_SAVED,
    selected: true,
    stack: 'share',
    charts: ['activity', 'engagement'],
  },
  user_shared_meeting: {
    name: 'Shared Meeting',
    key: 'user_shared_meeting',
    color: Palette.BADGES,
    selected: true,
    stack: 'share',
    charts: ['activity', 'engagement'],
  },
  user_shared_meeting_from_chat: {
    name: 'Shared Meeting (chat)',
    key: 'user_shared_meeting_from_chat',
    color: Palette.BADGES,
    selected: true,
    stack: 'share',
    charts: ['activity', 'engagement'],
  },
  user_shared_meeting_from_my_meetings: {
    name: 'Shared Meeting (meetings)',
    key: 'user_shared_meeting_from_my_meetings',
    color: Palette.BADGES,
    selected: true,
    stack: 'share',
    charts: ['activity', 'engagement'],
  },
  user_saved_resource: {
    name: 'Saved Resource',
    key: 'user_saved_resource',
    color: Palette.RESOURCE_SAVED,
    selected: true,
    stack: 'activity',
    charts: ['activity'],
  },
  user_saved_meeting: {
    name: 'Saved Meeting',
    key: 'user_saved_meeting',
    color: Palette.MEETING_SAVED,
    selected: true,
    stack: 'activity',
    charts: ['activity'],
  },
  user_added_friend: {
    name: 'New Relationship',
    key: 'user_added_friend',
    color: Palette.RELATIONSHIP,
    selected: true,
    stack: 'activity',
    charts: ['activity'],
  },
  user_added_checkin: {
    name: 'Check-In',
    key: 'user_added_checkin',
    color: Palette.CHECKIN,
    selected: true,
    stack: 'activity',
    charts: ['activity', 'engagement'],
  },

  // ADMIN STACK
  super_admin_added_admin: {
    name: 'Admin Added',
    key: 'super_admin_added_admin',
    color: Palette.ADDED_ADMIN,
    selected: true,
    stack: 'staff',
    charts: ['staff'],
  },
  super_admin_edited_user: {
    name: 'User Edited',
    key: 'super_admin_edited_user',
    color: Palette.EDITED_USER,
    selected: true,
    stack: 'staff',
    charts: ['staff'],
  },
  admin_created_group: {
    name: 'Group Created',
    key: 'admin_created_group',
    color: Palette.ADDED_GROUP,
    selected: true,
    stack: 'staff',
    charts: ['staff'],
  },
  admin_edited_group: {
    name: 'Group Edited',
    key: 'admin_edited_group',
    color: Palette.EDITED_GROUP,
    selected: true,
    stack: 'staff',
    charts: ['staff'],
  },
  admin_deleted_member: {
    name: 'Member Deleted',
    key: 'admin_deleted_member',
    color: Palette.REMOVED_MEMBER,
    selected: true,
    stack: 'staff',
    charts: ['staff'],
  },

  // NAVIGATION
  // PROFILE
  user_viewed_announcements: {
    name: 'Viewed Announcements',
    key: 'user_viewed_announcements',
    color: Palette.VIEW_ANNOUNCEMENT,
    selected: true,
    stack: 'profile',
    charts: ['appnavigation'],
  },
  user_viewed_checkin_chart_info: {
    name: 'Viewed Chart Info',
    key: 'user_viewed_checkin_chart_info',
    color: Palette.VIEW_CHART_INFO,
    selected: true,
    stack: 'profile',
    charts: ['appnavigation'],
  },
  user_viewed_chart_time: {
    name: 'Viewed Chart (time)',
    key: 'user_viewed_chart_time',
    color: Palette.VIEW_CHART_TIME,
    selected: true,
    stack: 'profile',
    charts: ['appnavigation'],
  },
  user_viewed_chart_date: {
    name: 'Viewed Chart (date)',
    key: 'user_viewed_chart_date',
    color: Palette.VIEW_CHART_DATE,
    selected: true,
    stack: 'profile',
    charts: ['appnavigation'],
  },
  user_viewed_chart_day: {
    name: 'Viewed Chart (day)',
    key: 'user_viewed_chart_day',
    color: Palette.VIEW_CHART_DAY,
    selected: true,
    stack: 'profile',
    charts: ['appnavigation'],
  },
  user_viewed_checkin: {
    name: 'Viewed Checkin',
    key: 'user_viewed_checkin',
    color: Palette.VIEW_CHECKIN,
    selected: true,
    stack: 'profile',
    charts: ['appnavigation'],
  },
  user_viewed_my_meetings: {
    name: 'Viewed My Meetings',
    key: 'user_viewed_my_meetings',
    color: Palette.VIEW_MY_MEETINGS,
    selected: true,
    stack: 'profile',
    charts: ['appnavigation'],
  },
  user_viewed_add_meeting: {
    name: 'Viewed Add Meeting',
    key: 'user_viewed_add_meeting',
    color: Palette.VIEW_ADD_MEETINGS,
    selected: true,
    stack: 'profile',
    charts: ['appnavigation'],
  },
  user_viewed_saved_resources: {
    name: 'Viewed Saved Resources',
    key: 'user_viewed_saved_resources',
    color: Palette.VIEW_SAVED_RESOURCES,
    selected: true,
    stack: 'profile',
    charts: ['appnavigation'],
  },
  user_viewed_settings: {
    name: 'Viewed Settings',
    key: 'user_viewed_settings',
    color: Palette.VIEW_SETTINGS,
    selected: true,
    stack: 'profile',
    charts: ['appnavigation'],
  },
  // STORIES
  user_viewed_stories: {
    name: 'Viewed Stories',
    key: 'user_viewed_stories',
    color: Palette.VIEW_STORIES,
    selected: true,
    stack: 'stories',
    charts: ['appnavigation'],
  },
  user_viewed_story: {
    name: 'View Story',
    key: 'user_viewed_story',
    color: Palette.VIEW_STORY,
    selected: true,
    stack: 'stories',
    charts: ['activity', 'appnavigation'],
  },
  // COMMUNITY
  user_viewed_conversations: {
    name: 'Viewed Conversations',
    key: 'user_viewed_conversations',
    color: Palette.VIEW_CONVERSATIONS,
    selected: true,
    stack: 'community',
    charts: ['appnavigation'],
  },
  user_viewed_chat: {
    name: 'Viewed Chat',
    key: 'user_viewed_chat',
    color: Palette.VIEW_CHAT,
    selected: true,
    stack: 'community',
    charts: ['appnavigation'],
  },
  user_viewed_contacts: {
    name: 'Viewed Contacts',
    key: 'user_viewed_contacts',
    color: Palette.VIEW_CONTACTS,
    selected: true,
    stack: 'community',
    charts: ['appnavigation'],
  },
  user_viewed_directory: {
    name: 'Viewed Directory',
    key: 'user_viewed_directory',
    color: Palette.VIEW_DIRECTORY,
    selected: true,
    stack: 'community',
    charts: ['appnavigation'],
  },
  user_viewed_channel_detail: {
    name: 'Viewed Channel Detail',
    key: 'user_viewed_channel_detail',
    color: Palette.VIEW_CHANNEL_DETAIL,
    selected: true,
    stack: 'community',
    charts: ['appnavigation'],
  },
  user_viewed_resources: {
    name: 'Viewed Resources',
    key: 'user_viewed_resources',
    color: Palette.VIEW_RESOURCES,
    selected: true,
    stack: 'resources',
    charts: ['appnavigation'],
  },
  user_viewed_badges: {
    name: 'Viewed Badges',
    key: 'user_viewed_badges',
    color: Palette.VIEW_BADGES,
    selected: true,
    stack: 'tree',
    charts: ['appnavigation'],
  },
  user_viewed_badge_detail: {
    name: 'Viewed Badge Detail',
    key: 'user_viewed_badge_detail',
    color: Palette.VIEW_BADGE_DETAIL,
    selected: true,
    stack: 'tree',
    charts: ['appnavigation'],
  },
  // ENGAGEMENT
  user_opened_app: {
    name: 'Opened App',
    key: 'user_opened_app',
    color: Palette.OPEN_APP,
    selected: true,
    stack: 'general',
    charts: ['engagement'],
  },
  user_created_meeting: {
    name: 'Created Meeting',
    key: 'user_created_meeting',
    color: Palette.CREATE_MEETING,
    selected: true,
    stack: 'collect',
    charts: ['engagement'],
  },
  user_updated_meeting: {
    name: 'Updated Meeting',
    key: 'user_updated_meeting',
    color: Palette.UPDATE_MEETING,
    selected: true,
    stack: 'collect',
    charts: ['engagement'],
  },
  user_hid_channel: {
    name: 'Hid Channel',
    key: 'user_hid_channel',
    color: Palette.HID_CHANNEL,
    selected: true,
    stack: 'warning',
    charts: ['engagement'],
  },
  user_saved_meeting_from_chat: {
    name: 'Saved Meeting (chat)',
    key: 'user_saved_meeting_from_chat',
    color: Palette.SAVED_MEETING_FROM_CHAT,
    selected: true,
    stack: 'collect',
    charts: ['engagement'],
  },
  user_saved_resource_from_chat: {
    name: 'Saved Resource (chat)',
    key: 'user_saved_resource_from_chat',
    color: Palette.SAVED_RESOURCE_FROM_CHAT,
    selected: true,
    stack: 'collect',
    charts: ['engagement'],
  },
  user_saved_resource_from_resources: {
    name: 'Saved Resource (resources)',
    key: 'user_saved_resource_from_resources',
    color: Palette.SAVED_RESOURCE_FROM_RESOURCES,
    selected: true,
    stack: 'collect',
    charts: ['engagement'],
  },
  // BADGE
  user_earned_badge_progress: {
    name: 'Badge Progress',
    key: 'user_earned_badge_progress',
    color: Palette.BADGE_PROGRESS,
    selected: true,
    stack: 'badges',
    charts: ['badge'],
  },
  user_earned_badge: {
    name: 'Badge Earned',
    key: 'user_earned_badge',
    color: Palette.BADGES,
    selected: true,
    stack: 'badges',
    charts: ['badge'],
  },
};
