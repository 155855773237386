import React, {useEffect, useState} from 'react';
import {Api} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {getTimestampFromPubnubTimetoken} from '../utils/Utils';
import {Palette} from '../palette';

const AppUpdater = () => {
  const {meStore, messageStore, pubnubStore, channelStore} = useStores();
  const [error, setError] = useState(false);

  useEffect(() => {
    const load = async () => {
      await channelStore.loadChannels();
      await messageStore.loadAllFromAsync();
      await pubnubStore._loadAsync();

      let channels = {};

      try {
        const response = await Api.Instance.current().get(
          '/messaging/channels',
        );
        channels = response.data.channels;
      } catch (err) {
        setError(true);
        return;
      }

      if (!pubnubStore.connected) {
        await pubnubStore.connect();
      }

      function getLastMessageFromPubnub(historyObj) {
        return new Promise((resolve) => {
          pubnubStore.pubnub.fetchMessages(historyObj, (status, response) => {
            resolve(response);
          });
        });
      }

      for (const channel of channels) {
        let message = messageStore.messages.find(
          (m) => m.message.channel === channel.name,
        );

        const lastViewed = message
          ? getTimestampFromPubnubTimetoken(message.timetoken) - 100
          : getTimestampFromPubnubTimetoken(pubnubStore.lastViewed);

        let data = {
          last_channel_activity: new Date(lastViewed),
          last_viewed: {
            [meStore.me.id]: new Date(lastViewed),
          },
        };

        let historyObj = {
          channels: [channel.name],
          count: 1,
        };

        const response = await getLastMessageFromPubnub(historyObj);

        if (response) {
          if (
            channel.name in response.channels &&
            response.channels[channel.name].length
          ) {
            data.last_channel_activity = new Date(
              getTimestampFromPubnubTimetoken(
                response.channels[channel.name][0].timetoken,
              ),
            );
          }
        }
        channelStore.updateChannelData(channel, data);
        channelStore.clearUnreadChannel(channel);
      }
      messageStore.clear();
      pubnubStore.clearLastViewed();
      window.location.reload();
    };
    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <div style={{...styles.container, ...styles.error}}>
        There was an error updating message data. Please try refreshing.
      </div>
    );
  }

  return (
    <div style={{...styles.container, ...styles.updating}}>
      Updating message data...
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    padding: 40,
    fontWeight: 'bold',
  },
  error: {
    color: Palette.BAD,
  },
  updating: {
    color: Palette.PRIMARY,
  },
};

export default AppUpdater;
