// @flow
import React from 'react';
import type {Node} from 'react';

import {Theme} from '@wellstone-solutions/common';
import {Typography, Box, Stack, Link} from '@wellstone-solutions/web';

import {
  WEBSITE_URL,
  TERMS_OF_USE_URL,
  PRIVACY_POLICY_URL,
  EMAIL_ADDRESS,
} from './constants';

type PropsType = {
  version: string,
};

export const SiteFooter = ({version}: PropsType): Node => {
  return (
    <Typography variant="body">
      <Box sx={styles.footer} data-testid="site-footer">
        <Stack sx={styles.footerContent} data-testid="site-footer-content">
          <Stack sx={styles.contentLeft}>
            <Stack sx={styles.siteInfo}>
              <Box sx={{fontWeight: 800}}>Pathfinder Bridge {version} </Box>
              <Box>
                &nbsp;© {new Date().getFullYear()}{' '}
                <Link
                  rel="noopener noreferrer"
                  href={WEBSITE_URL}
                  target="_blank"
                  sx={styles.link}>
                  Pathfinder Solutions PBC
                </Link>
              </Box>
            </Stack>
            <Box sx={styles.siteInfo}>
              For technical assistance,{' '}
              <Link sx={styles.link} href={`mailto:${EMAIL_ADDRESS}`}>
                email us
              </Link>
              .
            </Box>
          </Stack>
          <Stack sx={styles.contentRight}>
            <Link
              rel="noopener noreferrer"
              href={TERMS_OF_USE_URL}
              target="_blank"
              sx={styles.link}>
              Terms of Use
            </Link>
            <Link
              rel="noopener noreferrer"
              href={PRIVACY_POLICY_URL}
              target="_blank"
              sx={styles.link}>
              Privacy Policy
            </Link>
          </Stack>
        </Stack>
      </Box>
    </Typography>
  );
};

const styles = {
  footer: {
    marginTop: 2,
    backgroundColor: Theme.colorPalette.mediumLight,
    color: Theme.colorPalette.darkest,
    padding: '20px 28px',
    borderTop: `1px ${Theme.colorPalette.medium} solid`,
  },
  footerContent: {
    justifyContent: 'space-between',
    flexDirection: {
      xs: 'column',
      sm: 'column',
      md: 'row',
    },
    alignItems: {
      xs: 'center',
      sm: 'center',
      md: 'flex-start',
    },
  },
  contentLeft: {
    gap: 0.5,
    flexDirection: 'column',
  },
  siteInfo: {
    flexDirection: {
      xs: 'column',
      sm: 'column',
      md: 'row',
    },
    alignItems: {
      xs: 'center',
      sm: 'center',
      md: 'flex-start',
    },
  },
  name: {
    fontWeight: 800,
  },
  contentRight: {
    minWidth: 300,
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: '4px',
    alignItems: {
      xs: 'center',
      sm: 'center',
      md: 'start',
    },
  },
  link: {
    color: '#0277BD',
    textDecoration: 'underline',
  },
};
