// @flow
import {IconNames} from '@wellstone-solutions/web';
import {Hooks} from '@wellstone-solutions/common';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {getDirectionsUrl} from 'utils/addressHelpers';
import {CONTACT_METHODS} from '../constants';

type ContactMethodType = {
  text: string,
  iconName: string,
  href: string,
  type: $Values<typeof CONTACT_METHODS>,
};

const {useAddress} = Hooks;

export const useContactMethods = (
  resource: UIResourceType,
): {
  contactMethods: Array<ContactMethodType>,
} => {
  const contactMethods: Array<ContactMethodType> = [];

  const {formattedAddress, ...restLocation} = resource.location || {};
  const {displayCityStateZip} = useAddress(restLocation);
  const displayLocation = formattedAddress || displayCityStateZip || null;
  if (displayLocation) {
    contactMethods.push({
      text: displayLocation,
      iconName: IconNames.MapPin,
      href: getDirectionsUrl(displayLocation),
      type: CONTACT_METHODS.address,
    });
  }

  if (resource?.website) {
    contactMethods.push({
      text: resource.website,
      iconName: IconNames.Laptop,
      href: resource.website,
      type: CONTACT_METHODS.website,
    });
  }

  if (resource?.phoneNumber) {
    contactMethods.push({
      text: resource.phoneNumber,
      iconName: IconNames.Phone,
      href: `tel:${resource.phoneNumber}`,
      type: CONTACT_METHODS.phone,
    });
  }

  if (resource?.email) {
    contactMethods.push({
      text: resource.email,
      iconName: IconNames.Mail,
      href: `mailto:${resource.email}`,
      type: CONTACT_METHODS.email,
    });
  }

  return {contactMethods};
};
