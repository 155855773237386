// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {Box, Stack, TextField, Typography} from '@wellstone-solutions/web';
import {Models, Theme} from '@wellstone-solutions/common';
import PfModal from 'components/modal/PfModal';
import {PfButton, PfButtonSize} from 'components/shared/PfButton';
import {PfConfirmInline} from 'components/shared/PfConfirmInline';
import {EventEmitter} from 'utils/EventEmitter';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {
  LAST_INVITATION_REINVITE_MINUTES,
  PATIENT_CREATED_REINVITE_MINUTES,
  REINVITE_TOOLTIP,
} from '../../constants';

const {Member} = Models;

type PropTypes = {
  id: string,
  memberCreated: string,
  lastInviteSent?: Date,
  email: string,
  name: string,
  organizationId: string,
};

export const ResendInvitationEmail = ({
  id,
  memberCreated,
  lastInviteSent,
  email,
  name,
  organizationId,
}: PropTypes): Node => {
  const [message, setMessage] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [disableResend, setDisableResend] = useState(false);

  const now = moment();
  const lastSent = lastInviteSent ? moment(lastInviteSent) : null;
  const isNewMember =
    now.diff(moment(memberCreated), 'minutes') <
    PATIENT_CREATED_REINVITE_MINUTES;

  const isRecentLastSent =
    lastSent &&
    now.diff(lastSent, 'minutes') < LAST_INVITATION_REINVITE_MINUTES;

  const disabled = isNewMember || isRecentLastSent;

  const reset = () => {
    setMessage('');
    setShowConfirm(false);
  };

  const sendEmail = async () => {
    const result = await Member.sendInvitation(
      {
        orgId: organizationId,
        id,
      },
      {message},
    );

    if (result.isSuccess) {
      showAlert(`Re-sent invitation to ${name}.`);
      EventEmitter.dispatch('closeModal');

      reset();
    } else {
      showAlert(
        'Could not resend invitation, please try again',
        ALERT_TYPES.ERROR,
      );
    }
    setDisableResend(true);
  };

  return (
    <PfModal
      disabled={disabled || disableResend}
      tooltip={disabled ? REINVITE_TOOLTIP : null}
      button={{
        text: 'Resend Invitation',
        outlined: false,
      }}
      buttonStyle={{backgroundColor: Theme.colorPalette.teal}}
      buttonContainerStyle={styles.container}
      large
      modal={{
        closeOnBlur: true,
        showClose: true,
        closeOnEsc: true,
      }}>
      <Stack>
        <Typography variant="h6" sx={{color: Theme.colorPalette.primary}}>
          Send Invitation
        </Typography>
        <Typography variant="body2" sx={{pb: 2}}>
          Send an email to {name} ({email}) with directions to download the app.
        </Typography>
        <TextField
          onChange={(e) => setMessage(e.target.value)}
          multiline
          maxRows={4}
          placeholder="Add a personal message here"
          size="small"
          inputProps={{
            style: {fontSize: '0.8rem'},
          }}
          value={message}
        />
        <Box sx={{mt: 2}}>
          {showConfirm ? (
            <PfConfirmInline
              buttonPosition="left"
              showConfirm={showConfirm}
              confirm={sendEmail}
              cancelConfirm={() => setShowConfirm(false)}
              color="primary"
            />
          ) : (
            <PfButton
              onClick={() => setShowConfirm(true)}
              outlined
              color="primary"
              size={PfButtonSize.SMALL}>
              Send
            </PfButton>
          )}
        </Box>
      </Stack>
    </PfModal>
  );
};

const styles = {
  container: {
    marginTop: '0.6rem',
  },
};
