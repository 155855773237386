// @flow
import {Factories} from '@wellstone-solutions/common';
import {ContactModel} from '../contact';
import type {FactoryAnswerType, UIAnswerType} from '../../types';

const expirationDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 30);

  return date;
};

export const Factory = (overrides?: FactoryAnswerType): UIAnswerType =>
  Factories.createFactory(
    {
      contacts: [ContactModel.Factory()],
      description: '',
      expires: expirationDate().toISOString(),
      id: '',
      modified: '',
      organizationTitle: '',
      questionId: '',
      state: '',
      subtitle: '',
      title: '',
    },
    overrides,
  );
