// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Icon,
  IconNames,
  Stack,
  Tooltip,
  Typography,
} from '@wellstone-solutions/web';
import {CARD_TYPES, INTERVAL_STRINGS} from '../../constants';

type PropsType = {
  cardType: string,
  title: string,
  currentValue: number,
  priorValue?: number,
  interval?: number,
  formatter?: (number) => string | number,
  tooltip?: string,
};

export const AnalyticsCard = ({
  cardType,
  title,
  currentValue,
  priorValue,
  formatter,
  interval,
  tooltip,
}: PropsType): Node => {
  const isComparison = cardType === CARD_TYPES.comparison;
  priorValue = priorValue || 0;

  let iconColor;
  let iconName;
  // Only show icon if there is a positive or negatve change
  if (currentValue > priorValue) {
    iconColor = Theme.colorPalette.secondary;
    iconName = IconNames.ArrowUpCircle;
  } else if (currentValue < priorValue) {
    iconColor = Theme.colorPalette.red;
    iconName = IconNames.ArrowDownCircle;
  }

  const formattedPrior = formatter ? formatter(priorValue) : priorValue;
  const formattedCurrent = formatter ? formatter(currentValue) : currentValue;

  return (
    <Tooltip
      title={
        tooltip && interval
          ? `${tooltip} this ${INTERVAL_STRINGS[interval]}`
          : tooltip
      }
      placement="top"
      enterDelay={500}
      enterNextDelay={250}
      leaveDelay={250}
      arrow>
      <div style={styles.tooltip}>
        <Card sx={styles.card}>
          <CardContent sx={styles.content}>
            <Stack direction="row">
              {isComparison ? (
                <Stack
                  direction="row"
                  sx={{
                    width: '100%',
                    justifyContent: 'space-around',
                  }}>
                  <Box sx={styles.box}>
                    <Typography variant="h4">{formattedCurrent}</Typography>
                  </Box>

                  <Box sx={styles.iconContainer}>
                    {!!iconName && !!iconColor && (
                      <Icon
                        color={iconColor}
                        style={styles.icon}
                        size={24}
                        name={iconName}
                      />
                    )}
                  </Box>

                  <Box sx={styles.box}>
                    <Typography variant="h5" sx={styles.prior}>
                      {formattedPrior}
                    </Typography>
                    {!!interval && (
                      <Typography
                        sx={
                          styles.prior
                        }>{`last ${INTERVAL_STRINGS[interval]}`}</Typography>
                    )}
                  </Box>
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  sx={{width: '100%', justifyContent: 'center'}}>
                  <Typography variant="h4">{formattedCurrent}</Typography>
                </Stack>
              )}
            </Stack>

            <Divider sx={styles.divider} />

            <Typography align="center" sx={styles.title}>
              {title}
            </Typography>

            {!!interval && (
              <Typography align="center" sx={styles.prior}>
                {`this ${INTERVAL_STRINGS[interval]}`}
              </Typography>
            )}
          </CardContent>
        </Card>
      </div>
    </Tooltip>
  );
};

const styles = {
  box: {
    flexGrow: 1,
    textAlign: 'center',
  },
  card: {
    height: '100%',
  },
  content: {
    p: Theme.spacing.mini,
    '&:lastChild': {
      pb: Theme.spacing.mini,
    },
  },
  prior: {
    color: Theme.colorPalette.dark,
    lineHeight: 1.2,
  },
  divider: {
    mx: -Theme.spacing.mini,
    my: Theme.spacing.micro,
    height: '1px',
    borderColor: Theme.colorPalette.mediumLight,
  },
  iconContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
    flexGrow: 1,
    justifyContent: 'center',
    textAlign: 'center',
  },
  icon: {
    strokeWidth: 2,
  },
  title: {
    fontWeight: Theme.typography.weight.bold,
  },
  tooltip: {
    height: '100%',
  },
};
