// @flow
import React from 'react';
import {lighten} from '@mui/material';
import {Chip} from '@wellstone-solutions/web';

export const ColorChip = ({
  label,
  color,
  sx,
}: {
  label: string,
  color: string, // a hex value
  sx?: any,
}): Node => {
  return (
    <Chip
      variant="outlined"
      label={label}
      sx={{
        borderColor: color,
        color: color,
        backgroundColor: lighten(color, 0.9),
        ...sx,
      }}
    />
  );
};
