// @flow
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import type {Node} from 'react';
import {useTheme} from '@mui/material/styles';
import {Theme} from '@wellstone-solutions/common';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Button,
  Icon,
  IconNames,
  Chip,
  Box,
  Stack,
  Typography,
  AutocompleteSearch,
} from '@wellstone-solutions/web';

import {useStores} from 'hooks/useStores';
import {getAdminRole} from 'utils/Forms';
import {SiteHeaderDrawer} from './SiteHeaderDrawer';

type MemberOptionType = {
  id: string,
  name: string,
  role: string,
};

type MemberResultType = {
  label: Node,
  option: MemberOptionType,
};

const MemberOption = (member: MemberResultType): Node => {
  const chipColor = Theme.colorPalette.secondary3;
  const chipTextColor = Theme.colorPalette.onSecondary;
  const {label, option} = member;
  const userRole = getAdminRole(option.role) ?? {
    name: 'Patient',
    value: 'patient',
    color: 'Primary',
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      flex={1}>
      <Box data-testid="member_element">
        <Typography variant="body1">{label}</Typography>
      </Box>
      <Box>
        <Chip
          label={userRole.name}
          variant="default"
          size="small"
          sx={{
            backgroundColor: chipColor,
            color: chipTextColor,
          }}
          data-testid="member_chip"
        />
      </Box>
    </Stack>
  );
};

export const SearchPanel = (): Node => {
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const {organizationStore} = useStores();
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setSearchAnchorEl(null);
  };
  const handleClick = (event) => {
    let anchor = event.target;
    setSearchAnchorEl(anchor);
  };
  const onSearch = async (searchTerm) => {
    const response = await organizationStore.search({
      searchTerm,
      queryBy: ['members'],
      statusFilters: ['active'],
    });

    if (response.isSuccess && response.data.members) {
      return response.data.members.map(({id, name, role}) => ({
        id,
        name,
        role,
      }));
    } else {
      return [];
    }
  };

  const onMemberSelect = (result: MemberOptionType) => {
    let role = result.role === 'patient' ? 'members' : 'staff';
    let target = `/${role}/${result.id}`;
    handleClose();
    navigate(target);
  };

  return isTablet ? (
    <>
      <Button
        data-testid="header-search-button"
        onClick={handleClick}
        tabIndex={0}
        sx={styles.button}>
        <Icon
          name={IconNames.Search}
          size={isMobile ? 22 : 24}
          color={theme.palette.primary.contrastText}
        />
      </Button>
      <SiteHeaderDrawer
        onClose={handleClose}
        title="Search"
        anchorEl={searchAnchorEl}
        transitionDirection="left"
        transitionTimeout={0}>
        <Box pt={1} px={2}>
          <AutocompleteSearch
            testId="header-search-form-drawer"
            optionLabelKey="name"
            optionValueKey="id"
            renderOption={MemberOption}
            onOptionSelect={onMemberSelect}
            onSearch={onSearch}
            placeholder="Search for members"
            value={null}
            slotProps={{
              paper: {
                sx: styles.mobileResults,
              },
            }}
          />
        </Box>
      </SiteHeaderDrawer>
    </>
  ) : (
    <AutocompleteSearch
      testId="header-search-form"
      optionLabelKey="name"
      optionValueKey="id"
      renderOption={MemberOption}
      onOptionSelect={onMemberSelect}
      onSearch={onSearch}
      placeholder="Search for members by name or email"
      value={null}
      sx={styles.headerForm}
      size="small"
    />
  );
};

const styles = {
  button: {
    padding: '8px 0',
    minWidth: '40px',
    marginRight: '0px',
  },
  chip: {
    fontWeight: 800,
    textTransform: 'capitalize',
    backgroundColor: Theme.colorPalette.secondary3,
    color: Theme.colorPalette.onSecondary,
  },
  headerForm: {
    width: 'clamp(300px, 75%, 500px)',
    marginRight: '5px',
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: Theme.colorPalette.surface,
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'primary3.main',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'primary3.main',
      },
    },
  },
  mobileResults: {
    boxShadow: 'none',
    '& .MuiAutocomplete-option': {
      borderBottom: '1px rgba(0, 0, 0, 0.12) solid',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    '& .MuiAutocomplete-listbox': {
      height: 'auto',
      maxHeight: 'calc(100svh - 200px)',
    },
  },
};
