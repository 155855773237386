// @flow
import React, {useEffect, useRef, useState} from 'react';
import type {Node} from 'react';
import {Events, Hooks, Models} from '@wellstone-solutions/common';
import {Box, DataGrid, usePaging} from '@wellstone-solutions/web';
import {ListHeader} from './ListHeader';
import {useStores} from 'hooks/useStores';
import {useStaffColumnMap} from '../../hooks';
import {ALL_PROGRAMS_FILTER} from '../../constants';
import {AccessControl} from 'modules/rbac';

type ParamsType = {
  program_id?: string,
};

const {usePaginatedData} = Hooks;
const {Staff} = Models;
const EVENT_TABLE_NAME = 'StaffList';

const buildParams = (filter: string): ParamsType => {
  const params = {};

  if (filter !== ALL_PROGRAMS_FILTER.value) {
    // filter is set to a program id
    params.program_id = filter;
  }

  return params;
};

export const StaffList = (): Node => {
  const initialLoad = useRef(true);
  const {eventStore, meStore, RBACStore} = useStores();

  const initialFilter = ALL_PROGRAMS_FILTER.value;
  const initialParams = buildParams(initialFilter);

  const [params, setParams] = useState(initialParams);
  const [filter, setFilter] = useState(initialFilter);

  const initialSortField = 'name';
  const initialSortOrder = 'asc';
  const initialState = {
    sorting: {
      sortModel: [{field: initialSortField, sort: initialSortOrder}],
    },
  };

  const {
    offset,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
  } = usePaging({
    initialSortField,
    initialSortOrder,
  });

  const queryResults = usePaginatedData({
    url: Staff.routes.index(meStore.me.membership.organization.id),
    dataTransformer: ({members, total}) => ({
      staff: members.map(Staff.toUI),
      total,
    }),
    pageSize,
    currentPage: offset,
    sortField,
    sortOrder,
  });

  const refetchData = () => {
    if (queryResults.refetch) {
      queryResults.refetch({
        pageSize,
        currentPage: offset,
        params,
        sortField,
        sortOrder,
      });
    }
  };

  useEffect(() => {
    if (!initialLoad.current) {
      refetchData();
    }
    initialLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, currentPage, pageSize, sortField, sortOrder]);

  const columnMap = useStaffColumnMap(refetchData);
  const columns = [columnMap.name, columnMap.role, columnMap.groups];

  // Add EHR column if EHR integration is enabled on org
  if (!!meStore.ehrIntegrationOption) {
    columns.push(columnMap.ehr);
  }

  if (RBACStore.hasAccess(AccessControl.staff.viewListActions)) {
    columns.push(columnMap.actions);
  }
  if (RBACStore.hasAccess(AccessControl.staff.viewListProgress)) {
    columns.push(columnMap.progress);
  }

  const handleFilterChange = (updatedFilter) => {
    setFilter(updatedFilter);
    setParams(buildParams(updatedFilter));
    setCurrentPage(0);
  };

  const handleSortChange = (sortedColumns) => {
    let updatedSortField = '';
    let updatedSortOrder = '';

    if (sortedColumns?.length > 0) {
      const [sortedColumn] = sortedColumns;
      updatedSortField = sortedColumn.field;
      updatedSortOrder = sortedColumn.sort;

      eventStore.addEvent(Events.USER_SORTED_TABLE, {
        sort_field: updatedSortField,
        sort_order: updatedSortOrder,
        table: EVENT_TABLE_NAME,
      });
    }

    setSortField(updatedSortField);
    setSortOrder(updatedSortOrder);
  };

  const handlePageChange = (updatedPage) => {
    setCurrentPage(updatedPage);
  };

  const handlePageSizeChange = (updatedPageSize) => {
    setPageSize(updatedPageSize);
  };

  return (
    <>
      <Box sx={{mb: 2}}>
        <ListHeader
          filter={filter}
          setFilter={handleFilterChange}
          refetch={refetchData}
        />
      </Box>
      <DataGrid
        autoHeight
        getRowHeight={() => 'auto'}
        loading={queryResults.isLoading}
        rows={queryResults.data?.staff ?? []}
        columns={columns}
        noRowsText="No staff"
        page={currentPage}
        pageSize={pageSize}
        paginationMode="server"
        sortingMode="server"
        initialState={initialState}
        rowCount={queryResults.data?.total ?? 0}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSortModelChange={handleSortChange}
        sx={{
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: 1},
        }}
      />
    </>
  );
};
