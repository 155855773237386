// @flow
import React from 'react';
import type {Node} from 'react';

import {
  Box,
  Button,
  Icon,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {AttendingStatusIcon} from '../../../AttendingStatusIcon';
import type {UIParticipantType} from '../../../../../types';

type PropsType = {
  guest: UIParticipantType,
  handleGuestRemoval: (userId: string) => void,
};

export const GuestListItem = ({guest, handleGuestRemoval}: PropsType): Node => {
  // $FlowFixMe
  const {name, email, id: user} = guest;

  return (
    <Button
      fullWidth
      sx={{background: Theme.colorPalette.medium, mt: 1, textTransform: 'none'}}
      onClick={() => {
        handleGuestRemoval(user);
      }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{width: '100%'}}>
        <Stack direction="row">
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              pr: 1,
            }}>
            <AttendingStatusIcon isAttending={guest.isAttending} />
          </Box>
          <Stack>
            <Typography
              color={Theme.colorPalette.darkest}
              sx={{fontWeight: 'bold', textAlign: 'left'}}>
              {name}
            </Typography>
            <Typography color={Theme.colorPalette.dark}>{email}</Typography>
          </Stack>
        </Stack>
        <Icon name={IconNames.MinusSquare} />
      </Stack>
    </Button>
  );
};
