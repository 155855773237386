// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {Box} from '@wellstone-solutions/web';
import {Calendar} from 'components/Calendar';
import {useStores} from 'hooks/useStores';
import moment from 'moment';
import {DailyEvent, MonthlyEvent, WeeklyEvent} from '../Calendar';

type PropsType = {
  startDate: string,
};

export const AdjacentEvents = ({startDate}: PropsType): Node => {
  const [adjacentEvents, setAdjacentEvents] = useState([]);
  const {calendarStore} = useStores();

  useEffect(() => {
    if (startDate) {
      calendarStore.getAdjacentEvents(startDate).then((events) => {
        setAdjacentEvents(events);
      });
    }
  }, [calendarStore, startDate]);

  const viewDate = startDate ? moment(startDate).toDate() : new Date();

  return (
    <Box sx={styles}>
      <Calendar
        components={{
          agenda: {
            event: DailyEvent,
          },
          day: {
            event: DailyEvent,
          },
          month: {
            event: MonthlyEvent,
          },
          week: {
            event: WeeklyEvent,
          },
        }}
        date={viewDate}
        onNavigate={() => {}}
        defaultView="day"
        events={adjacentEvents}
        onSelectEvent={(event) => {}}
      />
    </Box>
  );
};

const styles = {
  flex: 1,
  '.rbc-toolbar > .rbc-btn-group': {display: 'none'},
};
