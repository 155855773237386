// @flow
import React from 'react';
import type {Node} from 'react';
import {AutocompleteSearch} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';

type ValueType = {id: string, name: string} | null;
type PropsType = {
  label: string,
  value: ValueType,
  roles: Array<string>,
  onOptionSelect: (newValue: ValueType) => void,
  testId?: string,
};

export const DirectorySearch = ({
  label,
  value,
  roles,
  onOptionSelect,
  testId = 'directory-search',
}: PropsType): Node => {
  const {organizationStore} = useStores();

  const handleSearch = async (searchTerm) => {
    const response = await organizationStore.search({
      searchTerm,
      queryBy: ['members'],
      statusFilters: ['active'],
    });

    if (response.isSuccess && response.data.members) {
      return response.data.members
        .filter(({role}) => roles.includes(role))
        .map(({id, name}) => ({
          id,
          name,
        }));
    } else {
      return [];
    }
  };

  return (
    <AutocompleteSearch
      label={label}
      value={value}
      optionLabelKey="name"
      optionValueKey="id"
      onOptionSelect={onOptionSelect}
      onSearch={handleSearch}
      testId={testId}
    />
  );
};
