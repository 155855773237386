// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {Box, Stack, Typography} from '@wellstone-solutions/web';
import {
  Events,
  Hooks,
  Models,
  Theme,
  Transforms,
} from '@wellstone-solutions/common';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {useStores} from 'hooks/useStores';
import {NAVIGATION_NAMES} from 'constants/Navigation';

import {PfButton} from 'components/shared/PfButton';
import {Form} from './Form';
import {AdjacentEvents} from './AdjacentEvents';
import {STATUSES} from '../../constants';

type PropsType = {};

const FORM_ID = 'new_form';

export const New = (props: PropsType): Node => {
  const {appUIStore, eventStore, meStore, calendarStore} = useStores();
  const navigate = useNavigate();

  const {CalendarEvent} = Models;

  const {useForm} = Hooks;

  // $FlowFixMe
  const timezone = moment.tz.guess(true);

  const defaultStart = moment();

  const calendarEvent = CalendarEvent.Factory({
    startDate: defaultStart.toISOString(),
    startTime: defaultStart.format('HH:00'),
    endTime: defaultStart.add(1, 'hours').format('HH:00'),
    timezone,
    status: STATUSES.created,
    owner: meStore.me.id,
  });

  const form = useForm({
    initialValues: {
      ...calendarEvent,
      invitations: [],
    },
    schema: Models.CalendarEvent.schema,
    onSubmit: async (values, {resetForm}) => {
      const response = await calendarStore.createCalendarEvent({
        ...calendarEvent,
        ...values,
      });

      if (response.isSuccess) {
        const originalStart = response.data.original_start;
        const eventId = response.data.id;

        eventStore.addEvent(Events.CREATED_NEW_EVENT, {
          calendar_event_id: eventId,
          has_note: (calendarEvent.data.notes || '').length > 0,
          has_link: (calendarEvent.data.link || '').length > 0,
          has_address:
            Object.values(calendarEvent.data.location || {}).filter(Boolean)
              .length > 0,
          original_start: originalStart,
        });

        const params = {
          originalStart,
          applyToAll: form.values.isRecurring,
        };

        if (response.data.invitations.length > 0) {
          // should get handled in first addEvent call
          eventStore.addEvent(Events.INVITED_PARTICIPANTS, {
            calendar_event_id: eventId,
            count: form.values.invitations.length,
            ...Transforms.toApiObject(params),
          });
        } else {
          // invitations should always include the owner invite so if its missing, something is wrong.
          showAlert(
            'Some guests may not have been invited, please check your guest list and try again.',
            ALERT_TYPES.ERROR,
          );
        }

        resetForm();
        form.setFieldValue('startDate', null);
        await calendarStore.getAllCalendarEvents();

        showAlert('Successfully created new event.');

        navigate('/calendar');
      } else {
        showAlert('Could not create event at this time.', ALERT_TYPES.ERROR);
      }
    },
  });

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: NAVIGATION_NAMES.calendar,
        url: '/calendar',
      },
      {
        name: NAVIGATION_NAMES.newEvent,
        url: '/calendar/calendar-event/new',
        active: true,
      },
    ]);
  }, [appUIStore]);

  return (
    <Box>
      <Typography variant="h5" sx={{color: Theme.colorPalette.primary, mb: 2}}>
        New Event
      </Typography>
      <Stack direction={{xs: 'column', md: 'row'}}>
        <Stack
          flex={1}
          direction="column"
          justifyContent={{md: 'space-between'}}
          sx={{mb: 4, pr: 8}}>
          <Box sx={{flex: 1}}>
            <Form form={form} formId={FORM_ID} />
          </Box>
          <Box sx={{flex: 1, mt: 4}}>
            <PfButton
              isDisabled={!form.isValid || !form.dirty || form.isSubmitting}
              form={FORM_ID}
              color="primary"
              type="submit"
              onClick={() => {}}>
              Create Event
            </PfButton>
          </Box>
        </Stack>
        <AdjacentEvents startDate={form.values.startDate} />
      </Stack>
    </Box>
  );
};
