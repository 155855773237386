// @flow
import {Factories} from '@wellstone-solutions/common';
import type {FactoryCategoryType, UICategoryType} from '../../types';

export const Factory = (overrides?: FactoryCategoryType): UICategoryType =>
  Factories.createFactory(
    {
      id: '',
      name: '',
      questions: [],
    },
    overrides,
  );
