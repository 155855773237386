// @flow
import React from 'react';
import type {Node} from 'react';
import {OutlinedInput} from '@mui/material';
import {Select} from '@wellstone-solutions/web';
import {Modules} from '@wellstone-solutions/common';
import type {UIRecurrenceType} from '@wellstone-solutions/common';

type PropsType = {
  onChange: (UIRecurrenceType) => void,
  recurrence: UIRecurrenceType,
  startDate: string,
};

export const DaysOfMonth = ({
  recurrence,
  startDate,
  onChange,
}: PropsType): Node => {
  const {useMonthlySelect} = Modules.Schedule;
  const {items, valueKey, valueMap} = useMonthlySelect({
    recurrence,
    startDate,
  });

  return (
    <Select
      data-testid="days-of-month"
      canUnselect={false}
      input={<OutlinedInput notched={false} />}
      items={items}
      value={valueKey}
      onChange={(e) => {
        onChange({
          ...recurrence,
          ...valueMap[e.target.value],
        });
      }}
    />
  );
};
