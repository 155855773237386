// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {Events} from '@wellstone-solutions/common';
import {PfButton} from 'components/shared/PfButton';
import {useStores} from 'hooks/useStores';
import {showAlert, ALERT_TYPES} from 'utils/showAlert';
import {createBlankDocument, getDocumentUrl} from '../utils';

type PropTypes = {
  memberId: string,
};

export const CreateSessionDocumentButton = ({memberId}: PropTypes): Node => {
  const {sessionDocumentStore, meStore, eventStore} = useStores();
  const navigate = useNavigate();
  const [creatingDocument, setCreatingDocument] = useState(false);

  const createDoc = async () => {
    setCreatingDocument(true);

    const blankDocument = createBlankDocument({
      memberId,
      staffId: meStore.activeMembership.id,
    });

    const response = await sessionDocumentStore.createSessionDoc(blankDocument);
    if (response.isSuccess) {
      eventStore.addEvent(Events.CREATED_SESSION_DOC, {
        session_document_id: response.data.id,
      });
      navigate(getDocumentUrl(memberId, response.data.id));
      return;
    } else {
      showAlert(
        'Failed to save the document. Please try again.',
        ALERT_TYPES.ERROR,
      );
    }
    setCreatingDocument(false);
  };

  return (
    <PfButton
      color="primary"
      outlined
      loading={creatingDocument}
      onClick={createDoc}>
      Create Documentation
    </PfButton>
  );
};
