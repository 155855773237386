// React
import React, {useState} from 'react';
import {arrayOf, shape, date, string, number} from 'prop-types';

// Bulma
import BDropdown from 'react-bulma-components/lib/components/dropdown';
import Level from 'react-bulma-components/lib/components/level';

// WS
import {useStores} from 'hooks/useStores';
import Dropdown from 'components/tutorial/dropdown';
import {Palette} from 'palette';
import {HabitsScatterChart} from './HabitsScatterChart';

export const HabitsChart = (props) => {
  const {habitStore} = useStores();
  const [selectedFilterName, setSelectedFilterName] = useState('None');

  const filters = [
    {name: 'None', type: null, key: null},
    ...habitStore.habitActivityFilters,
  ];

  const _getActiveFilter = () =>
    filters.find((filter) => filter.name === selectedFilterName);

  const _showActiveFilter = () => {
    const activeFilter = _getActiveFilter();
    return selectedFilterName === 'None'
      ? ''
      : `Filter by ${activeFilter.name}`;
  };

  const _handleFilterChange = (name) => {
    setSelectedFilterName(name);
  };

  if (!props.habitData) {
    return null;
  }

  return (
    <div style={styles.container}>
      <Level style={styles.filterContainer}>
        <Level.Side>
          <Level.Item>
            <div>{_showActiveFilter()}</div>
          </Level.Item>
        </Level.Side>
        <Level.Side>
          <Level.Item>
            <span style={styles.viewBy}>Filter</span>
            <Dropdown
              value={selectedFilterName}
              onChange={_handleFilterChange}
              className="right-drop"
              id="habits-filter-button">
              {filters.map((filter) => (
                <BDropdown.Item key={filter.key} value={filter.name}>
                  {filter.name}
                </BDropdown.Item>
              ))}
            </Dropdown>
          </Level.Item>
        </Level.Side>
      </Level>
      <HabitsScatterChart
        size={{height: 400, width: '95%'}}
        filterData={_getActiveFilter()}
        scatterData={props.habitData}
        x={{key: props.aggregator, type: 'category'}}
        y={{key: 'index', type: 'number'}}
      />
    </div>
  );
};

const styles = {
  container: {
    minHeight: '355px',
  },
  filterContainer: {
    marginBottom: 0,
  },
  viewBy: {
    marginRight: 10,
    fontWeight: 'bold',
    color: Palette.INFO,
  },
};

HabitsChart.propTypes = {
  habitData: arrayOf(
    shape({
      name: string.isRequired,
      data: arrayOf(
        shape({
          date: date,
          index: number.isRequired,
          value: number.isRequired,
          value_userlist: arrayOf(string),
        }),
      ).isRequired,
    }),
  ).isRequired,
  aggregator: string.isRequired,
};
