// @flow
import React, {useState, useCallback} from 'react';
import type {Node} from 'react';
// TODO replace with import from common libs when available
import InputAdornment from '@mui/material/InputAdornment';
import {
  Box,
  TextField,
  Icon,
  IconButton,
  IconNames,
} from '@wellstone-solutions/web';
import {Utils} from '@wellstone-solutions/common';

type PropsType = {
  onSearch: (search: string) => Promise<void>,
};

const WAIT = 400;
const MIN_LENGTH = 3;
const DEFAULT_VALUE = '';

export const SearchInput = ({onSearch}: PropsType): Node => {
  const [value, setValue] = useState(DEFAULT_VALUE);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    Utils.debouncePromise(onSearch, WAIT),
    [],
  );

  const handleChange = (event) => {
    const {value} = event.target;
    setValue(value);

    if (value.length === 0 || value.length >= MIN_LENGTH) {
      debouncedSearch(value);
    }
  };

  const handleClear = () => {
    setValue(DEFAULT_VALUE);
    // Don't debounce on clear to avoid delay before refreshing the list
    onSearch(DEFAULT_VALUE);
  };

  return (
    <Box py={1}>
      <TextField
        value={value}
        onChange={handleChange}
        placeholder="Search for members or staff"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon name={IconNames.Search} />
            </InputAdornment>
          ),
          endAdornment: value.length > 0 && (
            <InputAdornment position="end">
              <IconButton icon={IconNames.Xmark} onClick={handleClear} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
