// @flow
import {IconNames} from '@wellstone-solutions/web';

export const MAX_FILE_SIZE_ERROR =
  'Cover Image is required and the file must be smaller than 2.5MB';

export const MAX_DESC_ERROR =
  'Story content is required and cannot be greater than 2.5MB';

export const TOOLTIP_PREVIEW =
  'This is how the story summary will appear in the story list screen in Companion';

export const TOOLTIP_FULLSTORY =
  'This is how the story will appear on the full story detail screen in Companion';

export const STATUSES = {
  draft: 'pending',
  published: 'approved',
  removed: 'deleted',
};

export const STATUS_MAP = {
  pending: 'Draft',
  approved: 'Published',
  deleted: 'Removed',
};

export const STORY_TYPE_ICONS = {
  video: IconNames.VideoCamera,
  youtube: IconNames.YouTube,
  audio: IconNames.Headset,
  default: IconNames.Page,
};

export const TAG_CHIP_COLOR = {
  a: '#bb4a15',
  b: '#1cabc0',
  c: '#8ca915',
  d: '#1bad6b',
  e: '#bba200',
  f: '#c49e01',
  g: '#67b22d',
  h: '#7ba227',
  i: '#8d3030',
  j: '#7b5b85',
  k: '#a9ad00',
  l: '#565670',
  m: '#3f8667',
  n: '#838a22',
  o: '#3b792f',
  p: '#c0a107',
  q: '#988a21',
  r: '#ab334f',
  s: '#23a49a',
  t: '#4d9b8e',
  u: '#608830',
  v: '#32938a',
  w: '#34884a',
  x: '#bda201',
  y: '#887f2e',
  z: '#2c8c52',
};
