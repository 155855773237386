// @flow
import React from 'react';
import type {Node} from 'react';
import {Divider, Stack} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {Header} from './Header';
import {Content} from './Content';

type PropsType = {
  abstract?: string,
  host?: string,
  organization?: string,
  file?: mixed,
  markdownText?: string,
  mediaImage?: Blob | null,
  posterImageUrl?: string,
  title?: string,
};

export const Abstract = ({
  abstract,
  posterImageUrl = '',
  file = '',
  host = '',
  organization = '',
  title = '',
  mediaImage,
  markdownText,
}: PropsType): Node => {
  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      sx={styles.container}>
      <Stack flexDirection="column">
        <Header
          title={title}
          host={host}
          organization={organization}
          mediaImage={mediaImage}
          posterImageUrl={posterImageUrl}
          preview={true}
        />
        <Divider sx={styles.divider} />
        <Content abstract={abstract} />
      </Stack>
    </Stack>
  );
};

const styles = {
  container: {
    borderRadius: 5,
    color: Theme.colorPalette.darkest,
    width: 300,
    borderBottom: `1px solid ${Theme.colorPalette.mediumLight}`,
    margin: 0,
    boxShadow: `0px 3px 5px ${Theme.colorPalette.medium}`,
    overflow: 'auto',
  },
  divider: {
    marginTop: 2,
  },
};
