// @flow
import React from 'react';
import type {Node} from 'react';
import {HabitSchedule} from 'modules/habits';
import {HabitScheduleV2} from 'modules/habits/v2/components';
import {hasFlag, HABITS_V2} from '../../../../constants/FeatureFlags';
import {useStores} from 'hooks/useStores';

type PropsType = {};

export const HabitScheduleTabContent = ({}: PropsType): Node => {
  const {meStore} = useStores();
  const hasHabitsV2 = hasFlag(HABITS_V2, meStore.features);

  return hasHabitsV2 ? <HabitScheduleV2 /> : <HabitSchedule />;
};
