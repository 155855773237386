// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import type {
  UIMemberIntegrationType,
  UIIntegrationType,
} from '@wellstone-solutions/common';
import {CompletedRow} from '../components/CompletedRow';

type PropsType = {
  orgIntegration: UIIntegrationType,
  integrations: Array<UIMemberIntegrationType>,
};

export const EHRStatusView = ({
  orgIntegration,
  integrations,
}: PropsType): Node => {
  const ehrName = orgIntegration?.name;

  const integrationId =
    integrations && integrations.length > 0
      ? integrations[0].externalRelationshipId
      : null;
  return (
    <>
      {integrationId ? (
        <CompletedRow label={`${ehrName} ID`} value={integrationId} />
      ) : (
        <CompletedRow
          label={`${ehrName} ID`}
          value={'Not connected'}
          valueSX={{color: Theme.colorPalette.red, fontWeight: 'bold'}}
        />
      )}
    </>
  );
};
