// @flow
import React from 'react';
import type {Node} from 'react';
import {Box, Icon, IconNames} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  isAttending?: boolean | null,
};

export const AttendingStatusIcon = ({isAttending}: PropsType): Node => {
  return (
    <Box sx={styles.container}>
      {isAttending === true ? (
        <Icon name={IconNames.Check} color={Theme.colorPalette.green} />
      ) : isAttending === false ? (
        <Icon name={IconNames.Xmark} color={Theme.colorPalette.red} />
      ) : (
        <></>
      )}
    </Box>
  );
};

const styles = {
  container: {
    width: 24,
    height: 24,
  },
};
