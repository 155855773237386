// @flow
import React from 'react';
import type {Node} from 'react';
import {Typography} from '@wellstone-solutions/web';
import {pluralize} from 'utils/Utils';

type PropsType = {
  totalContacts: number,
};

export const OtherContactsLabel = ({totalContacts}: PropsType): Node => {
  const remainingCount = totalContacts - 1;
  return (
    <Typography
      variant="body2"
      sx={{textAlign: 'center', fontStyle: 'italic', pt: 1}}>
      {remainingCount} other {pluralize('contact', remainingCount)} available
    </Typography>
  );
};
