// @flow
import React from 'react';
import type {Node} from 'react';

import {
  FormControlLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
} from '@mui/material';

import {Theme} from '@wellstone-solutions/common';
import type {FormType} from '@wellstone-solutions/common';
import {ErrorMessage, Typography} from '@wellstone-solutions/web';
import type {UISessionDocumentType} from 'modules/documentation/model/types';

type PropsType = {
  form: FormType<UISessionDocumentType>,
  hasError: boolean,
};

export const MemberAttendRadio = ({
  form,
  hasError = false,
}: PropsType): Node => (
  <>
    <Typography
      variant="subtitle1"
      sx={{mt: 1, color: Theme.colorPalette.darkest}}>
      Did the Member attend?
    </Typography>
    <MUIRadioGroup
      sx={{
        paddingLeft: Theme.spacing.micro,
        maxHeight: '20rem',
        overflowY: 'auto',
        flexWrap: 'nowrap',
        flexDirection: {xs: 'column', md: 'row'},
        gap: `0 ${Theme.spacing.mini}`,
      }}
      aria-label="did the member attend?"
      name="didMemberAttend"
      value={form.values.didMemberAttend}
      onChange={form.handleFieldChange('didMemberAttend')}
      data-error={hasError ? true : null}>
      <FormControlLabel
        key={'yes'}
        value={true}
        control={<Radio />}
        label="Yes"
      />
      <FormControlLabel
        key={'no'}
        value={false}
        control={<Radio />}
        label="No"
      />
    </MUIRadioGroup>
    <ErrorMessage
      name="didMemberAttend"
      errors={form.errors}
      touched={form.touched}
    />
  </>
);
