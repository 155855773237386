// @flow
import {Api} from '@wellstone-solutions/common';
import {routes} from 'api';
import type {APICategoryType} from '../../types';

export const getAll = async (): Promise<APICategoryType[]> => {
  const response = await Api.Instance.current().get(
    routes.resources.categories,
  );

  return response.isSuccess ? response.data.groups : [];
};
