// @flow
import React from 'react';
import type {Node} from 'react';
import {
  Box,
  Button,
  Icon,
  IconNames,
  Progress,
  ProgressTypes,
  Stack,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  isSubmitting?: boolean,
  isFirstStep?: boolean,
  isLastStep?: boolean,
  isComplete: boolean,
  disabled?: boolean,
  handleBack: () => void,
  onNext: () => Promise<any> | void,
  nextText?: string,
};

export const WizardFooter = ({
  isSubmitting = false,
  isFirstStep = false,
  isLastStep = false,
  isComplete,
  disabled = false,
  handleBack,
  onNext,
  nextText,
}: PropsType): Node => {
  return (
    <footer
      style={{...styles.wizardFooter, display: disabled ? 'none' : 'flex'}}
      data-testid="wizardFooter">
      <Stack direction="row">
        {!isFirstStep && (
          <Button
            onClick={handleBack}
            disabled={isSubmitting}
            startIcon={
              <Icon
                name={IconNames.NavArrowLeft}
                strokeWidth={2}
                color={Theme.colorPalette.primary}
                size={16}
              />
            }
            sx={styles.actionButton}
            data-testid="wizardBackButton">
            Back
          </Button>
        )}
        <Button
          variant="contained"
          disableRipple
          onClick={onNext}
          disabled={!isComplete || isSubmitting}
          endIcon={
            !isFirstStep &&
            !isLastStep && (
              <Icon
                name={IconNames.NavArrowRight}
                strokeWidth={2}
                color={
                  isComplete
                    ? Theme.colorPalette.lightest
                    : Theme.colorPalette.mediumDark
                }
                size={16}
              />
            )
          }
          sx={{...styles.actionButton, marginLeft: 2}}
          data-testid="wizardNextButton">
          {isSubmitting ? (
            <Box
              sx={{
                width: 33,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Progress
                color="primary"
                progressType={ProgressTypes.circular}
                size={20}
              />
            </Box>
          ) : (
            nextText || 'Next'
          )}
        </Button>
      </Stack>
    </footer>
  );
};

const styles = {
  wizardFooter: {
    marginTop: 20,
  },
  actionButton: {
    textTransform: 'capitalize',
    borderRadius: 20,
    fontWeight: 'bold',
  },
};
