// @flow
import {Regex} from '@wellstone-solutions/common';

export const MESSAGE_PART_TYPES = {
  text: 'text',
  link: 'link',
};

const _lastIsNewLine = (parts) => parts[parts.length - 1] === '\n';

export const breakMessageIntoTypedParts = (
  msg: string,
): Array<{msg: string, type: string}> | Array<null> => {
  let strParts = [];
  const formattedParts = msg
    .split('\n')
    .join(' \n ') // essentially a replaceAll
    .split(' ')
    .map((str) => {
      // make sure its not an email before verifying its a url
      if (
        !Regex.isFullMatch(str, 'email') &&
        Regex.isFullMatch(str, 'url', 'gim')
      ) {
        const link = {
          msg: _lastIsNewLine(strParts) ? str + ' ' : ' ' + str + ' ',
          type: MESSAGE_PART_TYPES.link,
        };

        const msgParts =
          strParts.length > 0
            ? [
                {
                  msg: strParts.join(' ').split(' \n ').join('\n'),
                  type: MESSAGE_PART_TYPES.text,
                },
                link,
              ] // includes text that came before the link
            : link;

        strParts = []; // clear out strParts since theyve been formatted
        return msgParts;
      }
      strParts.push(str);
    })
    .flat()
    .filter(Boolean);

  if (strParts.length > 0) {
    // $FlowFixMe
    formattedParts.push({
      msg: strParts.join(' ').split(' \n ').join('\n'),
      type: MESSAGE_PART_TYPES.text,
    });
  }

  // $FlowFixMe
  return formattedParts;
};
