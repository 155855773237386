// @flow
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

type PropTypes = {
  preventScrollState: string,
};

export const useScrollToTop = ({preventScrollState}: PropTypes) => {
  const {pathname, state} = useLocation();
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    if (preventScrollState && state === preventScrollState) {
      return;
    }
    // Doesnt work without the timeout.
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }, [pathname, state, preventScrollState]);
};
