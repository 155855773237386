// @flow
import {Factories} from '@wellstone-solutions/common';
import type {
  FactoryResourceQuestionType,
  UIResourceQuestionType,
} from '../../types';

export const Factory = (
  overrides?: FactoryResourceQuestionType,
): UIResourceQuestionType =>
  Factories.createFactory(
    {
      id: '',
      title: '',
      categories: [],
      phases: [],
      modified: '',
      answers: [],
    },
    overrides,
  );
