// @flow
import React, {useEffect, useRef} from 'react';
import type {Node} from 'react';
import type {GridColDef} from '@mui/x-data-grid';
import type {OptimisticRowType} from '../../types';
import {Transforms, Hooks} from '@wellstone-solutions/common';
import {DataGrid, Paper, usePaging, Typography} from '@wellstone-solutions/web';
import {pluralize} from 'utils/Utils';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_ORDER,
  DEFAULT_NO_ROWS_TEXT,
  DEFAULT_DENSITY,
  DEFAULT_VARIANT,
  DEFAULT_ELEVATION,
} from '../../constants';

type PropsType = {
  columns: Array<GridColDef<any>>,
  dataTransformer: (result: any) => {rows: Array<any>, total: number},
  initialSortField: string,
  url: string,
  params: {[string]: mixed},
  initialPageSize?: number,
  initialSortOrder?: string,
  isLimitOffset?: boolean,
  noRowsText?: string,
  onSortChange?: (updatedSortField: string, updatedSortOrder: string) => void,
  density?: string,
  variant?: string,
  optimisticRows?: Array<OptimisticRowType>,
  clearOptimisticRows?: () => void,
  elevation?: number,
  label?: string,
  ...
};

const {usePaginatedData} = Hooks;
const {toApiObject} = Transforms;

export const PagedDataGrid = ({
  columns,
  dataTransformer,
  initialSortField,
  url,
  params,
  initialPageSize = DEFAULT_PAGE_SIZE,
  initialSortOrder = DEFAULT_SORT_ORDER,
  isLimitOffset = false,
  noRowsText = DEFAULT_NO_ROWS_TEXT,
  onSortChange,
  density = DEFAULT_DENSITY,
  variant = DEFAULT_VARIANT,
  optimisticRows = [],
  clearOptimisticRows,
  elevation = DEFAULT_ELEVATION,
  label,
  ...rest
}: PropsType): Node => {
  const initialLoad = useRef(true);
  const initialState = {
    sorting: {
      sortModel: [
        {
          field: initialSortField,
          sort: initialSortOrder,
        },
      ],
    },
  };

  const {
    offset,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
  } = usePaging({
    initialSortField,
    initialSortOrder,
    initialPageSize,
  });

  // Support old APIs that use limit/offset pagination instead of page number
  // The API expects the page number to be 1-based but the datr grid uses 0-based
  const apiCurrentPage = isLimitOffset ? offset : currentPage + 1;

  const queryResults = usePaginatedData({
    url,
    params: toApiObject(params),
    dataTransformer,
    pageSize,
    currentPage: apiCurrentPage,
    sortField,
    sortOrder,
    isLimitOffset,
  });

  const refetchData = () => {
    if (queryResults.refetch) {
      // $FlowIgnore
      queryResults.refetch({
        pageSize,
        params: toApiObject(params),
        currentPage: apiCurrentPage,
        sortField,
        sortOrder,
      });
    }

    if (clearOptimisticRows) {
      clearOptimisticRows();
    }
  };

  // If the params change, we need to reset the page number to 0
  useEffect(() => {
    // Resetting the page number will trigger a refetch
    if (!initialLoad.current && currentPage > 0) {
      setCurrentPage(0);
    } else if (!initialLoad.current) {
      refetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!initialLoad.current) {
      refetchData();
    }
    initialLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sortField, sortOrder]);

  const handleSortChange = (sortedColumns) => {
    let updatedSortField = '';
    let updatedSortOrder = '';

    if (sortedColumns?.length > 0) {
      const [sortedColumn] = sortedColumns;
      updatedSortField = sortedColumn.field;
      updatedSortOrder = sortedColumn.sort;

      if (onSortChange) {
        onSortChange(updatedSortField, updatedSortOrder);
      }
    }

    setSortField(updatedSortField);
    setSortOrder(updatedSortOrder);
    setCurrentPage(0);
  };

  const handlePageChange = (updatedPage) => {
    setCurrentPage(updatedPage);
  };

  const handlePageSizeChange = (updatedPageSize) => {
    setPageSize(updatedPageSize);
  };

  // Instead of refetching data for the entire datagrid to capture the updated
  // we are optimistically updating rows so the user sees instant changes
  const optimisticallyUpdateRow = (row: any) => {
    if (optimisticRows?.length > 0) {
      const rowToUpdate = optimisticRows.find(
        ({id, idField = 'id'}) => id === row[idField],
      );
      if (rowToUpdate) {
        return rowToUpdate.newData;
      }
    }
    return row;
  };

  const resultCount = queryResults.data?.total ?? 0;

  return (
    <>
      {label && (
        <Typography variant="body" sx={{mb: 1}} data-testid="record-count">
          Showing {resultCount} {pluralize(label, resultCount)}
        </Typography>
      )}
      <Paper elevation={elevation}>
        <DataGrid
          {...rest}
          autoHeight
          loading={queryResults.isLoading}
          rows={
            queryResults.data?.rows
              .map(optimisticallyUpdateRow)
              .filter(Boolean) ?? []
          }
          columns={columns}
          noRowsText={noRowsText}
          page={currentPage}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          initialState={initialState}
          rowCount={resultCount}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onSortModelChange={handleSortChange}
          variant={variant}
          density={density}
        />
      </Paper>
    </>
  );
};
