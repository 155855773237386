// @flow
import React from 'react';
import type {Node} from 'react';
import {SuspendedComponent} from '@wellstone-solutions/web';
import type {
  UIResourceQuestionType,
  UIResourceAnswerType,
} from '@wellstone-solutions/common';
import {QuestionAccordion} from '../QuestionAccordion';
import {LoadingIndicator} from 'components/shared/LoadingIndicator';
import {useDataLoader} from '../../dataloaders/questionsDataLoader';

export const SuspendedQuestions = ({
  categoryId,
  shareResource,
  isModal,
}: {
  categoryId: string,
  shareResource: (
    resource: UIResourceQuestionType | UIResourceAnswerType,
    action?: string,
  ) => void,
  isModal: boolean,
}): Node => {
  // `key` attribute forces remount of suspended component on prop change
  return (
    <SuspendedComponent
      loaderComponent={<LoadingIndicator />}
      useDataLoader={useDataLoader}
      dataLoaderArgs={{categoryId}}
      keyProp={categoryId}>
      <QuestionAccordion
        shareResource={shareResource}
        categoryId={categoryId}
        isModal={isModal}
      />
    </SuspendedComponent>
  );
};
