import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import {Events} from '@wellstone-solutions/common';
import {Icon, IconNames} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import PfLoader from 'components/shared/PfLoader';
import {PastAnnouncement} from './PastAnnouncement';
import styles from './PastAnnouncements.module.scss';

type PropsType = {
  groupId?: string,
};

export const PastAnnouncements = observer(({groupId}: PropsType) => {
  const {announcementStore, eventStore, meStore} = useStores();
  const {pastAnnouncements} = announcementStore;
  const organizationId = meStore.me.membership.organization.id;

  useEffect(() => {
    announcementStore.syncPastAnnouncements({
      organizationId,
      groupId,
    });

    eventStore.addEvent(Events.USER_VIEWED_ANNOUNCEMENTS, {
      group_id: groupId,
    });
  }, [announcementStore, eventStore, organizationId, groupId]);

  const currentUserId = meStore.me.id;
  const handleRemove = async (announcementId) => {
    await announcementStore.deleteAnnouncement({
      announcementId,
      organizationId,
    });

    announcementStore.syncPastAnnouncements({
      organizationId,
      groupId,
    });
  };

  return (
    <Box className={styles.container}>
      <Heading size={5} className={styles.header}>
        <Icon className={styles.icon} name={IconNames.Calendar} />
        Past Announcements
      </Heading>
      <div className={styles.content}>
        {!pastAnnouncements ? (
          <PfLoader isCentered={true} />
        ) : (
          pastAnnouncements.map((pastAnnouncement) => (
            <PastAnnouncement
              key={pastAnnouncement.id}
              announcement={pastAnnouncement}
              currentUserId={currentUserId}
              onRemove={handleRemove}
            />
          ))
        )}
        {pastAnnouncements && !pastAnnouncements.length ? (
          <div className={styles.empty}>No announcements</div>
        ) : null}
      </div>
    </Box>
  );
});
