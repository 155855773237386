// @flow

export const getDirectionsUrl = (fullAddress: string): string => {
  const query = encodeURIComponent(fullAddress);

  return `https://www.google.com/maps/dir/?api=1&destination=${query}`;
};

export const buildCityStateZip = (
  location: {
    address1?: string,
    address2?: string,
    city?: string,
    state?: string,
    zip?: string,
  },
  includeAddress: boolean,
): string => {
  const parts = [];

  if (includeAddress) {
    if (location.address1) {
      parts.push(location.address1);
    }

    if (location.address2) {
      parts.push(location.address2);
    }
  }

  if (location.city) {
    parts.push(`${location.city},`);
  }

  if (location.state) {
    parts.push(location.state);
  }

  if (location.zip) {
    parts.push(location.zip);
  }

  return parts.join(' ');
};
