// @flow
import {Factories} from '@wellstone-solutions/common';
import type {UIContactType} from '../../types';

export const Factory = (overrides?: UIContactType): UIContactType =>
  Factories.createFactory(
    {
      title: '',
      website: '',
      websiteLabel: '',
      phoneNumber: '',
      phoneNumberLabel: '',
      email: '',
      emailLabel: '',
      dateTimeInformation: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    },
    overrides,
  );
