// @flow
import {action, decorate, observable, runInAction} from 'mobx';
import type {IObservableArray} from 'mobx';
import RootStore from 'mobx/RootStore';
import {SessionDocument} from './model';
import {Models} from '@wellstone-solutions/common';
import type {ApiResponseType} from '@wellstone-solutions/common';
import type {
  UISessionDocumentType,
  ApiSessionDocumentInputType,
  ApiSessionDocumentType,
  UISessionServiceType,
  ApiSessionServiceType,
  SessionDocumentStatus,
  ApiPDFResponseType,
} from 'modules/documentation/model/types';

const {Integration} = Models;

export class SessionDocumentStore {
  rootStore: RootStore;
  serviceTypes: IObservableArray<UISessionServiceType> = observable.array();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  async createSessionDoc(
    sessionDoc: UISessionDocumentType,
  ): Promise<ApiResponseType<UISessionDocumentType>> {
    // $FlowIgnoreMe
    const org = this.rootStore.stores.meStore.organizationId;

    const payload: ApiSessionDocumentInputType = SessionDocument.toInputApi(
      sessionDoc,
    );

    const response: ApiResponseType<ApiSessionDocumentType> = await SessionDocument.create(
      org,
      payload,
    );

    const uiTransformedResponse = this._transformResponse(response);
    return uiTransformedResponse;
  }

  async updateSessionDoc(
    id: string,
    sessionDoc: UISessionDocumentType,
  ): Promise<ApiResponseType<UISessionDocumentType>> {
    // $FlowIgnoreMe
    const org = this.rootStore.stores.meStore.organizationId;

    const payload: ApiSessionDocumentInputType = SessionDocument.toInputApi(
      sessionDoc,
    );

    const response: ApiResponseType<ApiSessionDocumentType> = await SessionDocument.update(
      org,
      id,
      payload,
    );

    const uiTransformedResponse = this._transformResponse(response);
    return uiTransformedResponse;
  }

  async updateSessionDocStatus(
    id: string,
    sessionDoc: UISessionDocumentType,
    status: SessionDocumentStatus,
  ): Promise<ApiResponseType<UISessionDocumentType>> {
    // $FlowIgnoreMe
    const org = this.rootStore.stores.meStore.organizationId;

    const payload: ApiSessionDocumentInputType = SessionDocument.toInputApi({
      ...sessionDoc,
      status,
    });

    const response: ApiResponseType<ApiSessionDocumentType> = await SessionDocument.updateFields(
      org,
      id,
      payload,
    );

    return this._transformResponse(response);
  }

  async getSessionDoc(
    orgId: string,
    id: string,
  ): Promise<ApiResponseType<UISessionDocumentType>> {
    const response = await SessionDocument.get(orgId, id);

    const uiTransformedResponse = this._transformResponse(response);
    return uiTransformedResponse;
  }

  async createExternalEHRDocument(
    id: string,
  ): Promise<ApiResponseType<UISessionDocumentType>> {
    // $FlowIgnoreMe
    const org = this.rootStore.stores.meStore.organizationId;
    const response = await Integration.create(org, id);
    const uiSessionDocument = SessionDocument.toUI(response.data);

    return {...response, data: uiSessionDocument};
  }

  async updateExternalEHRDocument(
    id: string,
  ): Promise<ApiResponseType<UISessionDocumentType>> {
    // $FlowIgnoreMe
    const org = this.rootStore.stores.meStore.organizationId;
    const response = await Integration.update(org, id);

    const uiSessionDocument = SessionDocument.toUI(response.data);

    return {...response, data: uiSessionDocument};
  }

  _transformResponse(
    response: ApiResponseType<ApiSessionDocumentType>,
  ): ApiResponseType<UISessionDocumentType> {
    if (!response.isSuccess) {
      // $FlowIgnoreMe
      return response;
    }

    const uiSessionDocument: UISessionDocumentType = SessionDocument.toUI(
      response.data,
    );

    return {
      ...response,
      data: uiSessionDocument,
    };
  }

  async downloadPDF(id: string): Promise<ApiResponseType<ApiPDFResponseType>> {
    // $FlowIgnoreMe
    const org = this.rootStore.stores.meStore.organizationId;
    const response = await SessionDocument.getPDF(org, id);

    if (response.isSuccess) {
      // response contains Base64 encoded string for the file
      const url = window.URL.createObjectURL(
        new Blob([atob(response.data.file)], {type: 'application/pdf'}),
      );

      // dynamically add link, define attrs and click it
      const link = document.createElement('a');
      link.href = url;
      link.download = response.data.filename;
      document.body?.appendChild(link);
      link.click();

      // remove the link
      link.parentNode?.removeChild(link);
    }

    return response;
  }

  async getServiceTypes(
    orgId: string,
  ): Promise<ApiResponseType<Array<UISessionServiceType>>> {
    if (this.serviceTypes.length > 0) {
      // $FlowIgnoreMe
      return Promise.resolve({
        isSuccess: true,
        data: Array.from(this.serviceTypes),
      });
    }

    const response: ApiResponseType<
      Array<ApiSessionServiceType>,
    > = await SessionDocument.getServiceTypes(orgId);

    if (!response.isSuccess) {
      // $FlowIgnoreMe
      return response;
    }

    const apiServiceTypes = response.data;

    const uiServiceTypes = SessionDocument.serviceTypesToUIList(
      apiServiceTypes,
    );

    runInAction(() => {
      this.serviceTypes.replace(uiServiceTypes);
    });

    // format for UI
    return {
      ...response,
      data: uiServiceTypes,
    };
  }

  async removeSessionDoc(id: string): Promise<ApiResponseType<{}>> {
    // $FlowIgnoreMe
    const org = this.rootStore.stores.meStore.organizationId;
    const response = await SessionDocument.remove(org, id);

    return response;
  }
}

decorate(SessionDocumentStore, {getServiceTypes: action});
