// @flow
import {useCallback, useEffect, useState} from 'react';
import type {UIMemberBasicType} from '@wellstone-solutions/common';
import type {UISessionServiceType, UISessionDocumentType} from '../model/types';
import {useStores} from 'hooks/useStores';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';

export const useDocumentationDataLoader = ({
  documentId,
  initialFetch = true,
}: {
  documentId: string,
  initialFetch?: boolean,
}): ({
  isLoading: boolean,
  data: {
    member: UIMemberBasicType,
    documentation: UISessionDocumentType,
    serviceTypes: Array<UISessionServiceType>,
  },
  loadDocumentation: (documentation: UISessionDocumentType) => void,
  fetchData: () => Promise<void>,
}) => {
  const {sessionDocumentStore, meStore} = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const loadDocumentation = useCallback(
    (documentation) => {
      setData({
        member: documentation.member,
        documentation,
        serviceTypes: data.serviceTypes,
      });
    },
    [setData, data],
  );

  const fetchData = useCallback(async () => {
    const orgId = meStore.organizationId;
    if (!orgId) {
      return;
    }
    setIsLoading(true);

    const [serviceTypeResponse, documentResponse] = await Promise.all([
      sessionDocumentStore.getServiceTypes(orgId),
      sessionDocumentStore.getSessionDoc(orgId, documentId),
    ]);

    if (!serviceTypeResponse.isSuccess || !documentResponse?.isSuccess) {
      showAlert('Could not load document data', ALERT_TYPES.ERROR);
      setIsLoading(false);
      return;
    }

    const memberData = documentResponse.data.member;

    setData({
      member: memberData,
      documentation: documentResponse.data,
      serviceTypes: serviceTypeResponse.data,
    });

    setIsLoading(false);
  }, [meStore, sessionDocumentStore, documentId]);

  useEffect(() => {
    if (initialFetch) {
      fetchData();
    }
  }, [fetchData, initialFetch]);

  return {isLoading, data, fetchData, loadDocumentation};
};
