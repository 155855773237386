// @flow
import {createContext} from 'react';
import type {UIMemberType} from '@wellstone-solutions/common';

type MemberDetailState = {
  mainColumn: string,
  searchBoxTally: number,
  recentEvents: any, // TODO is this an array of event types?
  aggregator: string,
  activityData: any,
  appnavigationData: any,
  badgeData: any,
  checkinData: any,
  habitData: any,
  engagementData: any,
  rawEventData: any,
  selectionRange: string,
};

type PropTypes = {
  getChatModule: () => Node | void,
  state: MemberDetailState,
  getData: () => Promise<any>,
  setState: (state: MemberDetailState) => void,
  memberNotifications: any,
  handleSelectAggregator: (selector: string) => void,
  member: UIMemberType,
};

type ReturnTypes = {
  getChatModule: () => Node | void,
  state: MemberDetailState,
  getData: () => Promise<any>,
  setState: (state: MemberDetailState) => void,
  memberNotifications: any,
  handleSelectAggregator: (selector: string) => void,
  member: UIMemberType,
  Consumer: any,
  Provider: any,
  displayName?: string,
};

// $FlowFixMe
export const MemberDetailTabContext: ReturnTypes = createContext<?PropTypes>(
  undefined,
);
