// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import type {FormType, UIStaffType} from '@wellstone-solutions/common';
import {Roles, Theme, Validation} from '@wellstone-solutions/common';
import {
  Alert,
  Box,
  DatePicker,
  ErrorMessage,
  FormRow,
  OutlinedInput,
  Select,
  TextField,
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {ROLES_NAMES_MAP} from 'utils/Permissions';
import {sortBy} from 'utils/Utils';
import {genders, identities} from 'utils/Forms';
import {AccessControl} from 'modules/rbac';
import {DEFAULT_DATE_FORMAT} from 'constants/dates';

type PropsType = {
  form: FormType<any>,
  data?: {
    canAccess: boolean,
    staff: UIStaffType | void,
    revoked: boolean,
  },
};

const INVALID_FIELD = 'Invalid field';
const INVALID_NAME = 'Name must be 32 characters or less';

const selectLabelProps = {
  sx: {
    backgroundColor: Theme.colorPalette.lightest,
    padding: '0 8px',
  },
};

export const MemberInfoStep = ({data = {}, form}: PropsType): Node => {
  const {RBACStore} = useStores();
  const genderItems = genders.map(({name, value}) => ({label: name, value}));
  const identityItems = identities.map(({name, value}) => ({
    label: name,
    value,
  }));

  const {staff, canAccess, revoked} = data;
  const isExistingMember = Boolean(staff && staff.id);
  // Cannot create patients, super admins or if 403 returned
  const isInaccessibleMember =
    !canAccess ||
    revoked ||
    staff?.role === Roles.PATIENT ||
    staff?.role === Roles.SUPER_ADMIN;

  const role = isInaccessibleMember ? '' : staff?.role || form.values.role;

  const creatableRoles = RBACStore.hasAccess(AccessControl.staff.addAdmins)
    ? [Roles.PEER, Roles.COUNSELOR, Roles.ADMIN]
    : [Roles.PEER, Roles.COUNSELOR];

  const rolesItems = sortBy(
    Object.entries(ROLES_NAMES_MAP)
      // No patients ever
      // No Super admins if not super admin
      .filter(([value]) => creatableRoles.includes(value))
      .map(([value, label]) => ({
        label,
        value,
      })),
    'label',
  );

  // Determine if we found a match for the email
  // If we did, let's lock down this form
  useEffect(() => {
    // If form is valid, we've already entered data
    // and now are returning, let's not overwrite everything
    // or
    // if the member is inaccessible, do not populate the form
    // so it remains invalid. (disables the "next" button)
    // $FlowIgnoreMe
    if (form.isValid || isInaccessibleMember) {
      return;
    }

    if (isExistingMember) {
      form.setFieldValue('id', staff?.id);
      form.setFieldValue('name', staff?.name);
      form.setFieldValue('birthdate', staff?.birthdate);
      form.setFieldValue('role', staff?.role);
      form.setFieldValue('gender', staff?.gender);
      form.setFieldValue('identifiesAs', staff?.identifiesAs);

      const existingPrograms = (staff?.authorizations || [])
        .filter(
          // $FlowIgnoreMe
          (authorization) => authorization.obj_type.toLowerCase() === 'program',
        )
        // $FlowIgnoreMe
        .map((authorization) => ({id: authorization.obj_id, existing: true}));

      form.setFieldValue('programs', [
        ...new Set([...form.values.programs, ...existingPrograms]),
      ]);
    } else {
      // set initial values unless they already exist
      form.setFieldValue('id', form.values.id || '');
      form.setFieldValue('name', form.values.name || '');
      form.setFieldValue('birthdate', form.values.birthdate || null);
      form.setFieldValue('role', form.values.role || '');
      form.setFieldValue('gender', form.values.gender || '');
      form.setFieldValue('identifiesAs', form.values.identifiesAs || '');
      form.setFieldValue('programs', form.values.programs || []);
      form.setFieldValue('groups', form.values.groups || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Custom check on birthdate.  It is not required for existing users
  // but we want to enforce it with new.  Having trouble getting formik
  // to play nice, so ended up using this brutish approach
  if (
    !isExistingMember &&
    form.touched.birthdate &&
    !Validation.isRequired(form.values.birthdate)
  ) {
    // $FlowIgnoreMe
    form.errors.birthdate = INVALID_FIELD;
  }
  //$FlowIgnoreMe
  if (form.errors.name) {
    form.errors.name = INVALID_NAME;
  }

  return (
    <>
      {isInaccessibleMember && (
        <Alert
          data-testid="alert-invalid-account"
          severity="error"
          sx={{mb: 3}}>
          {revoked
            ? 'This email cannot be used to create a new staff member.'
            : 'This email is already in use and cannot be added again.'}
        </Alert>
      )}
      <Box
        sx={{
          display: isInaccessibleMember ? 'none' : 'block',
        }}>
        {isExistingMember && (
          <Alert data-testid="alert" severity="info" sx={{mb: 3}}>
            {`This user is already a ${String(
              ROLES_NAMES_MAP[staff?.role],
            )}.  Continue to add them to a new program, or consult a super administrator.`}
          </Alert>
        )}
        <FormRow>
          <TextField
            formField="email"
            onChange={form.handleFieldChange('email')}
            disabled={true}
            value={form.values.email}
            label="Staff member's email address"
          />
          <ErrorMessage
            name="email"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <TextField
            autoFocus
            formField="name"
            onChange={form.handleFieldChange('name')}
            disabled={isExistingMember}
            value={form.values.name}
            label="Full Name*"
          />
          <ErrorMessage
            name="name"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <DatePicker
            label="Birthdate*"
            disabled={isExistingMember}
            fieldName="birthdate"
            onChange={(date) => {
              form.setFieldValue(
                'birthdate',
                date?.isValid() ? date.format(DEFAULT_DATE_FORMAT) : '',
                false,
              );
              form.setFieldTouched('birthdate');
            }}
            value={form.values.birthdate}
          />
          <ErrorMessage
            name="birthdate"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <Select
            data-testid="roles-dropdown"
            disabled={isExistingMember}
            canUnselect={false}
            label="Role*"
            labelProps={selectLabelProps}
            input={<OutlinedInput data-testid="role-input" notched={false} />}
            items={rolesItems}
            value={role}
            onChange={(e) => {
              form.setFieldValue('role', e.target.value);
            }}
          />
          <ErrorMessage
            name="role"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <Select
            data-testid="gender-dropdown"
            disabled={isExistingMember}
            canUnselect={false}
            label="Sex*"
            labelProps={selectLabelProps}
            input={<OutlinedInput notched={false} />}
            items={genderItems}
            value={form.values.gender}
            onChange={(e) => {
              form.setFieldValue('gender', e.target.value);
            }}
          />
          <ErrorMessage
            name="gender"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <Select
            data-testid="identifies-dropdown"
            disabled={isExistingMember}
            canUnselect={false}
            label="Identifies as*"
            labelProps={selectLabelProps}
            input={<OutlinedInput notched={false} />}
            items={identityItems}
            value={form.values.identifiesAs}
            onChange={(e) => {
              form.setFieldValue('identifiesAs', e.target.value);
            }}
          />
          <ErrorMessage
            name="identifiesAs"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
      </Box>
    </>
  );
};
