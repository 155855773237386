// @flow
import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {Icon, IconNames} from '@wellstone-solutions/web';
import Box from 'react-bulma-components/lib/components/box';
import Modal from 'react-bulma-components/lib/components/modal';
import Heading from 'react-bulma-components/lib/components/heading';
import Dropdown from 'react-bulma-components/lib/components/dropdown';
import {PfButton} from 'components/shared/PfButton';
import {useStores} from 'hooks/useStores';
import {sortBy} from 'utils/Utils';
import {HabitListItem} from './HabitListItem';
import styles from './HabitList.module.scss';
import {Palette} from '../../../../palette';

const DEFAULT_CATEGORY = 'All';

type PropsType = {};

// $FlowFixMe
export const HabitList = observer(({}: PropsType) => {
  const [selectedCategory, setSelectedCategory] = useState(DEFAULT_CATEGORY);
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const {memberDetailStore, organizationStore} = useStores();

  const organizationHabits = organizationStore.habits;

  const uniqueHabitCategories: string[] = [
    ...new Set(organizationHabits.flatMap((h) => h.tags)),
  ].sort();

  // habits in category filter
  const filteredHabits =
    selectedCategory === DEFAULT_CATEGORY
      ? organizationHabits
      : organizationHabits.filter((habit) =>
          habit.tags.includes(selectedCategory),
        );

  const displayHabits = sortBy(filteredHabits, 'name');

  const mergedHabit = (id) => {
    const memberHabit = memberDetailStore.currentMemberHabits.find(
      (mHabit) => mHabit.habitId === id && mHabit.type === 'member',
    );
    const userHabit = memberDetailStore.currentMemberHabits.find(
      (mHabit) => mHabit.habitId === id && mHabit.type === 'user',
    );

    return memberHabit || userHabit;
  };

  return (
    <>
      <Modal
        onClose={() => {}}
        show={showConfirmReset}
        closeOnBlur={true}
        closeOnEsc={true}>
        <Modal.Content className={styles.modal}>
          <Heading size={4}>Confirm</Heading>
          <p>
            Resetting will remove all assigned member habits and cannot be
            undone. Do you wish to proceed?
          </p>
          <div className={styles.modalButtonContainer}>
            <PfButton
              onClick={() => {
                memberDetailStore.removeAllMemberHabits();
                setShowConfirmReset(false);
              }}>
              Yes
            </PfButton>
            <PfButton
              color="light"
              onClick={() => {
                setShowConfirmReset(false);
              }}>
              Cancel
            </PfButton>
          </div>
        </Modal.Content>
      </Modal>
      <div className={styles.container}>
        <div className={styles.column1}>
          <div className={styles.introduction}>
            <p>
              We've collected a group of activities and habits that can help any
              one of us to live a healthier lifestyle, and really help someone
              in recovery anchor their days in a healthy routine. Each client is
              going through their own recovery though and will follow their own
              path.
            </p>
            <p>
              The interface at the right is to help you and your client work
              together to set up a collection of healthy habits that suit their
              specific needs. Select or remove the habits at the right, and for
              those habits that should be encouraged multiple times each day,
              select the times of day.
            </p>
          </div>
          <div className={styles.buttons}>
            <PfButton
              isDisabled={!memberDetailStore.hasStagedHabits}
              onClick={() => {
                memberDetailStore.saveMemberHabits();
              }}>
              Update
            </PfButton>
            <PfButton
              color="light"
              onClick={() => {
                setShowConfirmReset(true);
              }}>
              Reset Defaults
            </PfButton>
          </div>
        </div>
        <Box className={styles.column2}>
          <div className={styles.habitListBox}>
            <Dropdown
              value={selectedCategory}
              onChange={(v) => setSelectedCategory(v)}>
              <Dropdown.Item
                key={'all-categories'}
                title={'All Categories'}
                value={DEFAULT_CATEGORY}>
                {'All Categories'}
              </Dropdown.Item>
              {uniqueHabitCategories.map((category) => (
                <Dropdown.Item key={category} title={category} value={category}>
                  {category}
                </Dropdown.Item>
              ))}
            </Dropdown>
            <div className={styles.habitsHeader}>
              <div>
                <Icon
                  name={IconNames.SunLight}
                  size={20}
                  color={Palette.MESSAGE_MARKED}
                />
              </div>
              <div>
                <Icon
                  name={IconNames.Cloud}
                  size={20}
                  color={Palette.DEPRESSED}
                />
              </div>
              <div>
                <Icon
                  name={IconNames.HalfMoon}
                  size={20}
                  color={Palette.VIEW_ADD_MEETINGS}
                />
              </div>
            </div>
          </div>
          <div className={styles.habitListItems}>
            {displayHabits.map((habit) => (
              <HabitListItem
                key={habit.id}
                habit={habit}
                mergedHabit={mergedHabit(habit.id)}
                addMemberHabit={memberDetailStore.addMemberHabit}
                deleteMemberHabit={memberDetailStore.deleteMemberHabit}
                updateMemberHabit={memberDetailStore.updateMemberHabit}
              />
            ))}
          </div>
        </Box>
      </div>
    </>
  );
});
