// @flow
import React from 'react';
import type {Node} from 'react';
import type {UIMemberBasicType} from '@wellstone-solutions/common';
import {Theme} from '@wellstone-solutions/common';
import {Box, Chip, Icon, IconNames} from '@wellstone-solutions/web';

type PropsType = {
  selectedMembers: Array<UIMemberBasicType>,
  setSelectedMembers: (Array<UIMemberBasicType>) => void,
};

export const SelectedChips = ({
  selectedMembers,
  setSelectedMembers,
}: PropsType): Node => {
  return (
    <Box sx={styles.chipWrapper}>
      {selectedMembers.map((member) => (
        <Chip
          sx={styles.chip}
          key={member.id}
          label={member.name}
          variant="solid"
          onDelete={() =>
            setSelectedMembers(
              selectedMembers.filter(({id}) => id !== member.id),
            )
          }
          deleteIcon={<Icon name={IconNames.Xmark} />}
        />
      ))}
    </Box>
  );
};

const styles = {
  chipWrapper: {
    mt: 1,
    mb: 2,
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    color: Theme.colorPalette.onSurface,
    backgroundColor: Theme.colorPalette.secondary4,
    margin: 0.5,
    '& .MuiChip-deleteIcon': {
      color: Theme.colorPalette.primary,
    },
  },
};
