// @flow
import React, {useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {Events} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {Calendar as CommonCalendar} from 'components/Calendar';
import {DailyEvent} from './DailyEvent';
import {MonthlyEvent} from './MonthlyEvent';
import {WeeklyEvent} from './WeeklyEvent';
import {EventModal} from './EventModal';
import {CALENDAR_VIEWS} from '../../constants';
import {toScheduledEvent} from '../../transforms';

export const Calendar: ComponentType<{}> = observer((): Node => {
  const [showModal, setShowModal] = useState(false);
  const [activeEvent, setActiveEvent] = useState(null);
  const {eventStore, meStore, calendarStore} = useStores();

  const myUserId = meStore.me.id;
  const events = calendarStore.activeEvents.map((calendarEvent) =>
    toScheduledEvent({
      calendarEvent,
      invitations: calendarEvent.invitations,
      myUserId,
    }),
  );

  const handleViewChange = (viewName: string): void => {
    meStore.setActiveCalendarView(viewName);

    eventStore.addEvent(Events.VIEWED_CALENDAR, {view: viewName});
  };

  return (
    <>
      <CommonCalendar
        onView={handleViewChange}
        components={{
          agenda: {
            event: DailyEvent,
          },
          day: {
            event: DailyEvent,
          },
          month: {
            event: MonthlyEvent,
          },
          week: {
            event: WeeklyEvent,
          },
        }}
        defaultView={meStore.activeCalendarView || CALENDAR_VIEWS.month}
        events={events}
        onSelectEvent={(event) => {
          setShowModal(true);
          setActiveEvent(event.resource);
        }}
        style={{height: '100vh'}}
      />
      {activeEvent && (
        <EventModal
          open={showModal}
          setShowModal={setShowModal}
          calendarEvent={activeEvent}
        />
      )}
    </>
  );
});
