// React
import React from 'react';

// Third Party
import moment from 'moment';

// Bulma
import BDropdown from 'react-bulma-components/lib/components/dropdown';

// WS
import Dropdown from '../../tutorial/dropdown';
import {months} from '../../../constants/Months';

const DatePickerInput = (props) => {
  const selectDay = (val) => {
    if (props.disabled) {
      return false;
    }
    props.setDate(new moment(props.val.date(val)));
    if (props.after && props.val < props.after) {
      selectMonth(props.after.month() + 1);
    }
  };
  const selectMonth = (val) => {
    if (props.disabled) {
      return false;
    }
    props.setDate(new moment(props.val.month(val)));
    if (props.after && props.val < props.after) {
      selectYear(props.after.year() + 1);
    }
  };
  const selectYear = (val) => {
    if (props.disabled) {
      return false;
    }
    props.setDate(new moment(props.val.year(val)));
  };

  return (
    <>
      <div style={styles.label}>{props.label}</div>
      <div>
        <div style={styles.inlineDrop}>
          <Dropdown
            id={props.id ? props.id + '-month-dropdown' : 'month-dropdown'}
            value={props.val.month()}
            className="top-drop short-drop"
            disabled={props.disabled || false}
            onChange={selectMonth}>
            {[...Array(12)].map((m, i) => (
              <BDropdown.Item key={i} value={i}>
                {months[i]}
              </BDropdown.Item>
            ))}
          </Dropdown>
        </div>
        <div style={styles.inlineDrop}>
          <Dropdown
            id={props.id ? props.id + '-date-dropdown' : 'date-dropdown'}
            value={props.val.date()}
            className="top-drop short-drop"
            disabled={props.disabled || false}
            onChange={selectDay}>
            {[...Array(props.val.daysInMonth())].map((d, i) => (
              <BDropdown.Item key={i + 1} value={i + 1}>
                {i + 1}
              </BDropdown.Item>
            ))}
          </Dropdown>
        </div>
        <div style={styles.inlineDrop}>
          <Dropdown
            id={props.id ? props.id + '-year-dropdown' : 'year-dropdown'}
            value={props.val.year()}
            className="top-drop short-drop"
            disabled={props.disabled || false}
            onChange={selectYear}>
            {[props.val.year() - 1, props.val.year(), props.val.year() + 1].map(
              (y) => (
                <BDropdown.Item key={y} value={y}>
                  {y}
                </BDropdown.Item>
              ),
            )}
          </Dropdown>
        </div>
      </div>
    </>
  );
};

const styles = {
  inlineDrop: {
    display: 'inline-block',
    width: 'auto',
    marginRight: 10,
  },
  label: {
    marginBottom: 5,
    fontWeight: 'bold',
  },
};

export default DatePickerInput;
