// React
import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';

// Third Party
import {inject, observer} from 'mobx-react';

// Bulma
import Level from 'react-bulma-components/lib/components/level';
import Heading from 'react-bulma-components/lib/components/heading';

// WS
import {Api, BridgeEventSources, Events} from '@wellstone-solutions/common';
import {hasFlag, HIDE_MEMBERS_NAV} from 'constants/FeatureFlags';
import {useEventSource} from 'hooks/useEventSource';
import {AccessControl} from 'modules/rbac';
import {GroupMemberList} from 'modules/group';
import {AddMemberToGroup} from 'modules/group';
import PfModal from '../../components/modal/PfModal';
import {Palette} from '../../palette';
import Link from '../../components/tutorial/link';
import PfLoader from '../../components/shared/PfLoader';
import {PfLinkButton} from '../../components/shared/PfLinkButton';
import Activity from '../../components/group/detail/Activity';
import BDropdown from 'react-bulma-components/lib/components/dropdown';
import Dropdown from '../../components/tutorial/dropdown';

const GroupDetail = (props) => {
  const params = useParams();
  useEventSource(BridgeEventSources.GROUP_DETAIL_PAGE);

  const {
    appUIStore,
    eventStore,
    meStore,
    memberNotifications,
    RBACStore,
  } = props;
  const org = meStore.me.membership.organization.id;
  const groupId = params.group;

  const [activeTab, setActiveTab] = useState('members');
  const [group, setGroup] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [memberFilter, setMemberFilter] = useState('active');
  const groupMemberListRef = useRef();

  const groupMemberListRefetch = () => {
    groupMemberListRef.current?.refetch();
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      const response = await Api.Instance.current().get(
        `/orgs/${org}/groups/${groupId}`,
      );

      appUIStore.setBreadcrumbs([
        {
          name: 'Groups',
          url: '/groups',
        },
        {
          name: response.data.name,
          url: `/groups/${groupId}`,
          active: true,
        },
      ]);
      setGroup(response.data);
      setIsLoading(false);
    };

    getData();

    eventStore.addEvent(Events.VIEWED_GROUP_DETAIL, {
      group_id: groupId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUIStore, groupId, org]);

  if (isLoading) {
    return <PfLoader />;
  }

  return (
    <div>
      <Level>
        <Level.Side />
        <Level.Side>
          <Level.Item>
            <PfLinkButton to={`/announcements/${groupId}`} color={'primary'}>
              Add Announcement
            </PfLinkButton>
          </Level.Item>
          {!hasFlag(HIDE_MEMBERS_NAV, meStore.features) && (
            <Level.Item>
              <PfModal
                button={{
                  outlined: true,
                  text: 'Add Member',
                  id: 'add-group-member-button',
                }}
                large
                contentStyle={styles.modalContent}
                disabled={
                  !RBACStore.hasAccess(AccessControl.groups.addMemberToGroup)
                }
                modal={{
                  closeOnBlur: true,
                  showClose: false,
                  closeOnEsc: true,
                }}>
                <Heading size={4}>
                  Add Member to <span style={styles.bold}>{group?.name}</span>
                </Heading>
                <AddMemberToGroup
                  group={group}
                  groupListRefetch={groupMemberListRefetch}
                />
              </PfModal>
            </Level.Item>
          )}
        </Level.Side>
      </Level>
      <div className="tabs">
        <ul>
          <li className={activeTab === 'members' ? 'is-active' : ''}>
            <Link
              onClick={() => setActiveTab('members')}
              id="tab-group-detail-members">
              General
            </Link>
          </li>
          <li className={activeTab === 'activity' ? 'is-active' : ''}>
            <Link
              onClick={() => setActiveTab('activity')}
              id="tab-member-detail-activity">
              Activity
            </Link>
          </li>
        </ul>
      </div>

      {activeTab === 'members' && (
        <div>
          {RBACStore.hasAccess(AccessControl.staff.viewDischargedMembers) && (
            <Dropdown
              data-testid="active-discharge-dropdown"
              value={memberFilter}
              style={styles.listFilter}
              onChange={(val) => {
                setMemberFilter(val);
              }}>
              <BDropdown.Item value="active">
                Active Enrollments in this Group
              </BDropdown.Item>
              <BDropdown.Item value="discharged">
                Discharged from this Group
              </BDropdown.Item>
            </Dropdown>
          )}
          {!hasFlag(HIDE_MEMBERS_NAV, meStore.features) ? (
            <GroupMemberList
              groupId={groupId}
              filter={memberFilter}
              dataGridRef={groupMemberListRef}
            />
          ) : (
            <Heading size={6}>No members accessible</Heading>
          )}
        </div>
      )}

      {activeTab === 'activity' && (
        <Activity
          org={org}
          groupId={groupId}
          memberNotifications={memberNotifications}
        />
      )}
    </div>
  );
};

const styles = {
  bold: {fontWeight: 'bold'},
  listFilter: {
    marginBottom: 20,
  },
  cardHolder: {
    padding: 15,
  },
  cardHeader: {
    color: Palette.DARK_INFO,
  },
  cardSubhead: {
    color: Palette.GREY_3,
    fontWeight: 'bold',
  },
  cardBox: {
    height: '100%',
  },
  modalContent: {
    overflowY: 'hidden',
  },
  statColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  counter: {
    fontWeight: 'bold',
    fontSize: 36,
    color: Palette.SUCCESS,
  },
  fieldLabel: {
    paddingRight: 10,
  },
  stackedLevelItems: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  stackedLevelItem: {
    marginBottom: 10,
  },
  textLeft: {
    justifyContent: 'start',
  },
  textRight: {
    justifyContent: 'flex-end',
  },
  whiteLink: {
    color: 'white',
  },
};

export default inject(
  'appUIStore',
  'eventStore',
  'meStore',
  'RBACStore',
)(observer(GroupDetail));
