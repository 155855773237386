// @flow
import React, {useState} from 'react';
import type {Node, ComponentType} from 'react';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import {Models, Theme} from '@wellstone-solutions/common';
import type {
  UICalendarEventType,
  UICalendarEventInvitationType,
} from '@wellstone-solutions/common';
import {
  Box,
  Divider,
  IconButton,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {PfButton} from 'components/shared/PfButton';
import {CancelEventActions} from '../CancelEventActions';
import {AreYouAttending, EventContent, Participants} from '../CalendarEvent';
import {getEventDetailUrl} from '../../utils';

type PropsType = {
  isModalOpen: boolean,
  data: {
    calendarEvent: UICalendarEventType,
    calendarEventInvitations: Array<UICalendarEventInvitationType>,
  },
  setShowModal: (isOpen: boolean) => void,
};

const {CalendarEvent} = Models;

export const EventModalContent: ComponentType<PropsType> = observer(
  ({
    data: {calendarEvent, calendarEventInvitations},
    isModalOpen,
    setShowModal,
  }: PropsType): Node => {
    const {calendarStore, meStore} = useStores();
    const [refetchingAll, setRefetchingAll] = useState(false);

    const refetchAllEvents = async () => {
      setRefetchingAll(true);
      await calendarStore.getAllCalendarEvents();
      setRefetchingAll(false);
      setShowModal(false);
    };

    if (!calendarEvent) {
      return (
        <Box>
          <Stack direction="column" alignItems="center">
            <Typography variant="h5">
              We're sorry, we were unable to find this event
            </Typography>
          </Stack>
          <Stack alignItems="center" sx={{my: Theme.spacing.mini}}>
            <Typography variant="body" sx={{mb: Theme.spacing.micro}}>
              Please try refreshing your calendar to get the latest updates.
            </Typography>
            <PfButton
              color="primary"
              onClick={refetchAllEvents}
              isLoading={refetchingAll}>
              Refresh Calendar
            </PfButton>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{mt: 2}}>
            <PfButton onClick={() => setShowModal(false)}>Close</PfButton>
          </Stack>
        </Box>
      );
    }

    const navigate = useNavigate();
    const isOwner = calendarEvent.owner === meStore.me.id;
    const isEventCanceled = CalendarEvent.isCanceled(calendarEvent);

    return (
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="h5">{calendarEvent.title}</Typography>
          {!isEventCanceled && (
            <IconButton
              icon={isOwner ? IconNames.EditPencil : IconNames.InfoCircle}
              // $FlowFixMe
              iconProps={{
                color: Theme.colorPalette.secondary,
              }}
              onClick={() => {
                navigate(
                  getEventDetailUrl(
                    calendarEvent.id,
                    calendarEvent.originalStart,
                  ),
                );
              }}
            />
          )}
        </Stack>
        <Divider />

        <EventContent calendarEvent={calendarEvent} direction="row" />

        <Stack>
          <Participants
            invitations={calendarEventInvitations}
            calendarEvent={calendarEvent}
            isOwner={isOwner}
          />
          {!isOwner && (
            <>
              <Divider />
              <AreYouAttending calendarEvent={calendarEvent} />
            </>
          )}
        </Stack>

        {isOwner && (
          <>
            <Divider />
            <footer style={styles.footer}>
              <CancelEventActions
                isModalOpen={isModalOpen}
                isRecurringEvent={calendarEvent.isRecurring}
                calendarEvent={calendarEvent}
                setShowModal={setShowModal}
              />
            </footer>
          </>
        )}
      </Box>
    );
  },
);

const styles = {
  footer: {
    display: 'flex',
    paddingTop: 16,
    justifyContent: 'flex-end',
  },
};
