// @flow
import React from 'react';
import type {Node} from 'react';
import {DatePicker, Select, Stack, Typography} from '@wellstone-solutions/web';

type SelectedItemType = {
  id: string,
  startDate: string,
  endDate: string,
};

type PropTypes = {
  selectOptions: Array<{label: string, value: any}>,
  admission: SelectedItemType,
  setAdmission: (params: SelectedItemType) => void,
  label: string,
};

export const ItemSelection = ({
  setAdmission,
  admission,
  selectOptions,
  label,
}: PropTypes): Node => {
  const handleChange = (evt) => {
    setAdmission({...admission, id: evt.target.value});
  };

  return (
    <div style={styles.selectContainer}>
      <Stack direction="column">
        {selectOptions.length > 0 ? (
          <>
            <Select
              label={label}
              items={selectOptions}
              value={admission.id}
              onChange={handleChange}
              data-testid="select-item"
            />
            <Stack sx={{pt: 2}} direction="row" justifyContent="space-between">
              <DatePicker
                label="Start Date"
                fieldName="admissionStart"
                onChange={(date) => {
                  setAdmission({
                    ...admission,
                    startDate: date?.isValid() ? date.toISOString() : '',
                  });
                }}
                value={admission.startDate}
              />
              <DatePicker
                label="(Estimated) End Date"
                fieldName="admissionEnd"
                onChange={(date) => {
                  setAdmission({
                    ...admission,
                    endDate: date?.isValid() ? date.toISOString() : '',
                  });
                }}
                value={admission.endDate}
              />
            </Stack>
          </>
        ) : (
          <Typography>{`No additional ${label.toLowerCase()} to add.`}</Typography>
        )}
      </Stack>
    </div>
  );
};

const styles = {
  selectContainer: {
    marginBottom: '30px',
  },
};
