import React from 'react';
import MultiStepModal from '../MultiStepModal';
import AddGroupForm from '../../forms/group/AddGroupForm';

const CreateModal = ({onCreate = () => null, ...rest}) => {
  const steps = [<AddGroupForm onCreate={onCreate} />];
  return (
    <MultiStepModal
      {...rest}
      steps={steps}
      button={{id: 'add-group-button', text: 'Add New Group', outlined: true}}
    />
  );
};

export default CreateModal;
