// @flow
import React from 'react';
import type {Node} from 'react';

import {
  FormControlLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
} from '@mui/material';

import {Theme} from '@wellstone-solutions/common';
import type {FormType} from '@wellstone-solutions/common';
import {ErrorMessage, Typography} from '@wellstone-solutions/web';
import type {UISessionDocumentType} from 'modules/documentation/model/types';
import {SESSION_TYPES} from 'modules/documentation/constants';

type PropsType = {
  form: FormType<UISessionDocumentType>,
  hasError: boolean,
};

export const SessionTypeRadio = ({form, hasError = false}: PropsType): Node => (
  <>
    <Typography
      variant="subtitle1"
      sx={{mt: 1, color: Theme.colorPalette.darkest}}>
      Type of session
    </Typography>
    <MUIRadioGroup
      sx={{
        paddingLeft: Theme.spacing.micro,
        maxHeight: '20rem',
        overflowY: 'auto',
        flexWrap: 'wrap',
        flexDirection: {xs: 'column', md: 'row'},
        gap: `0 ${Theme.spacing.mini}`,
      }}
      aria-label="session type"
      name="sessionType"
      value={form.values.sessionType}
      onChange={form.handleFieldChange('sessionType')}
      data-error={hasError ? true : null}>
      {SESSION_TYPES.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </MUIRadioGroup>
    <ErrorMessage
      name="sessionType"
      errors={form.errors}
      touched={form.touched}
    />
  </>
);
