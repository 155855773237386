// @flow
import {ActivityTabContent} from './ActivityTabContent';
import {HabitScheduleTabContent} from './HabitScheduleTabContent';
import {GeneralTabContent} from './GeneralTabContent';
import {SessionDocTabContent} from './SessionDocTabContent';

export {MemberDetail} from './MemberDetail';

export const MemberDetailTabs = {
  ActivityTabContent,
  HabitScheduleTabContent,
  GeneralTabContent,
  SessionDocTabContent,
};
