// React
import React from 'react';

// Bulma
import Columns from 'react-bulma-components/lib/components/columns';
import {
  Field,
  Control,
  Help,
  Label,
} from 'react-bulma-components/lib/components/form';
import Level from 'react-bulma-components/lib/components/level';

// WS
import Input from '../../tutorial/input';
import {fieldStatus} from '../../../utils/Forms';

const PfInput = (props) => {
  const {setval = () => {}} = props;
  return props.fullWidth ? (
    <Field>
      <Columns>
        <Columns.Column size={4}>
          <Label style={styles.fieldLabel} htmlFor={props.field.name}>
            {props.label}
          </Label>
        </Columns.Column>
        <Columns.Column size={8}>
          <Control>
            <Input
              id={props.field.name}
              aria-label={props.field.name}
              aria-required={props.field.required}
              color={
                fieldStatus(props.val, props.field.test, props.field.required)
                  .color
              }
              type="text"
              fieldname={props.field.name}
              required={props.field.required}
              placeholder={props.placeholder}
              onChange={(e) => setval(e.target.value)}
              setval={setval}
              disabled={props.disabled}
              value={props.val}
            />
          </Control>
          <Help color={fieldStatus(props.val, props.field.test).color}>
            {fieldStatus(props.val, props.field.test).error}
          </Help>
        </Columns.Column>
      </Columns>
    </Field>
  ) : (
    <Level>
      <Level.Side>
        <Field>
          <Label style={styles.fieldLabel} htmlFor={props.field.name}>
            {props.label}
          </Label>
        </Field>
      </Level.Side>
      <Level.Side>
        <Level.Item style={styles.stackedRightItems}>
          <Control>
            <Input
              id={props.field.name}
              aria-label={props.field.name}
              aria-required={props.field.required}
              color={
                fieldStatus(props.val, props.field.test, props.field.required)
                  .color
              }
              type="text"
              required={props.field.required}
              placeholder={props.placeholder}
              onChange={(e) => setval(e.target.value)}
              setVal={setval}
              disabled={props.disabled}
              value={props.val}
            />
          </Control>
          <Help color={fieldStatus(props.val, props.field.test).color}>
            {fieldStatus(props.val, props.field.test).error}
          </Help>
        </Level.Item>
      </Level.Side>
    </Level>
  );
};

const styles = {
  fieldLabel: {
    paddingRight: 20,
  },
  stackedRightItems: {
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
};
export default PfInput;
