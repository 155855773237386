// @flow
import React from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {Theme} from '@wellstone-solutions/common';
import type {UIMemberBasicType, UIStaffType} from '@wellstone-solutions/common';
import {Typography} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import type {UISessionDocumentType} from '../../model/types';
import {DATE_TIME_FORMAT} from 'modules/documentation/constants';
import {RevertedDetailRow} from './RevertedDetailRow';
import {CompletedRow} from 'modules/integration/components/CompletedRow';
import {EHRStatusView} from 'modules/integration/components';

type PropTypes = {
  member: UIMemberBasicType,
  staff: UIStaffType,
  documentation: UISessionDocumentType,
};

export const Header = ({member, staff, documentation}: PropTypes): Node => {
  const {meStore} = useStores();

  const orgIntegration = meStore.ehrIntegrationOption;

  const formattedCreated = moment(documentation.created).format(
    DATE_TIME_FORMAT,
  );

  const formattedModified = moment(documentation.modified).format(
    DATE_TIME_FORMAT,
  );

  const isReverted = (documentation.reverted?.changedAt.length ?? 0) > 0;

  const timeLabel =
    formattedCreated === formattedModified ? 'Created' : 'Edited';

  return (
    <>
      <Typography
        variant="h5"
        sx={{color: Theme.colorPalette.darkest, mb: Theme.spacing.micro}}>
        Session Documentation
      </Typography>
      <CompletedRow label="Peer" value={meStore.me.name} />
      {orgIntegration && (
        <EHRStatusView
          orgIntegration={orgIntegration}
          integrations={staff?.integrations}
        />
      )}
      {!isReverted && (
        <CompletedRow label={timeLabel} value={formattedModified} />
      )}

      <RevertedDetailRow documentation={documentation} />
    </>
  );
};
