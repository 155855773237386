// @flow
import React from 'react';
import type {Node} from 'react';
import type {FormType} from '@wellstone-solutions/common';
import {SelectableFormGroups} from 'components/SelectableFormGroups';

type PropsType = {
  form: FormType<any>,
};

export const GroupsStep = ({form}: PropsType): Node => {
  return <SelectableFormGroups form={form} />;
};
