// @flow
import {Theme} from '@wellstone-solutions/common';

type PropTypes = {
  isAttending: boolean,
};

export const useCalendarEventStyles = ({
  isAttending,
}: PropTypes): {[string]: string} => {
  const backgroundColor = isAttending
    ? Theme.colorPalette.secondary
    : Theme.colorPalette.lightest;
  const foreColor = isAttending
    ? Theme.colorPalette.lightest
    : Theme.colorPalette.secondary;

  let styles: {[string]: string} = {
    backgroundColor: backgroundColor,
    border: `1px solid ${foreColor}`,
    color: foreColor,
  };

  if (isAttending === false) {
    styles = {
      ...styles,
      textDecoration: 'line-through',
    };
  }

  return styles;
};
