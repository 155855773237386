// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import Dropdown from 'components/tutorial/dropdown';
import BDropdown from 'react-bulma-components/lib/components/dropdown';
import {Modal} from '@mui/material';
import {PfButton} from 'components/shared/PfButton';
import {Box, Stack} from '@wellstone-solutions/web';

import {CreateWizard} from '../CreateWizard';
import {useStores} from 'hooks/useStores';
import {hasFlag, DISABLE_ADD_NEW_MEMBER} from 'constants/FeatureFlags';
import {ALL_MEMBERS} from '../../constants';
import {AccessControl} from 'modules/rbac';

type PropsType = {
  filter: string,
  setFilter: (string) => void,
  refetch: () => void,
};

export const ListHeader = ({filter, setFilter, refetch}: PropsType): Node => {
  const [showModal, setShowModal] = useState(false);
  const {meStore, RBACStore} = useStores();

  const allMembersItem = {
    label: 'All Members',
    value: ALL_MEMBERS,
  };

  const filterItems = [
    ...meStore.myPrograms.map(({id, name}) => ({
      label: name,
      value: id,
    })),
    allMembersItem,
  ];

  const toggleModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      setShowModal(!showModal);
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Box sx={{mr: 1}}>
        <Dropdown value={filter} onChange={(val) => setFilter(val)}>
          {filterItems.map(({label, value}) => (
            <BDropdown.Item key={value} value={value}>
              {label}
            </BDropdown.Item>
          ))}
        </Dropdown>
      </Box>

      <Box>
        {RBACStore.hasAccess(AccessControl.members.addMember) &&
          !hasFlag(DISABLE_ADD_NEW_MEMBER, meStore.features) && (
            <div>
              <PfButton color="primary" onClick={toggleModal}>
                Add New Member
              </PfButton>
              <Modal open={showModal} onClose={toggleModal}>
                <div>
                  <CreateWizard onCancel={toggleModal} />
                </div>
              </Modal>
            </div>
          )}
      </Box>
    </Stack>
  );
};
