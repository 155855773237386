// @flow
import {action, decorate, observable, runInAction} from 'mobx';
import type {IObservableArray} from 'mobx';
import moment from 'moment';
import {Api, Models} from '@wellstone-solutions/common';
import type {
  ApiResponseType,
  ApiMemberType,
  UIMemberType,
  ApiMemberInputType,
  UIAdmissionInputType,
  ApiGroupAdmissionType,
  ApiProgramAdmissionType,
  ApiMemberIntegrationType,
  UIGroupType,
} from '@wellstone-solutions/common';
import RootStore from 'mobx/RootStore';
import {DEFAULT_DATE_FORMAT} from '../../constants/dates';
import {routes} from 'api';
import type {OptionsType} from 'types';
import {buildIdLookup} from 'utils/buildIdLookup';

const {Integration, Member, GroupAdmission, ProgramAdmission} = Models;

export class MemberStore {
  rootStore: RootStore;
  members: IObservableArray<UIMemberType> = observable.array();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  async createMember(
    input: ApiMemberInputType,
  ): Promise<ApiResponseType<ApiMemberType>> {
    // $FlowFixMe
    const orgId: string = this.rootStore.stores.meStore.organizationId;
    const response = await Member.create(orgId, input);

    return response;
  }

  async getMemberForUserId(userId: string): Promise<ApiMemberType> {
    // $FlowFixMe
    const orgId: string = this.rootStore.stores.meStore.organizationId;
    const response = await Api.Instance.current().post(
      `/orgs/${orgId}/exchange_user_id`,
      {
        user_id: userId,
      },
    );

    return response.data;
  }

  async getAllMembers(
    options: OptionsType,
  ): Promise<ApiResponseType<{members: Array<ApiMemberType>}>> {
    // $FlowFixMe
    const orgId: string = this.rootStore.stores.meStore.organizationId;
    return Api.Instance.current().get(
      routes.organization.members(orgId),
      options,
    );
  }

  async getMember({
    id,
    forceFetch = false,
  }: {
    id: string,
    forceFetch?: boolean,
  }): Promise<UIMemberType | void> {
    const foundMember = this.findMember(id);

    if (!forceFetch && foundMember) {
      return Promise.resolve(foundMember);
    }

    // $FlowFixMe
    const orgId: string = this.rootStore.stores.meStore.organizationId;
    const response = await Member.get({orgId, id});
    const apiMember = response.isSuccess ? response.data : null;

    return apiMember
      ? this._transformAndStoreMember(apiMember, foundMember)
      : undefined;
  }

  // Search all members by email
  // Returns all members including staff members in UIMemberType shape
  async getMemberByEmail({
    email,
    forceFetch = false,
  }: {
    email: string,
    forceFetch?: boolean,
  }): Promise<{member: UIMemberType | void, canAccess: boolean}> {
    const foundMember = this._findMemberByEmail(email);

    if (!forceFetch && foundMember) {
      return Promise.resolve({member: foundMember, canAccess: true});
    }

    const response = await this.getAllMembers({params: {email}});

    const apiMember = response.isSuccess ? response.data.members[0] : null;
    // 403 indicates we found a member, but they are unavailable
    const canAccess = response.status !== 403;

    return {
      member: apiMember
        ? this._transformAndStoreMember(apiMember, foundMember)
        : undefined,
      canAccess,
    };
  }

  async getMembersByIds(
    ids: Array<string>,
  ): Promise<Array<UIMemberType | void>> {
    const response = await this.getAllMembers({
      params: {
        limit: ids.length,
        user_id: ids,
      },
    });

    const rawMembers = response.isSuccess ? response.data.members : [];

    const membersLookup = buildIdLookup(this.members);

    const uiMembers = rawMembers.map((apiMember) => {
      const foundMember = membersLookup[apiMember.id];
      return this._transformAndStoreMember(apiMember, foundMember);
    });

    return uiMembers;
  }

  async createEHRIntegration({
    memberId,
    externalRelationshipId,
  }: {
    memberId: string,
    externalRelationshipId: string,
  }): Promise<ApiResponseType<{data: ApiMemberIntegrationType}>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.organizationId;
    const response = await Integration.connect(
      orgId,
      memberId,
      externalRelationshipId,
    );

    return response;
  }

  _transformAndStoreMember(
    apiMember: ApiMemberType,
    foundMember: UIMemberType | void,
  ): UIMemberType | void {
    const uiMember = Member.toUI(apiMember);

    // update this.members to include the new fetched member
    const uiAllMembersWithNew = foundMember
      ? this.members.map((member: UIMemberType) =>
          member.id === foundMember.id ? uiMember : member,
        )
      : [...this.members, uiMember];

    runInAction(() => {
      this.members.replace(uiAllMembersWithNew);
    });

    return this.findMember(uiMember.id);
  }

  setMembers(members: Array<UIMemberType>): void {
    this.members.replace(members);
  }

  findMember(id: string): UIMemberType | void {
    return this.members.find((member: UIMemberType) => member.id === id);
  }

  _findMemberByEmail(email: string): UIMemberType | void {
    return this.members.find(
      (member: UIMemberType) => member.user.email === email,
    );
  }

  // list of groups that the member is actively admitted to (filters out discharged groups)
  admittedGroups({memberId}: {memberId: string}): Array<UIGroupType> | void {
    const member = this.findMember(memberId);
    if (!member) {
      return;
    }
    const groups = this.rootStore.stores.groupStore.groups;

    return (
      member.groupAdmissions
        // $FlowFixMe
        .map(({group: admGroup, discharge_date}) => {
          // do not include discharged admissions
          if (discharge_date) {
            return;
          }
          return groups.find((group) => group.id === admGroup.id);
        })
        .filter(Boolean)
    );
  }

  async createGroupAdmission({
    groupId,
    admissionInput,
  }: {
    groupId: string,
    admissionInput: UIAdmissionInputType,
  }): Promise<ApiResponseType<ApiGroupAdmissionType>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.organizationId;
    const apiAdmissionInput = GroupAdmission.toApiInput(admissionInput);
    const response = await GroupAdmission.create(
      orgId,
      groupId,
      apiAdmissionInput,
    );

    // refetch the member to update the store
    await this.getMember({id: admissionInput.memberId, forceFetch: true});

    return response;
  }

  async createProgramAdmission({
    programId,
    admissionInput,
  }: {
    programId: string,
    admissionInput: UIAdmissionInputType,
  }): Promise<ApiResponseType<ApiProgramAdmissionType>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.organizationId;
    const apiAdmissionInput = ProgramAdmission.toApiInput(admissionInput);
    const response = await ProgramAdmission.create(
      orgId,
      programId,
      apiAdmissionInput,
    );

    // refetch the member to update the store
    await this.getMember({id: admissionInput.memberId, forceFetch: true});

    return response;
  }

  async dischargeFromGroup({
    memberId,
    groupId,
    admissionId,
  }: {
    memberId: string,
    groupId: string,
    admissionId: string,
  }): Promise<ApiResponseType<ApiGroupAdmissionType>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.organizationId;

    const response = await GroupAdmission.remove(orgId, groupId, admissionId, {
      discharge_date: moment().format(DEFAULT_DATE_FORMAT),
    });

    // refetch the member to update the store
    await this.getMember({id: memberId, forceFetch: true});

    return response;
  }

  async dischargeFromProgram({
    memberId,
    programId,
    admissionId,
    dischargeDate,
    dischargeStatus,
  }: {
    memberId: string,
    programId: string,
    admissionId: string,
    dischargeDate: string,
    dischargeStatus: string,
  }): Promise<ApiResponseType<ApiProgramAdmissionType>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.organizationId;

    const response = await ProgramAdmission.remove(
      orgId,
      programId,
      admissionId,
      {
        discharge_date: moment(dischargeDate).format(DEFAULT_DATE_FORMAT),
        discharge_status: dischargeStatus,
      },
    );

    // refetch the member to update the store
    await this.getMember({id: memberId, forceFetch: true});

    return response;
  }

  getPrimaryGroupIds(member: UIMemberType): Array<string> {
    const {organizationStore} = this.rootStore.stores;

    // $FlowFixMe
    if (!organizationStore.primaryCategory) {
      return [];
    }

    return member.groupAdmissions
      .filter(
        (admission) =>
          // $FlowFixMe
          admission.group.category_id === organizationStore.primaryCategory.id,
      )
      .map((admission) => admission.group.id);
  }
}

decorate(MemberStore, {
  setMembers: action,
});
