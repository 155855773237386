// @flow
import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import type {
  UIStaffType,
  ApiRevocationInputType,
} from '@wellstone-solutions/common';
import {Icon, IconNames, Stack, TextField} from '@wellstone-solutions/web';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {PfButton} from 'components/shared/PfButton';
import {useStores} from 'hooks/useStores';
import {PatientSearchResults} from 'components/SearchResults';
import {Search} from 'components/Search';
import {SaveCancelModal} from 'components/modal/saveCancelModal';

type PropTypes = {
  open: boolean,
  setShowModal: (show: boolean) => void,
  staff: UIStaffType,
};

export const AddRevokeModal = ({
  open,
  setShowModal,
  staff,
}: PropTypes): Node => {
  const {staffStore, organizationStore} = useStores();
  const [selectedUser, setSelectedUser] = useState<{
    name: string,
    id: string,
  } | void>();
  const [notes, setNotes] = useState<string>('');

  useEffect(() => {
    setSelectedUser(undefined);
    setNotes('');
  }, [open]);

  const onSave = async () => {
    if (!selectedUser) {
      return;
    }
    const revocationInput: ApiRevocationInputType = {
      staff_id: staff.id,
      patient_id: selectedUser.id,
      notes: notes,
    };

    const response = await staffStore.createRevocation({
      staffId: staff.id,
      revocationInput,
    });

    if (response.isSuccess) {
      setShowModal(false);
    } else {
      const errorMsg =
        'Unable to revoke member' +
        (response.errors.length > 0 ? `: ${response.errors.join('. ')}` : '.');
      showAlert(errorMsg, ALERT_TYPES.ERROR);
    }
  };

  const cancelAdd = () => {
    setSelectedUser(undefined);
    setNotes('');
  };

  const onSelectSearchItem = ({id, name}) => {
    setSelectedUser({id, name});
  };

  return (
    <SaveCancelModal
      open={open}
      setShowModal={setShowModal}
      title="Revoke a member"
      onSave={onSave}
      disableSave={!selectedUser}>
      <div style={styles.searchContainer}>
        {!selectedUser ? (
          <Search
            onSearch={(term) =>
              organizationStore.search({searchTerm: term, queryBy: ['members']})
            }
            onSelectItem={onSelectSearchItem}
            ResultsComponent={PatientSearchResults}
            label="Search Members"
          />
        ) : (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{mb: 1}}
              data-testid="selected-member-area">
              Selected member:
              <PfButton
                color="danger"
                onClick={cancelAdd}
                style={styles.revokeButton}
                aria-label="cancel revoke member"
                data-testid="cancel-revoke-member">
                {selectedUser.name}
                <Icon
                  name={IconNames.Xmark}
                  color="white"
                  size={18}
                  style={styles.buttonIcon}
                />
              </PfButton>
            </Stack>
            <TextField
              label="Notes"
              onChange={(evt) => setNotes(evt.target.value)}
              value={notes}
              fullWidth={true}
            />
          </>
        )}
      </div>
    </SaveCancelModal>
  );
};

const styles = {
  searchContainer: {
    marginBottom: '32px',
  },
  revokeButton: {
    marginLeft: '8px',
    fontSize: '11px',
    padding: '4px 8px',
    borderWidth: '2px',
  },
  buttonIcon: {
    marginLeft: '4px',
  },
};
