// @flow

import React from 'react';
import type {Node} from 'react';
import {
  Box,
  Icon,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Events, Theme, Modules, Hooks} from '@wellstone-solutions/common';
import type {UICalendarEventType} from '@wellstone-solutions/common';
import {PfButton} from 'components/shared/PfButton';
import {useStores} from 'hooks/useStores';
import {buildCityStateZip, getDirectionsUrl} from 'utils/addressHelpers';
import {toFullUrl} from 'utils/toFullUrl';

const {useAddress} = Hooks;

type PropsType = {
  calendarEvent: UICalendarEventType,
  direction?: string,
};

export const EventContent = ({
  calendarEvent,
  direction = 'column',
}: PropsType): Node => {
  const {eventStore} = useStores();
  const {location, link, notes} = calendarEvent.data;
  const {
    displayCityStateZip,
    displayFullAddress,
    displayStreetAddress,
    hasCityOrStateOrZip,
    hasStreetAddress,
  } = useAddress(location);

  const {displayDate, displayTime} = Modules.Schedule.useRecurrenceDisplayText({
    calendarEvent,
    hideRecurrence: calendarEvent.data?.override ?? false,
  });

  return (
    <Stack
      direction={direction}
      sx={{width: direction === 'column' ? '50%' : '100%', my: 4}}>
      <Stack sx={{width: '50%'}}>
        <Typography
          variant="h5"
          sx={{color: Theme.colorPalette.blue, fontWeight: 300}}>
          {displayDate}
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            color: Theme.colorPalette.primary,
          }}>
          {displayTime}
        </Typography>
      </Stack>
      <Stack sx={{width: '50%'}}>
        {displayFullAddress.length > 0 && (
          <Stack
            sx={{
              mt: direction === 'column' ? 4 : 0,
              mb: 2,
            }}>
            <Stack direction="row" sx={{flex: 1, alignItems: 'center'}}>
              <Icon
                name={IconNames.MapPin}
                size={40}
                color={Theme.colorPalette.secondary}
              />
              <Stack sx={{ml: 1, mb: 1, color: Theme.colorPalette.primary}}>
                {hasStreetAddress && (
                  <Typography>{displayStreetAddress}</Typography>
                )}
                {hasCityOrStateOrZip && (
                  <Typography>{displayCityStateZip}</Typography>
                )}
              </Stack>
            </Stack>
            <PfButton
              style={styles.buttonStyle}
              color="primary"
              onClick={() => {
                eventStore.addEvent(Events.VISITED_CALENDAR_ADDRESS, {
                  calendar_event_id: calendarEvent.id,
                  original_start: calendarEvent.originalStart,
                });
                window.open(
                  getDirectionsUrl(buildCityStateZip(location || {}, true)),
                  '_blank',
                );
              }}>
              Get Directions
            </PfButton>
          </Stack>
        )}
        {!!link && (
          <Box sx={{mb: 2}}>
            <PfButton
              style={styles.linkButton}
              name={'meeting-link'}
              color="primary"
              onClick={() => {
                eventStore.addEvent(Events.VISITED_CALENDAR_LINK, {
                  calendar_event_id: calendarEvent.id,
                  original_start: calendarEvent.originalStart,
                });

                window.open(toFullUrl(link), '_blank');
              }}>
              <Box sx={{display: 'flex', alignItems: 'center', mr: 1}}>
                <Icon name="Link" color={Theme.colorPalette.blue} />
              </Box>
              <Typography noWrap>{link}</Typography>
            </PfButton>
          </Box>
        )}
        {!!notes && (
          <Typography noWrapMultiLine lines={6}>
            {notes}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

const styles = {
  buttonStyle: {maxWidth: '300px', marginTop: '8px'},
  linkButton: {width: '100%'},
};
