// @flow
import {useState, useEffect} from 'react';
import {Habit} from '@wellstone-solutions/common/models/rest';
import type {
  UIUserHabitType,
  UIHabitAssignmentType,
  UIHabitType,
} from '@wellstone-solutions/common/models/rest/habit/types';
import {Api} from '@wellstone-solutions/common';

export const useMemberHabits = (
  orgId: string,
  memberId: string,
): ({
  memberHabits: UIUserHabitType[],
  organizationHabits: UIHabitType[],
  isLoading: boolean,
  saveMemberHabits: (habitData: Array<UIHabitAssignmentType>) => Promise<void>,
}) => {
  const [memberHabits, setMemberHabits] = useState<UIUserHabitType[]>([]);
  const [organizationHabits, setOrganizationHabits] = useState<UIHabitType[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const fetchMemberHabits = async () => {
    try {
      const response = await Habit.getMemberHabits(orgId, memberId);
      if (response.isSuccess) {
        setMemberHabits(
          // $FlowIgnore
          response.data.habits.map((apiHabit) => Habit.toUI(apiHabit)),
        );
      } else {
        console.error('Failed to fetch member habits:', response);
      }
    } catch (error) {
      console.error('Error fetching member habits:', error);
    }
  };

  const fetchOrganizationHabits = async () => {
    try {
      // this is legacy API - there is an existing ticket to move this to rest. Will add to models in common at that time
      const response = await Api.Instance.current().get(
        `/habits?org_id=${orgId}`,
      );
      if (response.isSuccess) {
        setOrganizationHabits(
          response.data.habits.map((apiHabit) => ({
            id: apiHabit.id,
            name: apiHabit.name,
            key: apiHabit.key,
            tags: apiHabit.tags.map((tag) => ({id: tag.id, name: tag.name})),
            translation: apiHabit.translation,
          })),
        );
      } else {
        console.error('Failed to fetch organization habits:', response);
      }
    } catch (error) {
      console.error('Error fetching organization habits:', error);
    }
  };

  const saveMemberHabits = async (habitData: Array<UIHabitAssignmentType>) => {
    const apiHabitData = habitData.map(Habit.toApiHabitAssignment);
    try {
      const response = await Habit.saveMemberHabit(
        orgId,
        memberId,
        apiHabitData,
      );
      if (response.isSuccess) {
        // Refresh the member habits after saving
        fetchMemberHabits();
      } else {
        console.error('Failed to save member habits:', response);
      }
    } catch (error) {
      console.error('Error saving member habits:', error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([fetchMemberHabits(), fetchOrganizationHabits()]).finally(
      () => {
        setIsLoading(false);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, memberId]);

  return {memberHabits, isLoading, saveMemberHabits, organizationHabits};
};
