// @flow
import React from 'react';
import type {Node} from 'react';
import {Box, Typography} from '@wellstone-solutions/web';

type PropsType = {
  title: string,
  subtitle?: string,
  imgSrc: string,
  imgAlt: string,
};

export const NoResourceMessage = ({
  title,
  subtitle,
  imgSrc,
  imgAlt,
}: PropsType): Node => {
  return (
    <Box align="center">
      <Typography sx={styles.title}>
        {title.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </Typography>
      {subtitle && <Typography sx={styles.subtitle}>{subtitle}</Typography>}
      <img alt={imgAlt} src={imgSrc} />
    </Box>
  );
};

const styles = {
  title: {
    fontSize: '28px',
    lineHeight: '28px',
    color: 'onSurface.main',
    fontWeight: 700,
    padding: 2,
    marginTop: 4,
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '28px',
    textAlign: 'center',
    marginBottom: 3,
  },
};
