// @flow
import React from 'react';
import type {Node} from 'react';
import {Link as CommonLink} from '@wellstone-solutions/web';
import {Link as RouterLink} from 'react-router-dom';

type PropsType = {
  to: string,
  children: any,
  sx?: any,
  onClick?: (event: mixed) => void,
};

export const Link = ({to, children, sx, onClick}: PropsType): Node => {
  return (
    <CommonLink
      component={RouterLink}
      to={to}
      underline="none"
      sx={{fontWeight: 'bold', ...sx}}
      onClick={onClick}>
      {children}
    </CommonLink>
  );
};
