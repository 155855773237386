// @flow
import moment from 'moment';
import {Models} from '@wellstone-solutions/common';
import type {UISessionDocumentType} from './model/types';
import {SessionDocument} from './model';
import {
  DOCUMENT_STATUSES,
  UNIT_LENGTH,
  UNIT_ROUNDING_THRESHOLD,
  DATE_FORMAT_LONG,
} from './constants';
import humanizeDuration from 'humanize-duration';
import {getFullMomentDateTimes} from 'utils/getFullMomentDateTimes';

const {MemberBasic} = Models;

export const createBlankDocument = ({
  memberId,
  staffId,
}: {
  memberId: string,
  staffId: string,
}): UISessionDocumentType => {
  const defaultStart = moment();

  return SessionDocument.Factory({
    member: MemberBasic.Factory({id: memberId}),
    staff: MemberBasic.Factory({id: staffId}),
    status: DOCUMENT_STATUSES.created,
    dateOfService: defaultStart.toISOString(),
    startTime: defaultStart.format('HH:00'),
    endTime: defaultStart.add(1, 'hours').format('HH:00'),
    typeOfService: [],
  });
};

// returns a duration string in hours and minutes with how many 15 minutes units are included.
export const getDurationAndUnits = ({
  startTime,
  endTime,
  dateOfService,
}: {
  startTime?: string,
  endTime?: string,
  dateOfService?: string,
}): string => {
  let duration = '0 hours';
  let diffInMinutes = 0;

  if (startTime && endTime && dateOfService) {
    // calculating duration
    const {start, end} = getFullMomentDateTimes(
      dateOfService,
      startTime,
      endTime,
    );

    if (start && end && start.isBefore(end)) {
      // $FlowIgnore
      duration = humanizeDuration(end.diff(start, 'milliseconds'), {
        round: true,
      });

      // calculate diff in minutes for units
      diffInMinutes = end.diff(start, 'minutes');
    }
  }

  const remainder = diffInMinutes % UNIT_LENGTH;
  let units = Math.floor(diffInMinutes / UNIT_LENGTH);
  units += remainder < UNIT_ROUNDING_THRESHOLD ? 0 : 1;

  return `${duration} (${UNIT_LENGTH} minute units = ${units})`;
};

export const getStatusDate = ({
  documentation,
  format = DATE_FORMAT_LONG,
}: {
  documentation: UISessionDocumentType,
  format?: string,
}): string => {
  const {submittedAt, completed, edited, reverted, status} = documentation;

  const dateMap = {
    [DOCUMENT_STATUSES.draft]: reverted?.changedAt ?? edited,
    [DOCUMENT_STATUSES.completed]: completed,
    [DOCUMENT_STATUSES.submitted]: submittedAt,
  };

  const statusDate = dateMap[status] ?? edited;
  return moment(statusDate).format(format);
};

export const getDocumentUrl = (memberId: string, docId: string): string =>
  `/members/${memberId}/documentation/${docId}`;
