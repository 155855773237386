// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';

import {Box, Calendar as CommonCalendar} from '@wellstone-solutions/web';
import type {ScheduledEventType} from 'modules/calendar/types';

type PropsType = {
  events: Array<ScheduledEventType>,
  onSelectEvent: (ScheduledEventType) => any,
  style?: any,
  ...
};

export const Calendar = (props: PropsType): Node => (
  <Box sx={styles}>
    {/* $FlowFixMe - isAttending is missing on EventType in common */}
    <CommonCalendar {...props} />
  </Box>
);

const styles = {
  '[class^=rbc-], [class*=" rbc-"]': {
    fontFamily: 'inherit !important',
    fontSize: '0.75rem',
    lineHeight: 1.25,
  },
  '.rbc-current-time-indicator': {
    backgroundColor: Theme.colorPalette.blue,
    height: '2px',
  },
  '.rbc-header': {
    backgroundColor: Theme.colorPalette.mediumLight,
    color: Theme.colorPalette.dark,
  },
  '.rbc-agenda-view .rbc-header': {
    pt: 1,
    pb: 1,
  },
  '.rbc-month-view .rbc-header': {
    pt: 1,
    pb: 1,
  },
  '.rbc-time-view .rbc-header': {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.rbc-agenda-view, .rbc-month-view, .rbc-time-view': {
    borderRadius: 1,
  },
  '.rbc-btn-group > button': {
    backgroundColor: Theme.colorPalette.primary,
    borderWidth: 0,
    color: Theme.colorPalette.lightest,
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: 1.25,
    '&:hover': {
      backgroundColor: Theme.colorPalette.violet,
    },
    '&.rbc-active, &.rbc-active:hover': {
      boxShadow: 'none',
      borderWidth: 0,
      backgroundColor: Theme.colorPalette.secondary,
      color: Theme.colorPalette.lightest,
      fontWeight: 700,
    },
  },
  '.rbc-toolbar-label': {
    color: Theme.colorPalette.primary,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  '.rbc-event': {
    padding: 0,
  },
  '.rbc-event, .rbc-day-slot .rbc-background-event': {
    backgroundColor: 'transparent',
  },
  '.rbc-event-content': {
    borderStyle: 'none',
    fontSize: '0.75rem',
    lineHeight: 1,
  },
  '.rbc-today': {
    backgroundColor: Theme.colorPalette.seafoamLight,
  },
  '.rbc-day-slot.rbc-time-column .rbc-event, .rbc-day-slot .rbc-background-event': {
    borderStyle: 'none',
  },
  '.rbc-day-slot.rbc-time-column .rbc-event .rbc-event-label': {
    display: 'none',
  },
  '.rbc-date-cell > .rbc-button-link': {
    fontSize: '0.75rem',
    pb: 0.5,
    pt: 0.5,
  },
  '.rbc-show-more': {
    backgroundColor: 'transparent',
    color: Theme.colorPalette.seafoamDark,
    fontWeight: 'bold',
    pt: 0.5,
    pl: 0.5,
  },
  '.rbc-timeslot-group .rbc-time-slot': {
    pt: 0.5,
  },
  '.rbc-time-slot': {
    fontSize: '0.75rem',
  },
  '.rbc-agenda-table tbody > tr': {
    borderLeft: 'none',
  },
  '*': {
    borderColor: Theme.colorPalette.medium,
  },
  '* button': {
    cursor: 'pointer',
  },
};
