// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {Hooks} from '@wellstone-solutions/common';
import type {UIMemberBasicType} from '@wellstone-solutions/common';
import {Box, Stack} from '@wellstone-solutions/web';
import type {UISessionDocumentType} from 'modules/documentation/model/types';
import {useScrollToTop} from 'hooks/useScrollToTop';
import {usePrompt} from 'hooks/usePrompt';
import {useStores} from 'hooks/useStores';
import {SessionDocument} from '../../model';
import {Header} from './Header';
import {MemberCard} from './MemberCard';
import {FormActionButtons} from './FormActionButtons';
import {Form} from './Form';
import {showAlert, ALERT_TYPES} from 'utils/showAlert';
import {DOCUMENT_STATUSES} from 'modules/documentation/constants';
import {BackButton} from './BackButton';
import {ViewErrors} from 'modules/integration/components';

const FORM_ID = 'new_form';

type PropTypes = {
  member: UIMemberBasicType,
  documentation: UISessionDocumentType,
  loadDocumentation: (documentation: UISessionDocumentType) => void,
  togglePreviewMode: (value: boolean) => void,
};

export const FormContainer = ({
  member,
  documentation,
  loadDocumentation,
  togglePreviewMode,
}: PropTypes): Node => {
  const {sessionDocumentStore} = useStores();
  const {useForm} = Hooks;
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  useScrollToTop({preventScrollState: 'create-documentation'});

  const form = useForm({
    initialValues: documentation,
    schema: {
      ...SessionDocument.schema,
    },
    onSubmit: async (values, rest) => {
      window.scrollTo(0, 0);
      // preview toggles form validation
      togglePreviewMode(true);
      // toggles form.isSubmitting
      return Promise.resolve();
    },
  });

  const updateDoc = async () => {
    setIsSaving(true);
    // no validation on save
    const response = await sessionDocumentStore.updateSessionDoc(
      documentation.id,
      {...form.values, status: DOCUMENT_STATUSES.draft},
    );
    if (response.isSuccess) {
      showAlert('Successfully updated the document.');
      loadDocumentation(response.data);
      form.resetForm({values: response.data});
    } else {
      showAlert(
        'Failed to save the document. Please try again.',
        ALERT_TYPES.ERROR,
      );
    }
    setIsSaving(false);
  };

  const removeDoc = async () => {
    const response = await sessionDocumentStore.removeSessionDoc(
      documentation.id,
    );

    if (response.isSuccess) {
      showAlert('Successfully deleted the document');
      navigate(`/members/${documentation.member.id}/session-docs`);
    } else {
      showAlert('Failed to delete the document. Please try again.');
    }
  };

  const isCreatedStatus = documentation.status === DOCUMENT_STATUSES.created;

  // prevent unintentionally losing unsaved changes
  usePrompt(
    'Are you sure you want to leave? You have unsaved changes that will be lost if you navigate away from this page.',
    // $FlowIgnoreMe
    !isSaving && !form.isSubmitting && (isCreatedStatus || form.dirty),
    isCreatedStatus
      ? () => sessionDocumentStore.removeSessionDoc(documentation.id)
      : null,
  );

  return (
    <Box>
      <ViewErrors documentation={documentation} />
      <Stack direction={{xs: 'column', md: 'row'}}>
        <Stack
          flex={1}
          direction="column"
          justifyContent={{md: 'space-between'}}
          sx={{mb: 4}}>
          <Header
            member={member}
            staff={documentation.staff}
            documentation={documentation}
          />
          <MemberCard member={member} />
          <Form form={form} formId={FORM_ID} />
          <FormActionButtons
            formId={FORM_ID}
            form={form}
            onSave={updateDoc}
            onDelete={removeDoc}
            isSaving={isSaving}
          />
          <BackButton memberId={member.id} memberName={member.name} />
        </Stack>
      </Stack>
    </Box>
  );
};
