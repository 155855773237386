// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {Navigate} from 'react-router-dom';
import {Events, BridgeEventSources} from '@wellstone-solutions/common';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import {hasFlag, TMP_STORY_MANAGER} from 'constants/FeatureFlags';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {StoryList} from './StoryList';

export const StoriesRoot = (): Node => {
  const {appUIStore, eventStore, meStore} = useStores();

  useEventSource(BridgeEventSources.STORIES_PAGES);

  useEffect(() => {
    eventStore.addEvent(Events.VIEWED_STORY_LIST);
    appUIStore.setBreadcrumbs([
      {
        name: NAVIGATION_NAMES.stories,
        url: '/stories',
        active: true,
      },
    ]);
  }, [eventStore, appUIStore]);

  // Remove TMP_STORY_MANAGER feature flag, see PAT-3021
  if (!hasFlag(TMP_STORY_MANAGER, meStore.features)) {
    return <Navigate to="/" />;
  }

  return <StoryList />;
};
