// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import moment from 'moment';
import type {FormType} from '@wellstone-solutions/common';
import {
  Alert,
  Checkbox,
  Stack,
  DatePicker,
  ErrorMessage,
  FormRow,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {uniqueBy} from 'utils/uniqueBy';

type PropsType = {
  form: FormType<any>,
};

export const ProgramsStep = ({form}: PropsType): Node => {
  const {meStore} = useStores();
  const [hasPrograms, setHasPrograms] = useState(true);

  useEffect(() => {
    // If form is valid, we've already entered data
    // and now are returning, let's not overwrite everything
    // $FlowIgnoreMe
    if (form.isValid) {
      return;
    }

    const availablePrograms = meStore.myPrograms.filter(
      (myProgram) =>
        !form.values.programs.find(
          ({id, existing}) => myProgram.id === id && existing,
        ),
    );

    if (availablePrograms.length === 1) {
      // Preselect the first program if there is only one
      form.setFieldValue(
        'programs',
        uniqueBy(
          [
            ...form.values.programs,
            {id: availablePrograms[0].id, existing: false},
          ],
          'id',
        ),
      );
    }

    setHasPrograms(availablePrograms.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (program) => (event) => {
    // Update the form values
    if (event.target.checked) {
      form.setFieldValue(
        'programs',
        uniqueBy(
          [...form.values.programs, {id: program.id, existing: false}],
          'id',
        ),
      );
    } else {
      form.setFieldValue(
        'programs',
        form.values.programs.filter(({id}) => id !== program.id),
      );
    }
  };

  const minEndDate = moment(form.values.admissionStart).add(1, 'days');

  return (
    <>
      <Stack sx={{px: 2}}>
        {!hasPrograms && (
          <Alert severity="info">
            There are no additional programs to assign.
          </Alert>
        )}
        {meStore.myPrograms.map((program) => (
          <div key={program.id}>
            <Checkbox
              checked={form.values.programs.some(({id}) => id === program.id)}
              disabled={form.values.programs.some(
                ({id, existing}) => program.id === id && existing,
              )}
              fontControlLabelProps={{
                style: {color: Theme.colorPalette.darkest},
              }}
              label={program.name}
              onChange={handleCheckboxChange(program)}
              data-testid={`checkbox-${program.id}`}
            />
          </div>
        ))}
      </Stack>
      <Stack sx={{px: 1}} direction="row">
        <FormRow sx={{marginRight: 4}}>
          <DatePicker
            label="Start Date"
            fieldName="admissionStart"
            onChange={(date) => {
              form.setFieldValue(
                'admissionStart',
                date?.isValid() ? date.toISOString() : '',
              );
            }}
            value={form.values.admissionStart}
          />
          <ErrorMessage
            name="admissionStart"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <DatePicker
            minDate={minEndDate}
            label="(Estimated) End Date"
            fieldName="admissionEnd"
            onChange={(date) => {
              form.setFieldTouched('admissionEnd', true);
              form.setFieldValue(
                'admissionEnd',
                date?.isValid() ? date.toISOString() : '',
              );
            }}
            value={form.values.admissionEnd}
          />
          <ErrorMessage
            name="admissionEnd"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
      </Stack>
    </>
  );
};
