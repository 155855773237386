import React, {useEffect, useState} from 'react';
import {IdleTimerProvider, useIdleTimerContext} from 'react-idle-timer';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import Button from 'react-bulma-components/lib/components/button';
import {useStores} from 'hooks/useStores';

export const SESSION_TIMEOUT_WARNING = 1000 * 60 * 20; // 20 minutes
export const SESSION_LOGOUT_DELAY = 1000 * 30; // 30 second warning

function Prompt() {
  const [remaining, setRemaining] = useState(SESSION_LOGOUT_DELAY / 1000);
  const {activate, getRemainingTime} = useIdleTimerContext();

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Dialog onClose={() => {}} open={true}>
      <DialogTitle>Session Expiration Warning</DialogTitle>
      <DialogContent>
        <p>Your session is about to expire in {remaining} seconds.</p>
        <p>Click below to remain logged in.</p>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', paddingBottom: 4, px: 4}}>
        <Button onClick={activate} variant="contained" color="primary">
          I'm Here
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const IdleTimer = () => {
  const {meStore} = useStores();

  const [showPrompt, setShowPrompt] = useState(false);

  // invoked by idle-timer after a delay of timeout minus delay
  const onPrompt = () => {
    setShowPrompt(true);
  };

  // invoked after prompt ignored and full timeout reached
  const onIdle = () => {
    meStore.signOut();
  };

  const onActive = (event) => {
    setShowPrompt(false);
  };

  return (
    <IdleTimerProvider
      timeout={SESSION_TIMEOUT_WARNING}
      promptBeforeIdle={SESSION_LOGOUT_DELAY}
      onPrompt={onPrompt}
      onIdle={onIdle}
      onActive={onActive}>
      {showPrompt && <Prompt />}
    </IdleTimerProvider>
  );
};
