class OrgStore {
  routes = [
    {
      method: 'GET',
      route: /orgs\/[A-Z0-9-]*$/i,
      action: this.getOrg.bind(this),
    },
  ];

  constructor(tutorialStore) {
    this.tutorialStore = tutorialStore;
  }

  getOrg({route, params}) {
    const {programStore, groupStore} = this.tutorialStore.stores;

    return {
      data: {
        programs: programStore.programs,
        group_categories: groupStore.groupCategories,
        habits: [],
      },
      status: 'ok',
      messages: [],
      messages_type: 'string_array',
    };
  }
}

export default OrgStore;
