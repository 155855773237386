// @flow
import React from 'react';
import type {Node} from 'react';
import {PfButton} from 'components/shared/PfButton';
import styles from './GettingStarted.module.scss';

type PropsType = {
  onGetStarted: () => any,
};

export const GettingStarted = ({onGetStarted}: PropsType): Node => {
  return (
    <>
      <div className={styles.introduction}>
        <p>
          We've collected a group of activities and habits that can help any one
          of us to live a healthier lifestyle, and really help someone in
          recovery anchor their days in a healthy routine. Each client is going
          through their own recovery though and will follow their own path.
        </p>
        <p>Get started setting up your client’s routine.</p>
      </div>
      <PfButton onClick={onGetStarted}>Get Started</PfButton>
    </>
  );
};
