// @flow
import {IconNames} from '@wellstone-solutions/web';

export const PUBNUB_TYPES = {
  canceled: 'canceled',
  created: 'created',
  deleted: 'deleted',
  updated: 'updated',
  updated_time: 'updated_time',
  updated_location: 'updated_location',
};

export const documentStatusMap = {
  reverted: {
    icon: IconNames.PageEdit,
    title: 'Documentation Reverted',
    statusMessage: 'Session document has been moved to in-progress.',
  },
};
