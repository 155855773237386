import React, {useRef, useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';

/**
 * A wrapping element for use in the tutorial.
 * This will silently allow an element to be popped over the tutorial
 * blocker window, and allow for functions to be ran when rendering takes place.
 * @param {Object} props
 */
const TutorialDiv = (props) => {
  const {id, tutorialFunction, children, tutorialStore, styles} = props;
  const element = useRef(null);
  const [isTutorialComponent, setIsTutorialComponent] = useState(false);
  const [extraStyles, setExtraStyles] = useState({});
  const [hasRunFunction, setHasRunFunction] = useState(false);

  useEffect(() => {
    if (!element || !tutorialStore || !id) {
      return;
    }
    if (tutorialStore.highlightComponents.indexOf(id) !== -1) {
      setIsTutorialComponent(true);
      setExtraStyles({
        zIndex: 501,
        background: '#FFF',
        position: 'relative',
        boxShadow: '0 0 8px 12px rgba(255, 255, 255, 1)',
      });

      if (
        !hasRunFunction &&
        tutorialFunction &&
        typeof tutorialFunction === 'function'
      ) {
        tutorialFunction();
        setHasRunFunction(true);
      }
    } else {
      setExtraStyles({});
    }

    return;
  }, [
    hasRunFunction,
    id,
    isTutorialComponent,
    tutorialFunction,
    tutorialStore,
    tutorialStore.currentStep,
  ]);

  const childProps = {
    id: id,
    children: children,
  };

  return (
    <div {...childProps} ref={element} style={{...styles, ...extraStyles}} />
  );
};

export default inject('tutorialStore')(observer(TutorialDiv));
