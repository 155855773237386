// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {Divider, Stack, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import moment from 'moment';
import {useStores} from 'hooks/useStores';
import {showAlert} from 'utils/showAlert';
import {getDurationAndUnits, getStatusDate} from '../../../utils';
import {getFullMomentDateTimes} from 'utils/getFullMomentDateTimes';
import type {UISessionDocumentType} from 'modules/documentation/model/types';
import {
  DOCUMENT_STATUSES,
  DATE_FORMAT_LONG,
  DATE_TIME_FORMAT,
  TIME_FORMAT_12,
  DOCUMENT_STATUS_LOOKUP,
  SESSION_TYPES,
} from 'modules/documentation/constants';
import {BackButton} from '../BackButton';
import {ActionButton} from './ActionButton';
import {StatusChip} from '../StatusChip';
import {PreviewActionButtons} from '../PreviewActionButtons';
import {DocumentDetailRow} from '../DocumentDetailRow';
import {RevertedDetailRow} from '../RevertedDetailRow';
import {EHRStatusView} from 'modules/integration/components/EHRStatusView';
import {ConnectWarning} from 'modules/integration/components/ConnectWarning';
import {ViewErrors} from 'modules/integration/components/ViewErrors';

type PropsType = {
  documentation: UISessionDocumentType,
  loadDocumentation: (documentation: UISessionDocumentType) => void,
  togglePreviewMode: (value: boolean) => void,
  isPreviewing?: boolean,
};

const ContentPlaceholder = ({textOverride}: {textOverride?: string}): Node => (
  <Typography style={{...styles.content, ...styles.contentPlaceholder}}>
    {textOverride ?? 'Document is in progress.'}
  </Typography>
);

export const View = ({
  documentation,
  loadDocumentation,
  togglePreviewMode,
  isPreviewing,
}: PropsType): Node => {
  const {meStore} = useStores();
  const isOwner = meStore.activeMembership.id === documentation.staff.id;
  const orgIntegration = meStore.ehrIntegrationOption;

  const showIntegrationAlert =
    isOwner &&
    !!orgIntegration &&
    (documentation.member.integrations?.length === 0 ||
      documentation.staff.integrations?.length === 0);

  const {
    dateOfService,
    didMemberAttend,
    isAllDay,
    location,
    member,
    notes,
    nextSteps,
    startTime,
    status,
    endTime,
    sessionType,
    staff,
    completed,
    titleOrReason,
    typeOfService,
  } = documentation;

  const duration = getDurationAndUnits({
    dateOfService,
    startTime,
    endTime,
  });

  const {start: startTimeAsDate, end: endTimeAsDate} = getFullMomentDateTimes(
    dateOfService,
    startTime,
    endTime,
  );

  const staffEmail = staff.email ?? '-';

  const dateFormat = (date) =>
    date ? moment(date).format(DATE_FORMAT_LONG) : '-';

  const formCompleted =
    [DOCUMENT_STATUSES.completed, DOCUMENT_STATUSES.submitted].indexOf(
      status,
    ) >= 0;

  const statusDate = getStatusDate({
    documentation,
    format:
      status === DOCUMENT_STATUSES.submitted
        ? DATE_FORMAT_LONG
        : DATE_TIME_FORMAT,
  });

  const readableTime = (dateTime: moment | null): string => {
    if (!dateTime) {
      return '-';
    }
    return dateTime.format(TIME_FORMAT_12);
  };

  const sessionTypeObject = SESSION_TYPES.find(
    (type) => type.value === sessionType,
  );

  // typeOfService contains array of objects with id and name
  const serviceTypeNames = typeOfService?.map(({name}) => name).join(', ');

  const hasNotes = notes && notes.length > 0;
  const hasSteps = nextSteps && nextSteps.length > 0;

  const sessionDetails = [
    {label: 'Peer', value: staff.name},
    {label: 'Date of service', value: dateFormat(dateOfService)},
    // $FlowIgnore
    {
      label: 'Time',
      value: isAllDay
        ? '-'
        : `${readableTime(startTimeAsDate)} to ${readableTime(endTimeAsDate)}`,
    },
    {label: 'Duration', value: isAllDay ? 'All Day' : duration},
    {
      label: 'Attendance',
      value: `${didMemberAttend ? 'Member attended' : 'Member did not attend'}`,
    },
    {label: 'Type of session', value: sessionTypeObject?.label},
    {label: 'Location of the service', value: location},
    {label: 'Type of service', value: serviceTypeNames},
    {label: 'Title or reason for session', value: titleOrReason},
  ];

  useEffect(() => {
    // remind owner to mark as submitted once completed
    if (isOwner && status === DOCUMENT_STATUSES.completed) {
      showAlert(
        'Once ready, click "Tag as Submitted" to finish the documentation process.',
        'info',
      );
    }
  }, [isOwner, status]);

  return (
    <>
      {/* display warning if pre-conditions for EHR submit haven't been met */}
      {showIntegrationAlert && (
        <ConnectWarning
          documentation={documentation}
          orgIntegration={orgIntegration}
        />
      )}

      <ViewErrors documentation={documentation} />

      <Stack direction="column">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Typography sx={{fontSize: 32, color: Theme.colorPalette.darker}}>
              Session documentation
            </Typography>
            <DocumentDetailRow label="Peer" sx={styles.detailRow}>
              {staff.name}
            </DocumentDetailRow>
            {orgIntegration && (
              <EHRStatusView
                orgIntegration={orgIntegration}
                integrations={documentation.staff.integrations}
              />
            )}
            <DocumentDetailRow
              label={DOCUMENT_STATUS_LOOKUP[status]?.label}
              sx={styles.detailRow}>
              {statusDate}
            </DocumentDetailRow>
            {!formCompleted && (
              <RevertedDetailRow
                documentation={documentation}
                sx={styles.detailRow}
              />
            )}
            <StatusChip
              status={status}
              sx={{
                height: Theme.spacing.big,
                mt: `${Theme.spacing.small}px`,
              }}
            />
          </Stack>

          {formCompleted && (
            <ActionButton
              documentation={documentation}
              loadDocumentation={loadDocumentation}
            />
          )}
        </Stack>

        <Stack style={styles.memberHeader}>
          <Typography sx={{color: Theme.colorPalette.darkest}} variant="h5">
            {member.name}
          </Typography>
          {orgIntegration && (
            <EHRStatusView
              orgIntegration={orgIntegration}
              integrations={documentation.member?.integrations}
            />
          )}
          <Typography sx={{marginTop: 1}}>{`Birthdate: ${dateFormat(
            member.birthdate,
          )}`}</Typography>
        </Stack>

        <Divider />
        {/* session details */}
        <Stack>
          <Stack style={styles.section} direction={{xs: 'column', md: 'row'}}>
            <Typography style={styles.header}>Session details</Typography>
            <Stack style={styles.content} direction="column">
              {sessionDetails.map(({label, value}) => (
                <DocumentDetailRow
                  key={`${label}-${String(value)}`}
                  label={label}
                  sx={styles.detailRow}>
                  {String(value ?? '')}
                </DocumentDetailRow>
              ))}
            </Stack>
          </Stack>
          {/* session Notes */}
          <Stack style={styles.section} direction={{xs: 'column', md: 'row'}}>
            <Typography style={styles.header}>Session notes</Typography>
            {hasNotes || formCompleted ? (
              <Typography style={styles.content}>{String(notes)}</Typography>
            ) : (
              <ContentPlaceholder />
            )}
          </Stack>
          {/* Next Steps */}
          <Stack style={styles.section} direction={{xs: 'column', md: 'row'}}>
            <Typography style={styles.header}>Next steps</Typography>
            {hasSteps || formCompleted ? (
              <Typography style={styles.content}>
                {String(nextSteps)}
              </Typography>
            ) : (
              <ContentPlaceholder textOverride="Not Available." />
            )}
          </Stack>
          {/* Service Provider */}
          <Stack style={styles.section} direction={{xs: 'column', md: 'row'}}>
            <Typography style={styles.header}>Service provider</Typography>
            <Stack style={styles.content} direction="column">
              <DocumentDetailRow label="Peer" sx={styles.detailRow}>
                {staff.name}
              </DocumentDetailRow>
              {orgIntegration && (
                <EHRStatusView
                  orgIntegration={orgIntegration}
                  integrations={documentation.staff.integrations}
                />
              )}
              {completed && (
                <Typography>{`Digitally signed by ${staff.name} on ${moment(
                  completed,
                ).format(DATE_TIME_FORMAT)}`}</Typography>
              )}
              <a href={`mailto:${staffEmail}`} target="_blank">
                <Typography sx={styles.email}>{staffEmail}</Typography>
              </a>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {isPreviewing ? (
        <PreviewActionButtons
          documentation={documentation}
          ehrIntegration={orgIntegration}
          togglePreviewMode={togglePreviewMode}
          loadDocumentation={loadDocumentation}
        />
      ) : (
        <BackButton memberId={member.id} memberName={member.name} />
      )}
    </>
  );
};

const styles = {
  detailRow: {
    lineHeight: 2,
    fontSize: Theme.spacing.medium,
  },
  header: {
    fontSize: 24,
    color: Theme.colorPalette.darkest,
    flex: 1,
  },
  button: {
    mobile: {
      backgroundColor: Theme.colorPalette.primary,
      borderRadius: 40,
      padding: 0,
      height: 60,
    },
    desktop: {
      backgroundColor: Theme.colorPalette.primary,
      color: 'white',
      borderRadius: 40,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: Theme.spacing.big,
      paddingRight: Theme.spacing.big,
      height: Theme.spacing.monster,
      textTransform: 'none',
      fontSize: 16,
    },
  },
  icon: {strokeWidth: 3},
  memberHeader: {
    marginTop: Theme.spacing.large,
    marginBottom: Theme.spacing.medium,
  },
  section: {
    paddingTop: Theme.spacing.small,
    paddingBottom: Theme.spacing.small,
    marginTop: 12,
  },
  content: {
    color: Theme.colorPalette.darker,
    marginLeft: Theme.spacing.large,
    marginTop: Theme.spacing.mini,
    flex: 2,
    whiteSpace: 'pre-line',
  },
  contentPlaceholder: {
    color: Theme.colorPalette.dark,
    fontStyle: 'italic',
  },
  label: {
    fontWeight: 'bold',
    color: Theme.colorPalette.darkest,
  },
  email: {color: Theme.colorPalette.blue, fontWeight: 'bold'},
};
