import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Heading from 'react-bulma-components/lib/components/heading';
import Dropdown from 'react-bulma-components/lib/components/dropdown';
import Level from 'react-bulma-components/lib/components/level';

import {BridgeEventSources} from '@wellstone-solutions/common';
import {useEventSource} from 'hooks/useEventSource';
import {useStores} from 'hooks/useStores';
import {ENTITY_LEVEL, AccessControl} from 'modules/rbac';
import {NewAnnouncement, PastAnnouncements} from 'modules/announcements';
import styles from './Announcements.module.scss';

const ALL_MEMBERS_VALUE = -1;

export const AnnouncementsPage = () => {
  useEventSource(BridgeEventSources.ANNOUNCEMENTS_PAGE);

  const {group_id} = useParams();
  const {appUIStore, groupStore, RBACStore} = useStores();
  const navigate = useNavigate();

  const canSendToAllMembers = RBACStore.hasAccess(
    AccessControl.announcements.sendToAllMembers,
  );
  const canAccessAllGroups = RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all);

  const groups = canAccessAllGroups ? groupStore.groups : groupStore.myGroups;
  const selectedGroup = groups.find((g) => g.id === group_id)?.name;
  const groupName =
    canSendToAllMembers && !selectedGroup ? 'All Members' : selectedGroup;

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: 'Announcements',
        url: '/announcements',
        active: true,
      },
    ]);
  }, [appUIStore]);

  const handleChange = (groupId) => {
    if (groupId !== ALL_MEMBERS_VALUE) {
      navigate(`/announcements/${groupId}`);
      return;
    }

    navigate('/announcements');
  };

  return (
    <div>
      <Level>
        <Level.Side align="left">
          <Level.Item>
            <Heading className={styles.heading} size={5}>
              Announcements for
            </Heading>
          </Level.Item>
          <Level.Item>
            <Dropdown
              value={group_id}
              onChange={handleChange}
              className="short-drop evaluator-drop">
              <Dropdown.Item title="All Members" value={ALL_MEMBERS_VALUE}>
                All Members
              </Dropdown.Item>
              {groups.map((group) => (
                <Dropdown.Item
                  key={group.id}
                  title={group.name}
                  value={group.id}>
                  {group.name}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </Level.Item>
        </Level.Side>
      </Level>
      <Level className={styles.content}>
        <Level.Side className={styles.newAnnouncement}>
          <NewAnnouncement groupId={group_id} groupName={groupName} />
        </Level.Side>
        <Level.Side className={styles.pastAnnouncements}>
          <PastAnnouncements groupId={group_id} />
        </Level.Side>
      </Level>
    </div>
  );
};
