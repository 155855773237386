// @flow
export const CARD_TYPES = {
  basic: 'basic',
  comparison: 'comparison',
};

export const CARD_VALUE_FORMATS = {
  percent: '.0%',
};

export const INTERVALS = {
  week: 7,
  month: 30,
};

// $FlowIgnore
export const INTERVAL_STRINGS = {
  [INTERVALS.week]: 'week',
  [INTERVALS.month]: 'month',
};

export const DATASOURCE = {
  byOrg: 'GetDashboardDataByOrg',
  byGroups: 'GetDashboardDataByGroups',
  byPrograms: 'GetDashboardDataByPrograms',
};

export const CARD_MAP = {
  patientTotalEnrolledCount: {
    title: 'Total Members',
    tooltip: 'Members enrolled to use Pathfinder Companion',
    cardType: CARD_TYPES.basic,
  },
  patientActiveRate: {
    title: 'Active Members',
    tooltip: 'Percentage of total members that have used Pathfinder Companion',
    cardType: CARD_TYPES.comparison,
    valueFormat: CARD_VALUE_FORMATS.percent,
  },
  patientNewCount: {
    title: 'New Members Added',
    tooltip: 'New members that have been enrolled to use Pathfinder Companion',
    cardType: CARD_TYPES.comparison,
  },
  userOpenedAppCount: {
    title: 'App Opens',
    tooltip: 'Number of times members have opened Pathfinder Companion',
    cardType: CARD_TYPES.comparison,
  },
  userAddedCheckinCount: {
    title: 'Check-Ins Added',
    tooltip: 'Number of Check-Ins completed by members',
    cardType: CARD_TYPES.comparison,
  },
  userCompletedHabitCount: {
    title: 'Habits Completed',
    tooltip: 'Number of habits completed by members',
    cardType: CARD_TYPES.comparison,
  },
  staffTotalCount: {
    title: 'Total Staff',
    tooltip: 'Staff enrolled to use Pathfinder Bridge',
    cardType: CARD_TYPES.basic,
  },
  staffLoginRate: {
    title: 'Active Staff',
    tooltip:
      'Percentage of total staff that have logged into Pathfinder Bridge',
    cardType: CARD_TYPES.comparison,
    valueFormat: CARD_VALUE_FORMATS.percent,
  },
  staffNewCount: {
    title: 'New Staff Added',
    tooltip: 'New staff members that have been added to Pathfinder Bridge',
    cardType: CARD_TYPES.comparison,
  },
};

export const GROUP_QUERY_DEFAULTS = {
  columns: [
    'patientActiveRate',
    'patientNewCount',
    'userAddedCheckinCount',
    'userCompletedHabitCount',
  ],
  dataSource: DATASOURCE.byGroups,
  interval: INTERVALS.week,
};

export const PROGRAM_QUERY_DEFAULTS = {
  columns: [
    'patientTotalEnrolledCount',
    'staffTotalCount',
    'userOpenedAppCount',
    'patientActiveRate',
    'staffLoginRate',
    'patientNewCount',
    'staffNewCount',
    'userAddedCheckinCount',
  ],
  dataSource: DATASOURCE.byPrograms,
  interval: INTERVALS.month,
};
