// @flow
import React, {useState} from 'react';
import type {Node} from 'react';

import {
  Icon,
  Button,
  IconNames,
  Box,
  Typography,
} from '@wellstone-solutions/web';

import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {useStores} from 'hooks/useStores';
import {PrimaryNavigation} from 'modules/app/components/SiteNavigation/PrimaryNavigation';

import {SiteHeaderDrawer} from './SiteHeaderDrawer';

export const NavigationPanel = (): Node => {
  const theme = useTheme();
  const {meStore} = useStores();

  const orgName = meStore.activeMembership?.organization?.name ?? '';

  const [navigationAnchorEl, setNavigationAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose = () => {
    setNavigationAnchorEl(null);
  };
  const handleClick = (event) => {
    event.stopPropagation();
    let anchor =
      event.target.closest('.header_tool') ?? event.target.parentNode;
    setNavigationAnchorEl(anchor);
  };

  const handleNavigateEvent = () => {
    handleClose();
  };

  return (
    <>
      <Button
        data-testid="header-navigation-button"
        onClick={handleClick}
        tabIndex={1}
        sx={style.button}>
        <Icon
          name={IconNames.Menu}
          size={isMobile ? 22 : 24}
          color={theme.palette.primary.contrastText}
          sx={{margin: 0, cursor: 'pointer'}}
        />
      </Button>
      <SiteHeaderDrawer
        onClose={handleClose}
        anchorEl={navigationAnchorEl}
        title={null}
        fullHeight={true}>
        <Box sx={{padding: '20px', width: '300px'}}>
          <Typography
            component="h3"
            sx={style.organization}
            data-testid="organization_name">
            {orgName}
          </Typography>
          <PrimaryNavigation onNavigate={handleNavigateEvent} />
        </Box>
      </SiteHeaderDrawer>
    </>
  );
};

const style = {
  button: {
    padding: 0,
  },
  organization: {
    marginBottom: 3,
    fontWeight: 500,
    fontSize: 24,
  },
};
