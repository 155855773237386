// @flow
import {RBACStore} from './store';
import {ROLES} from 'utils/Permissions';

// A helper to mock out the permitted groups and programs a test user may have
// When definining your mock store in the tests set:
// `RBACStore: mockRBACStore({myRole: role})`
export const mockRBACStore = ({
  myRole = ROLES.counselor,
  permittedGroupIds = [],
  permittedProgramIds = [],
}: {
  myRole: $Values<typeof ROLES>,
  permittedGroupIds: Array<string>,
  permittedProgramIds: Array<string>,
}): RBACStore => {
  // $FlowIgnoreMe
  const store = new RBACStore({
    stores: {
      meStore: {
        permittedGroupIds,
        permittedProgramIds,
        me: {
          membership: {
            role: myRole,
          },
        },
      },
    },
  });

  return store;
};
