// @flow
import {Models, Factories} from '@wellstone-solutions/common';
import type {
  ApiSessionDocumentInputType,
  ApiSessionDocumentInputFactoryType,
  ApiSessionDocumentType,
  ApiSessionDocumentFactoryType,
  UISessionDocumentType,
  UISessionDocumentFactoryType,
} from './types';

const {MemberBasic} = Models;
const {createFactory} = Factories;

const ApiBaseFields = {
  id: null,
  change_reason: '',
  edited: null,
  status: 'draft',
  organization: '',
  date_of_service: '',
  start_time: '',
  end_time: '',
  title_or_reason: '',
  session_type: '',
  did_member_attend: '',
  location: '',
  notes: '',
  next_steps: '',
  is_all_day: false,
  submitted_at: null,
  completed: null,
  reverted: null,
  integration_errors: [],
  integration_relationship: {},
};

const UIBaseFields = {
  id: null,
  changeReason: '',
  edited: null,
  status: 'draft',
  dateOfService: '',
  startTime: '',
  endTime: '',
  titleOrReason: '',
  sessionType: '',
  didMemberAttend: '',
  location: '',
  notes: '',
  nextSteps: '',
  isAllDay: false,
  submittedAt: null,
  completed: null,
  typeOfService: [],
  reverted: null,
  integrationErrors: [],
  integrationRelationship: {},
};

// input/payloads
const defaultApiInputFields = {
  ...ApiBaseFields,
  member_id: '',
  staff_id: '',
  type_of_service_ids: [],
};

// response
const defaultApiFields = {
  ...ApiBaseFields,
  member: MemberBasic.ApiFactory(),
  staff: MemberBasic.ApiFactory(),
  created: '',
  modified: '',
  type_of_service: [],
};

const defaultUIFields = {
  ...UIBaseFields,
  member: MemberBasic.Factory(),
  staff: MemberBasic.Factory(),
  created: '',
  modified: '',
};

// used for tests - to mock a call to api endpoint
export const ApiInputFactory = (
  overrides: ApiSessionDocumentInputFactoryType,
): ApiSessionDocumentInputType =>
  createFactory(defaultApiInputFields, overrides);

// Response shapes
export const ApiFactory = (
  overrides: ApiSessionDocumentFactoryType,
): ApiSessionDocumentType => createFactory(defaultApiFields, overrides);

// makes UI Response shape
export const Factory = (
  overrides: UISessionDocumentFactoryType,
): UISessionDocumentType => createFactory(defaultUIFields, overrides);
