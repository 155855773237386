import React, {useRef, useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import RootStore from '../../../mobx/RootStore';

/**
 * A core NavLink component that abstracts the provided react-router-dom
 * version with extra functionality around tutorial.
 * NavLinks tend to have a lot of custom context, therefore a prop of 'tutorialStyles'
 * can be sent to apply custom styles, only when in use.
 */
export default (props) => {
  const {
    id,
    onClick = null,
    tutorialStyles = {},
    activeClassName = '',
    className = '',
  } = props;
  const buttonEl = useRef(null);
  const [zIndex, setZIndex] = useState(0);
  const [isTutorialComponent, setIsTutorialComponent] = useState(false);
  const [extraStyles, setExtraStyles] = useState({});

  const rootStore = RootStore.getInstance();
  const {tutorialStore} = rootStore.stores;

  useEffect(() => {
    if (!buttonEl || !tutorialStore || !id) {
      return;
    }

    if (tutorialStore.activeComponents.indexOf(id) !== -1) {
      setIsTutorialComponent(true);
      if (id !== 'nextButton') {
        setZIndex(buttonEl.current.style.zIndex);
        setExtraStyles(tutorialStyles);
      }
    }

    return () => {
      setExtraStyles({zIndex: zIndex});
    };
  }, [id, tutorialStore, tutorialStore.currentStep, tutorialStyles, zIndex]);

  const childProps = Object.assign({}, props);
  delete childProps.tutorialStyles;
  delete childProps.activeClassName;
  delete childProps.className;

  return (
    <div style={extraStyles}>
      <NavLink
        {...childProps}
        className={({isActive}) =>
          isActive
            ? [activeClassName, className].filter(Boolean).join(' ')
            : className
        }
        ref={buttonEl}
        onClick={(element) => {
          if (isTutorialComponent) {
            tutorialStore.nextStep();
            // Return to previous zIndex.
            setExtraStyles({zIndex: zIndex});
          }
          if (typeof onClick === 'function') {
            onClick(element);
          }
        }}
      />
    </div>
  );
};
