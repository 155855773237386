// @flow

import {Roles} from '@wellstone-solutions/common';

export const ENTITY_LEVEL = {
  all: 'all',
  program: 'program',
  group: 'group',
};

// Entity Access Control
// Used to determine which entities are accessible by which roles
export const EntityAC = {
  all: [Roles.SUPER_ADMIN],
  program: [Roles.ADMIN],
  group: [Roles.COUNSELOR, Roles.PEER],
};

// Types of accessible entities
// note: "member" is missing from here because there are no checks in bridge for member access
export const ENTITY_TYPES = {
  program: 'program',
  group: 'group',
};

// Feature Access Control
// Determines which roles can access which features in the app
// Used in conjection with `RBACStore.hasAccess()`
export const AccessControl = {
  announcements: {
    sendToAllMembers: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  dashboard: {
    viewPrograms: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  groups: {
    viewListActions: [Roles.SUPER_ADMIN, Roles.ADMIN],
    defaultFilterAll: [Roles.SUPER_ADMIN, Roles.ADMIN],
    createGroup: [Roles.SUPER_ADMIN, Roles.ADMIN],
    addMemberToGroup: [Roles.SUPER_ADMIN, Roles.ADMIN], // note: counselors can add members to groups from MemberDetail
  },
  members: {
    viewListActions: [Roles.SUPER_ADMIN, Roles.ADMIN],
    editMember: [Roles.SUPER_ADMIN, Roles.ADMIN],
    sendReinvite: [Roles.SUPER_ADMIN, Roles.ADMIN],
    addMember: [Roles.SUPER_ADMIN, Roles.ADMIN],
    canAddProgramEnrollment: [Roles.SUPER_ADMIN, Roles.ADMIN],
    canAssignEHRIntegrations: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  staff: {
    viewListActions: [Roles.SUPER_ADMIN],
    viewListProgress: [Roles.SUPER_ADMIN],
    addStaff: [Roles.SUPER_ADMIN, Roles.ADMIN],
    addAdmins: [Roles.SUPER_ADMIN],
    editStaff: [Roles.SUPER_ADMIN, Roles.ADMIN],
    editAllStaff: [Roles.SUPER_ADMIN],
    viewSelfStaffDetail: [Roles.SUPER_ADMIN],
    viewPermissions: [Roles.SUPER_ADMIN, Roles.ADMIN],
    editSelfProgramPermissions: [Roles.SUPER_ADMIN],
    viewActivity: [Roles.SUPER_ADMIN],
    canAddProgramEnrollment: [Roles.SUPER_ADMIN],
    editRevocations: [Roles.SUPER_ADMIN],
    viewDischargedMembers: [Roles.SUPER_ADMIN],
    canAssignEHRIntegrations: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  documentation: {
    viewMySessionDocs: [Roles.COUNSELOR, Roles.PEER],
    viewProgramFilter: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
};
