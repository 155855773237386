// Third Party
import moment from 'moment';

// WS
import {Storage} from '@wellstone-solutions/web';
import {Api, EventPlatforms} from '@wellstone-solutions/common';

const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY;
const asyncPrefix = 'event_';

export const eventTypes = {
  USER_CREATED_MESSAGE: 'user_created_message',
  USER_SHARED_RESOURCE_FROM_CHAT: 'user_shared_resource_from_chat',
  // 'user_opened_app',
  // 'user_reached_30s',
  // 'user_viewed_announcements',
  // 'user_viewed_checkin_chart_info',
  // 'user_viewed_chart_time',
  // 'user_viewed_chart_date',
  // 'user_viewed_chart_day',
  // 'user_viewed_checkin',
  // 'user_added_checkin',
  // 'user_viewed_my_meetings',
  // 'user_viewed_add_meeting',
  // 'user_created_meeting',
  // 'user_updated_meeting',
  // 'user_viewed_saved_resources',
  // 'user_viewed_settings',
  // 'user_viewed_stories',
  // 'user_viewed_story',
  // 'user_started_story',
  // 'user_finished_story',
  // 'user_viewed_conversations',
  // 'user_hid_channel',
  // 'user_viewed_chat',
  // 'user_saved_resource_from_chat',
  // 'user_saved_meeting_from_chat',
  // 'user_shared_resource_from_resources',
  // 'user_shared_resource_from_saved_resources',
  // 'user_shared_meeting_from_chat',
  // 'user_shared_meeting_from_my_meetings',
  // 'user_marked_message',
  // 'user_had_message_marked',
  // 'user_reported_abusive_user',
  // 'user_reported_as_abusive',
  // 'user_relapsed',
  // 'user_viewed_contacts',
  // 'user_added_friend',
  // 'user_viewed_directory',
  // 'user_viewed_channel_detail',
  // 'user_viewed_resources',
  // 'user_saved_resource_from_resources',
  // 'user_viewed_badges',
  // 'user_viewed_badge_detail',
};

class EventStore {
  eventSource = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this._loadAsync();
  }

  _loadAsync() {
    try {
      for (const type of Object.values(eventTypes)) {
        this.events[type] =
          Storage.getItems(asyncPrefix + type, CRYPTO_KEY) || [];
      }
    } catch {
      // Could not decrypt, just clear out
      this.clear();
    }
  }

  events = {};

  clear() {
    this.events = {};

    Storage.removeItems(asyncPrefix);
  }

  async addEvent(eventType, eventData = {}) {
    if (!this.events[eventType]) {
      this.events[eventType] = [];
    }

    const timestamp = moment().format();
    const eventPayload = {
      data: {
        ...eventData,
        platform: EventPlatforms.BRIDGE,
        source: this.eventSource,
      },
      timestamp,
    };

    this.events[eventType].push(eventPayload);

    const key = asyncPrefix + eventType + '_' + moment().format('X');
    Storage.setItem(key, eventPayload, CRYPTO_KEY);

    this.reportEvents(eventType);
  }

  checkEvents() {
    // check all arrays and report events for those that are not empty
    Object.values(eventTypes).forEach((type) => {
      if (this.events[type] && this.events[type].length > 0) {
        this.reportEvents(type);
      }
    });
  }

  async reportEvents(eventType) {
    // TODO: For future use if batch processing
    // const first = moment(this.events[type][0]);
    // const last = moment(this.events[type][this.events[type].length - 1]);
    // if (
    //   Math.floor(last.diff(first, 'hours')) >= 1 ||
    //   this.events[type].length > 10
    // ) {}

    const eventArray = this.events[eventType].map((event, t) => {
      const {data = {}, timestamp} = event;
      return {
        category: eventType,
        created: timestamp,
        data,
      };
    });
    const payload = {
      events: eventArray,
    };
    try {
      const org = this.rootStore.stores.meStore.me.membership.organization.id;
      let response = await Api.Instance.current().post(
        `/orgs/${org}/events`,
        payload,
      );
      this.events[eventType] = [];

      Storage.removeItems(asyncPrefix + eventType);
      return response;
    } catch ({status}) {}
  }

  setEventSource(updatedEventSource) {
    this.eventSource = updatedEventSource;
  }
}

export default EventStore;
