// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import type {UIProgramType, UIStaffType} from '@wellstone-solutions/common';
import {ROLES_NAMES_MAP} from 'utils/Permissions';
import {buildIdLookup} from 'utils/buildIdLookup';
import {useStores} from 'hooks/useStores';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {adminProgramPermissionsMsg} from '../../constants';
import {CardDataList} from 'components/CardDataList';
import {AddGroupModal} from './AddGroupModal';
import {ENTITY_TYPES, ENTITY_LEVEL} from 'modules/rbac';
import {AccessControl} from '../../../rbac/constants';

const GroupItemDetail = ({item}: {item: any}): Node => {
  return <div>{item.group.name}</div>;
};

type PropTypes = {
  program: UIProgramType,
  staffMember: UIStaffType,
};

export const StaffProgramAuthorization = ({
  program,
  staffMember,
}: PropTypes): Node => {
  const {meStore, staffStore, groupStore, RBACStore} = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const {id: staffId} = staffMember;

  // check if the staff member we are viewing has program level access
  const specialMessage = RBACStore.hasEntityLevelAccess(
    ENTITY_LEVEL.program,
    staffMember.role,
  )
    ? adminProgramPermissionsMsg(ROLES_NAMES_MAP[staffMember.role])
    : undefined;

  const isMe = meStore.me.id === staffMember.user.id;
  const editOwnPrograms = RBACStore.hasAccess(
    AccessControl.staff.editSelfProgramPermissions,
  );
  const hasProgramAccess = RBACStore.hasAccessToEntity(
    ENTITY_TYPES.program,
    program.id,
  );
  const isProgramEditable = isMe
    ? hasProgramAccess && editOwnPrograms
    : hasProgramAccess;

  const isGroupEditable = hasProgramAccess;

  // map of the staff members authorized groups
  const staffAuthGroupMap = buildIdLookup(
    staffStore.authorizedGroups({staffId}),
  );

  // list of groups from the program that the staff is authorized for
  const staffProgramGroups = [];
  // list of groups from the program that I am authorized for but staff isnt
  const addableProgramGroups = [];

  program.groups.forEach((groupId) => {
    const foundGroup = groupStore.groups.find((group) => group.id === groupId);
    if (!foundGroup) {
      return;
    }

    if (!staffAuthGroupMap[groupId]) {
      if (RBACStore.hasAccessToEntity(ENTITY_TYPES.group, groupId)) {
        addableProgramGroups.push(foundGroup);
      }
      return;
    }

    staffProgramGroups.push(foundGroup);
  });

  const cardItems = staffProgramGroups.map((group) => ({
    group,
    preventDelete: !RBACStore.hasAccessToEntity(ENTITY_TYPES.group, group.id),
  }));

  const removeListItem = async (item: any): Promise<void> => {
    await removeAuth(item.group.id);
  };

  const removeList = async (): Promise<void> => {
    await removeAuth(program.id);
  };

  const removeAuth = async (itemId: string): Promise<void> => {
    const authToDelete = staffMember.authorizations.find(
      // $FlowFixMe
      (auth) => auth.obj_id === itemId,
    );

    if (!authToDelete) {
      showAlert('Something went wrong.  Please try again.', ALERT_TYPES.ERROR);
      return;
    }

    const response = await staffStore.removeAuthorization({
      staffId,
      authorizationId: authToDelete.id,
    });

    if (!response.isSuccess) {
      showAlert(
        'Unable to remove this item from the staff member',
        ALERT_TYPES.ERROR,
      );
    }
  };

  return (
    <div
      style={styles.cardContainer}
      data-testid={`program-card-${program.id}`}>
      <CardDataList
        specialMessage={specialMessage}
        title={program.name}
        ItemDetailsComponent={GroupItemDetail}
        data={cardItems}
        removeListItem={isGroupEditable ? removeListItem : undefined}
        headerProps={{
          onActionClick: isProgramEditable ? removeList : undefined,
        }}
        emptyListText="Staff member does not have groups in this program."
        footerProps={
          isGroupEditable
            ? {
                actionText: 'Add to Another Group',
                onActionClick: () => setShowModal(true),
              }
            : undefined
        }
      />
      <AddGroupModal
        open={showModal}
        setShowModal={setShowModal}
        addableGroups={addableProgramGroups}
      />
    </div>
  );
};

const styles = {
  cardContainer: {
    marginBottom: '30px',
  },
};
