// @flow
import React from 'react';
import type {Node} from 'react';
import type {UIResourceAnswerType} from '@wellstone-solutions/common';
import {Theme} from '@wellstone-solutions/common';
import {
  Box,
  DialogTitle,
  DialogContent,
  Grid,
  MarkdownViewer,
  Typography,
} from '@wellstone-solutions/web';

import {ContactCard} from '../../../components/ContactCard';

type PropsType = {
  answer: UIResourceAnswerType,
};

export const AnswerDetailView = ({answer}: PropsType): Node => (
  <Box>
    <DialogTitle>
      <Typography sx={styles.title}>{answer.title}</Typography>

      {!!answer.subtitle && (
        <Typography sx={styles.subtitle}>{answer.subtitle}</Typography>
      )}
    </DialogTitle>

    <DialogContent>
      <Box mb={3}>
        <MarkdownViewer value={answer.description} />
      </Box>

      <Grid container rowSpacing={{xs: 2, lg: 4}} spacing={{xs: 2, lg: 4}}>
        {answer.contacts.map((contact, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <ContactCard contact={contact} isFullScreen={true} />
          </Grid>
        ))}
      </Grid>
    </DialogContent>
  </Box>
);

const styles = {
  title: {
    color: Theme.colorPalette.onSurface,
    fontSize: 22,
    lineHeight: '24px',
    marginBottom: 1,
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: Theme.colorPalette.onSurface,
  },
};
