// @flow
import React, {useCallback} from 'react';
import type {ComponentType, Node} from 'react';
import {
  Form as CommonForm,
  FormRow,
  ErrorMessage,
  RichTextEditor,
  TextField,
} from '@wellstone-solutions/web';
import {TEMPORARY_IMAGE_FOLDER} from 'constants/Images';
import {useToastUIImageUploadHandler} from 'hooks/useToastUIImageUploadHandler';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {AnnouncementContentWrapper} from '../AnnouncementContentWrapper';
import type {AnnouncementFormType} from '../../types';

type PropsType = {
  form: AnnouncementFormType,
  formId: string,
};

export const Form: ComponentType<PropsType> = React.forwardRef(
  ({form, formId}: PropsType, ref: any): Node => {
    const toolbarItems = [['heading', 'bold', 'italic', 'hr', 'link', 'image']];

    const handleContentChange = useCallback(() => {
      // $FlowFixMe
      const content = ref.current.getInstance().getMarkdown();
      form.setFieldTouched('content');
      form.setFieldValue('content', content);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleImageUpload = useToastUIImageUploadHandler({
      folder: TEMPORARY_IMAGE_FOLDER,
      onError: () => {
        showAlert(
          'Something went wrong.  Please try again.',
          ALERT_TYPES.ERROR,
        );
      },
    });

    return (
      // $FlowFixMe
      <CommonForm id={formId} onSubmit={form.handleSubmit}>
        <FormRow>
          <TextField
            formField="title"
            onChange={form.handleFieldChange('title')}
            value={form.values.title}
            label="Title"
          />
          <ErrorMessage
            name="title"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <AnnouncementContentWrapper>
            <RichTextEditor
              initialValue={form.values.content}
              customImageHandler={handleImageUpload}
              toolbarItems={toolbarItems}
              onChange={handleContentChange}
              ref={ref}
            />
            <ErrorMessage
              name="content"
              errors={form.errors}
              touched={form.touched}
            />
          </AnnouncementContentWrapper>
        </FormRow>
      </CommonForm>
    );
  },
);
