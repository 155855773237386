import {Palette} from '../palette';

export const hiddenIfEmpty = (val) => {
  if (!val) {
    return {display: 'none'};
  }
};

export const TextColor = (clr) => {
  return {color: Palette[clr]};
};

export const prettyPhone = (str) => {
  if (str.length === 10) {
    return (
      '(' +
      str.substring(0, 3) +
      ') ' +
      str.substring(3, 6) +
      '-' +
      str.substring(6, 10)
    );
  }
  return str;
};

export const Fade = (color1, color2) => {
  if (color1 && color2) {
    return {background: 'linear-gradient(' + color1 + ', ' + color2 + ')'};
  } else {
    return {background: 'linear-gradient(#FCFCFC, #FFFFFF)'};
  }
};

const getLetter = (l) => {
  return l.match(/[aA-zZ]/i)
    ? l.toLowerCase()
    : l.match(/[0-9]/i)
    ? letterColors[parseInt(l, 10)].letter
    : 'z';
};

export const getColorFromLetter = (l) => {
  const letter = getLetter(l);
  let clr = letterColors.find((lc) => {
    return letter === lc.letter.toLowerCase();
  });
  return clr.color;
};

export const getTextColorforLetterColor = (l) => {
  const letter = getLetter(l);
  let clr = letterColors.find((lc) => {
    return letter === lc.letter.toLowerCase();
  });
  return clr.text;
};

export const styleTag = (string, useFirstLetter) => {
  let a = 'j'; //neutral grey
  if (string) {
    const index = useFirstLetter ? 0 : string.length - 1;
    a = string.charAt(index);
  }
  return {
    color: getTextColorforLetterColor(a),
    backgroundColor: getColorFromLetter(a),
    fontWeight: 'bold',
    margin: 2,
  };
};

export const styleLabel = (string, useFirstLetter) => {
  let a = 'j'; //neutral grey
  if (string) {
    const index = useFirstLetter ? 0 : string.length - 1;
    a = string.charAt(index);
  }
  return {
    color: getColorFromLetter(a),
    boxShadow: '0 0 2px 1px ' + getColorFromLetter(a), //rgba(10, 10, 10, 0.2)', //'0 0 2 ' + getColorFromLetter(a),
    backgroundColor: getTextColorforLetterColor(a),
    fontWeight: 'bold',
  };
};

const letterColors = [
  {letter: 'a', color: '#f1773e', text: '#ffffff'},
  {letter: 'b', color: '#63afba', text: '#ffffff'},
  {letter: 'c', color: '#cbe952', text: '#ffffff'},
  {letter: 'd', color: '#4b9673', text: '#ffffff'},
  {letter: 'e', color: '#ffea57', text: '#000000'},
  {letter: 'f', color: '#fbdf5d', text: '#000000'},
  {letter: 'g', color: '#80cf43', text: '#ffffff'},
  {letter: 'h', color: '#a1d040', text: '#ffffff'},
  {letter: 'i', color: '#ffffff', text: '#000000'},
  {letter: 'j', color: '#8c8390', text: '#ffffff'},
  {letter: 'k', color: '#edf081', text: '#000000'},
  {letter: 'l', color: '#f2f2fa', text: '#000000'},
  {letter: 'm', color: '#72af93', text: '#ffffff'},
  {letter: 'n', color: '#d5e366', text: '#000000'},
  {letter: 'o', color: '#47773e', text: '#ffffff'},
  {letter: 'p', color: '#47c7d9', text: '#ffffff'},
  {letter: 'q', color: '#f4d43e', text: '#ffffff'},
  {letter: 'r', color: '#cf8898', text: '#ffffff'},
  {letter: 's', color: '#509d96', text: '#ffffff'},
  {letter: 't', color: '#81c5ba', text: '#ffffff'},
  {letter: 'u', color: '#76954f', text: '#ffffff'},
  {letter: 'v', color: '#60aba3', text: '#ffffff'},
  {letter: 'w', color: '#5d936b', text: '#ffffff'},
  {letter: 'x', color: '#f1d53e', text: '#ffffff'},
  {letter: 'y', color: '#fff8a3', text: '#000000'},
  {letter: 'z', color: '#68a27f', text: '#ffffff'},
];
