// @flow
import React, {useEffect, useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {Button, Icon, IconNames} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';

type PropsType = {
  resourceId: string,
  iconSize?: number,
};

export const SaveResourceButton: ComponentType<PropsType> = observer(
  ({resourceId, iconSize = 16}: PropsType): Node => {
    const {resourceStoreV2} = useStores();
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    useEffect(() => {
      setIsSaved(resourceStoreV2.isSavedResource(resourceId));
    }, [resourceStoreV2, resourceId]);

    const handleSavePress = async () => {
      setIsDisabled(true);
      await resourceStoreV2.handleUserResource(resourceId);
      setIsSaved(resourceStoreV2.isSavedResource(resourceId));
      setIsDisabled(false);
    };

    return (
      <Button
        disabled={isDisabled}
        data-testid="save-resource"
        onClick={(e) => {
          e.stopPropagation();
          handleSavePress();
        }}
        sx={styles.headerButton}
        startIcon={
          <Icon
            name={isSaved ? IconNames.Trash : IconNames.Bookmark}
            size={iconSize}
            color="primary"
          />
        }>
        {isSaved ? 'Remove' : 'Save'}
      </Button>
    );
  },
);

const styles = {
  headerButton: {
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: 0.75,
      marginLeft: 0,
    },
  },
};
