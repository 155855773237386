// @flow
import type {UIHabitType, UIMemberHabitType} from './types';
import {HABIT_TYPES} from './constants';

export const EmptyHabit = (): UIHabitType => HabitFactory();

export const HabitFactory = (overrides?: UIHabitType): UIHabitType => ({
  id: '',
  isEvening: false,
  isAfternoon: false,
  isMorning: false,
  key: '',
  name: '',
  tags: [],
  ...overrides,
});

export const MemberHabitFactory = (
  overrides?: UIMemberHabitType,
): UIMemberHabitType => ({
  isMorning: false,
  isAfternoon: false,
  isEvening: false,
  habitId: '',
  type: HABIT_TYPES.member,
  ...overrides,
});
