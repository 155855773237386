// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {Box} from '@wellstone-solutions/web';
import {Events, Theme} from '@wellstone-solutions/common';
import {MembershipSelect, MEMBERSHIPS} from 'components/MembershipSelect';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import {useStores} from 'hooks/useStores';
import {useAnalyticsDataloader} from '../dataloaders';
import {useCompareQueries} from '../hooks';
import {useEventSource} from 'hooks/useEventSource';
import {AnalyticsGrid} from './AnalyticsGrid';
import {GROUP_QUERY_DEFAULTS, PROGRAM_QUERY_DEFAULTS} from '../constants';
import {AccessControl} from 'modules/rbac';
import {CheckinChart} from './CheckinChart';

export const DashboardRoot = (): Node => {
  const {appUIStore, eventStore, groupStore, meStore, RBACStore} = useStores();

  useEventSource('dashboard_page');

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: NAVIGATION_NAMES.dashboard,
        url: '/dashboard',
        active: true,
      },
    ]);

    eventStore.addEvent(Events.VIEWED_DASHBOARD);
  }, [appUIStore, eventStore]);

  const canViewPrograms = RBACStore.hasAccess(
    AccessControl.dashboard.viewPrograms,
  );

  const membershipFilterType = canViewPrograms
    ? MEMBERSHIPS.PROGRAM
    : MEMBERSHIPS.GROUP;

  // initial value for non-superadmins
  const initialValue =
    membershipFilterType === MEMBERSHIPS.GROUP
      ? Object.keys(groupStore.myGroupsLookup).slice(0, 1)
      : Object.keys(meStore.myProgramsLookup).slice(0, 1);

  const [membershipFilter, setMembershipFilter] = useState<Array<string>>(
    meStore.isSuperAdmin ? Object.keys(meStore.myProgramsLookup) : initialValue,
  );

  const baseQuery = canViewPrograms
    ? {
        ...PROGRAM_QUERY_DEFAULTS,
        programs: membershipFilter,
      }
    : {
        ...GROUP_QUERY_DEFAULTS,
        groups: membershipFilter,
      };

  const {datasetQueries} = useCompareQueries(baseQuery);
  const {isLoading, datasets} = useAnalyticsDataloader({
    datasetQueries,
  });

  // checkin chart params
  const checkinDateRange = {
    startDate: new Date(
      new Date().setDate(new Date().getDate() - (canViewPrograms ? 30 : 7)),
    ),
    endDate: new Date(),
  };

  const checkinFormat = canViewPrograms ? 'date' : 'day';

  const checkinLabel = canViewPrograms
    ? 'Last 30 days for Members across all Programs'
    : 'Last 7 days for Members across all Groups';

  return (
    <Box p={2}>
      <Box sx={{maxWidth: 400, pb: Theme.spacing.micro}}>
        <MembershipSelect
          value={meStore.isSuperAdmin ? 'All' : membershipFilter[0]}
          hideAllOption={!meStore.isSuperAdmin}
          type={membershipFilterType}
          onChange={(updatedFilter) => {
            eventStore.addEvent(Events.USER_FILTERED_DASHBOARD, {
              filter: updatedFilter,
              filter_type: membershipFilterType,
            });
            setMembershipFilter(updatedFilter);
          }}
        />
      </Box>

      <AnalyticsGrid
        isAll={membershipFilter.length > 1}
        isLoading={isLoading}
        columns={baseQuery.columns}
        currentDataset={datasets?.current}
        priorDataset={datasets?.prior}
        filterType={membershipFilterType}
      />

      <CheckinChart
        dateRange={checkinDateRange}
        format={checkinFormat}
        label={checkinLabel}
      />
    </Box>
  );
};
