// @flow
import moment from 'moment';

export const toRelativeDate = (date: string): string => {
  if (!date) {
    return 'None';
  }

  const relativeDate = todayTomorrowYesterday(date);

  if (relativeDate.today) {
    return 'Today';
  }

  if (relativeDate.yesterday) {
    return 'Yesterday';
  }

  return `${moment().diff(date, 'days')} days ago`;
};

export const toSemiRelativeDate = (date: string, format: string): string => {
  if (!date) {
    return 'None';
  }

  const relativeDate = todayTomorrowYesterday(date);

  if (relativeDate.today) {
    return 'Today';
  }

  if (relativeDate.yesterday) {
    return 'Yesterday';
  }

  if (relativeDate.tomorrow) {
    return 'Tomorrow';
  }

  return moment(date).format(format);
};

const todayTomorrowYesterday = (
  date: string,
): {today: boolean, tomorrow: boolean, yesterday: boolean} => {
  const today = moment();
  const yesterday = moment().subtract(1, 'days');
  const tomorrow = moment().add(1, 'days');

  return {
    today: moment(date).isSame(today, 'day'),
    tomorrow: moment(date).isSame(tomorrow, 'day'),
    yesterday: moment(date).isSame(yesterday, 'day'),
  };
};

// pubnub returns unix 17 digit precision time, this method converts to milliseconds then to a moment object
// https://support.pubnub.com/hc/en-us/articles/360051495812-How-do-I-convert-the-PubNub-timetoken-to-Unix-timestamp-
export const unix17DigitTimeToMoment = (epoch17digit: number): moment =>
  moment(epoch17digit / 10000);
