// @flow
import React from 'react';
import type {Node} from 'react';

import {Utils} from '@wellstone-solutions/common';
import type {FormType, UICalendarEventType} from '@wellstone-solutions/common';
import {
  Checkbox,
  DatePicker,
  ErrorMessage,
  Form as CommonForm,
  FormRow,
  Stack,
  TextField,
  Typography,
} from '@wellstone-solutions/web';
import {Recurrence} from './Recurrence';
import {EventDataTabs} from './EventDataTabs';
import {Guests} from './Guests';

type PropsType = {
  formId: string,
  form: FormType<UICalendarEventType>,
};

export const Form = ({form, formId}: PropsType): Node => {
  const handleStartTimeChange = (event) => {
    const startTime = event.target.value;

    if (startTime.length > 0) {
      const shiftedEndTime = Utils.shiftEndTime(
        {
          startTime: form.values.startTime,
          endTime: form.values.endTime,
        },
        startTime,
      );

      form.setTouched({startTime: true, endTime: true});
      form.setValues({
        ...form.values,
        startTime: startTime,
        endTime: shiftedEndTime,
      });
    } else {
      form.setFieldValue('startTime', event.target.value);
    }
  };

  return (
    <CommonForm
      data-testid="calendar-event-form"
      id={formId}
      // $FlowFixMe
      onSubmit={form.handleSubmit}>
      <Stack direction="column">
        <FormRow>
          <TextField
            formField="title"
            label="Title"
            onChange={form.handleFieldChange('title')}
            value={form.values.title}
          />
        </FormRow>
        <Stack flexDirection="row" alignItems="center">
          <FormRow>
            <DatePicker
              data-testid="calendar-event-form-datepicker"
              fieldName="startDate"
              label="Scheduled for"
              onChange={(date) => {
                form.setFieldValue(
                  'startDate',
                  date?.isValid() ? date.toISOString() : '',
                );
              }}
              value={form.values.startDate}
            />
            <ErrorMessage
              name="start"
              errors={form.errors}
              touched={form.touched}
            />
          </FormRow>
          <FormRow sx={{ml: 2}}>
            <Checkbox
              checked={form.values.isAllDay}
              label="All Day"
              value={form.values.isAllDay}
              onChange={(event) => {
                form.setFieldValue('isAllDay', event.target.checked);
              }}
            />
          </FormRow>
        </Stack>
        <FormRow
          data-testid="event-start-end-times"
          sx={{visibility: !form.values.isAllDay ? 'visible' : 'hidden'}}>
          <Stack alignItems="center" direction="row" sx={{mb: 1}}>
            <TextField
              inputProps={{'data-testid': 'start-time'}}
              formField="startTime"
              onChange={handleStartTimeChange}
              type="time"
              value={form.values.startTime}
            />
            <Typography sx={{ml: 1, mr: 1}}>To</Typography>
            <TextField
              inputProps={{'data-testid': 'end-time'}}
              formField="endTime"
              onChange={form.handleFieldChange('endTime')}
              type="time"
              value={form.values.endTime}
            />
          </Stack>
          <ErrorMessage
            name="endTime"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <Recurrence form={form} />
        </FormRow>
        <FormRow>
          <EventDataTabs form={form} />
        </FormRow>
        <FormRow gutters={4}>
          <Guests form={form} />
        </FormRow>
      </Stack>
    </CommonForm>
  );
};
