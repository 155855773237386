// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {
  Field,
  Control,
  Label,
  Input,
} from 'react-bulma-components/lib/components/form';
import {Typography} from '@wellstone-solutions/web';
import {PfButton} from 'components/shared/PfButton';
import {Api, Theme} from '@wellstone-solutions/common';
import {Alert, Box, Stack} from '@wellstone-solutions/web';
import {routes} from 'api';
import {RESET_STAGES} from '../constants';

const INVALID_LINK_MESSAGE =
  'The reset password link provided is no longer valid.';

type PropsType = {
  setStage: (string) => void,
  owner: string,
  token: string,
};

export const SetPasswordStage = ({setStage, owner, token}: PropsType): Node => {
  const [newPassword1, setNewPassword1] = useState();
  const [newPassword2, setNewPassword2] = useState();
  const [resetError, setResetError] = useState(false);
  const [messaging, setMessaging] = useState();
  const [isLoading, setIsLoading] = useState();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setMessaging(null);
      const response = await Api.Instance.current().post(
        routes.resetPassword(owner, token),
        {
          password: newPassword1,
        },
      );

      if (!response.isSuccess) {
        const error = response.data.error;
        const errorFormatted = Array.isArray(error) ? error.join(' ') : error;
        setMessaging(errorFormatted ?? INVALID_LINK_MESSAGE);
        return;
      }

      setStage(RESET_STAGES.success);
    } catch (ex) {
      if (ex.error) {
        setMessaging(ex.error);
      }
      setMessaging(INVALID_LINK_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = (password1, password2) => {
    if (password2 && password1 !== password2) {
      setResetError(true);
      return;
    }
    setResetError(false);
  };

  return (
    <Stack>
      <Field>
        <Label style={{color: Theme.colorPalette.primary}}>New Password</Label>
        <Control>
          <Input
            data-testid="password-input"
            type="password"
            required
            onChange={(newVal) => {
              setNewPassword1(newVal.target.value);
              validateForm(newVal.target.value, newPassword2);
            }}
            value={newPassword1}
          />
        </Control>
      </Field>
      <Field>
        <Label style={{color: Theme.colorPalette.primary}}>
          Re-enter New Password
        </Label>
        <Control>
          <Input
            data-testid="password-input-copy"
            type="password"
            required
            onChange={(newVal) => {
              setNewPassword2(newVal.target.value);
              validateForm(newPassword1, newVal.target.value);
            }}
            value={newPassword2}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
        </Control>
        {resetError && (
          <Typography sx={{fontSize: '0.75rem', color: Theme.colorPalette.red}}>
            Passwords do not match
          </Typography>
        )}
      </Field>
      <PfButton
        color="primary"
        data-testid="reset-password-button"
        isDisabled={!newPassword1 || resetError}
        onClick={handleSubmit}
        isLoading={isLoading}>
        Change My Password
      </PfButton>
      {messaging && (
        <Typography
          sx={{
            fontSize: '0.75rem',
            color: Theme.colorPalette.red,
            textAlign: 'center',
            mt: Theme.spacing.micro,
          }}>
          {messaging}
        </Typography>
      )}
      <Alert severity="info" sx={{mt: 2}}>
        <Stack direction="column">
          <Typography variant="h8" sx={{fontWeight: 'bold'}}>
            Secure Password Tips:
          </Typography>
          <Box sx={{ml: 2, mt: 1}}>
            <ul>
              <li style={styles.tipList}>
                <Typography variant="body2">
                  Use at least 8 characters, a combination of numbers and
                  letters is best.
                </Typography>
              </li>
              <li style={styles.tipList}>
                <Typography variant="body2">
                  Do not use the same password you have used with us previously.
                </Typography>
              </li>
              <li style={styles.tipList}>
                <Typography variant="body2">
                  Do not use dictionary words, your name, email address, or
                  other personal information that can be easily obtained.
                </Typography>
              </li>
              <li style={styles.tipList}>
                <Typography variant="body2">
                  Do not use the same password for multiple online accounts.
                </Typography>
              </li>
            </ul>
          </Box>
        </Stack>
      </Alert>
    </Stack>
  );
};

const styles = {
  tipList: {
    listStyle: 'disc',
  },
};
