// @flow
import React from 'react';
import type {Node} from 'react';
import {
  Button,
  Icon,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {buildCityStateZip, getDirectionsUrl} from 'utils/addressHelpers';
import type {UIContactType} from '../../types';

type PropsType = {
  contact: UIContactType,
  isFullScreen: boolean,
};

export const ContactCard = ({contact, isFullScreen}: PropsType): Node => {
  const rowLinkStyle = isFullScreen
    ? {
        backgroundColor: Theme.colorPalette.lightest,
        borderRadius: 1,
        p: 0.5,
      }
    : {};

  const ellipsisStyle = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };

  const iconStyle = {minWidth: 28};

  const hasTitleOrDateTimeInfo = () =>
    (contact.title && contact.title.length > 0) ||
    (contact.dateTimeInformation && contact.dateTimeInformation.length > 0);

  return (
    <Stack
      sx={{
        backgroundColor: Theme.colorPalette.light,
        borderRadius: 4,
        flex: 1,
        height: '100%',
        justifyContent: 'space-between',
        p: 2,
      }}>
      <Stack
        direction="column"
        sx={{
          mb: hasTitleOrDateTimeInfo() ? 1 : 0,
        }}>
        <Typography
          color={Theme.colorPalette.blue}
          sx={{
            fontSize: 16,
          }}>
          {/* $FlowFixMe */}
          {contact.title}
        </Typography>
        <Typography color={Theme.colorPalette.dark} variant="body2">
          {/* $FlowFixMe */}
          {contact.dateTimeInformation}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        sx={{
          'a + a': {
            mt: 1,
          },
        }}>
        {!!contact.phoneNumber && (
          <a href={`tel:${contact.phoneNumber}`} target="_blank">
            <Stack alignItems="center" direction="row" sx={rowLinkStyle}>
              <Icon
                name={IconNames.SmartphoneDevice}
                color={Theme.colorPalette.primary}
                size={28}
                style={iconStyle}
              />
              <Typography
                color={Theme.colorPalette.primary}
                variant="body2"
                sx={{...ellipsisStyle, fontWeight: 'bold', ml: 1}}>
                {contact.phoneNumberLabel || contact.phoneNumber}
              </Typography>
            </Stack>
          </a>
        )}
        {!!contact.email && (
          <a href={`mailto:${contact.email}`} target="_blank">
            <Stack alignItems="center" direction="row" sx={rowLinkStyle}>
              <Icon
                name={IconNames.Mail}
                color={Theme.colorPalette.primary}
                size={28}
                style={iconStyle}
              />
              <Typography
                color={Theme.colorPalette.primary}
                variant="body2"
                sx={{...ellipsisStyle, fontWeight: 'bold', ml: 1}}>
                {contact.emailLabel || contact.email}
              </Typography>
            </Stack>
          </a>
        )}
        {!!contact.website && (
          <a href={contact.website} target="_blank">
            <Stack alignItems="center" direction="row" sx={rowLinkStyle}>
              <Icon
                name={IconNames.Internet}
                color={Theme.colorPalette.primary}
                size={28}
                style={iconStyle}
              />
              <Typography
                color={Theme.colorPalette.primary}
                variant="body2"
                sx={{...ellipsisStyle, fontWeight: 'bold', ml: 1}}>
                {contact.websiteLabel || contact.website}
              </Typography>
            </Stack>
          </a>
        )}
        {!isFullScreen && !!contact.address1 && (
          <Button
            variant="text"
            sx={{textTransform: 'none', p: 0, mt: 1}}
            onClick={() =>
              window.open(
                getDirectionsUrl(buildCityStateZip(contact, true)),
                '_blank',
              )
            }>
            <Stack alignItems="center" direction="row" sx={{width: '100%'}}>
              <Icon
                name={IconNames.MapPin}
                color={Theme.colorPalette.primary}
                size={28}
                style={iconStyle}
              />
              <Typography
                color={Theme.colorPalette.primary}
                variant="body2"
                sx={{...ellipsisStyle, fontWeight: 'bold', ml: 1}}>
                {buildCityStateZip(contact, true)}
              </Typography>
            </Stack>
          </Button>
        )}
      </Stack>
      {isFullScreen && !!contact.address1 && (
        <Stack
          pt={1}
          mt={2}
          borderTop={`1px solid ${Theme.colorPalette.mediumLight}`}>
          <Stack alignItems="center" direction="row">
            <Icon
              name={IconNames.MapPin}
              color={Theme.colorPalette.secondary}
              size={32}
            />
            <Stack direction="column" pl={1}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: Theme.colorPalette.primary,
                  lineHeight: 1.2,
                }}>
                {contact.address1}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: Theme.colorPalette.primary,
                  lineHeight: 1.2,
                }}>
                {/* $FlowFixMe */}
                {contact.address2}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: Theme.colorPalette.primary,
                  lineHeight: 1.2,
                }}>
                {buildCityStateZip(contact, false)}
              </Typography>
            </Stack>
          </Stack>
          <Button
            onClick={() => window.open(getDirectionsUrl(contact), '_blank')}
            variant="contained"
            sx={{mt: 1, textTransform: 'none'}}>
            Get Directions
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
