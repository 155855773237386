// @flow
import {IconNames} from '@wellstone-solutions/web';

export const STATUSES = {
  canceled: 'canceled',
  created: 'created',
  updated: 'updated',
};

export const PUBNUB_TYPES = {
  canceled: 'canceled',
  created: 'created',
  deleted: 'deleted',
  updated: 'updated',
  updated_time: 'updated_time',
  updated_location: 'updated_location',
};

export const CALENDAR_VIEWS = {
  month: 'month',
  week: 'week',
  day: 'day',
  agenda: 'agenda',
};

export const RECURRENCE_FREQ_MAP = {
  daily: 'DAILY',
  weekly: 'WEEKLY',
  monthly: 'MONTHLY',
  yearly: 'YEARLY',
};

export const inboxStatusMap = {
  canceled: {
    icon: IconNames.XmarkCircle,
    statusMessage: 'Event Canceled',
  },
  deleted: {
    icon: IconNames.XmarkCircle,
    statusMessage: 'Event Canceled',
  },
  created: {
    icon: IconNames.Calendar,
    statusMessage: 'New Event Invitation',
  },
  updated: {
    icon: IconNames.RefreshCircle,
    statusMessage: 'Event was Updated',
  },
  updated_location: {
    icon: IconNames.RefreshCircle,
    statusMessage: 'Event Location was Updated',
  },
  updated_time: {
    icon: IconNames.RefreshCircle,
    statusMessage: 'Event Time was Updated',
  },
};
