class ChannelStore {
  routes = [
    {
      method: 'GET',
      route: /messaging\/channels/,
      action: this.getChannels.bind(this),
    },
  ];

  constructor(tutorialStore) {
    this.tutorialStore = tutorialStore;
  }

  getChannels() {
    let chMember = this.tutorialStore.stores.memberStore.members.find(
      (member) => member.name === 'Xavier',
    );
    return {
      data: {
        channels: [
          {
            id: 'ch-chat.demo-channel',
            members: [
              {
                name: chMember.name,
                id: chMember.user.id,
              },
            ],
            status: 'active',
          },
        ],
      },
      status: 'ok',
      messages: [],
      messages_type: 'string_array',
    };
  }
}

export default ChannelStore;
