// @flow
import {action, decorate, computed, observable} from 'mobx';
import RootStore from 'mobx/RootStore';
import {IObservableArray} from 'mobx';
import type {PubSubMessageType} from 'types';
import {toNotification} from './transforms';
import type {NotificationType} from './types';

export class AppNotificationStore {
  rootStore: RootStore;
  notifications: IObservableArray<NotificationType<any>> = observable.array();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  init() {
    this.getMessages();
  }

  handlePublishedMessage(
    message: PubSubMessageType,
    isRealtime: boolean = false,
    realtimeCallback?: () => void,
  ): void {
    const notification = toNotification(message);

    this.addNotification(notification);

    if (isRealtime && realtimeCallback) {
      realtimeCallback();
    }
  }

  getMessages() {
    const {pubnubStore} = this.rootStore.stores;
    const channels = [this.defaultChannel, this.calendarChannel];

    const end = '00000000000000001';
    // $FlowFixMe
    pubnubStore.pubnub.fetchMessages({channels, end}, (status, response) => {
      const messages = response
        ? Object.values(response.channels).flatMap((channel) => channel)
        : [];

      if (!response) {
        console.log('getInboxHistory:error', {status, response});
      }
      if (messages?.length > 0) {
        // $FlowFixMe
        pubnubStore.loadAllMessages(messages);
      }
    });
  }

  addNotification(notification: NotificationType<any>) {
    this.notifications.unshift(notification);
  }

  dismissNotification(dismissedNotification: NotificationType<any>) {
    const updatedInbox = this.notifications.filter(
      (notification) =>
        notification.timetoken !== dismissedNotification.timetoken,
    );

    this.notifications.replace(updatedInbox);

    // $FlowFixMe
    this.rootStore.stores.pubnubStore.deleteMessage(
      dismissedNotification.channel,
      dismissedNotification.timetoken,
    );
  }

  get count(): number {
    return this.notifications.length;
  }

  get defaultChannel(): string {
    const {meStore} = this.rootStore.stores;
    // $FlowFixMe
    return `ch-inbox.${meStore.me.id}.notifications`;
  }

  get calendarChannel(): string {
    const {meStore} = this.rootStore.stores;
    // $FlowFixMe
    return `ch-inbox.${meStore.me.id}.calendar`;
  }

  get allNotificationChannels(): Array<string> {
    return [this.defaultChannel, this.calendarChannel];
  }
}

decorate(AppNotificationStore, {
  notifications: observable,
  addNotification: action,
  dismissedNotification: action,
  defaultChannel: computed,
  calendarChannel: computed,
  count: computed,
});
