import React from 'react';
import {Link} from 'react-router-dom';
import {bool, string} from 'prop-types';

// This is using the Bulma class names to match a button
const toClassNames = ({color, outlined}) => {
  const classNames = ['button', `is-${color}`];

  if (outlined) {
    classNames.push('is-outlined');
  }

  return classNames.join(' ');
};

export const PfLinkButton = ({children, color, outlined, to}) => {
  return (
    <Link className={toClassNames({color, outlined})} to={to}>
      {children}
    </Link>
  );
};

PfLinkButton.defaultProps = {
  outlined: true,
  color: 'info',
};

PfLinkButton.propTypes = {
  outlined: bool,
  color: string,
};
