import {computed, observable, decorate, action} from 'mobx';
import {Api, Models} from '@wellstone-solutions/common';
import type {
  ApiResponseType,
  NameIdType,
  UIMemberBasicType,
} from '@wellstone-solutions/common';
import {routes} from 'api';
import {buildIdLookup} from 'utils/buildIdLookup';
import {Transforms as HabitTransforms} from 'modules/habits';

const {Integration, MemberBasic} = Models;
export class OrganizationStore {
  constructor(rootStore: any) {
    this.rootStore = rootStore;

    // Ensure always bound to this when passed around
    this.search = this.search.bind(this);
  }

  loadOrganization = async (org) => {
    const tmpOrg = org ? org : this.organizationId;
    try {
      const response = await Api.Instance.current().get('/orgs/' + tmpOrg, {});
      this.organizationId = org;
      this.programs = response.data.programs;
      this.groupCategories = response.data.group_categories;
      this.habits = response.data.habits.map(HabitTransforms.toUI);
      this.setPrimary();
    } catch (e) {}
  };

  setPrimary = () => {
    this.groupCategories.forEach((category, c) => {
      if (category.type.name.toLowerCase() === 'primary') {
        this.primaryCategory = category;
      }
    });
  };

  getGroupCategoryById = (id) => {
    const res = this.groupCategories.filter((category, c) => {
      return category.id === id;
    });
    return res && res.length ? res[0] : {};
  };

  programs = [];
  groupCategories = [];

  async search({
    searchTerm,
    queryBy = ['members', 'groups'],
    statusFilters = null,
  }: {
    searchTerm: string,
    queryBy: Array<string>,
    statusFilters?: Array<string>,
  }): Promise<
    ApiResponseType<{
      members: Array<NameIdType>,
      groups: Array<NameIdType>,
    }>,
  > {
    const {meStore} = this.rootStore.stores;

    const options = {
      params: {query: searchTerm, query_by: queryBy},
    };

    if (statusFilters) {
      options.params.status = statusFilters;
    }

    return Api.Instance.current().get(
      routes.organization.search(meStore.me.membership.organization.id),
      options,
    );
  }

  async searchMembers({
    search,
    pageNumber = 1,
    pageSize = 25,
  }: {
    search: string,
    pageNumber?: number,
    pageSize?: number,
  }): Promise<ApiResponseType<UIMemberBasicType[]>> {
    const {meStore} = this.rootStore.stores;

    let options = {
      params: {page: pageNumber, page_size: pageSize},
    };

    if (search.length > 0) {
      options.params.search = search;
    }

    const response = await MemberBasic.search(
      meStore.activeMembership.organization.id,
      options,
    );

    if (!response.isSuccess) {
      return response;
    }

    const uiMembers = response.data.map(MemberBasic.toUI);
    return {
      ...response,
      data: uiMembers,
      totalCount: response.total_count,
    };
  }

  get programsLookup() {
    return buildIdLookup(this.programs);
  }

  // This is currently used for getting EHR integrations
  async getExternalIntegration(
    externalRelationshipId: string,
    isStaff?: boolean = false,
  ): Promise<any> {
    const orgId: string = this.rootStore.stores.meStore.organizationId;

    const response = await Integration.find(
      orgId,
      externalRelationshipId,
      isStaff,
    );

    return response;
  }
}

decorate(OrganizationStore, {
  programsLookup: computed,
  groupCategories: observable,
  habits: observable,
  programs: observable,
  getGroupCategoryById: action,
  primaryCategory: observable,
  organizationId: observable,
});

export default OrganizationStore;
