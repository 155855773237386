// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Button, Chip, Stack, Typography} from '@wellstone-solutions/web';

type PropsType = {
  onClick: (any) => void,
  item: any,
  title: string,
  subtitle?: string,
  tag?: string,
};

export const SearchResult = ({
  onClick,
  item,
  title,
  subtitle,
  tag,
}: PropsType): Node => {
  return (
    <Button
      onClick={() => onClick(item)}
      sx={{
        backgroundColor: Theme.colorPalette.lightest,
        borderWidth: 0,
        justifyContent: 'flex-start',
        padding: 0,
        textTransform: 'unset',
      }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          flex: 1,
          py: 1,
          px: 2,
          borderBottom: `solid 1px ${Theme.colorPalette.medium}`,
        }}>
        <Stack justifyContent="flex-start">
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
            }}>
            {title}
          </Typography>
          {subtitle && (
            <Typography
              sx={{
                color: Theme.colorPalette.secondary,
                fontWeight: 'bold',
                lineHeight: 1,
                textAlign: 'left',
              }}>
              {subtitle}
            </Typography>
          )}
        </Stack>
        {tag && (
          <Chip
            label={tag}
            variant="filled"
            color="info"
            sx={{cursor: 'pointer'}}
          />
        )}
      </Stack>
    </Button>
  );
};
