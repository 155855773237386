// @flow
import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {Models, Theme} from '@wellstone-solutions/common';
import {Icon, Stack} from '@wellstone-solutions/web';
import type {UICalendarEventType} from '@wellstone-solutions/common';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {useStores} from 'hooks/useStores';
import {PfButton} from 'components/shared/PfButton';
import {PfConfirmInline} from 'components/shared/PfConfirmInline';

const LOADING_TARGETS = {
  single: 'single',
  recurring: 'recurring',
};

type LoadingTargetType = ?'single' | ?'recurring';

type PropsType = {
  isModalOpen: boolean,
  isRecurringEvent: boolean,
  calendarEvent: UICalendarEventType,
  setShowModal: (showModal: boolean) => void,
  showRecurringOptionsOverride?: boolean,
  onSuccess?: () => void,
};

const {CalendarEvent} = Models;

export const CancelEventActions = ({
  isModalOpen,
  isRecurringEvent,
  calendarEvent,
  setShowModal,
  showRecurringOptionsOverride,
  onSuccess,
}: PropsType): Node => {
  const {calendarStore} = useStores();

  const [showRecurringOptions, setShowRecurringOptions] = useState<boolean>(
    Boolean(showRecurringOptionsOverride),
  );
  const [loadingTarget, setLoadingTarget] = useState<LoadingTargetType>();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    setShowRecurringOptions(Boolean(showRecurringOptionsOverride));
  }, [isModalOpen, showRecurringOptionsOverride]);

  const handleCancelClick = () => {
    if (isRecurringEvent) {
      setShowRecurringOptions(true);
    } else {
      setShowConfirm(true);
    }
  };

  const actionClickWrapper = async () => {
    setShowConfirm(false);
    cancelEvent(false);
  };

  const cancelEvent = async (applyToAll: boolean) => {
    setLoadingTarget(
      applyToAll ? LOADING_TARGETS.recurring : LOADING_TARGETS.single,
    );
    const response = await calendarStore.cancelCalendarEvent(
      calendarEvent,
      applyToAll,
    );

    if (response.isSuccess) {
      setShowModal(false);
      onSuccess?.();
    } else {
      showAlert(
        'Unable to cancel this event. Please try again later.',
        ALERT_TYPES.ERROR,
      );
    }
    setLoadingTarget();
  };

  const cancelRemove = () => {
    setShowConfirm(false);
  };

  if (CalendarEvent.isCanceled(calendarEvent)) {
    return (
      <PfButton
        color="light"
        outlined
        disabled={true}
        style={styles.canceledButton}>
        Event was Canceled
      </PfButton>
    );
  }

  if (showConfirm) {
    return (
      <PfConfirmInline
        showConfirm={showConfirm}
        confirm={actionClickWrapper}
        cancelConfirm={cancelRemove}
        style={styles.confirm}
        color="danger"
      />
    );
  }

  if (showRecurringOptions) {
    return (
      <Stack
        direction="row"
        justifyContent="flex-end"
        gap="8px"
        alignItems="center">
        <PfButton
          outlined
          color="danger"
          onClick={() => cancelEvent(false)}
          loading={loadingTarget === LOADING_TARGETS.single}
          disabled={Boolean(loadingTarget)}>
          Cancel This Event
        </PfButton>
        <PfButton
          outlined
          color="danger"
          onClick={() => cancelEvent(true)}
          loading={loadingTarget === LOADING_TARGETS.recurring}
          disabled={Boolean(loadingTarget)}>
          Cancel This and Following Events
        </PfButton>
      </Stack>
    );
  }

  return (
    <PfButton
      outlined
      onClick={handleCancelClick}
      color="danger"
      loading={loadingTarget === LOADING_TARGETS.single}
      disabled={Boolean(loadingTarget)}>
      <Icon name="Trash" color="error" style={styles.spaceRight} />
      Cancel Event
    </PfButton>
  );
};

const styles = {
  spaceRight: {
    marginRight: 8,
  },
  canceledButton: {
    borderColor: Theme.colorPalette.mediumDark,
    color: Theme.colorPalette.mediumDark,
  },
  confirm: {
    marginLeft: '8px',
  },
};
