// @flow
import {Api} from '@wellstone-solutions/common';
import type {UIHabitType, UIMemberHabitType} from './types';
import {toApi, toMemberUI, toUI} from './transforms';
export type orgMemberParams = {
  organizationId: string,
  memberId: string,
};

export const getAll = async (): Promise<UIHabitType[]> => {
  const result = await Api.Instance.current().get('/habits');

  return result.data?.habits ? result.data.habits.map(toUI) : [];
};

export const getMemberHabits = async ({
  organizationId,
  memberId,
}: orgMemberParams): Promise<UIHabitType[]> => {
  const result = await Api.Instance.current().get(
    `/orgs/${String(organizationId)}/members/${memberId}/habits`,
  );

  return result.data?.habits ? result.data.habits.map(toMemberUI) : [];
};

export const getUserHabits = async ({
  organizationId,
  memberId,
}: orgMemberParams): Promise<UIHabitType[]> => {
  const result = await Api.Instance.current().get(
    `/orgs/${String(organizationId)}/members/${memberId}/user/habits`,
  );

  return result.data?.habits ? result.data.habits.map(toMemberUI) : [];
};

export const saveMemberHabits = async (
  organizationId: string,
  memberId: string,
  habits: UIMemberHabitType[],
  // $FlowFixMe
) => {
  try {
    const result = await Api.Instance.current().put(
      `/orgs/${String(organizationId)}/members/${memberId}/habits`,
      {habits: habits.map(toApi)},
    );

    return result;
  } catch (error) {
    console.log(error);
  }
};
