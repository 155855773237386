// @flow
import React from 'react';
import type {Node} from 'react';
import {useParams} from 'react-router-dom';
import {SuspendedComponent} from '@wellstone-solutions/web';
import {useDataLoader} from '../../dataloaders/storyDataLoader';
import {Edit} from './Edit';

type PropsType = {
  children?: Node | (() => any),
};

export const StoryLoader = ({children}: PropsType): Node => {
  const params = useParams();
  const storyId = params.id;
  const dataLoaderArgs = {
    storyId,
    initialFetch: true,
  };

  return (
    <SuspendedComponent
      dataLoaderArgs={dataLoaderArgs}
      useDataLoader={useDataLoader}
      keyProp={storyId}>
      {/* $FlowIgnore */}
      {children || <Edit />}
    </SuspendedComponent>
  );
};
