// React
import React from 'react';

// Third party
import {Area, AreaChart} from 'recharts';

// WS
import PfLoader from 'components/shared/PfLoader';
import {Palette} from 'palette';

export class PfMiniChart extends React.Component {
  state = {
    ready: false,
  };

  componentDidMount() {
    this.setLoaded();
  }

  setLoaded() {
    this.setState({ready: true});
  }

  render() {
    if (!this.state.ready) {
      return <PfLoader />;
    }
    return (
      <AreaChart
        width={200}
        height={60}
        data={this.props.data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}>
        <Area
          type="monotone"
          dataKey={this.props.dataKey}
          stroke={Palette.INFO}
          fill={Palette.LIGHT_INFO}
        />
      </AreaChart>
    );
  }
}
