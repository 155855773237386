// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import moment from 'moment';
import type {FormType, UIMemberType} from '@wellstone-solutions/common';
import {Theme, Validation} from '@wellstone-solutions/common';
import {
  Alert,
  Box,
  DatePicker,
  ErrorMessage,
  FormRow,
  OutlinedInput,
  Select,
  TextField,
} from '@wellstone-solutions/web';
import {DEFAULT_DATE_FORMAT} from 'constants/dates';
import {ROLES, ROLES_NAMES_MAP} from 'utils/Permissions';
import {genders, identities} from 'utils/Forms';
import {EHRWrapper} from 'modules/integration/components/EHRWrapper';
import {INTEGRATION_STATUSES} from 'modules/integration/constants';

type PropsType = {
  form: FormType<any>,
  data?: UIMemberType,
  step?: any,
};

const INVALID_FIELD = 'Invalid field';
const INVALID_NAME = 'Name must be 32 characters or less';

const selectLabelProps = {
  sx: {
    backgroundColor: Theme.colorPalette.lightest,
    padding: '0 8px',
  },
};

export const MemberInfoStep = ({data, form, step}: PropsType): Node => {
  const genderItems = genders.map(({name, value}) => ({label: name, value}));
  const identityItems = identities.map(({name, value}) => ({
    label: name,
    value,
  }));

  const {member, canAccess} = data;
  const isExistingMember = Boolean(member && member.id);
  const isInaccessibleMember =
    !canAccess || (isExistingMember && member?.role !== ROLES.patient);

  // Determine if we found a match for the email
  // If we did, let's lock down this form
  useEffect(() => {
    // If form is valid, we've already entered data
    // and now are returning, let's not overwrite everything
    // $FlowIgnoreMe
    if (form.isValid) {
      return;
    }

    if (isExistingMember) {
      form.setFieldValue('id', member?.id);
      form.setFieldValue('name', member?.name);
      form.setFieldValue('birthdate', member?.birthdate);
      form.setFieldValue('role', member?.role);
      form.setFieldValue('gender', member?.gender);
      form.setFieldValue('identifiesAs', member?.identifiesAs);

      // program admissions
      const existingPrograms = (member?.admissions || []).map((admission) => ({
        id: admission.program.id,
        existing: true,
      }));

      form.setFieldValue('programs', [
        ...new Set([...form.values.programs, ...existingPrograms]),
      ]);
    } else {
      // set initial values unless they already exist
      form.setFieldValue('id', form.values.id || '');
      form.setFieldValue('name', form.values.name || '');
      form.setFieldValue('birthdate', form.values.birthdate || null);
      form.setFieldValue('role', ROLES.patient);
      form.setFieldValue('gender', form.values.gender || '');
      form.setFieldValue('identifiesAs', form.values.identifiesAs || '');
      form.setFieldValue(
        'admissionStart',
        form.values.admissionStart || moment().toISOString(),
      );
      form.setFieldValue('admissionEnd', form.values.admissionEnd || '');
      form.setFieldValue('programs', form.values.programs || []);
      form.setFieldValue('groups', form.values.groups || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Custom check on birthdate.  It is not required for existing users
  // but we want to enforce it with new.  Having trouble getting formik
  // to play nice, so ended up using this brutish approach
  if (
    !isExistingMember &&
    form.touched.birthdate &&
    !Validation.isRequired(form.values.birthdate)
  ) {
    // $FlowFixMe
    form.errors.birthdate = INVALID_FIELD;
  }
  // $FlowFixMe
  if (form.errors.name) {
    form.errors.name = INVALID_NAME;
  }

  //
  const showOrgIntegration =
    step?.data?.orgIntegration &&
    form.values.externalRelationshipId?.length > 0;

  return (
    <>
      {isInaccessibleMember && (
        <Alert
          data-testid="alert-invalid-account"
          severity="error"
          sx={{mb: 3}}>
          This email cannot be used to create a new member.
        </Alert>
      )}
      <Box
        sx={{
          display: isInaccessibleMember ? 'none' : 'block',
        }}>
        {isExistingMember && (
          <Alert data-testid="alert" severity="info" sx={{mb: 3}}>
            {`This user is already a ${String(
              ROLES_NAMES_MAP[member?.role],
            )}.  Continue to add them to a new program, or consult a super administrator.`}
          </Alert>
        )}
        <FormRow>
          <TextField
            formField="email"
            onChange={form.handleFieldChange('email')}
            disabled={true}
            value={form.values.email}
            label="Member's email address"
          />
          <ErrorMessage
            name="email"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <TextField
            autoFocus
            formField="name"
            onChange={form.handleFieldChange('name')}
            disabled={isExistingMember}
            value={form.values.name}
            label="Full Name*"
          />
          <ErrorMessage
            name="name"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <DatePicker
            label="Birthdate*"
            disabled={isExistingMember}
            fieldName="birthdate"
            onChange={(date) => {
              form.setFieldTouched('birthdate');
              form.setFieldValue(
                'birthdate',
                date?.isValid() ? date.format(DEFAULT_DATE_FORMAT) : '',
              );
            }}
            value={form.values.birthdate}
          />
          <ErrorMessage
            name="birthdate"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <Select
            data-testid="gender-dropdown"
            disabled={isExistingMember}
            canUnselect={false}
            label="Sex*"
            labelProps={selectLabelProps}
            input={<OutlinedInput notched={false} />}
            items={genderItems}
            value={form.values.gender}
            onChange={(e) => {
              form.setFieldValue('gender', e.target.value);
            }}
          />
          <ErrorMessage
            name="gender"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <Select
            data-testid="identifies-dropdown"
            disabled={isExistingMember}
            canUnselect={false}
            label="Identifies as*"
            labelProps={selectLabelProps}
            input={<OutlinedInput notched={false} />}
            items={identityItems}
            value={form.values.identifiesAs}
            onChange={(e) => {
              form.setFieldValue('identifiesAs', e.target.value);
            }}
          />
          <ErrorMessage
            name="identifiesAs"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        {showOrgIntegration && (
          <Box sx={{mt: 2}}>
            <EHRWrapper
              // $FlowFixMe
              initialStatus={INTEGRATION_STATUSES.CONFIRM}
              form={form}
              orgIntegration={step?.data?.orgIntegration}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
