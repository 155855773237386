// React
import React, {Component} from 'react';
import Columns from 'react-bulma-components/lib/components/columns';
import Level from 'react-bulma-components/lib/components/level';
import {Field, Label} from 'react-bulma-components/lib/components/form';
import {inject, observer} from 'mobx-react';

// WS
import {Theme} from '@wellstone-solutions/common';
import {Switch, Typography} from '@wellstone-solutions/web';
import {EventEmitter} from 'utils/EventEmitter';
import {states, groupStructure, fieldStatus} from 'utils/Forms';
import {Palette} from 'palette';
import Button from '../../tutorial/button';
import PfDropdown from '../components/PfDropdown';
import PfInput from '../components/PfInput';
import PfLoader from '../../shared/PfLoader';
import {ProgramsDropdown} from './ProgramsDropdown';

class AddGroupForm extends Component {
  state = {
    ready: false,
    name: groupStructure.name.default,
    category: this.props.organizationStore.groupCategories[0],
    street1: groupStructure.street1.default,
    street2: groupStructure.street2.default,
    city: groupStructure.city.default,
    state: groupStructure.state.default,
    programId: groupStructure.programId.default,
    zip_code: groupStructure.zip_code.default,
    is_public: this.props.organizationStore.groupCategories[0].is_public,
    isProcessing: false,
  };

  componentDidMount() {
    this.setLoaded();
  }

  setLoaded() {
    this.setState({ready: true});
  }

  _checkForErrors = () => {
    let hasErrors = false;
    for (let [key, value] of Object.entries(groupStructure)) {
      if (fieldStatus(this.state[key], value.test, value.required).error) {
        hasErrors = true;
      }
    }

    if (!hasErrors) {
      hasErrors = this.state.programId.length === 0;
    }

    return hasErrors;
  };

  _selectState = (sel) => {
    this.setState({state: sel});
  };

  _selectCategory = (sel) => {
    this.setState({category: sel, is_public: sel.is_public});
  };

  _addGroup = async () => {
    this.setState({isProcessing: true});
    const org = this.props.meStore.me.membership.organization.id;
    let params = {
      name: this.state.name,
      category_id:
        this.state.category.id ||
        this.props.organizationStore.groupCategories[0].id,
      street1: this.state.street1,
      street2: this.state.street2,
      city: this.state.city,
      state: this.state.state,
      zip_code: this.state.zip_code,
      is_public: this.state.is_public,
    };
    if (this.state.street2 && this.state.street2 !== '') {
      params.street2 = this.state.street2;
    }

    if (this.state.programId.length > 0) {
      params.programs = [{id: this.state.programId}];
    }

    try {
      await this.props.groupStore.addGroup(org, params);
      this.setState({isProcessing: false});
      await this.props.programStore.getAllPrograms();
      Promise.all([
        this.props.groupStore.loadGroups(
          org,
          this.props.tutorialStore.isActive,
        ),
        this.props.groupStore.requestMyGroups(
          this.props.meStore.permittedGroupIds,
        ),
      ]);
      this.props.nextStep();

      if (this.props.onCreate) {
        this.props.onCreate();
      }
    } catch (e) {
      this.setState({isProcessing: false});
    }
  };

  render() {
    const {meStore, organizationStore} = this.props;
    const {groupCategories} = organizationStore;
    const hasErrors = this._checkForErrors();

    if (!this.state.ready) {
      return <PfLoader />;
    }

    return (
      <div style={styles.form}>
        {/*NAME*/}
        <PfInput
          fullWidth
          placeholder="name of group"
          val={this.state.name}
          setval={(newVal) => this.setState({name: newVal})}
          label="Name*"
          field={{
            test: groupStructure.name.test,
            name: 'name',
            required: groupStructure.name.required,
          }}
        />
        {/*TYPE*/}
        <div>
          <PfDropdown
            fullWidth
            val={this.state.category}
            label="Type"
            labelPath="name"
            selectFunc={this._selectCategory}
            list={groupCategories}
            classes="right-drop short-drop"
            field={{
              test: groupStructure.category.test,
              name: 'category',
              required: groupStructure.category.required,
            }}
          />
        </div>
        <>
          <Field>
            <Columns>
              <Columns.Column size={9}>
                <Label htmlFor="is_public" style={styles.fieldNoGutter}>
                  Community Group
                </Label>
                <Typography sx={{fontSize: 14, color: Theme.colorPalette.blue}}>
                  Community Groups allow members to chat with each other.
                </Typography>
              </Columns.Column>
              <Columns.Column size={3} style={styles.flexColumn}>
                <>
                  <Switch
                    checked={this.state.is_public}
                    onChange={(event) => {
                      this.setState({is_public: event.target.checked});
                    }}
                  />
                </>
              </Columns.Column>
            </Columns>
          </Field>
          <ProgramsDropdown
            value={this.state.programId}
            onChange={(programId) => {
              this.setState({programId});
            }}
            programs={meStore.myPrograms.slice()}
          />
        </>
        {/*STREET 1*/}
        <PfInput
          fullWidth
          placeholder="Street"
          val={this.state.street1}
          setval={(newVal) => this.setState({street1: newVal})}
          label="Street"
          field={{
            test: groupStructure.street1.test,
            name: 'street1',
            required: groupStructure.street1.required,
          }}
        />
        {/*STREET 2*/}
        <PfInput
          fullWidth
          placeholder="Street 2"
          val={this.state.street2}
          setval={(newVal) => this.setState({street2: newVal})}
          label="Street 2"
          field={{
            test: groupStructure.street2.test,
            name: 'street2',
            required: groupStructure.street2.required,
          }}
        />
        {/*CITY*/}
        <PfInput
          fullWidth
          placeholder="city"
          val={this.state.city}
          setval={(newVal) => this.setState({city: newVal})}
          label="City"
          field={{
            test: groupStructure.city.test,
            name: 'city',
            required: groupStructure.city.required,
          }}
        />
        {/*STATE*/}
        <PfDropdown
          fullWidth
          val={this.state.state}
          label="State"
          selectFunc={this._selectState}
          list={states}
          classes="top-drop short-drop thin-drop"
          field={{
            test: groupStructure.state.test,
            name: 'state',
            required: groupStructure.state.required,
          }}
        />
        {/*ZIP*/}
        <PfInput
          fullWidth
          placeholder="5 digit zipcode"
          val={this.state.zip_code}
          setval={(newVal) => this.setState({zip_code: newVal})}
          label="Zipcode"
          field={{
            test: groupStructure.zip_code.test,
            name: 'zip_code',
            required: groupStructure.zip_code.required,
          }}
        />

        <Level>
          <Level.Side>
            <Level.Item>
              <Button
                color="primary"
                id="add-group-modal-button"
                isprocessing={this.state.isProcessing}
                disabled={hasErrors}
                onClick={() => {
                  if (!this._checkForErrors()) {
                    this._addGroup();
                  }
                }}>
                Create New Group
              </Button>
            </Level.Item>
          </Level.Side>
          <Level.Side>
            <Level.Item>
              <Button
                color="danger"
                onClick={() => EventEmitter.dispatch('closeModal', null)}>
                Cancel
              </Button>
            </Level.Item>
          </Level.Side>
        </Level>
      </div>
    );
  }
}

const styles = {
  flexColumn: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  fieldLabel: {
    paddingRight: 20,
  },
  fieldNoGutter: {
    marginBottom: 0,
  },
  form: {
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mRight: {
    marginRight: 10,
    transform: 'translateY(25%)',
  },
  communityLabel: {
    color: Palette.INFO,
    textAlign: 'right',
    paddingBottom: 20,
    fontSize: 14,
    fontStyle: 'italic',
  },
};

export default inject(
  'meStore',
  'organizationStore',
  'programStore',
  'groupStore',
  'tutorialStore',
)(observer(AddGroupForm));
