// @flow
import {action, decorate, observable, runInAction} from 'mobx';
import type {IObservableArray} from 'mobx';
import RootStore from 'mobx/RootStore';
import {Models} from '@wellstone-solutions/common';
import {PAGE_SIZE} from './constants';
import type {
  UIStaffType,
  UIAuthorizationType,
  ApiAuthorizationType,
  ApiAuthorizationInputType,
  ApiStaffType,
  ApiStaffInputType,
  UIProgramType,
  UIGroupType,
  ApiResponseType,
  ApiRevocationType,
  ApiRevocationInputType,
} from '@wellstone-solutions/common';
import type {BaseParamsType} from 'types';
import {buildIdLookup} from 'utils/buildIdLookup';

const {Staff, Authorization, Revocation} = Models;

export class StaffStore {
  rootStore: RootStore;
  staff: IObservableArray<UIStaffType> = observable.array();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  async init() {
    // Placeholder
  }

  async createStaffMember(
    input: ApiStaffInputType,
  ): Promise<ApiResponseType<ApiStaffType>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.organizationId;

    const response = await Staff.create(orgId, input);

    return response;
  }

  async getAllStaffMembers(
    params?: BaseParamsType,
  ): Promise<Array<UIStaffType>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.organizationId;

    const response = await Staff.getAll(orgId, {
      limit: params?.limit || PAGE_SIZE,
      offset: params?.offset || 0,
      ...(params || {}),
    });

    const rawStaffMembers = response.isSuccess ? response.data.members : [];

    const uiStaffMembers = rawStaffMembers.map(Staff.toUI);

    return uiStaffMembers;
  }

  async getStaffMember({
    id,
    forceFetch = false,
  }: {
    id: string,
    forceFetch?: boolean,
  }): Promise<UIStaffType | void> {
    const foundStaff = this.findStaffMember(id);

    if (!forceFetch && foundStaff) {
      return Promise.resolve(foundStaff);
    }

    // $FlowFixMe
    const orgId: string = this.rootStore.stores.meStore.organizationId;
    const response = await Staff.get({orgId, id});
    const rawStaffMember = response.isSuccess ? response.data : null;

    if (rawStaffMember) {
      const uiStaffMember = Staff.toUI(rawStaffMember);
      return this._storeStaffMember(uiStaffMember, foundStaff);
    }
  }

  async getStaffByEmail({
    email,
    forceFetch = false,
  }: {
    email: string,
    forceFetch?: boolean,
  }): Promise<{staff: UIStaffType | void, canAccess: boolean}> {
    const foundStaff = this._findStaffByEmail(email);

    if (!forceFetch && foundStaff) {
      return Promise.resolve({staff: foundStaff, canAccess: true});
    }

    // $FlowFixMe
    const orgId: string = this.rootStore.stores.meStore.organizationId;

    const response = await Staff.getAll(orgId, {
      params: {
        limit: 1,
        offset: 0,
        email,
      },
    });

    const apiMember = response.isSuccess ? response.data.members[0] : null;
    // 403 indicates we found a member, but they are unavailable
    const canAccess = response.status !== 403;

    return {
      staff: apiMember
        ? this._storeStaffMember(Staff.toUI(apiMember), foundStaff)
        : undefined,
      canAccess,
    };
  }

  _findStaffByEmail(email: string): UIStaffType | void {
    return this.staff.find((staff: UIStaffType) => staff.user.email === email);
  }

  async getStaffMembersByIds(
    ids: Array<string>,
  ): Promise<Array<UIStaffType | void>> {
    const uiStaffMembers = await this.getAllStaffMembers({
      params: {
        limit: ids.length,
        user_id: ids,
      },
    });

    const staffLookup = buildIdLookup(this.staff);
    const result = uiStaffMembers.map((staff) => {
      const foundStaff = staffLookup[staff.id];
      return this._storeStaffMember(staff, foundStaff);
    });

    return result;
  }

  _storeStaffMember(
    uiStaffMember: UIStaffType,
    foundStaff: UIStaffType | void,
  ): UIStaffType | void {
    // update this.staff to include the new fetched staff member
    const uiAllStaffWithNew = foundStaff
      ? this.staff.map((staff: UIStaffType) =>
          staff.id === uiStaffMember.id ? uiStaffMember : staff,
        )
      : [...this.staff, uiStaffMember];

    runInAction(() => {
      this.staff.replace(uiAllStaffWithNew);
    });

    return this.findStaffMember(uiStaffMember.id);
  }

  async createRevocation({
    staffId,
    revocationInput,
  }: {
    staffId: string,
    revocationInput: ApiRevocationInputType,
  }): Promise<ApiResponseType<ApiRevocationType>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.organizationId;
    const response = await Revocation.create(orgId, revocationInput);

    await this.getStaffMember({id: staffId, forceFetch: true});

    return response;
  }

  async removeRevocation({
    staffId,
    revocationId,
  }: {
    staffId: string,
    revocationId: string,
  }): Promise<ApiResponseType<boolean>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.organizationId;
    const response = await Revocation.remove(orgId, revocationId);

    await this.getStaffMember({id: staffId, forceFetch: true});

    return response;
  }

  async createAuthorization({
    staffId,
    authorizationInput,
  }: {
    staffId: string,
    authorizationInput: ApiAuthorizationInputType,
  }): Promise<ApiResponseType<ApiAuthorizationType>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.organizationId;
    const response = await Authorization.create(
      orgId,
      staffId,
      authorizationInput,
    );

    await this.getStaffMember({id: staffId, forceFetch: true});

    return response;
  }

  async removeAuthorization({
    staffId,
    authorizationId,
  }: {
    staffId: string,
    authorizationId: string,
  }): Promise<ApiResponseType<boolean>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.organizationId;
    const response = await Authorization.remove(
      orgId,
      staffId,
      authorizationId,
    );

    await this.getStaffMember({id: staffId, forceFetch: true});

    return response;
  }

  // returns a list of Programs that the staff member is authorized for
  authorizedPrograms({staffId}: {staffId: string}): Array<UIProgramType> {
    const programs: IObservableArray<UIProgramType> = this.rootStore.stores
      .programStore.programs;
    return this._authorizedObjects(staffId, programs);
  }

  // returns a list of Groups that the staff member is authorized for
  authorizedGroups({staffId}: {staffId: string}): Array<UIGroupType> {
    const groups: IObservableArray<UIGroupType> =
      this.rootStore.stores.groupStore.groups || [];

    return this._authorizedObjects(staffId, groups);
  }

  _authorizedObjects<T>(
    staffId: string,
    collection: IObservableArray<T>,
  ): Array<T> {
    const foundAuths = this.findStaffMember(staffId)?.authorizations;

    if (!foundAuths) {
      return [];
    }

    return foundAuths.reduce((accum, auth: UIAuthorizationType) => {
      // $FlowFixMe
      const obj = collection.find((o) => o.id === auth.obj_id);
      obj && accum.push(obj);
      return accum;
    }, []);
  }

  setStaff(staff: Array<UIStaffType>): void {
    this.staff.replace(staff);
  }

  findStaffMember(id: string): UIStaffType | void {
    return this.staff.find((staff: UIStaffType) => staff.id === id);
  }
}

decorate(StaffStore, {
  staff: observable,
  authorizations: observable,
  setStaff: action,
});
