// @flow
import {Api} from '@wellstone-solutions/common';
import type {ApiResponseType} from '@wellstone-solutions/common';
import type {
  ApiSessionDocumentInputType,
  ApiSessionDocumentType,
  ApiSessionServiceType,
  ApiPDFResponseType,
} from './types';

export const routes = {
  index: (orgId: string): string =>
    `/orgs/${orgId}/documentation/session-documentation`,
  detail: (orgId: string, id: string): string =>
    `/orgs/${orgId}/documentation/session-documentation/${id}`,
  pdf: (orgId: string, id: string): string =>
    `/orgs/${orgId}/documentation/session-documentation/${id}/pdf`,
  sessionTypes: (id: string): string =>
    `/orgs/${id}/documentation/session-service-types`,
};

export const create = async (
  orgId: string,
  sessionDocInput: ApiSessionDocumentInputType,
): Promise<ApiResponseType<ApiSessionDocumentType>> =>
  Api.Instance.current().post(routes.index(orgId), sessionDocInput);

export const update = async (
  orgId: string,
  id: string,
  sessionDocInput: ApiSessionDocumentInputType,
): Promise<ApiResponseType<ApiSessionDocumentType>> =>
  Api.Instance.current().put(routes.detail(orgId, id), sessionDocInput);

export const updateFields = async (
  orgId: string,
  id: string,
  sessionDocInput: ApiSessionDocumentInputType,
): Promise<ApiResponseType<ApiSessionDocumentType>> =>
  Api.Instance.current().patch(routes.detail(orgId, id), sessionDocInput);

export const get = async (
  orgId: string,
  id: string,
): Promise<ApiResponseType<ApiSessionDocumentType>> =>
  Api.Instance.current().get(routes.detail(orgId, id));

export const getPDF = async (
  orgId: string,
  id: string,
): Promise<ApiResponseType<ApiPDFResponseType>> =>
  Api.Instance.current().get(routes.pdf(orgId, id));

export const getServiceTypes = async (
  orgId: string,
): Promise<ApiResponseType<Array<ApiSessionServiceType>>> =>
  Api.Instance.current().get(routes.sessionTypes(orgId));

export const remove = async (
  orgId: string,
  id: string,
): Promise<ApiResponseType<{}>> =>
  Api.Instance.current().delete(routes.detail(orgId, id));
