// @flow
import React from 'react';
import type {Node} from 'react';
import {Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

export const PageTitle = (): Node => {
  return (
    <Typography variant="h1" sx={styles.title}>
      Resources
    </Typography>
  );
};

const styles = {
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: '700',
    color: Theme.colorPalette.primary,
  },
};
