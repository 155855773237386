// @flow
import React, {useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import moment from 'moment';
import {Button, Box, Stack} from '@wellstone-solutions/web';
import {Hooks, Theme} from '@wellstone-solutions/common';
import {Modal} from '@mui/material';
import {useStores} from 'hooks/useStores';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {GCS_BASE_URL, GCS_DEFAULT_BUCKET} from 'constants/CloudStorage';
import {WizardHeader} from 'components/Wizard/WizardHeader';
import {EHRWrapper} from '../EHRWrapper';
import {CompletedRow} from '../CompletedRow';

/*
    Two ways for this to render:
    1. Show EHR Integration if on member detail
    2. Show Connect button if no integration defined, but org has possibility
*/
type PropsType = {
  memberType: string,
  member: any,
};

export const EHRConnect: ComponentType<PropsType> = observer(
  ({memberType, member}: PropsType): Node => {
    const [showModal, setShowModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [externalMatch, setExternalMatch] = useState();
    const [externalIntegration, setExternalIntegration] = useState();
    const {memberStore, meStore, staffStore} = useStores();

    const memberId = member.id;
    const ehrIntegration = meStore?.ehrIntegrationOption; // this is the org's EHR integration option

    const ehrName = ehrIntegration?.name;
    const {integrations} = member;

    // Can get integration from saved member detail, or upon fresh connection
    const hasMemberIntegration =
      integrations.length > 0 || !!externalIntegration;

    const logoUrl = `${GCS_BASE_URL}/${GCS_DEFAULT_BUCKET}/${String(
      ehrIntegration?.logo,
    )}`;

    let memberEHRID = '';
    let memberEHRConnectedDate = '';

    if (hasMemberIntegration) {
      const primaryIntegration = integrations?.[0];

      memberEHRID =
        primaryIntegration?.external_relationship_id ??
        externalIntegration?.external_relationship_id;
      memberEHRConnectedDate = primaryIntegration
        ? moment(primaryIntegration.created).format('MMMM Do YYYY, h:mm:ss a')
        : moment(externalIntegration?.created).format(
            'MMMM Do YYYY, h:mm:ss a',
          );
    }

    const toggleModal = (event, reason: string) => {
      if (reason !== 'backdropClick') {
        setShowModal(!showModal);
      }

      if (!showModal) {
        form.resetForm();
      }
    };

    const saveConnection = async () => {
      setIsSaving(true);
      const response = await memberStore.createEHRIntegration({
        memberId,
        externalRelationshipId: form.values.externalRelationshipId,
      });

      if (response.isSuccess) {
        setShowModal(false);

        if (memberType === 'member') {
          memberStore.getMember({id: memberId, forceFetch: true});
        } else {
          staffStore.getStaffMember({id: memberId, forceFetch: true});
        }

        setExternalIntegration(response.data);
        showAlert(`Connection saved`, ALERT_TYPES.success);
      }

      if (!response.isSuccess) {
        showAlert(`Failed to add ${ehrName} integration.`, ALERT_TYPES.error);
      }

      setIsSaving(false);
    };

    const form = Hooks.useForm({
      initialValues: {
        externalRelationshipId: '',
      },
      schema: {
        externalRelationshipId: (value) => value.length > 0,
      },
    });

    return (
      <Box sx={{display: 'flex'}}>
        {!hasMemberIntegration && (
          <Stack direction="row" sx={{alignItems: 'center'}}>
            <img src={logoUrl} style={styles.logo} />
            <Button onClick={toggleModal} sx={styles.button}>
              Connect
            </Button>
            <Modal open={showModal} onClose={toggleModal}>
              <div>
                <Box sx={styles.modalContainer}>
                  <WizardHeader
                    title={`Connect ${
                      memberType === 'staff' ? 'staff ' : ''
                    }member to ${ehrName}`}
                    subtitle={`Connecting the ${
                      memberType === 'staff' ? 'staff ' : ''
                    }member’s profile from Bridge to ${ehrName} will synchronize records between the two systems. **This is required.**`}
                    onCancel={toggleModal}
                  />
                  <EHRWrapper
                    form={form}
                    memberType={memberType}
                    orgIntegration={ehrIntegration}
                    setIntegration={setExternalMatch}
                  />
                  <Box sx={{mt: 4}}>
                    <Button
                      disabled={isSaving || !externalMatch}
                      variant="contained"
                      sx={styles.saveButton}
                      onClick={saveConnection}>
                      Save
                    </Button>
                  </Box>
                </Box>
              </div>
            </Modal>
          </Stack>
        )}
        {/* Display integration once persisted */}
        {hasMemberIntegration && (
          <Stack direction="column">
            <img src={logoUrl} style={styles.logo} />
            <CompletedRow label={`${ehrName} identifier`} value={memberEHRID} />
            <CompletedRow label="Connected" value={memberEHRConnectedDate} />
          </Stack>
        )}
      </Box>
    );
  },
);

const styles = {
  logo: {
    width: 100,
  },
  button: {
    ml: 2,
    fontSize: 16,
    textTransform: 'capitalize',
    textDecoration: 'underline',
  },
  modalContainer: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Theme.colorPalette.lightest,
    padding: '34px',
    borderRadius: '22px',
    overflow: 'hidden',
    minWidth: '200px',
    width: {xs: '98%', sm: '85%', md: '60%', lg: '40%'},
    border: `1px solid ${Theme.colorPalette.dark}`,
  },
  saveButton: {
    borderRadius: 10,
    fontWeight: 'bold',
    px: 4,
    textTransform: 'capitalize',
  },
};
