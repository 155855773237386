// @flow
import {Models} from '@wellstone-solutions/common';
import {MAX_DESC_ERROR, MAX_FILE_SIZE_ERROR} from './constants';
import {MAX_FILE_SIZE} from 'constants/Images';

// Validators that sit on top of the common libs Schema

const isStringSizeValid = (str) => {
  const encoder = new TextEncoder();
  const strBytes = encoder.encode(str);
  return strBytes.length <= MAX_FILE_SIZE;
};

export const EditFormValidators = {
  mediaImage: {
    validate: (file: File): boolean => {
      if (!file) {
        return true; // not required on Edit
      }
      return Boolean(
        // $FlowIgnoreMe
        file.size < MAX_FILE_SIZE && Models.Story.schema.mediaImage(file),
      );
    },
    error: MAX_FILE_SIZE_ERROR,
  },
  desc: {
    validate: (desc: string): boolean => {
      return Models.Story.schema.desc(desc) && isStringSizeValid(desc);
    },
    error: MAX_DESC_ERROR,
  },
};

export const NewFormValidators = {
  mediaImage: {
    validate: (file: File): boolean =>
      Boolean(
        file &&
          file.size < MAX_FILE_SIZE &&
          // $FlowIgnoreMe
          Models.Story.schema.mediaImage(file),
      ),
    error: MAX_FILE_SIZE_ERROR,
  },
  desc: {
    validate: (desc: string): boolean => {
      return Models.Story.schema.desc(desc) && isStringSizeValid(desc);
    },
    error: MAX_DESC_ERROR,
  },
};
