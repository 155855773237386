// @flow
import {useCallback, useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useStores} from 'hooks/useStores';
import type {IObservable} from 'mobx';
import type {UIStaffType} from '@wellstone-solutions/common';

export const useDataLoader = ({
  initialFetch = true,
}: {
  initialFetch?: boolean,
} = {}): ({
  isLoading: boolean,
  data?: IObservable<UIStaffType>,
  fetchData: () => Promise<void>,
}) => {
  const {staffStore} = useStores();
  const {admin: staffId} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [currentStaff, setCurrentStaff] = useState(undefined);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const staff = await staffStore.getStaffMember({
      id: staffId,
      forceFetch: true,
    });
    setCurrentStaff(staff);
    setIsLoading(false);
  }, [staffStore, staffId]);

  useEffect(() => {
    if (initialFetch) {
      fetchData();
    }
  }, [fetchData, initialFetch]);

  return {isLoading: isLoading, data: currentStaff, fetchData};
};
