// @flow
import React from 'react';
import type {Node} from 'react';
import {styled} from '@mui/material/styles';
import {Theme} from '@wellstone-solutions/common';
import {Stack} from '@wellstone-solutions/web';

const Button = styled('button')(({theme}) => ({
  alignItems: 'center',
  borderRadius: theme.spacing(16),
  borderStyle: 'none',
  color: Theme.colorPalette.primary,
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 'bold',
  justifyContent: 'center',
  height: theme.spacing(4),
  marginRight: theme.spacing(1),
  width: theme.spacing(4),
  padding: theme.spacing(1),
}));

const SelectedButton = styled(Button)(({theme}) => ({
  backgroundColor: Theme.colorPalette.secondary,
  color: Theme.colorPalette.lightest,
}));

const valueMap = {
  monday: 'MO',
  tuesday: 'TU',
  wednesday: 'WE',
  thursday: 'TH',
  friday: 'FR',
  saturday: 'SA',
  sunday: 'SU',
};

const items = [
  {
    label: valueMap.sunday.charAt(0),
    value: valueMap.sunday,
  },
  {
    label: valueMap.monday.charAt(0),
    value: valueMap.monday,
  },
  {
    label: valueMap.tuesday.charAt(0),
    value: valueMap.tuesday,
  },
  {
    label: valueMap.wednesday.charAt(0),
    value: valueMap.wednesday,
  },
  {
    label: valueMap.thursday.charAt(0),
    value: valueMap.thursday,
  },
  {
    label: valueMap.friday.charAt(0),
    value: valueMap.friday,
  },
  {
    label: valueMap.saturday.charAt(0),
    value: valueMap.saturday,
  },
];

const SPLITTER = ',';

type PropsType = {
  onChange: (string) => void,
  value?: string,
};

export const DaysOfWeek = ({onChange, value}: PropsType): Node => {
  const values = value ? value.split(SPLITTER).filter(Boolean) : [];

  return (
    <Stack direction="row" data-testid="days-of-week">
      {items.map((item) =>
        values.includes(item.value) ? (
          <SelectedButton
            key={item.value}
            type="button"
            onClick={() => {
              onChange(values.filter((v) => v !== item.value).join(SPLITTER));
            }}>
            {item.label}
          </SelectedButton>
        ) : (
          <Button
            key={item.value}
            type="button"
            onClick={() => {
              onChange([...values, item.value].join(SPLITTER));
            }}>
            {item.label}
          </Button>
        ),
      )}
    </Stack>
  );
};
