import {Actions, Types} from '../../constants/Messages';

class PubnubStore {
  constructor(tutorialStore) {
    this.tutorialStore = tutorialStore;
  }

  routes = [];
  cannedResponse = {
    channel: 'pf-demo-channel',
    timetoken: new Date(),
    message: {
      data: {
        name: 'Xavier',
        author: 'a421c004-babf-4c82-a494-7be80fedb838',
        user_role: 'patient',
        content: 'Good! How are you?',
        action: Actions.CREATED,
        authorTT: new Date(),
        type: Types.MESSAGE,
      },
      channel: 'pf-demo-channel',
      signature:
        '018e9d8381b5f8ac828966a09d5ea9a87abe93ef6b6b5f10e7c8515b0f85c6b7542291c40d62db655ef715677b5f348ee31a0ef487f6780d894bb95c6a504f79724c5bb40c6fdcfe508dda1b11b347384e1499fd707101a01f3e7762c2e66b2b092e05cb7d8241dc9805fa2512b62b0978dbc51220ca99940eef3c803b589fa78a61c446a32e78facb4e2e08423fbe9e9922e19ca314b88e67f55ad910251f3d87f3f83af04503c0b8031609c778bd3872f90237b28c0cba75c6e8a2b3fe419518cc1c4af870d442bbdae1b08314603007bdd3027f3c174b266b3aafaf100a8f8dde4f8ac9646e993a226dae26079c727dc92b3512cdf02e7b629611bf6b634f',
    },
    messageType: null,
    uuid: 'a132c004-babf-4c82-a494-7be80fedb838',
  };

  publish(channel, message) {
    const {pubnubStore} = this.tutorialStore.rootStore.stores;
    message.timetoken = new Date();
    pubnubStore.handleMessage({message});
    setTimeout(() => {
      this.cannedResponse.channel = channel;
      this.cannedResponse.message.channel = channel;
      pubnubStore.handleMessage(this.cannedResponse);
    }, 1000);
  }

  getChannelHistory() {
    console.log('getting mock channel history');
    return [
      // {
      //   channel: 'pf-demo-channel',
      //   timetoken: new Date(),
      //   message: {
      //     data: {
      //       name: 'Shawn Northrop',
      //       author: 'a132c004-babf-4c82-a494-7be80fedb838',
      //       user_role: 'staff',
      //       content: 'hey man',
      //       action: 'chat',
      //       authorTT: 1602196064006,
      //       type: 'message',
      //     },
      //     channel: 'pf-demo-channel',
      //   },
      //   messageType: null,
      //   uuid: 'a132c004-babf-4c82-a494-7be80fedb838',
      // },
    ];
  }
}

export default PubnubStore;
