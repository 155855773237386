// @flow
import {IconNames} from '@wellstone-solutions/web';

export const getStoryIcon = (mimeType?: string): string => {
  if (mimeType) {
    switch (mimeType) {
      case 'video/mpeg':
        return IconNames.VideoCamera;
      case 'video/ogg':
        return IconNames.VideoCamera;
      case 'youtube':
        return IconNames.YouTube;
      case 'audio/mpeg':
        return IconNames.Headset;
      case 'audio/ogg':
        return IconNames.Headset;
      default:
        return IconNames.Page;
    }
  }

  return IconNames.Page;
};
