// @flow

export const ACCEPTED_IMAGE_TYPES = ['image/png', 'image/jpeg'];
export const INVALID_IMAGE_TYPE_ERROR =
  'Invalid file type, please add a jpeg or png.';

export const MAX_FILE_SIZE = 5_242_880 * 2;
export const MAX_INLINE_FILE_SIZE = 5_242_880;

export const MAX_INLINE_FILE_ERROR =
  'Image is too large, please add an image less than 5MB.';

export const TEMPORARY_IMAGE_FOLDER = 'tmp_images';
