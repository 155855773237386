// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Icon,
  IconNames,
  Stack,
  Tooltip,
  Typography,
} from '@wellstone-solutions/web';
import {Events, Hooks, Models, Theme} from '@wellstone-solutions/common';
import {usePrompt} from 'hooks/usePrompt';
import {useStores} from 'hooks/useStores';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {NAVIGATION_NAMES} from 'constants/Navigation';

import {ActionButtons} from './ActionButtons';
import {NewFormValidators} from '../../validators';
import {STATUSES, TOOLTIP_FULLSTORY, TOOLTIP_PREVIEW} from '../../constants';
import {Form} from './Form';
import {Abstract, FullStory} from '../StoryPreview';

const FORM_ID = 'new_form';

export const New = (): Node => {
  const {appUIStore, eventStore, meStore, storyStore} = useStores();
  const navigate = useNavigate();

  const {Story} = Models;

  const {useForm} = Hooks;

  const story = Story.Factory({
    availability: 'private',
    organization: meStore.activeMembership.organization.id,
    status: STATUSES.draft,
    data: {highlighted: true},
  });

  const form = useForm({
    initialValues: story,
    schema: {
      ...Story.schema,
      ...NewFormValidators,
    },
    onSubmit: async (values, {resetForm}) => {
      const response = await storyStore.createStory(values);
      if (response.isSuccess) {
        eventStore.addEvent(Events.ADDED_STORY);
        showAlert('Successfully created the story.');
        resetForm();
        navigate(`/stories/${response.data.id}`);
        return;
      } else {
        showAlert(
          'Failed to save the story. Please try again.',
          ALERT_TYPES.ERROR,
        );
      }
    },
  });

  useEffect(() => {
    eventStore.addEvent(Events.VIEWED_STORY_NEW);
    appUIStore.setBreadcrumbs([
      {
        name: NAVIGATION_NAMES.stories,
        url: '/stories',
      },
      {
        name: NAVIGATION_NAMES.storyNew,
        url: '/stories/new',
        active: true,
      },
    ]);
  }, [eventStore, appUIStore]);

  // prevent unintentionally losing unsaved changes
  usePrompt(
    'Are you sure you want to leave? You have unsaved changes that will be lost if you navigate away from this page.',
    // $FlowIgnoreMe
    !form.isSubmitting && form.dirty,
  );

  return (
    <Box>
      <Stack direction={{xs: 'column', md: 'row'}}>
        <Stack
          flex={1}
          direction="column"
          justifyContent={{md: 'space-between'}}
          sx={{mb: 4, pr: 8}}>
          <Typography variant="h5" sx={{color: Theme.colorPalette.primary}}>
            New Story
          </Typography>
          <Form form={form} formId={FORM_ID} />
          <ActionButtons formId={FORM_ID} form={form} />
        </Stack>
        <Stack
          flex={1}
          direction="column"
          justifyContent={{md: 'start'}}
          sx={{mb: 4, pr: 8}}>
          {/* Story Preview Here */}
          <Stack direction="row" sx={{mb: 2}}>
            <Typography variant="h6">Story Preview</Typography>
            <Tooltip title={TOOLTIP_PREVIEW} placement="top">
              <div style={styles.tooltip}>
                <Icon name={IconNames.InfoCircle} />
              </div>
            </Tooltip>
          </Stack>
          <Abstract
            abstract={form.values.abstract}
            title={form.values.title}
            host={form.values.host}
            organization={form.values.organization}
            mediaImage={
              NewFormValidators.mediaImage.validate(form.values.mediaImage)
                ? form.values.mediaImage
                : null
            }
            tags={form.values.tags}
            markdownText={form.values.desc}
            posterImageUrl={form.values.mediaUrl}
          />
          <Stack direction="row" sx={{mt: 2, mb: 2}}>
            <Typography variant="h6">Full Story</Typography>
            <Tooltip title={TOOLTIP_FULLSTORY} placement="top">
              <div style={styles.tooltip}>
                <Icon name={IconNames.InfoCircle} />
              </div>
            </Tooltip>
          </Stack>
          <FullStory
            abstract={form.values.abstract}
            title={form.values.title}
            host={form.values.host}
            organization={form.values.organization}
            tags={form.values.tags}
            markdownText={form.values.desc}
            mediaImage={
              NewFormValidators.mediaImage.validate(form.values.mediaImage)
                ? form.values.mediaImage
                : null
            }
            posterImageUrl={form.values.mediaUrl}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

const styles = {
  tooltip: {display: 'flex', alignItems: 'center', marginLeft: 4},
};
