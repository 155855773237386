// @flow
import {useCallback, useState, useEffect} from 'react';
import type {UIStaffType} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {ROLES} from 'utils/Permissions';

export const useDataLoader = ({
  initialFetch = true,
  email,
}: {
  initialFetch?: boolean,
  email: string,
} = {}): ({
  isLoading: boolean,
  data: {
    staff: UIStaffType | void,
    canAccess: boolean,
    revoked: boolean,
  },
  fetchData: () => Promise<void>,
}) => {
  const {staffStore, memberStore} = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    staff: undefined,
    canAccess: false,
    revoked: false,
  });

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const {staff, canAccess} = await staffStore.getStaffByEmail({
      email,
    });
    const {
      member,
      canAccess: canAccessMember,
    } = await memberStore.getMemberByEmail({
      email,
    });
    const isExistingPatient = Boolean(member && member.role === ROLES.patient);

    setData({
      canAccess: canAccess && !isExistingPatient,
      staff,
      revoked: !canAccessMember,
    });
    setIsLoading(false);
  }, [email, memberStore, staffStore]);

  useEffect(() => {
    if (initialFetch) {
      fetchData();
    }
  }, [fetchData, initialFetch]);

  return {isLoading, data, fetchData};
};
