export const buildIdLookup = (collection, key?: string) => {
  if (!collection || collection.length <= 0) {
    return {};
  }

  const lookupKey = key || 'id';

  return collection.reduce((lookup, item) => {
    if (!item[lookupKey]) {
      return lookup;
    }
    lookup[item[lookupKey]] = item;
    return lookup;
  }, {});
};
