// @flow
import {action, computed, observable, decorate, runInAction} from 'mobx';
import type {IObservableArray} from 'mobx';
import {Models} from '@wellstone-solutions/common';
import type {ApiResponseType, ApiMemberType} from '@wellstone-solutions/common';
import {
  Api as HabitsApi,
  Constants as HabitsConstants,
  Factories as HabitsFactories,
} from 'modules/habits';
import type {UIMemberHabitType} from 'modules/habits';
import {
  aggregateData,
  createRangeArray,
  eventToChartData,
  formatEvents,
  syncDataToRange,
} from 'utils/Charts';
import type {UIHabitType} from 'modules/habits/types';

const {Member} = Models;
const {HABIT_TYPES} = HabitsConstants;

class MemberDetailStore {
  currentMember: any | null = null;
  // $FlowFixMe
  currentMemberHabits: IObservableArray<UIMemberHabitType> = [];
  hasStagedHabits: boolean = false;
  isLoadingMemberHabits: boolean = true;
  rootStore: any = null;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  addMemberHabit: (UIMemberHabitType) => void = ({
    habitId,
    isMorning,
    isAfternoon,
    isEvening,
  }: UIMemberHabitType) => {
    const newMemberHabits = [
      ...this.currentMemberHabits,
      HabitsFactories.MemberHabitFactory({
        habitId: habitId,
        isMorning: isMorning,
        isAfternoon: isAfternoon,
        isEvening: isEvening,
        type: HABIT_TYPES.member,
      }),
    ];

    this.currentMemberHabits.replace(newMemberHabits);
    this.hasStagedHabits = true;
  };

  deleteMemberHabit: (habitId: string) => void = (habitId: string) => {
    const newMemberHabits = this.currentMemberHabits.filter(
      (memberHabit) =>
        memberHabit.habitId !== habitId ||
        (memberHabit.habitId === habitId &&
          memberHabit.type === HABIT_TYPES.user),
    );

    this.currentMemberHabits.replace(newMemberHabits);
    this.hasStagedHabits = true;
  };

  updateMemberHabit: (habitId: string, updates: UIMemberHabitType) => void = (
    habitId: string,
    updates: UIMemberHabitType,
  ) => {
    const updatedMemberHabits = this.currentMemberHabits.map((memberHabit) => {
      if (
        memberHabit.habitId === updates.habitId &&
        memberHabit.type === HABIT_TYPES.member
      ) {
        return {
          ...memberHabit,
          ...updates,
        };
      }

      return memberHabit;
    });

    this.currentMemberHabits.replace(updatedMemberHabits);
    this.hasStagedHabits = true;
  };

  removeAllMemberHabits() {
    const otherHabits = this.currentMemberHabits.filter(
      (memberHabit) => memberHabit.type !== HABIT_TYPES.member,
    );

    this.currentMemberHabits.replace(otherHabits);
    this.saveMemberHabits();
  }

  saveMemberHabits() {
    // persist to API
    const {organization} = this.rootStore.stores.meStore.me.membership;
    const organizationId = organization.id;

    const memberHabits = this.currentMemberHabits.filter(
      (memberHabit) => memberHabit.type === HABIT_TYPES.member,
    );

    const memberId =
      this.currentMember && this.currentMember.id
        ? this.currentMember.id
        : null;

    if (!memberId) {
      return;
    }

    HabitsApi.saveMemberHabits(organizationId, memberId, memberHabits);

    this.hasStagedHabits = false;
  }

  getBreadCrumbs(): [] | any[] {
    if (!this.currentMember) {
      return [];
    }

    const crumbs = [
      {
        name: 'Members',
        url: '/members',
      },
      {
        name: this.currentMember.name,
        url: `/members/${this.currentMember.id}`,
        active: true,
      },
    ];

    return crumbs;
  }

  get primaryGroups(): Array<string> | null {
    if (
      !this.currentMember ||
      !this.rootStore.stores.organizationStore.primaryCategory
    ) {
      return null;
    }

    return this.currentMember.group_admissions
      .filter(
        (admission, g) =>
          admission.group.category_id ===
          this.rootStore.stores.organizationStore.primaryCategory.id,
      )
      .map((admission) => admission.group.id);
  }

  getActivityData: ({
    aggregator: string,
    eventsData: any,
    eventCategory: string,
    groupingFn: (any) => any,
    selectionRange: any,
  }) => null = ({
    aggregator,
    eventsData,
    eventCategory,
    groupingFn,
    selectionRange,
  }) => {
    const range = createRangeArray(
      selectionRange.startDate,
      selectionRange.endDate,
      aggregator,
    );
    const eventCategoryData = formatEvents(eventsData, eventCategory);
    const groupedData = groupingFn(eventCategoryData);

    const dataForDateRange = groupedData.map((group, s) => {
      const series = aggregateData(group.data, 'value', {
        by: aggregator,
        aggIndex: false,
        aggValue: true,
      });

      const syncedData = syncDataToRange(series, 'value', {
        range: range,
        by: aggregator,
      });

      return {
        ...group,
        data: syncedData,
      };
    });

    return dataForDateRange;
  };

  groupHabits: (habits: any[]) => any = (habits) => {
    const {habitStore} = this.rootStore.stores;
    const allHabitsData = habitStore.allHabits.map((habit) => {
      const data = habits
        .filter((h) => h.data.habit_id === habit.id)
        .map((habitEvent) => eventToChartData(habitEvent, 1, null, 1));

      if (data.length === 0) {
        return null;
      }

      const memberHabits = this.currentMemberHabits.filter(
        (memberHabit) => memberHabit.habitId === habit.id,
      );
      const memberHabit = memberHabits.find(
        (targetHabit) => targetHabit.type === HABIT_TYPES.member,
      );
      const userHabit = memberHabits.find(
        (targetHabit) => targetHabit.type === HABIT_TYPES.user,
      );
      // Coalesce on habit to determine the target times per day
      // If there isn't a member or user habit, then they likely had one
      // completed it at some point and then it was removed.
      // Defaulting these cases to the schedule target for the base habit.
      const targetHabit = memberHabit || userHabit || habit;

      const target =
        Number(targetHabit.isMorning) +
          Number(targetHabit.isAfternoon) +
          Number(targetHabit.isEvening) || 1;

      return {
        name: habit.name,
        tags: Array.from(habit.tags),
        target,
        data,
      };
    });

    return allHabitsData.filter(Boolean);
  };

  async getCurrentMember(
    orgId: string,
    id: string,
  ): Promise<ApiResponseType<ApiMemberType>> {
    if (this.currentMember && this.currentMember.id === id) {
      return Promise.resolve({
        isSuccess: true,
        data: this.currentMember,
        errors: [],
        status: 200,
      });
    }

    const response = await Member.get({orgId, id});

    if (!response.isSuccess) {
      this.setCurrentMember(null);
    }

    this.setCurrentMember(response.data);

    return response;
  }

  setCurrentMember(member: any) {
    this.currentMember = member;
  }

  setCurrentMemberHabits(habits: UIHabitType[]) {
    // $FlowFixMe
    this.currentMemberHabits.replace(habits);
  }

  async syncCurrentMemberHabits() {
    runInAction(() => {
      this.isLoadingMemberHabits = true;
    });
    // $FlowFixMe
    const memberId = this.currentMember.id;
    const {organization} = this.rootStore.stores.meStore.me.membership;
    const organizationId = organization.id;

    // Get the members habits for the org
    const memberHabits = await HabitsApi.getMemberHabits({
      organizationId,
      memberId,
    });

    const mergedMemberHabits = memberHabits.map((h) => ({
      ...h,
      type: HABIT_TYPES.member,
    }));

    // Get the members user habits
    const userHabits = await HabitsApi.getUserHabits({
      organizationId,
      memberId,
    });

    const mergedUserHabits = userHabits.map((habit) => ({
      ...habit,
      type: HABIT_TYPES.user,
    }));

    const mergedHabits = [...mergedUserHabits, ...mergedMemberHabits];

    runInAction(() => {
      // $FlowFixMe
      this.currentMemberHabits = mergedHabits;
      this.isLoadingMemberHabits = false;
    });
  }
}

decorate(MemberDetailStore, {
  addMemberHabit: action,
  currentMember: observable,
  currentMemberHabits: observable,
  deleteMemberHabit: action,
  hasStagedHabits: observable,
  primaryGroups: computed,
  setCurrentMember: action,
  updateMemberHabit: action,
});

export default MemberDetailStore;
