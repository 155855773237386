// React
import React from 'react';

import Button from 'components/tutorial/button';

export class PfChartLegend extends React.Component {
  state = {
    ready: false,
  };

  componentDidMount() {
    this.setLoaded();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.labels !== this.props.labels) {
      this._updateLabels();
    }
  }

  setLoaded() {
    const labels = this.props.labels;
    this._sortLabels(labels);
    this.setState({
      ready: true,
      labels,
    });
  }

  _sortLabels(labels) {
    labels.sort((a, b) => {
      var stackA = a.stack.toUpperCase();
      var stackB = b.stack.toUpperCase();
      if (stackA < stackB) {
        return -1;
      }
      if (stackA > stackB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  _updateLabels = () => {
    const labels = this.props.labels;
    this._sortLabels(labels);
    // TODO determine if de-selected items should be maintained. this requires more robust back and forth
    this.setState({ready: true, labels});
  };

  _getTextColor(bg) {
    // Randomly update colours
    // rgb[0] = Math.round(Math.random() * 255);
    // rgb[1] = Math.round(Math.random() * 255);
    // rgb[2] = Math.round(Math.random() * 255);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(bg);
    const rgb = [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ];

    // http://www.w3.org/TR/AERT#color-contrast
    const brightness = Math.round(
      (parseInt(rgb[0], 16) * 299 +
        parseInt(rgb[1], 16) * 587 +
        parseInt(rgb[2], 16) * 114) /
        1000,
    );
    return brightness > 300 ? 'black' : 'white';

    // const backgroundColour = 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')';
    // $('#bg').css('color', textColour);
    // $('#bg').css('background-color', backgroundColour);
  }

  _updateChart = (l) => {
    let lbls = this.state.labels;
    const count = lbls.filter((obj) => obj.selected).length;
    if (lbls[l].selected && count < 2) {
      return;
    } else {
      lbls[l].selected = !lbls[l].selected;
      let selectedLabels = lbls.filter((label) => label.selected);
      this.props.updateChart(selectedLabels, this.props.chartType);
    }
  };

  render() {
    if (!this.state.ready) {
      return <div>loading</div>;
    }
    return (
      <div>
        {this.state.labels.map((label, l) => {
          return (
            <React.Fragment key={l}>
              <Button
                key={l}
                id={`chart-legend-${label.name
                  .toLowerCase()
                  .replace(' ', '-')}`}
                style={{
                  ...{
                    backgroundColor: label.selected ? label.color : '#c9c9c9',
                    color: this._getTextColor(label.color),
                  },
                  ...styles.selector,
                }}
                onClick={() => {
                  this._updateChart(l);
                }}>
                {label.name}
              </Button>
              {this.state.labels[l + 1] &&
              label.stack !== this.state.labels[l + 1].stack ? (
                <br />
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

const styles = {
  selector: {
    fontSize: 12,
    height: 30,
    paddingHorizontal: 15,
    marginRight: 5,
    marginBottom: 10,
    borderRadius: 20,
    borderColor: 'white',
  },
};
