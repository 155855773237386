// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {styled} from '@mui/material/styles';

import {
  Box,
  ErrorMessage,
  Icon,
  IconNames,
  Tabs,
  Tab,
  TabPanel,
  TextField,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import type {FormType, UICalendarEventType} from '@wellstone-solutions/common';

import {AddressForm} from './AddressForm';

type PropsType = {
  form: FormType<UICalendarEventType>,
};

const StyledTab = styled(Tab)({
  flex: 1,
  backgroundColor: `${Theme.colorPalette.primary} !important`,
  color: `${Theme.colorPalette.lightest} !important`,
  fontWeight: 'bold !important',
  fontSize: '1rem !important',
  textTransform: 'none !important',
  maxWidth: 'unset !important',
  minHeight: '63px !important',
});

export const EventDataTabs = ({form}: PropsType): Node => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabIndexChange = (event: mixed, index: number): void => {
    setTabIndex(index);
  };

  return (
    <Box>
      <Tabs
        TabIndicatorProps={{
          sx: {
            backgroundColor: `${Theme.colorPalette.secondary} !important`,
            height: '3px',
          },
        }}
        value={tabIndex}
        textColor={'secondary'}
        indicatorColor={'secondary'}
        onChange={handleTabIndexChange}
        sx={{
          background: Theme.colorPalette.primary,
          borderRadius: 3,
          pl: 0,
          color: 'white',
          mb: 3,
        }}>
        <StyledTab
          icon={<Icon name="Notes" color={Theme.colorPalette.yellow} />}
          label="Notes"
          iconPosition="start"
        />
        <StyledTab
          icon={<Icon name="Link" color={Theme.colorPalette.blue} />}
          label="Link"
          iconPosition="start"
        />
        <StyledTab
          icon={
            <Icon
              name={IconNames.MapPin}
              color={Theme.colorPalette.secondary}
            />
          }
          label="Location"
          iconPosition="start"
        />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <TextField
          fullWidth
          multiline
          minRows={5}
          label="Notes"
          formField={'data.notes'}
          value={form.values.data.notes}
          onChange={form.handleFieldChange('data.notes')}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <TextField
          label="Link"
          fullWidth
          formField={'data.link'}
          value={form.values.data.link}
          onChange={form.handleFieldChange('data.link')}
        />
        <ErrorMessage
          name="data.link"
          errors={form.errors}
          touched={form.touched}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <AddressForm form={form} />
      </TabPanel>
    </Box>
  );
};
