// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Stack} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {ROLES_NAMES_MAP} from 'utils/Permissions';
import {SearchResult} from './SearchResult';

export type PropsType = {
  includeMe?: boolean,
  onSelectItem: (any) => void,
  resetSearch: () => void,
  results: {
    members?: Array<{
      name: string,
      role: string,
      id: string,
      user: {
        email: string,
        id: string,
      },
    }>,
    groups?: Array<{
      name: string,
      id: string,
      programs: Array<{id: string}>,
    }>,
  },
};

export const OrganizationSearchResults = ({
  includeMe = true,
  onSelectItem,
  resetSearch,
  results,
}: PropsType): Node => {
  const {meStore} = useStores();
  const {groups = [], members = []} = results;

  const filteredMembers = includeMe
    ? members
    : members.filter((member) => member.user.id !== meStore.me.id);

  const hasResults = groups.length + filteredMembers.length > 0;

  if (!hasResults) {
    return null;
  }

  const extractProgramName = (group): string => {
    if (group.programs.length === 0) {
      return '';
    }

    return meStore.myProgramsLookup[group.programs[0].id]?.name || '';
  };

  return (
    <Stack
      sx={{
        backgroundColor: Theme.colorPalette.lightest,
        borderRadius: '4px',
        boxShadow: 1,
        position: 'absolute',
        height: '200px',
        overflowY: 'auto',
        top: '60px',
        width: '100%',
        zIndex: 1000,
      }}>
      {groups.map((group) => (
        <SearchResult
          title={group.name}
          subtitle={extractProgramName(group)}
          tag="Group"
          item={group}
          onClick={(item) => {
            onSelectItem(item);
            resetSearch();
          }}
          key={group.id}
        />
      ))}
      {filteredMembers.map((member) => (
        <SearchResult
          title={member.name}
          subtitle={member.user.email}
          tag={ROLES_NAMES_MAP[member.role]}
          item={member}
          onClick={(item) => {
            onSelectItem(item);
            resetSearch();
          }}
          key={member.id}
        />
      ))}
    </Stack>
  );
};
