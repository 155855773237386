// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {Navigate} from 'react-router-dom';
import {Theme, BridgeEventSources} from '@wellstone-solutions/common';
import {Paper} from '@wellstone-solutions/web';
import {hasFlag, TMP_SHARE_ANSWER} from 'constants/FeatureFlags';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {ResourceAccordion} from './ResourceAccordion';
import {ResourceModal, RESOURCE_MODAL_VIEWS} from '../v2';
export const ResourcesRoot = (): Node => {
  const {appUIStore, meStore} = useStores();

  const [showResourceModal, setShowResourceModal] = useState(false);
  const [currentResource, setCurrentResource] = useState(null);

  useEventSource(BridgeEventSources.RESOURCES_PAGE);

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: NAVIGATION_NAMES.resources,
        url: '/resources',
        active: true,
      },
    ]);
  }, [appUIStore]);

  const handleShareResource = (resource) => {
    setCurrentResource(resource);
    setShowResourceModal(true);
  };

  // Remove TMP_SHARE_ANSWER feature flag, see PAT-4212
  if (!hasFlag(TMP_SHARE_ANSWER, meStore.features)) {
    return <Navigate to="/" />;
  }

  return (
    <Paper sx={styles.wrapper}>
      <ResourceAccordion shareResource={handleShareResource} isModal={false} />

      <ResourceModal
        open={showResourceModal}
        onClose={() => setShowResourceModal(false)}
        resource={currentResource}
        defaultView={RESOURCE_MODAL_VIEWS.share}
      />
    </Paper>
  );
};

const styles = {
  wrapper: {
    backgroundColor: Theme.colorPalette.lightest,
    borderRadius: Theme.spacing.mini,
    padding: Theme.spacing.micro,
    marginBottom: 1,
    position: 'relative',
  },
};
