import {generateId} from './TutorialStore';

class ProgramStore {
  routes = [
    {
      method: 'POST',
      route: /orgs\/[A-Z0-9-]*\/admissions$/i,
      action: this.createProgramAdmission.bind(this),
    },
    {
      method: 'POST',
      route: /orgs\/[A-Z0-9-]*\/admissions\/[A-Z0-9-]*/i,
      action: this.dischargeProgramAdmission.bind(this),
    },
  ];

  programs = [
    {
      name: 'IOP',
      type: {name: 'IOP', id: generateId()},
      organization: {name: 'Wellstone Retreat', id: generateId()},
      id: 'iop',
    },
    {
      name: 'Aftercare',
      type: {name: 'Continuing Care', id: generateId()},
      id: generateId(),
    },
  ];

  constructor(tutorialStore) {
    this.tutorialStore = tutorialStore;
  }

  createProgramAdmission({route, params}) {
    const {start_date, end_date, member_id, program_id} = params;
    const id = generateId();

    const admission = {
      id,
      start_date,
      end_date,
      program: this.programs.find((p) => p.id === program_id),
    };

    const member = this.tutorialStore.stores.memberStore.members.find(
      (m) => m.id === member_id,
    );

    member.admissions.push(admission);
    return {
      data: {id},
      status: 'ok',
    };
  }

  dischargeProgramAdmission({route, params}) {
    const {members} = this.tutorialStore.stores.memberStore;
    const {discharge_date, discharge_status, id} = params;
    let memberIndex = -1;
    let admissionIndex = -1;

    members.some((m, i) => {
      const aIndex = m.admissions?.findIndex((a) => a.id === id);
      if (aIndex !== -1) {
        memberIndex = i;
        admissionIndex = aIndex;
        return true;
      } else {
        return false;
      }
    });

    if (memberIndex !== -1 && admissionIndex !== -1) {
      const admission = {
        ...members[memberIndex].admissions[admissionIndex],
        discharge_date: discharge_date,
        discharge_status: discharge_status,
      };

      const member = members[memberIndex];
      member.admissions[admissionIndex] = admission;
      members[memberIndex].group_admissions.forEach((ga) => {
        ga.discharge_date = discharge_date;
      });
      members[memberIndex].has_community_access = false;
    }

    return {
      status: 'ok',
    };
  }
}

export default ProgramStore;
