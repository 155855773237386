// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {Box, Icon, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {ChipBlank} from './ChipBlank';
import {getStoryIcon} from '../../utils';

const IMAGE_HEIGHT_PLACEHOLDER = 160;

type PropsType = {
  mediaImage?: Blob | null,
  mimeType?: string,
  posterImageUrl?: string,
  title?: string,
  host?: string,
  organization?: string,
  preview?: boolean,
};

export const Header = ({
  mediaImage,
  mimeType,
  posterImageUrl = '',
  title = '',
  host = '',
  organization = '',
  preview = false,
}: PropsType): Node => {
  const {meStore} = useStores();
  const [thumbnail, setThumbnail] = useState(posterImageUrl);
  const [imageHeight, setImageHeight] = useState(IMAGE_HEIGHT_PLACEHOLDER);
  const hasTitle = title?.length > 0;
  const hasHost = host?.length > 0;
  // Sanity check, although this should always be true
  const hasOrganization =
    organization?.length > 0 &&
    meStore.activeMembership.organization.id === organization;

  const getImageHeight = (src: string) => {
    const image = new Image();
    image.src = src;
    image.onload = function () {
      setImageHeight(image.height * (300 / image.width));
    };
  };

  const reader = new FileReader();
  reader.onloadend = (e) => {
    const binaryData = reader.result;
    // $FlowIgnoreMe
    getImageHeight(reader.result);
    setThumbnail(binaryData);
  };

  if (mediaImage) {
    reader.readAsDataURL(mediaImage);
  } else if (posterImageUrl) {
    getImageHeight(posterImageUrl);
  }

  return (
    <>
      <Box
        style={
          preview
            ? {
                ...styles.imageContainer,
                height: imageHeight,
                ...styles.preview,
              }
            : styles.imageContainer
        }>
        {!!thumbnail && (
          <img
            style={
              preview
                ? {height: imageHeight, ...styles.preview}
                : {...styles.image, ...styles.imageCover}
            }
            src={thumbnail || posterImageUrl}
          />
        )}
        {preview && (
          <Box sx={styles.iconContainer}>
            <Icon
              name={getStoryIcon(mimeType)}
              size={14}
              color={Theme.colorPalette.offWhite}
            />
          </Box>
        )}
      </Box>

      <Box sx={styles.titleContainer}>
        {hasTitle ? (
          <Typography sx={styles.titleText}>{title}</Typography>
        ) : (
          <ChipBlank size={'medium'} sx={styles.titleContainer} />
        )}
      </Box>
      {/* host/author */}
      {hasHost && (
        <Box px={2}>
          <Typography style={styles.host}>{`By ${host}`}</Typography>
        </Box>
      )}

      {/* org name */}
      {hasOrganization && (
        <Box px={2}>
          <Typography variant={'body2'} style={styles.organization}>
            {meStore.activeMembership.organization.name}
          </Typography>
        </Box>
      )}
    </>
  );
};

const styles = {
  imageContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: IMAGE_HEIGHT_PLACEHOLDER,
    backgroundColor: Theme.colorPalette.medium,
    position: 'relative',
  },
  image: {
    width: 320,
    height: IMAGE_HEIGHT_PLACEHOLDER,
  },
  imageCover: {
    objectFit: 'cover',
  },
  iconContainer: {
    border: '2px solid white',
    borderRadius: 25,
    padding: 0.75,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Theme.colorPalette.darkest,
    position: 'absolute',
    left: 12,
    bottom: 8,
  },
  icon: {
    position: 'absolute',
  },
  preview: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  titleContainer: {
    display: 'block',
    width: '90%',
    px: 2,
    marginTop: 1.0,
    minHeight: 32,
  },
  titleText: {
    color: Theme.colorPalette.primary,
    fontSize: '26px',
    fontWeight: 700,
  },
  host: {
    color: Theme.colorPalette.onSurface,
    fontSize: Theme.typography.size.s3,
  },
  organization: {
    color: Theme.colorPalette.onSurface,
    fontWeight: '400',
    fontStyle: 'italic',
    fontSize: Theme.typography.size.s1,
  },
};
