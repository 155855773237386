// @flow

import * as Sentry from '@sentry/react';
import {Api, Transforms} from '@wellstone-solutions/common';
import type {ApiResponseType} from '@wellstone-solutions/common';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {hasFlag, SENTRY_DEBUG} from '../constants/FeatureFlags';
import type {ProxyType} from './types';
import {ApiResponses} from 'constants/ApiResponses';

const checkUpgrade = (headers: {'x-client-requires-update'?: mixed, ...}) => {
  const upgradeRequired = Object.keys(headers).includes(
    'x-client-requires-update',
  );

  if (upgradeRequired) {
    showAlert(
      '<strong style="color: white">Client out of date</strong>' +
        '<div>Refresh the browser and clear your cache to update. If message persists <a href="mailto:info@pfsbc.com">contact us</a>.</div>' +
        '<button onClick="window.location.reload(true)">refresh</button>',
      ALERT_TYPES.INFO,
      {
        // $FlowIgnoreMe
        html: true,
        position: 'top',
        effect: 'slide',
        timeout: 'none',
      },
    );
  }

  return upgradeRequired;
};

export const proxy: ProxyType = {
  stores: {
    meStore: null,
    tutorialStore: null,
    appStore: null,
  },
};

const cleanResponse = (response) =>
  Transforms.omitKeys(response, ['config', 'headers']);

export const handleResponse = async (
  response: ApiResponseType<any>,
): Promise<ApiResponseType<any>> => {
  if (response.isSuccess) {
    return cleanResponse(response);
  }

  const errorResponse = processErrors(response);

  return errorResponse;
};

export const handleErrors = async (
  response: ApiResponseType<any>,
): Promise<ApiResponseType<any>> => {
  const errorResponse = processErrors(response);
  return Promise.reject(errorResponse);
};

// if receiving an error with status 500 it will come directly through
// this handler without first going through `handleResponse`
const processErrors = (response: ApiResponseType<any>): Promise<any> => {
  const {config, errors, headers, status} = response;

  // if call was canceled, ignore it
  if (Api.Instance.current().isAborted(response)) {
    return cleanResponse(response);
  }

  // handle when the API is in MAINTENANCE_MODE
  if (errors?.[0] === ApiResponses.maintenance) {
    proxy.stores.appStore?.enableMaintenanceMode();
    Api.Instance.current().abort();
    return cleanResponse(response);
  }

  // log user out if they're unauthorized, but not from login attempt
  // $FlowIgnore
  if (status === 401 && config.url !== '/auth/login') {
    Api.Instance.current().abort();
    proxy.stores.meStore?.clearData();
    return cleanResponse(response);
  }

  // checkUpgrade prompts user to reload
  if (headers && checkUpgrade(headers)) {
    // TODO: move to appStore
    Api.Instance.current().abort();
    return cleanResponse(response);
  }

  // do not log errors from certain urls
  if (skipLog(config?.url, status)) {
    return cleanResponse(response);
  }

  // Log the error if it hasn't been handled by the above scenarios
  const contrivedStatus = status ? status : 999;

  if (status) {
    if (status >= 500) {
      showGenericError();
    } else {
      showSpecificError(response);
    }

    const error = new Error(
      `Api Call Failed: Status: ${contrivedStatus} ${config?.method || ''} ${
        config?.url || ''
      }`,
    );

    Sentry.withScope((scope) => {
      scope.setFingerprint(`${config?.method || ''}:${config?.url || ''}`);
      if (
        proxy.stores.meStore &&
        hasFlag(SENTRY_DEBUG, proxy.stores.meStore.features)
      ) {
        scope.setExtra('payload', config);
      }
      Sentry.captureException(error);
    });
  }

  return cleanResponse(response);
};

const skipLog = (route = '', status) => {
  const skips = [
    {
      match: /\/orgs\/.*.\/exchange_user_id/,
      statuses: [404, 403],
    },
    {
      match: /\/auth\/validate-code/,
      statuses: [404],
    },
    {
      match: /\/auth\/login/,
      statuses: [401],
    },
    {
      match: /\/auth\/reset-password/,
      statuses: [400],
    },
    {
      match: /\/orgs\/.*.\/integrations\/ehr\/members\/find/,
      statuses: [404],
    },
  ];

  const skip = skips.some(
    ({match, statuses}) => match.test(route) && statuses.includes(status),
  );

  return skip;
};

const showGenericError = () => {
  showAlert(
    'We were unable to process your request.  Please try again.',
    ALERT_TYPES.ERROR,
  );
};

const showSpecificError = async (error: ApiResponseType<any>) => {
  try {
    if (error.messages_type === 'string_array') {
      // $FlowIgnoreMe
      const errorMsg = error.messages.join('\n');
      showAlert(`Something went wrong: ${errorMsg}`, ALERT_TYPES.ERROR);
    } else if (error.messages_type === 'object_array') {
      // $FlowIgnoreMe
      const [firstMessage] = error.messages;
      const [firstEntry] = Object.entries(firstMessage);
      let [key, message] = firstEntry;
      // $FlowIgnoreMe
      showAlert(`${key}: ${message}`, ALERT_TYPES.ERROR);
    } else {
      showGenericError();
    }
  } catch (e) {
    console.log('Api Error', e);
  }
};
