// @flow
import React from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStores} from 'hooks/useStores';
import {DATE_FORMAT_LONG} from 'modules/documentation';
import type {
  NotificationType,
  CalendarEventNotificationDataType,
} from '../../types';
import {getEventDetailUrl} from 'modules/calendar/utils';
import {unix17DigitTimeToMoment, toSemiRelativeDate} from 'utils/dateHelpers';
import {DefaultTemplate} from '../NotificationTemplates';
import {inboxStatusMap} from 'modules/calendar/constants';
import {PUBNUB_TYPES} from 'modules/calendar/constants';

const EXCLUDED_TYPES = [PUBNUB_TYPES.deleted, PUBNUB_TYPES.canceled];
type PropsType = {
  item: NotificationType<CalendarEventNotificationDataType>,
  closeMenu: () => void,
};

export const CalendarEventNotification = ({
  item,
  closeMenu,
}: PropsType): Node => {
  const navigate = useNavigate();
  const {appNotificationStore} = useStores();
  const {action, timetoken} = item;
  const {icon, statusMessage} = inboxStatusMap[action];
  const {id, originalStart, title} = item.data?.calendarEvent ?? {};

  const handleClick = (event) => {
    handleDismiss();
    if (item.data && !EXCLUDED_TYPES.includes(action)) {
      navigate(getEventDetailUrl(id, originalStart));

      closeMenu();
    }
  };

  const handleDismiss = () => {
    appNotificationStore.dismissNotification(item);
  };

  const humanTime = toSemiRelativeDate(
    unix17DigitTimeToMoment(Number(timetoken)).toISOString(),
    DATE_FORMAT_LONG,
  );

  const message = `${humanTime} - ${statusMessage}`;

  return (
    <DefaultTemplate
      title={title}
      icon={icon}
      message={message}
      handleClick={handleClick}
      handleDismiss={handleDismiss}
    />
  );
};
