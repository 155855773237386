// React
import React from 'react';

// WS
import LoginModule from '../components/auth/LoginModule';

const LoginPage = () => (
  <div style={styles.container}>
    <LoginModule />
  </div>
);

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
  },
};

export default LoginPage;
