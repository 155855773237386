// @flow
import React from 'react';
import type {Node} from 'react';
import {Alert, Typography} from '@wellstone-solutions/web';
import type {UISessionDocumentType} from '../../documentation/model/types';
import {useStores} from 'hooks/useStores';

type PropsType = {
  documentation: UISessionDocumentType,
};

export const ViewErrors = ({documentation}: PropsType): Node => {
  const {meStore} = useStores();
  const orgIntegration = meStore.ehrIntegrationOption;

  return (
    <>
      {documentation.integrationErrors?.length > 0 && (
        <Alert severity="error" sx={styles.container}>
          <Typography sx={{fontSize: 16, fontWeight: 'bold'}}>
            {`Sync to ${orgIntegration.name} failed`}
          </Typography>
          {documentation.integrationErrors?.map((error, i) => (
            <Typography variant="body1" key={`integration-error-${i}`}>
              {error}
            </Typography>
          ))}
        </Alert>
      )}
      {documentation.changeReason?.length > 0 && (
        <Alert severity="error" sx={styles.container}>
          <Typography sx={{fontSize: 16, fontWeight: 'bold'}}>
            Reverted Reason
          </Typography>
          <Typography variant="body1">{documentation.changeReason}</Typography>
        </Alert>
      )}
    </>
  );
};

const styles = {
  container: {
    mb: 2,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
};
