// @flow
import {Validation} from '@wellstone-solutions/common';

export const isSnakeCase = (input: {...}): boolean =>
  Object.keys(input).some((key) => {
    const value = input[key];
    if (Validation.isObject(value)) {
      return isSnakeCase(value);
    }

    return key.indexOf('_') >= 0;
  });
