// @flow
import React from 'react';
import type {Node} from 'react';
import {AutocompleteSearch} from '@wellstone-solutions/web';
import {Models} from '@wellstone-solutions/common';
import type {
  UILocationType,
  UILocationSuggestionType,
} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';

type PropsType = {
  value: UILocationType | null,
  onOptionSelect: (value: UILocationType | null) => void,
};

const {Location} = Models;

export const LocationSearch = ({value, onOptionSelect}: PropsType): Node => {
  const {locationStore} = useStores();

  const handleSearch = async (query) => {
    const result = await locationStore.getLocationSuggestions(query);
    return result.data;
  };

  const handleOptionSelect = (suggestion: ?UILocationSuggestionType) => {
    if (!suggestion) {
      onOptionSelect(null);
      return;
    }

    const {geometry, ...rest} = suggestion;
    const newLocation = Location.LocationFactory({
      ...rest,
      latitude: geometry.location.lat,
      longitude: geometry.location.lng,
    });

    onOptionSelect(newLocation);
  };

  return (
    <AutocompleteSearch
      autoSelect={true}
      autoHighlight={true}
      label="Zip Code, City, or State"
      // $FlowFixMe - mismatch on how optional properties are handled on location and suggestion types
      value={value}
      optionValueKey="formattedAddress"
      optionLabelKey="formattedAddress"
      onSearch={handleSearch}
      onOptionSelect={handleOptionSelect}
    />
  );
};
