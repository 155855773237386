// React
import React, {Component, Fragment} from 'react';

// Third party
import {inject, observer} from 'mobx-react';

// Bulma
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Content from 'react-bulma-components/lib/components/content';
import Heading from 'react-bulma-components/lib/components/heading';
import Level from 'react-bulma-components/lib/components/level';

// WS
import TutorialDiv from './tutorial/tutorialdiv';
import {
  PfBarChart,
  PfChartLegend,
  PfCheckinChart,
  HabitsChart,
} from 'modules/activity';
import PfLoader from './shared/PfLoader';
import {Palette} from '../palette';
import {hasFlag, PATHFINDER_DATA_ACCESS} from '../constants/FeatureFlags';
import {Icon, IconNames} from '@wellstone-solutions/web';
import ScrollTo from './tutorial/scrollto';

class MembersOverview extends Component {
  state = {
    activityDataPanelOpen: true,
    habitsPanelOpen: false,
    checkinPanelOpen: false,
  };

  constructor(props) {
    super(props);
    const {me, features} = this.props.meStore;
    this.chartRole = hasFlag(PATHFINDER_DATA_ACCESS, features)
      ? 'pathfinder'
      : me.membership.role;
  }

  componentDidMount() {}

  updateChart = (labels, type) => {
    this.setState({[type]: labels});
  };

  _togglePanel = (panel, force) => {
    const panelState = force ? force.state : !this.state[panel + 'PanelOpen'];
    this.setState({
      [panel + 'PanelOpen']: panelState,
    });
  };

  _showDataChart = (type, title) => {
    if (
      !this.props[type] ||
      !this.props[type].legend ||
      !this.props[type].series
    ) {
      return null;
    }

    return (
      <TutorialDiv id="member-overview-data-chart">
        <Box style={styles.box}>
          <Level style={styles.cardHeader}>
            <Level.Side>
              <Heading style={styles.cardHeaderText} size={4}>
                {title}
              </Heading>
            </Level.Side>
            <Level.Side>
              <Button
                onClick={() => this._togglePanel(type)}
                style={styles.expandButton}>
                <Icon
                  name={
                    this.state[type + 'PanelOpen']
                      ? IconNames.Minus
                      : IconNames.Plus
                  }
                  color="#FFF"
                />
              </Button>
            </Level.Side>
          </Level>

          {this.state[type + 'PanelOpen'] ? (
            <Content style={styles.chartContainer}>
              <PfChartLegend
                updateChart={this.updateChart}
                chartType={type}
                labels={this.props[type].legend}
              />
              <PfBarChart
                data={this.props[type].series}
                x={{key: this.props.aggregator, type: 'category'}}
                orientation="horizontal"
                selectedValues={this.state[type] || this.props[type].legend}
                size={{height: 400, width: '100%'}}
                showDetailsModal={this.props.showDetailsModal ?? true}
              />
            </Content>
          ) : null}
        </Box>
      </TutorialDiv>
    );
  };

  _hideAllPanels = (cb) => {
    this.setState(
      {
        ['activityDataPanelOpen']: false,
        ['badgeDataPanelOpen']: false,
        ['appnavigationDataPanelOpen']: false,
        ['engagementDataPanelOpen']: false,
        ['checkinPanelOpen']: false,
        ['habitsPanelOpen']: false,
      },
      cb,
    );
  };

  _showHabitsChart = () => {
    if (!this.props.habitData) {
      return <PfLoader />;
    } else if (this.props.habitData.length === 0) {
      return null;
    }

    return (
      <TutorialDiv
        id="member-overview-habits-chart"
        tutorialFunction={async () => {
          this._hideAllPanels(() => this._togglePanel('habits', {state: true}));
        }}>
        <ScrollTo stepId="habits" />
        <Box style={styles.box}>
          <Level style={styles.cardHeader}>
            <Level.Side>
              <Heading size={4} style={styles.cardHeaderText}>
                Habits
              </Heading>
            </Level.Side>
            <Level.Side>
              <Button
                onClick={() => this._togglePanel('habits')}
                style={styles.expandButton}>
                <Icon
                  name={
                    this.state.habitsPanelOpen
                      ? IconNames.Minus
                      : IconNames.Plus
                  }
                  color="#FFF"
                />
              </Button>
            </Level.Side>
          </Level>

          {this.state.habitsPanelOpen ? (
            <Content style={styles.chartContainer}>
              <HabitsChart
                aggregator={this.props.aggregator}
                habitData={this.props.habitData}
              />
            </Content>
          ) : null}
        </Box>
      </TutorialDiv>
    );
  };

  _showCheckinChart = () => {
    if (!this.props.checkinData) {
      return <PfLoader />;
    } else if (this.props.checkinData.length === 0) {
      return null;
    }

    return (
      <TutorialDiv
        id="member-overview-checkin-chart"
        tutorialFunction={() => {
          this._hideAllPanels(() =>
            this._togglePanel('checkin', {state: true}),
          );
        }}>
        <ScrollTo stepId="checkins" />
        <Box style={styles.box}>
          <Level style={styles.cardHeader}>
            <Level.Side>
              <Heading size={4} style={styles.cardHeaderText}>
                Checkins
              </Heading>
            </Level.Side>
            <Level.Side>
              <Button
                onClick={() => this._togglePanel('checkin')}
                style={styles.expandButton}>
                <Icon
                  name={
                    this.state.checkinPanelOpen
                      ? IconNames.Minus
                      : IconNames.Plus
                  }
                  color="#FFF"
                />
              </Button>
            </Level.Side>
          </Level>

          {this.state.checkinPanelOpen ? (
            <Content style={styles.chartContainer}>
              <PfCheckinChart
                aggregator={this.props.aggregator}
                checkinData={this.props.checkinData}
                showDetailsModal={this.props.showDetailsModal ?? true}
              />
            </Content>
          ) : null}
        </Box>
      </TutorialDiv>
    );
  };

  render() {
    return (
      <Fragment>
        {this._showDataChart('activityData', 'Basic Activity')}
        {this._showHabitsChart()}
        {this._showCheckinChart()}
        {this.chartRole === 'pathfinder'
          ? this._showDataChart('staffData', 'Staff Events')
          : null}
        {this.chartRole === 'pathfinder'
          ? this._showDataChart('badgeData', 'Badge Earning')
          : null}
        {this.chartRole === 'pathfinder'
          ? this._showDataChart('appnavigationData', 'Navigation')
          : null}
        {this.chartRole === 'pathfinder'
          ? this._showDataChart('engagementData', 'Engagement')
          : null}
        {/*{this._showEvalCard()}*/}
      </Fragment>
    );
  }
}

const styles = {
  box: {
    marginBottom: 20,
    padding: 0,
    overflow: 'hidden',
  },
  cardHeader: {
    backgroundColor: Palette.DARK_INFO,
    paddingTop: 10,
    paddingLeft: 20,
    paddingBottom: 10,
    paddingRight: 20,
    width: '100%',
  },
  cardHeaderText: {
    color: 'white',
  },
  chartContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  expandButton: {
    fontSize: 20,
    fontWeight: 'bold',
    background: 'transparent',
    borderWidth: 0,
    height: 50,
    paddingTop: 6,
    color: 'white',
    padding: 10,
  },
};

export default inject('meStore')(observer(MembersOverview));
