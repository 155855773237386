// @flow
import React from 'react';
import type {Node} from 'react';
import type {FormType} from '@wellstone-solutions/common';
import {SuspendedComponent} from '@wellstone-solutions/web';
import {MemberInfoStep} from './MemberInfoStep';
import {useDataLoader} from './useDataLoader';

type PropsType = {
  form: FormType<any>,
};

export const MemberInfoStepLoader = (props: PropsType): Node => {
  const {form} = props;
  const {email} = form.values;

  const dataLoaderArgs = {
    email,
    initialFetch: true,
  };

  return (
    <SuspendedComponent
      useDataLoader={useDataLoader}
      dataLoaderArgs={dataLoaderArgs}
      keyProp={String(email)}>
      <MemberInfoStep {...props} />
    </SuspendedComponent>
  );
};
