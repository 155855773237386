// @flow
import React from 'react';
import type {Node} from 'react';
import {styled} from '@mui/material/styles';
import {Theme} from '@wellstone-solutions/common';
import {Stack, Typography} from '@wellstone-solutions/web';

const LabelTypography = styled(Typography)(({theme}) => ({
  color: Theme.colorPalette.darker,
  fontWeight: 'bold',
  fontSize: Theme.spacing.average,
}));

const ValueTypography = styled(Typography)(({theme}) => ({
  color: Theme.colorPalette.dark,
  paddingLeft: theme.spacing(1),
  maxWidth: '500px',
  fontSize: Theme.spacing.average,
}));

type DetailRowPropTypes = {
  label: string,
  children: Node,
  sx?: any,
  labelColor?: string,
};

export const DocumentDetailRow = ({
  label,
  children,
  sx,
  labelColor,
}: DetailRowPropTypes): Node => (
  <Stack direction="row">
    <LabelTypography sx={{color: labelColor, ...sx}}>{label}:</LabelTypography>
    <ValueTypography sx={sx}>{children}</ValueTypography>
  </Stack>
);
