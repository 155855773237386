// @flow
import React from 'react';
import type {Node} from 'react';
import type {UIMemberBasicType} from '@wellstone-solutions/common';
import {Theme} from '@wellstone-solutions/common';
import {
  Avatar,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@wellstone-solutions/web';
import {getInitials} from 'utils/Utils';
import {ROLES_NAMES_MAP} from 'utils/Permissions';

type PropsType = {
  member: UIMemberBasicType,
  isSelected: boolean,
  onClick: () => void,
};

export const ShareListItem = ({
  member,
  isSelected,
  onClick,
}: PropsType): Node => {
  const label = `share-list-${member.id}`;
  return (
    <ListItem key={member.id} disablePadding data-testid="member-list-item">
      <ListItemButton onClick={onClick} disableRipple>
        <ListItemAvatar>
          <Avatar sx={styles.avatar}>{getInitials(member.name)}</Avatar>
        </ListItemAvatar>
        <ListItemText
          id={label}
          primary={member.name}
          secondary={ROLES_NAMES_MAP[member.role]}
        />
        <Checkbox
          checked={isSelected}
          tabIndex={-1}
          inputProps={{'aria-labelledby': label}}
        />
      </ListItemButton>
    </ListItem>
  );
};

const styles = {
  avatar: {
    color: Theme.colorPalette.onSurface,
    backgroundColor: Theme.colorPalette.secondary4,
    textTransform: 'uppercase',
  },
};
