import {observable, action, decorate} from 'mobx';
import MemberStore from './MemberStore';
import StaffStore from './StaffStore';
import GroupStore from './GroupStore';
import ChannelStore from './ChannelStore';
import PubnubStore from './PubnubStore';
import {
  counsellor_steps,
  primary_counsellor_steps,
  admin_steps,
  coreSteps,
} from './steps';
import ProgramStore from './ProgramStore';
import OrgStore from './OrgStore';

export const generateId = () => {
  return Math.floor(Math.random() * Math.pow(100, 10)).toString(32);
};

class TutorialStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.getStepById = this.getStepById.bind(this);
  }

  isActive = false;
  isComplete = true;
  currentStep = 0;
  activeComponents = [];
  highlightComponents = [];
  disabledComponents = [];
  prefillComponents = [];
  coreSteps = coreSteps;
  steps = [];
  stores = {};
  routes = {};
  router = null;

  async exitTutorial() {
    await this.syncStep(true);
    this.setIsComplete();
    this.isActive = false;
    this.isComplete = false;
  }

  setRole(role) {
    switch (role) {
      case 'super_admin':
        this.steps = admin_steps;
        break;
      case 'admin':
        this.steps = admin_steps; // primary_counsellor_steps; //
        break;
      case 'primary_counselor':
        // The meStore is the only data that does not have a tutorial comparative
        // This means that the authorizations are "real" and don't align with the
        // Tutorial data, unless augmented
        // this.rootStore.stores.meStore.addTutorialAuthorization({
        //   obj_id: 'primary-group-a',
        //   id: 'tutorial-auth',
        //   obj_type: 'tutorial-group',
        // });
        this.steps = primary_counsellor_steps;
        break;
      case 'counselor':
        // this.rootStore.stores.meStore.addTutorialAuthorization({
        //   obj_id: 'resource-group-a',
        //   id: 'tutorial-auth',
        //   obj_type: 'tutorial-group',
        // });
        this.steps = counsellor_steps;
        break;
      default:
        this.steps = coreSteps;
        break;
    }
  }

  setStep(setId) {
    let index = this.steps.indexOf(setId);
    if (!index) {
      index = 0;
    }
    this.currentStep = index;
  }

  setRouter(router) {
    this.router = router;
  }

  async nextStep() {
    if (this.steps.length - 1 === this.currentStep) {
      await this.syncStep(true);
      this.setIsComplete();
    } else {
      this.currentStep += 1;
      this.checkAndSetURL();
      this.setActiveComponents();
      this.setPrefillComponents();
      this.setHighlightComponents();
      this.setDisabledComponents();
      this.rootStore.stores.meStore.tickSearchBox();
      this.syncStep();
    }
  }

  async syncStep(override = false) {
    const step =
      this.currentStep === -1 || override === true
        ? 'complete'
        : this.steps[this.currentStep];

    await this.rootStore.stores.meStore.updateTutorialStep(step);
  }

  checkAndSetURL() {
    const {baseURL} = this.getStepById(this.steps[this.currentStep]);
    if (baseURL && window.location.pathname !== baseURL) {
      this.router.navigate(baseURL);
    }
  }

  getStepById(id) {
    return this.coreSteps.find((step) => {
      if (step.id === id) {
        return step;
      }
      return false;
    });
  }

  setPrefillComponents() {
    const {prefill = []} = this.getStepById(this.steps[this.currentStep]);
    this.prefillComponents = prefill;
  }

  setDisabledComponents() {
    const {disable = []} = this.getStepById(this.steps[this.currentStep]);
    this.disabledComponents = disable;
  }

  setActiveComponents() {
    const {proceed = []} = this.getStepById(this.steps[this.currentStep]);
    this.activeComponents = proceed;
  }

  setHighlightComponents() {
    const {highlight = []} = this.getStepById(this.steps[this.currentStep]);
    this.highlightComponents = highlight;
  }

  skipToComplete() {
    this.currentStep = this.steps.length - 1;

    const stepName = this.steps[this.currentStep];
    this.rootStore.stores.meStore.updateTutorialStep(stepName);

    this.setActiveComponents();
    this.setPrefillComponents();
    this.setHighlightComponents();
    this.setDisabledComponents();
    this.rootStore.stores.meStore.tickSearchBox();
  }

  setIsActive(isActive) {
    if (!this.steps.length) {
      return;
    }
    this.isActive = isActive;
    if (isActive) {
      this.rootStore.stores.appStore.toggle();
      this.stores = {
        orgStore: new OrgStore(this),
        programStore: new ProgramStore(this),
        groupStore: new GroupStore(this),
        channelStore: new ChannelStore(this),
        memberStore: new MemberStore(this),
        staffStore: new StaffStore(this),
        pubnubStore: new PubnubStore(this),
      };

      Object.values(this.stores).forEach((store) => {
        store._init && store._init();
      });

      this.routes = Object.keys(this.stores).flatMap(
        (id) => this.stores[id].routes,
      );

      this.isComplete = false;
      this.checkAndSetURL();
      this.setActiveComponents();
      this.setHighlightComponents();
      this.setDisabledComponents();
    } else {
      this.historyListener && this.historyListener();
      this.setIsComplete();
    }
  }

  async resetTutorial() {
    this.currentStep = 0;
    await this.syncStep();

    window.location = '/';
  }

  setIsComplete() {
    // Currently we are forcing a browser refresh to get new content.
    // The commented code below is how to remove the tutorial immediately,
    // but without refreshing content.

    // this.isComplete = true;
    // this.isActive = false;
    // this.currentStep = -1;
    // this.activeComponents = [];
    // this.highlightComponents = [];
    // this.prefillComponents = [];

    window.location = '/';
  }

  async call({method, route, params}) {
    const matchedRoute = this.routes.findIndex((r) => {
      if (typeof r.route === 'string') {
        return method === r.method && route === r.route;
      } else {
        return method === r.method && route.match(r.route);
      }
    });

    if (matchedRoute !== -1) {
      const data = JSON.stringify(
        this.routes[matchedRoute].action({route, params}),
      );

      return new Promise((resolve) => {
        resolve({ok: true, data});
      });
    } else {
      return {ok: false};
    }
  }
}

decorate(TutorialStore, {
  isActive: observable,
  isComplete: observable,
  exitTutorial: action,
  currentStep: observable,
  coreSteps: observable,
  setIsActive: action,
  setIsComplete: action,
  resetTutorial: action,
  nextStep: action,
  skipToComplete: action,
});

export default TutorialStore;
