// @flow
import Alert from 'react-s-alert';

export const ALERT_TYPES = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
};

const DEFAULT_OPTIONS = {
  position: 'bottom-left',
  effect: 'slide',
};

export const showAlert = (
  message: string,
  type?: 'success' | 'error' | 'info' = 'success',
  options?: {[string]: string},
): void => {
  Alert[type](message, {...DEFAULT_OPTIONS, ...options});
};
