// @flow
import React, {useContext} from 'react';
import type {Node} from 'react';
import {Box, Stack} from '@wellstone-solutions/web';
import {MemberDetailTabContext} from '../../contexts/MemberDetailTabContext';

import {
  SessionDocumentList,
  CreateSessionDocumentButton,
} from 'modules/documentation';

export const SessionDocTabContent = (): Node => {
  const {member} = useContext(MemberDetailTabContext);

  return (
    <Box mb={1}>
      <Stack direction="row" justifyContent="flex-end" mb={3}>
        <CreateSessionDocumentButton memberId={member.id} />
      </Stack>

      <SessionDocumentList
        title={`${member.name}'s support sessions`}
        description="This list represents all peer support documentation written by the member's support staff."
        columnNames={[
          'dateOfService',
          'sessionType',
          'typeOfService',
          'staff',
          'status',
          'actions',
        ]}
        defaultParams={{member: member.id}}
        showMemberFilter={false}
        showStaffFilter={true}
      />
    </Box>
  );
};
