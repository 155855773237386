// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {BridgeEventSources, Events} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {StaffList} from './StaffList';

export const StaffRoot = (): Node => {
  const {appUIStore, eventStore} = useStores();

  useEventSource(BridgeEventSources.STAFF_PAGE);

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: 'Staff',
        url: '/staff',
        active: true,
      },
    ]);

    eventStore.addEvent(Events.VIEWED_STAFF_LIST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUIStore]);

  return <StaffList />;
};
