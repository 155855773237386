// @flow
import {decorate, observable, runInAction} from 'mobx';
import type {IObservableMap} from 'mobx';
import moment from 'moment';
import RootStore from 'mobx/RootStore';
import type {
  UIAnalyticsDatasetQueryType,
  UIAnalyticsDatasetType,
} from '@wellstone-solutions/common';
import {Api, Models, Utils} from '@wellstone-solutions/common';
import {processRawData} from 'utils/Charts';

const {DashboardAnalytics} = Models;

export class DashboardStore {
  rootStore: RootStore;
  datasets: IObservableMap<string, UIAnalyticsDatasetType> = observable.map();
  abortController: AbortController;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  async getAnalytics({
    datasetQueries,
    forceFetch = false,
  }: {
    datasetQueries: Array<UIAnalyticsDatasetQueryType>,
    forceFetch?: boolean,
  }): Promise<Array<UIAnalyticsDatasetType> | false> {
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    const datasetIds = [];
    const missingDatasets = [];

    // Build a list of dataset ids that we already have in the store
    // and a list of datasets that we need to fetch
    datasetQueries.forEach((query) => {
      const id = query.id || this._getDatasetId(query);
      if (!forceFetch && this.datasets.has(id)) {
        datasetIds.push(id);
      } else {
        missingDatasets.push({...query, id});
      }
    });

    // Fetch the missing datasets
    if (missingDatasets.length > 0) {
      const params = DashboardAnalytics.toApi({
        // $FlowIgnore
        organization: this.rootStore.stores.meStore.organizationId,
        datasets: missingDatasets,
      });

      try {
        const response = await DashboardAnalytics.get({
          params,
          signal: this.abortController.signal,
        });

        if (response.isSuccess) {
          const uiAnalytics = DashboardAnalytics.toUI(response.data);
          runInAction(() => {
            uiAnalytics.datasets.forEach((uiDataset) => {
              this.datasets.set(uiDataset.id, uiDataset);
              datasetIds.push(uiDataset.id);
            });
          });
        }
      } catch (error) {
        if (Api.Instance.current().isAborted(error)) {
          return false;
        } else {
          return [];
        }
      }
    }

    // $FlowFixMe - Update common libs so id is not optional in UIAnalyticsDatasetType
    return datasetIds.map((id) => this._findDataset(id)).filter(Boolean);
  }

  _getDatasetId(query: UIAnalyticsDatasetQueryType): string {
    return Utils.hashObject(query);
  }

  _findDataset(id: string): ?UIAnalyticsDatasetType {
    if (this.datasets.has(id)) {
      return this.datasets.get(id);
    }
  }

  async getCheckins({
    dateRange,
    format,
  }: {
    dateRange: {
      startDate: Date,
      endDate: Date,
    },
    format: 'date' | 'day' | 'hour',
  }): Promise<Array<mixed>> {
    // $FlowIgnore
    const orgId = this.rootStore.stores.meStore.organizationId;

    let params = {
      limit: 99999,
      range_start: moment(dateRange.startDate).format('YYYY-MM-DD'),
      range_end: moment(dateRange.endDate).format('YYYY-MM-DD'),
      category: ['user_added_checkin'],
    };

    const response = await Api.Instance.current().get(`/orgs/${orgId}/events`, {
      params,
    });

    let start = dateRange.startDate;
    let end = dateRange.endDate;

    const formattedData = await processRawData(
      orgId,
      response,
      {
        startDate: start,
        endDate: end,
        key: 'selection',
      },
      format,
    );

    return formattedData.checkinData;
  }
}

decorate(DashboardStore, {
  datasets: observable,
});
