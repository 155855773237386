// @form
import React from 'react';
import type {Node} from 'Node';
import {Stack, TextField} from '@wellstone-solutions/web';
import type {FormType, UICalendarEventType} from '@wellstone-solutions/common';

type PropsType = {
  form: FormType<UICalendarEventType>,
};
export const AddressForm = ({form}: PropsType): Node => (
  <Stack direction="column">
    <TextField
      formField={'data.location.address1'}
      label="Street"
      onChange={form.handleFieldChange('data.location.address1')}
      value={form.values.data.location.address1}
    />
    <TextField
      formField={'data.location.address2'}
      label="Apt/Unit"
      onChange={form.handleFieldChange('data.location.address2')}
      sx={{mt: 2}}
      value={form.values.data.location.address2}
    />
    <TextField
      formField={'data.location.city'}
      label="City"
      onChange={form.handleFieldChange('data.location.city')}
      sx={{mt: 2}}
      value={form.values.data.location.city}
    />
    <Stack direction="row">
      <TextField
        formField={'data.location.state'}
        label="State"
        onChange={form.handleFieldChange('data.location.state')}
        sx={{flex: 1, mt: 2}}
        value={form.values.data.location.state}
      />
      <TextField
        formField={'data.location.zip'}
        label="Zip"
        onChange={form.handleFieldChange('data.location.zip')}
        sx={{flex: 1, ml: 2, mt: 2}}
        value={form.values.data.location.zip}
      />
    </Stack>
  </Stack>
);
