export const GROUP_CATEGORY_TYPES = {
  Primary: 'primary',
  Resource: 'resource',
  Residence: 'residence',
};

export const DISCHARGE_STATUSES = [
  {
    name: 'Successful',
    value: 'successful',
  },
  {
    name: 'Unsuccessful',
    value: 'unsuccessful',
  },
  {
    name: 'Unspecified',
    value: 'unspecified',
  },
];
