// @flow
import {useState} from 'react';
import type {OptimisticRowType} from '../types';

type ReturnType = {
  optimisticRows: Array<OptimisticRowType>,
  setOptimisticRows: (optimisticRows: Array<OptimisticRowType>) => void,
  addOptimisticRow: (optimisticRow: OptimisticRowType) => void,
  clearOptimisticRows: () => void,
};

export const useOptimisticRows = (): ReturnType => {
  const [optimisticRows, setOptimisticRows] = useState([]);

  const addOptimisticRow = (newOptimisticRow: OptimisticRowType): void => {
    let foundExisting = false;
    const updatedRows = optimisticRows.map((or: OptimisticRowType) => {
      if (or.id === newOptimisticRow.id) {
        foundExisting = true;
        return newOptimisticRow;
      }
      return or;
    });

    setOptimisticRows(
      foundExisting ? updatedRows : [...optimisticRows, newOptimisticRow],
    );
  };

  const clearOptimisticRows = (): void => setOptimisticRows([]);

  return {
    optimisticRows,
    setOptimisticRows,
    addOptimisticRow,
    clearOptimisticRows,
  };
};
