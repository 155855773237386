// @flow
import React from 'react';
import type {Node} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {hasFlag, TMP_PEERS_DOCUMENTATION} from 'constants/FeatureFlags';
import {useStores} from 'hooks/useStores';
import {DocumentationLoader} from './DocumentationLoader';
import {DocumentationContainer} from './DocumentationContainer';

export const DocumentationRoot = (): Node => {
  const {meStore} = useStores();
  const params = useParams();

  // Remove TMP_PEER_DOCUMENTATION feature flag, see PAT-3502
  if (!hasFlag(TMP_PEERS_DOCUMENTATION, meStore.features)) {
    return <Navigate to="/" />;
  }

  return (
    <DocumentationLoader documentId={params.id}>
      {/* $FlowFixMe */}
      <DocumentationContainer />
    </DocumentationLoader>
  );
};
