export const aggregatingList = [
  // {
  //   name: 'Month',
  //   key: 'month',
  // },
  {
    name: 'Day of Week',
    key: 'day',
  },
  {
    name: 'Date',
    key: 'date',
  },
  {
    name: 'Time of Day',
    key: 'hour',
  },
];
