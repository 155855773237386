import React from 'react';
import {bool, func, node, object, string} from 'prop-types';
import Button from 'react-bulma-components/lib/components/button';
import PfLoader from './PfLoader';

export const PfButtonSize = {
  LARGE: 'large',
  SMALL: 'small',
};

export const PfButton = ({
  children,
  color,
  isLoading = false,
  isDisabled,
  noStyles = false,
  onClick = () => {},
  style,
  size = PfButtonSize.LARGE,
  ...rest
}) => {
  const extraStyles = {
    ...(isDisabled ? styles.disabled : {}),
    ...(size === PfButtonSize.LARGE ? {} : styles.smallButton),
    ...(noStyles ? styles.noStyles : {}),
  };

  return (
    <Button
      {...rest}
      color={color}
      disabled={isDisabled}
      onClick={onClick}
      style={{...style, ...extraStyles}}>
      {isLoading ? <PfLoader isCentered={true} /> : children}
    </Button>
  );
};

const styles = {
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  noStyles: {
    backgroundColor: 'transparent',
    borderStyle: 'none',
    boxShadow: 'none',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  smallButton: {
    marginRight: '8px',
    fontSize: '11px',
    padding: '4px 8px',
    borderWidth: '2px',
  },
};

PfButton.defaultProps = {
  color: 'link',
  isDisabled: false,
  style: {},
};

PfButton.propTypes = {
  children: node.isRequired,
  color: string,
  isDisabled: bool,
  onClick: func,
  style: object,
};
