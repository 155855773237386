// @flow
import {useEffect} from 'react';

/*
  This will work out of the box with the formik `form` object
  and MUI inputs.
  It will scroll when the form is submitted.

  If you are working with a custom input, add the
  `data-error` prop to any component with an error and this
  will scroll to that.
*/
export const useScrollToError = (form: any): void => {
  const submitting = form?.isSubmitting;

  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]');
    if (!el) {
      return;
    }

    (el?.parentElement ?? el)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [submitting]);
};
