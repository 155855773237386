// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import Modal from 'react-bulma-components/lib/components/modal';
import {Box, Stack, Typography} from '@wellstone-solutions/web';
import {PfButton} from 'components/shared/PfButton';
import {Theme} from '@wellstone-solutions/common';

type PropTypes = {
  open: boolean,
  setShowModal: (open: boolean) => void,
  title: string,
  disableSave?: boolean,
  onSave: () => Promise<void>,
  children: Node,
};

export const SaveCancelModal = ({
  open,
  setShowModal,
  title,
  disableSave = false,
  onSave,
  children,
}: PropTypes): Node => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSave = async () => {
    setIsLoading(true);
    await onSave();
    setIsLoading(false);
  };

  return (
    <Modal
      show={open}
      onClose={() => {
        setShowModal(false);
        setIsLoading(false);
      }}
      closeOnBlur={true}
      closeOnEsc={true}>
      <Modal.Content style={styles.modal}>
        <header data-testid="header" style={styles.header}>
          <Typography
            variant="h6"
            sx={{
              color: Theme.colorPalette.secondary,
              strong: {
                color: Theme.colorPalette.secondary,
              },
            }}>
            <div
              data-testid="save-cancel-modal-title"
              dangerouslySetInnerHTML={{__html: title}}
            />
          </Typography>
        </header>
        <Box>{children}</Box>
        <footer style={styles.footer}>
          <Stack direction="row" justifyContent="space-between">
            <PfButton
              color="primary"
              onClick={handleSave}
              loading={isLoading}
              style={styles.actionButton}
              isDisabled={disableSave}
              data-testid="saveModalButton">
              Save
            </PfButton>
            <PfButton
              color="danger"
              onClick={() => setShowModal(false)}
              style={styles.actionButton}
              data-testid="cancelModalButton">
              Cancel
            </PfButton>
          </Stack>
        </footer>
      </Modal.Content>
    </Modal>
  );
};

const styles = {
  modal: {
    backgroundColor: Theme.colorPalette.lightest,
    padding: '30px',
    borderRadius: '10px',
    minWidth: '200px',
    width: '600px',
  },
  header: {
    padding: '0 0 16px',
  },
  footer: {
    padding: '16px 0 0px',
    borderTop: `1px solid ${Theme.colorPalette.mediumDark}`,
  },
  actionButton: {
    height: '32px',
    fontSize: '12px',
  },
};
