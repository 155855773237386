// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {observer} from 'mobx-react';
import {Modal} from '@mui/material';
import {Box, Stack, Select, Typography, Button} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

import {CreateWizard} from '../CreateWizard';
import {useStores} from 'hooks/useStores';
import {hasFlag, DISABLE_ADD_NEW_MEMBER} from 'constants/FeatureFlags';
import {ALL_MEMBERS, MEMBER_LIST_DESCRIPTION} from '../../constants';
import {AccessControl} from 'modules/rbac';

type PropsType = {
  filter: string,
  setFilter: (string) => void,
};

export const ListHeader: ComponentType<PropsType> = observer(
  ({filter, setFilter}): Node => {
    const [showModal, setShowModal] = useState(false);
    const [currentProgram, setCurrentProgram] = useState(filter);
    const {meStore, RBACStore} = useStores();

    const allMembersItem = {
      label: 'All Members',
      value: ALL_MEMBERS,
    };

    const programFilterItems = [
      ...meStore.myPrograms.map(({id, name}) => ({
        label: name,
        value: id,
      })),
      allMembersItem,
    ];

    const toggleModal = (event, reason) => {
      if (reason !== 'backdropClick') {
        setShowModal(!showModal);
      }
    };

    const onCurrentProgramChange = (newProgram) => {
      setCurrentProgram(newProgram);
      setFilter(newProgram);
    };

    return (
      <>
        <Stack direction="row" justifyContent="space-between" sx={{mb: 2}}>
          <Box>
            <Typography variant="h1" sx={styles.title}>
              Members
            </Typography>
            <Typography variant="body">{MEMBER_LIST_DESCRIPTION}</Typography>
          </Box>
          <Box>
            {RBACStore.hasAccess(AccessControl.members.addMember) &&
              !hasFlag(DISABLE_ADD_NEW_MEMBER, meStore.features) && (
                <Box>
                  <Button
                    color="primary"
                    onClick={toggleModal}
                    sx={styles.button}>
                    <span> + New Member</span>
                  </Button>
                  <Modal open={showModal} onClose={toggleModal}>
                    <div>
                      <CreateWizard onCancel={toggleModal} />
                    </div>
                  </Modal>
                </Box>
              )}
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box sx={{mr: 0}}>
            <Select
              canUnselect={false}
              value={currentProgram}
              items={programFilterItems}
              label="Program"
              data-testid="program-select"
              sx={{minWidth: 300, backgroundColor: Theme.colorPalette.lightest}}
              onChange={(ev) => {
                onCurrentProgramChange(ev.target.value);
              }}
            />
          </Box>
        </Stack>
      </>
    );
  },
);

const styles = {
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: '700',
    color: Theme.colorPalette.primary,
  },
  button: {
    padding: '6px 16px',
    width: '201px',
    borderRadius: '16px',
    color: Theme.colorPalette.onPrimary,
    backgroundColor: Theme.colorPalette.primary,
    border: '2px transparent solid',
    '&:hover': {
      color: Theme.colorPalette.primary,
      borderColor: Theme.colorPalette.primary,
    },
  },
};
