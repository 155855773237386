// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import moment from 'moment';
import type {
  ApiProgramAdmissionType,
  ApiGroupAdmissionType,
  UIGroupType,
} from '@wellstone-solutions/common';
import {Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {SLASH_DATE_FORMAT} from 'constants/dates';
import {useStores} from 'hooks/useStores';
import {CardDataList} from 'components/CardDataList';
import {buildIdLookup} from 'utils/buildIdLookup';
import {AddGroupModal} from './AddGroupModal';
import {DischargeModal} from './DischargeModal';
import {ENTITY_LEVEL, ENTITY_TYPES} from 'modules/rbac';

const formatDate = (date: string): string =>
  moment(date).format(SLASH_DATE_FORMAT);

const GroupItemDetail = ({item}: {item: any}): Node => {
  return (
    <div>
      <Typography
        variant="subtitle1"
        sx={{
          display: 'inline-block',
          mr: 1,
        }}>
        {item.group.name}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          display: 'inline-block',
          color: Theme.colorPalette.dark,
        }}>{`(${formatDate(item.admission.start_date)} - ${formatDate(
        item.admission.end_date,
      )})`}</Typography>
    </div>
  );
};

type GroupListItemType = {
  group: UIGroupType,
  admission: ApiGroupAdmissionType,
  preventDelete: boolean,
};

type PropTypes = {
  admission: ApiProgramAdmissionType,
  memberId: string,
};

export const MemberProgramAdmission = ({
  admission,
  memberId,
}: PropTypes): Node => {
  const {memberStore, groupStore, RBACStore} = useStores();
  const {program, start_date: startDate, end_date: endDate} = admission;
  const isProgramEditable =
    RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all) ||
    RBACStore.hasAccessToEntity(ENTITY_TYPES.program, program.id);
  const member = memberStore.findMember(memberId);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [
    showDischargeProgramModal,
    setShowDischargeProgramModal,
  ] = useState<boolean>(false);
  const [dischargeGroupProps, setDischargeGroupProps] = useState({
    open: false,
    item: null,
  });

  // ID map of the members admitted groups
  const memberGroupMap = buildIdLookup(memberStore.admittedGroups({memberId}));

  // list of groups from the program that the member is admitted to
  const memberProgramGroups = [];
  // list of groups from the program that I am authorized for but member is not admitted to
  const addableProgramGroups = [];

  // $FlowFixMe
  program.groups.forEach(({id: groupId}) => {
    const foundGroup = groupStore.groups.find((group) => group.id === groupId);
    if (!foundGroup) {
      return;
    }

    if (!memberGroupMap[groupId]) {
      if (
        RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all) ||
        RBACStore.hasAccessToEntity(ENTITY_TYPES.group, groupId)
      ) {
        addableProgramGroups.push(foundGroup);
      }
      return;
    }

    memberProgramGroups.push(foundGroup);
  });

  // the list of group items that go into the program card
  const cardItems: GroupListItemType[] = memberProgramGroups
    .map((group) => {
      const gAdmission = member.groupAdmissions.find(
        (ga) => ga.group.id === group.id,
      );

      if (!admission) {
        return;
      }

      return {
        group,
        admission: gAdmission,
        preventDelete: RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all)
          ? false
          : !RBACStore.hasAccessToEntity(ENTITY_TYPES.group, group.id), // is the group editable?
      };
    })
    .filter(Boolean);

  const dischargeGroup = async (dischargeDate: string): Promise<void> => {
    if (!dischargeGroupProps.item) {
      return;
    }

    const {item}: {item: GroupListItemType} = dischargeGroupProps;
    const response = await memberStore.dischargeFromGroup({
      memberId,
      groupId: item.group.id,
      admissionId: item.admission.id,
      dischargeDate,
    });

    if (!response.isSuccess) {
      showAlert('Unable to discharge member from group', ALERT_TYPES.ERROR);
    }

    setDischargeGroupProps({open: false, item: null});
  };

  const dischargeProgram = async (
    dischargeDate: string,
    status?: string,
  ): Promise<void> => {
    const response = await memberStore.dischargeFromProgram({
      memberId,
      programId: program.id,
      admissionId: admission.id,
      dischargeDate,
      dischargeStatus: status,
    });

    if (!response.isSuccess) {
      showAlert('Unable to discharge member from program', ALERT_TYPES.ERROR);
    }
  };

  // removeOverride is used to hijack the typical CardDataList remove process and instead
  // will show the Discharge modal where we can select a discharge date.
  const removeOverride = (item?: any) => {
    if (item) {
      setDischargeGroupProps({open: true, item});
    } else {
      setShowDischargeProgramModal(true);
    }
  };

  return (
    <div
      style={styles.cardContainer}
      data-testid={`program-card-${program.id}`}>
      <CardDataList
        title={program.name}
        subheader={`(${formatDate(startDate)} - ${formatDate(endDate)})`}
        ItemDetailsComponent={GroupItemDetail}
        data={cardItems}
        removeOverride={isProgramEditable ? removeOverride : undefined}
        headerProps={{
          actionOverride: isProgramEditable ? removeOverride : undefined,
        }}
        emptyListText="Member does not have groups in this program."
        footerProps={
          RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.program)
            ? {
                actionText: 'Add to Another Group',
                onActionClick: () => setShowAddModal(true),
              }
            : undefined
        }
      />
      <AddGroupModal
        open={showAddModal}
        setShowModal={setShowAddModal}
        addableGroups={addableProgramGroups}
      />
      {/* Modal to discharge from program */}
      <DischargeModal
        open={showDischargeProgramModal}
        setShowModal={setShowDischargeProgramModal}
        discharge={dischargeProgram}
        memberName={member.name}
        item={{
          name: program.name,
          startDate,
        }}
        selectStatus={true}
      />
      {/* Modal to discharge from group */}
      <DischargeModal
        memberName={member.name}
        open={dischargeGroupProps.open}
        item={{
          name: dischargeGroupProps.item?.group.name,
          startDate: dischargeGroupProps.item?.admission.start_date,
        }}
        setShowModal={(open: boolean) =>
          setDischargeGroupProps({open: false, item: null})
        }
        discharge={dischargeGroup}
      />
    </div>
  );
};

const styles = {
  cardContainer: {
    marginBottom: '30px',
  },
};
