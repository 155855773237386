// @flow

import React, {useState} from 'react';
import type {Node} from 'react';

import {
  Box,
  Button,
  Icon,
  Progress,
  ProgressTypes,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import type {FormType} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {pluralize} from 'utils/Utils';
import {buildIdLookup} from 'utils/buildIdLookup';
import {GuestListItem} from './GuestListItem';
import type {UIParticipantType} from '../../../../../types';

type PropsType = {
  form: FormType<any>,
  participants: Array<UIParticipantType>,
  isLoading: boolean,
};

export const GuestList = ({
  form,
  participants,
  isLoading = false,
}: PropsType): Node => {
  const {meStore} = useStores();
  const [open, setOpen] = useState(false);

  const handleGuestRemoval = (userId: string) => {
    form.setFieldValue(
      'invitations',
      form.values.invitations.filter((i) => i.user !== userId),
    );
  };

  const {invitations} = form.values;

  // Don't show the user their own invite
  const invitesSansMe = invitations.filter((i) => i.user !== meStore.me.id);
  const invitesLookup = buildIdLookup(invitesSansMe, 'user');

  // participants is a list of ui info corresponding to an invite
  // if a participant object is missing for an invite then the current user does not have access to that users info
  // if a participant object does not have a corresponding invite, it means that invite has been removed
  const visibleParticipants = participants
    .map((p) => (invitesLookup[p.id] ? p : null))
    .filter(Boolean);

  const hiddenCount = invitesSansMe.length - visibleParticipants.length;
  const arrowName = open ? 'NavArrowUp' : 'NavArrowDown';

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '2rem',
            justifyContent: 'center',
          }}>
          <Progress
            color="secondary"
            progressType={ProgressTypes.linear}
            sx={{width: '100%'}}
          />
        </Box>
      ) : (
        <>
          {invitesSansMe.length > 0 && (
            <>
              <Button
                sx={{height: '2rem'}}
                fullWidth={true}
                onClick={() => {
                  setOpen(!open);
                }}>
                <Stack
                  sx={{width: '100%', textTransform: 'none'}}
                  direction="row"
                  justifyContent="space-between">
                  <Typography>{`${invitesSansMe.length} ${pluralize(
                    'Guest',
                    invitesSansMe.length,
                  )} Added ${
                    hiddenCount > 0 ? '(' + hiddenCount + ' Hidden)' : ''
                  }`}</Typography>
                  <Icon name={arrowName} />
                </Stack>
              </Button>
              {open &&
                visibleParticipants.map((guest, key) => (
                  <GuestListItem
                    guest={guest}
                    key={guest.id}
                    handleGuestRemoval={handleGuestRemoval}
                  />
                ))}
            </>
          )}
        </>
      )}
    </>
  );
};
