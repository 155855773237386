// @flow
import {useEffect, useState} from 'react';
import moment from 'moment';
import type {UIAnalyticsDatasetQueryType} from '@wellstone-solutions/common';

export const useCompareQueries = ({
  dataSource,
  interval,
  columns,
  groups,
  programs,
  date,
}: {
  dataSource: string,
  interval: number,
  columns: Array<string>,
  programs?: Array<string>,
  groups?: Array<string>,
  date?: string,
}): {datasetQueries: Array<UIAnalyticsDatasetQueryType>} => {
  const [queries, setQueries] = useState([]);

  useEffect(() => {
    const startDate = date || moment().subtract(1, 'day').format('YYYY-MM-DD');

    const currentStartDate = moment(startDate)
      .subtract(interval, 'day')
      .format('YYYY-MM-DD');

    const priorStartDate = moment(startDate)
      .subtract(1, 'day')
      .subtract(interval * 2, 'day')
      .format('YYYY-MM-DD');

    const baseQuery = {
      dataSource,
      interval,
      columns,
      programs: programs || [],
      groups: groups || [],
    };

    const currentQuery = {
      ...baseQuery,
      startDate: currentStartDate,
      title: 'current',
    };

    const priorQuery = {
      ...baseQuery,
      startDate: priorStartDate,
      title: 'prior',
    };

    setQueries([currentQuery, priorQuery]);
  }, [date, dataSource, columns, interval, groups, programs]);

  return {datasetQueries: queries};
};
