// @flow
import {Transforms} from '@wellstone-solutions/common';
import type {
  APIResourceQuestionType,
  UIResourceQuestionType,
  ShareResourceQuestionType,
  HydratedResourceQuestionType,
} from '../../types';
import {Factory} from './factories';

export const toUI = (
  question: APIResourceQuestionType,
): UIResourceQuestionType => {
  const {groups, ...rest} = question;

  const uiQuestion = Factory(
    Transforms.toUIObject({
      ...rest,
      categories: groups,
    }),
  );

  return uiQuestion;
};

export const toShare = (
  question: HydratedResourceQuestionType,
): ShareResourceQuestionType => {
  const {id, title} = question;
  return {id, title};
};
