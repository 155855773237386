// @flow
import React from 'react';
import type {Node} from 'react';
import type {FormType} from '@wellstone-solutions/common';
import {
  ErrorMessage,
  FormRow,
  Stack,
  TextField,
} from '@wellstone-solutions/web';
import {WizardFooter} from '../../../../../components/Wizard/WizardFooter';

type PropsType = {
  form: FormType<any>,
  step: any,
  onNext: () => void,
  isComplete: boolean,
};

export const EmailStep = ({
  form,
  step,
  onNext,
  isComplete,
}: PropsType): Node => {
  /**
   * The default footer is disabled if org has an EHR integration, but not used
   * Show custom footer if there's an orgIntegration, but no externalRelationshipId
   */
  const showCustomFooter = step.data.orgIntegration;

  const onBack = () => {
    step.data.setRequiresEmailEntry?.(false);
  };

  return (
    <Stack direction="column">
      <FormRow>
        <TextField
          autoFocus
          formField="email"
          onChange={form.handleFieldChange('email')}
          value={form.values.email}
          label="Staff member's email address"
        />
        <ErrorMessage
          name="email"
          errors={form.errors}
          touched={form.touched}
        />
      </FormRow>
      {/* Footer and onNext handled manually due to complexity in this step*/}
      {showCustomFooter && (
        <WizardFooter
          isComplete={isComplete}
          nextText="Verify Email"
          onNext={onNext}
          handleBack={onBack}
        />
      )}
    </Stack>
  );
};
