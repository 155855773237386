// @flow
import React from 'react';
import type {Node} from 'react';
import {MultiSelect} from '@wellstone-solutions/web';
import type {FormType} from '@wellstone-solutions/common';
import type {UISessionDocumentType} from 'modules/documentation/model/types';
import {useStores} from 'hooks/useStores';
import {buildIdLookup} from 'utils/buildIdLookup';

type PropsType = {
  form: FormType<UISessionDocumentType>,
  hasError: boolean,
};

export const ServiceTypeMultiSelect = ({
  form,
  hasError = false,
}: PropsType): Node => {
  const {sessionDocumentStore} = useStores();
  const serviceTypes = sessionDocumentStore.serviceTypes;
  const serviceTypeLookup = buildIdLookup(serviceTypes);

  const handleChange = (values: Array<string>) => {
    const selectedValue = values.map((serviceTypeId) => ({
      id: serviceTypeId,
      name: serviceTypeLookup[serviceTypeId].name,
    }));

    form.setFieldTouched('typeOfService');
    form.setFieldValue('typeOfService', selectedValue);
  };

  const serviceTypeItems = serviceTypes.map(({id, name}) => ({
    value: id,
    label: name,
  }));

  const selectedTypeOfServiceIds = form.values.typeOfService.map(({id}) => id);
  return (
    <MultiSelect
      value={selectedTypeOfServiceIds}
      items={serviceTypeItems}
      label="Type of service"
      fieldName="typeOfService"
      onChange={handleChange}
      hasError={hasError}
      errors={form.errors}
      touched={form.touched}
    />
  );
};
