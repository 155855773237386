// @flow
import React from 'react';
import type {Node} from 'react';
import {OutlinedInput} from '@mui/material';
import {Select} from '@wellstone-solutions/web';
import {RECURRENCE_FREQ_MAP} from 'modules/calendar/constants';

const items = [
  {
    label: 'Day(s)',
    value: RECURRENCE_FREQ_MAP.daily,
  },
  {
    label: 'Week(s)',
    value: RECURRENCE_FREQ_MAP.weekly,
  },
  {
    label: 'Month(s)',
    value: RECURRENCE_FREQ_MAP.monthly,
  },
  {
    label: 'Year(s)',
    value: RECURRENCE_FREQ_MAP.yearly,
  },
];

type PropsType = {
  disabled?: boolean,
  onChange: (string) => void,
  value?: string,
};

export const FreqSelect = ({
  disabled = false,
  onChange,
  value,
}: PropsType): Node => {
  const augmentedValue =
    value && value.length > 0 ? value : RECURRENCE_FREQ_MAP.daily;

  return (
    <Select
      data-testid="freq-select"
      disabled={disabled}
      canUnselect={false}
      onChange={(e) => onChange(e.target.value)}
      input={<OutlinedInput notched={false} />}
      items={items}
      value={augmentedValue}
    />
  );
};
