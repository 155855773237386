// @flow
import React from 'react';
import type {Node} from 'react';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
  Icon,
  IconNames,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {pluralize} from 'utils/Utils';
import {ContactMethod} from '../ContactMethod';
import {useContactMethods} from '../../hooks';
import {SaveResourceButton} from '../SaveResourceButton';

type PropsType = {
  resource: UIResourceType,
  onShare: () => void,
};

export const ResourceCard = ({resource, onShare}: PropsType): Node => {
  const {contactMethods} = useContactMethods(resource);
  const [primaryContactMethod, ...otherContactMethods] = contactMethods;

  return (
    <Card sx={styles.card} elevation={0}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        ml={-1}>
        <Box>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onShare();
            }}
            sx={styles.headerButton}
            ml={-1}
            startIcon={
              <Icon
                name={IconNames.SendDiagonalSolid}
                size={16}
                color="primary"
                style={styles.buttonIcon}
              />
            }>
            Share
          </Button>

          <SaveResourceButton resourceId={resource.id} iconSize={16} />
        </Box>

        {resource.distance && (
          <Typography sx={styles.distance}>{`${resource.distance} ${pluralize(
            'mile',
            resource.distance,
          )}`}</Typography>
        )}
      </Stack>

      <Box>
        <Typography noWrapMultiLine lines={2} sx={styles.title}>
          {resource.title}
        </Typography>
        {resource.subtitle && (
          <Typography noWrapMultiLine lines={3} sx={styles.subtitle}>
            {resource.subtitle}
          </Typography>
        )}
      </Box>

      <Box>
        {!!resource.dateTimeInformation && (
          <Typography noWrapMultiLine lines={1} sx={styles.dateTimeText}>
            {resource.dateTimeInformation}
          </Typography>
        )}

        {!!primaryContactMethod && (
          <Box sx={styles.primaryContact}>
            <ContactMethod
              iconName={primaryContactMethod.iconName}
              text={primaryContactMethod.text}
              noWrap={true}
            />
          </Box>
        )}

        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={styles.otherContactMethods}>
          {otherContactMethods.map(({iconName}, index) => (
            <Icon
              key={index}
              name={iconName}
              size={20}
              color={Theme.colorPalette.primary3}
            />
          ))}
        </Stack>
      </Box>
    </Card>
  );
};

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    px: 3,
    py: 2,
    borderRadius: 2,
    border: `1px solid ${Theme.colorPalette.mediumLight}`,
    height: {
      lg: '290px',
      md: '270px',
      xs: 'auto',
    },
  },
  distance: {
    color: Theme.colorPalette.primary,
    fontSize: 14,
    fontWeight: 500,
  },
  title: {
    fontSize: 20,
    color: Theme.colorPalette.primary,
  },
  subtitle: {
    fontSize: 16,
  },
  dateTimeText: {
    fontSize: 12,
    color: Theme.colorPalette.dark,
  },
  primaryContact: {
    backgroundColor: Theme.colorPalette.secondary4,
    borderRadius: 3,
    px: 1.5,
    py: 1,
    marginTop: 1,
  },
  contactMethodIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 1,
  },
  contactMethodText: {
    fontSize: 14,
    color: Theme.colorPalette.onSurface,
  },
  otherContactMethods: {
    marginTop: 1,
    minHeight: '20px',
  },
  headerButton: {
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: 0.75,
      marginLeft: 0,
    },
  },
  buttonIcon: {
    strokeWidth: 2,
  },
};
