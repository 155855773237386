// @flow
import React, {useCallback, useRef, useState} from 'react';
import type {Node} from 'react';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import Level from 'react-bulma-components/lib/components/level';
import Modal from 'react-bulma-components/lib/components/modal';
import {Hooks, Models} from '@wellstone-solutions/common';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {AccessControl} from 'modules/rbac';
import {PfButton} from 'components/shared/PfButton';
import {Form} from '../Form';
import {Preview} from '../Preview';
import styles from './NewAnnouncement.module.scss';

const NEW_ORG_ANNOUNCEMENT = 'New Organization Announcement';
const NEW_GROUP_ANNOUNCEMENT = 'New Group Announcement';

const AdminsOnlyMessage = () => (
  <p className={styles.message}>
    Only administrators can send announcements to the entire organization.
    Select a group from the dropdown above to send a Group Announcement.
    <br />
    <br />
    If you would like to get a message out to the entire organization's clients,
    please get in touch with someone who has an admin account.
  </p>
);

type PropsType = {
  groupId: string,
  groupName: string,
};

const FORM_ID = 'new-form';
export const NewAnnouncement: any = observer(
  ({groupId, groupName}: PropsType): Node => {
    const [isModal, setIsModal] = useState(false);
    const contentRef = useRef();
    const {announcementStore, meStore, RBACStore} = useStores();
    const organizationId = meStore.me.membership.organization.id;

    const initialAnnouncement = Models.Announcement.Factory();
    const form = Hooks.useForm({
      initialValues: {
        content: initialAnnouncement.content,
        id: initialAnnouncement.id,
        scheduled: initialAnnouncement.scheduled,
        status: 'draft',
        title: initialAnnouncement.title,
      },
      schema: Models.Announcement.schema,
      onSubmit: async (values, {resetForm}) => {
        const result = await announcementStore.persistNewAnnouncement(
          {
            organizationId,
            groupId,
            author: getCreatedBy(),
          },
          values,
        );

        if (result.isSuccess) {
          announcementStore.syncPastAnnouncements({
            organizationId,
            groupId,
          });
          resetContent();
          resetForm();
          hideModal();
        }
      },
    });

    const isTouched = Object.keys(form.touched).length > 0;
    const hasErrors = Object.keys(form.errors).length > 0;

    const resetContent = useCallback(() => {
      // $FlowFixMe
      contentRef.current.getInstance().setMarkdown('');
    }, []);

    const hideModal = () => setIsModal(false);

    const canSendToAllMembers = RBACStore.hasAccess(
      AccessControl.announcements.sendToAllMembers,
    );

    const isOrgWide = !groupId;
    const isNotPermitted = isOrgWide && !canSendToAllMembers;

    const pageHeading = isOrgWide
      ? NEW_ORG_ANNOUNCEMENT
      : NEW_GROUP_ANNOUNCEMENT;

    const getCreatedBy = () => {
      if (groupName === 'All Members') {
        return meStore.me.membership.organization.name;
      }
      return meStore.me.name;
    };

    return (
      <>
        <Modal
          onClose={hideModal}
          show={isModal}
          closeOnBlur={true}
          closeOnEsc={true}>
          <Modal.Content className={styles.modal}>
            <Heading className={styles.heading} size={4}>
              Confirm
            </Heading>
            <div className={styles.modalPreview}>
              <Preview fields={{...form.values, author: getCreatedBy()}} />
            </div>
            <div className={styles.modalButtonContainer}>
              <PfButton
                form={FORM_ID}
                isDisabled={form.isSubmitting}
                isLoading={form.isSubmitting}
                color="primary"
                type="submit">
                Send to {groupName}
              </PfButton>
              <PfButton color="light" onClick={hideModal}>
                Cancel
              </PfButton>
            </div>
          </Modal.Content>
        </Modal>

        <Box className={styles.container}>
          <Heading className={styles.heading} size={4}>
            {pageHeading}
          </Heading>
          {isNotPermitted ? (
            <AdminsOnlyMessage />
          ) : (
            <Level className={styles.formContainer}>
              <Level.Side className={styles.form}>
                <div className={styles.formParent}>
                  {/* $FlowFixMe */}
                  <Form form={form} formId={FORM_ID} ref={contentRef} />
                </div>
                <div className={styles.buttonContainer}>
                  <PfButton
                    isDisabled={hasErrors || !isTouched}
                    color={'primary'}
                    onClick={() => setIsModal(true)}>
                    Create
                  </PfButton>
                </div>
              </Level.Side>
              <Level.Side align="left" className={styles.preview}>
                <Preview fields={{...form.values, author: getCreatedBy()}} />
              </Level.Side>
            </Level>
          )}
        </Box>
      </>
    );
  },
);
