// @flow
import React from 'react';
import type {Node} from 'react';
import type {GridColDef} from '@mui/x-data-grid';
import Tag from 'react-bulma-components/lib/components/tag';
import {Palette} from 'palette';
import type {UIGroupType} from '@wellstone-solutions/common';
import {Icon, IconNames} from '@wellstone-solutions/web';
import NavLink from 'components/tutorial/navlink';
import {NO_PROGRAM} from 'constants/groups';
import EditGroupModal from 'components/modal/group/EditModal';
import {styleTag} from 'utils/Styler';
import {useStores} from 'hooks/useStores';
import {ENTITY_TYPES, ENTITY_LEVEL} from 'modules/rbac';

type ColumnsMapType = {
  name: GridColDef<UIGroupType>,
  category: GridColDef<UIGroupType>,
  membersCount: GridColDef<UIGroupType>,
  program: GridColDef<UIGroupType>,
  actions: GridColDef<UIGroupType>,
};

const noop = () => {};

export const useGroupColumnMap = (
  refetch: () => void = noop,
): ColumnsMapType => {
  const {organizationStore, RBACStore} = useStores();

  const NameCell = ({group}): Node => {
    const isMyGroup = RBACStore.hasAccessToEntity(ENTITY_TYPES.group, group.id);
    return (
      <div style={styles.nameContainer}>
        {group.isPublic ? (
          <Icon
            color={Palette.GREY_4}
            name={IconNames.Group}
            style={styles.communityIcon}
          />
        ) : (
          <div style={styles.communityIcon} />
        )}
        {RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all) || isMyGroup ? (
          <div>
            <NavLink
              renderas="a"
              to={'/groups/' + group.id}
              className="inactive-link"
              activeClassName="active-link"
              tutorialStyles={styles.nameLink}
              style={styles.boldText}>
              {group.name}
            </NavLink>
            <span style={styles.groupInfo}>
              {group.street1} {group.street2} {group.city}
            </span>
          </div>
        ) : (
          <div>
            <div>{group.name}</div>
            <span style={styles.groupInfo}>
              {group.street1} {group.street2} {group.city}
            </span>
          </div>
        )}
      </div>
    );
  };

  const ProgramCell = ({group}): Node => {
    const groupPrograms = group.programs
      ? group.programs
          .map(({id}) => organizationStore.programsLookup[id])
          .filter(Boolean)
      : [];

    return groupPrograms.length > 0 ? (
      groupPrograms.map((program) => (
        <Tag
          title={program.name}
          key={program.id}
          style={styleTag(program.name, true)}>
          {program.name}
        </Tag>
      ))
    ) : (
      <Tag>{NO_PROGRAM}</Tag>
    );
  };

  const CategoryCell = ({group}): Node => {
    // TODO: Category is not available due to the toUI transform
    return group.category ? (
      <Tag
        style={styleTag(group.category?.name, true)}
        title={group.category?.name}>
        {group.category?.name}
      </Tag>
    ) : null;
  };

  const ActiveMembersCell = ({group}): Node => {
    const isMyGroup = RBACStore.hasAccessToEntity(ENTITY_TYPES.group, group.id);
    const canViewAnyGroup = RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all);

    return (canViewAnyGroup || isMyGroup) && group.membersCount ? (
      group.membersCount
    ) : (
      <em>No members</em>
    );
  };

  const ActionCell = ({group}): Node => {
    const isMyGroup = RBACStore.hasAccessToEntity(ENTITY_TYPES.group, group.id);

    if (
      RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all) ||
      (RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.program) && isMyGroup)
    ) {
      return <EditGroupModal group={group} onUpdate={refetch} />;
    }

    return null;
  };

  const columnMap = {
    name: {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 200,
      maxWidth: 450,
      renderCell: ({row: group}) => <NameCell group={group} />,
    },
    category: {
      field: 'category',
      headerName: 'Type',
      minWidth: 160,
      flex: 1,
      renderCell: ({row: group}) => <CategoryCell group={group} />,
    },
    membersCount: {
      field: 'membersCount',
      headerName: 'Active Members',
      minWidth: 130,
      renderCell: ({row: group}) => <ActiveMembersCell group={group} />,
    },
    program: {
      field: 'program',
      headerName: 'Program',
      flex: 1,
      minWidth: 100,
      renderCell: ({row: group}) => <ProgramCell group={group} />,
    },
    actions: {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 160,
      sortable: false,
      renderCell: ({row: group}) => <ActionCell group={group} />,
    },
  };

  return columnMap;
};

const styles = {
  boldText: {
    fontWeight: 'bold',
  },
  nameLink: {
    zIndex: 501,
    position: 'relative',
    background: 'white',
    boxShadow: '0 0 8px 12px rgba(255, 255, 255, 1)',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  communityIcon: {
    marginRight: 8,
    width: 24,
  },
  groupInfo: {
    fontSize: 13,
    color: Palette.GREY_4,
  },
};
