// @flow
import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {Hooks} from '@wellstone-solutions/common';
import {
  Box,
  Button,
  IconButton,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {PfButton} from 'components/shared/PfButton';
import {ActiveCount} from 'components/ActiveCount';
import {FilterDialog} from 'modules/datagrid';
import {FilterForm} from './FilterForm';

type PropsType = {
  initialValues?: {[string]: mixed},
  onSubmit: (values: {[string]: mixed}) => void,
  showMemberFilter?: boolean,
  showStaffFilter?: boolean,
  showProgramFilter?: boolean,
};

// Filter out null values and empty arrays
const isActiveFilter = (value: mixed): boolean =>
  value !== null && (!Array.isArray(value) || value.length > 0);

export const ListFilters = ({
  initialValues = {},
  onSubmit,
  showMemberFilter = false,
  showStaffFilter = false,
  showProgramFilter = false,
}: PropsType): Node => {
  const {useForm} = Hooks;
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const defaultValues = {
    sessionType: [],
    status: [],
    typeOfService: [],
    member: null,
    staff: null,
    program: [],
  };

  const form = useForm({
    initialValues: {
      ...defaultValues,
      ...initialValues,
    },
    schema: {},
    onSubmit: (values, {resetForm}) => {
      // Filter out null values and empty arrays
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => isActiveFilter(value)),
      );

      // Convert member and staff objects to id string values for the API
      if (filteredValues.member && filteredValues.member.id) {
        filteredValues.member = filteredValues.member.id;
      }
      if (filteredValues.staff && filteredValues.staff.id) {
        filteredValues.staff = filteredValues.staff.id;
      }

      // Call submit handler to update params
      onSubmit(filteredValues);

      // Close dialog and reset form with new state
      setFilterAnchorEl(null);
      resetForm({values: values});
    },
  });

  const clearFilters = () => {
    form.setValues(defaultValues);
  };

  // Close dialog and reset form to previous state
  const closeFilterDialog = () => {
    setFilterAnchorEl(null);
    form.resetForm();
  };

  // Update active filter count for each field that has a value
  useEffect(() => {
    setActiveFilterCount(
      Object.keys(form.values).filter((key) => isActiveFilter(form.values[key]))
        .length,
    );
  }, [form.values]);

  return (
    <>
      <Box sx={{position: 'relative'}}>
        <PfButton
          color="primary"
          outlined={true}
          onClick={(event) =>
            setFilterAnchorEl(event.currentTarget.parentNode)
          }>
          Filter
        </PfButton>
        <ActiveCount
          count={activeFilterCount}
          sx={{
            position: 'absolute',
            left: '-10px',
            top: '-2px',
          }}
          testId="active-filter-count"
        />
      </Box>

      <FilterDialog anchorEl={filterAnchorEl} onClose={closeFilterDialog}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5" sx={{fontWeight: 'bold'}}>
              Filters
            </Typography>
            <ActiveCount
              count={activeFilterCount}
              testId="active-filter-count"
            />
          </Stack>

          <IconButton
            onClick={closeFilterDialog}
            icon={IconNames.Xmark}
            iconProps={{name: IconNames.Xmark, style: {strokeWidth: 2}}}
            data-testid="close-dialog"
          />
        </Stack>

        <Box sx={{minHeight: '32px'}}>
          {activeFilterCount > 0 && (
            <Button
              size="small"
              onClick={clearFilters}
              sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                ml: '-5px',
              }}>
              Clear Filters
            </Button>
          )}
        </Box>

        <Box mt={2}>
          <FilterForm
            showMemberFilter={showMemberFilter}
            showStaffFilter={showStaffFilter}
            showProgramFilter={showProgramFilter}
            form={form}
          />
        </Box>
      </FilterDialog>
    </>
  );
};
