// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {useParams} from 'react-router-dom';
import {Models} from '@wellstone-solutions/common';
import type {UIGroupType} from '@wellstone-solutions/common';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {useStores} from 'hooks/useStores';
import {STAFF_PERMISSIONS} from 'constants/Permissions';
import {ItemSelection} from './ItemSelection';
import {SaveCancelModal} from 'components/modal/saveCancelModal';

const {Authorization} = Models;

type PropTypes = {
  open: boolean,
  setShowModal: (show: boolean) => void,
  addableGroups: UIGroupType[],
};

export const AddGroupModal = ({
  open,
  setShowModal,
  addableGroups,
}: PropTypes): Node => {
  const {staffStore} = useStores();
  const [selectedGroup, setSelectedGroup] = useState<string | void>();
  const {admin: staffId} = useParams();

  // create options list for select
  const selectItems = addableGroups.map((group) => ({
    label: group.name,
    value: group.id,
  }));

  const onSave = async () => {
    if (!selectedGroup) {
      return;
    }

    const group = addableGroups.find((g) => g.id === selectedGroup);
    if (!group) {
      showAlert('Something went wrong.  Please try again.', ALERT_TYPES.ERROR);
      return;
    }

    const authorizationInput = Authorization.fromGroupToApiInput(group, [
      STAFF_PERMISSIONS.read,
      STAFF_PERMISSIONS.write,
    ]);

    const response = await staffStore.createAuthorization({
      staffId,
      authorizationInput,
    });

    if (response.isSuccess) {
      setShowModal(false);
    } else {
      showAlert(
        'Could not add selected group to staff member',
        ALERT_TYPES.ERROR,
      );
    }
  };

  return (
    <SaveCancelModal
      open={open}
      setShowModal={setShowModal}
      title="Add a group"
      onSave={onSave}
      disableSave={Boolean(selectItems.length <= 0 || !selectedGroup)}>
      <ItemSelection
        staffId={staffId}
        setSelectedItem={setSelectedGroup}
        selectedItem={selectedGroup}
        selectOptions={selectItems}
        label="Groups"
      />
    </SaveCancelModal>
  );
};
