import moment from 'moment';
import {generateId} from './TutorialStore';

function randomTime(start, end, hour = 3) {
  const d = (+end - +start) * Math.random();
  return moment(new Date(+start + d))
    .set({hour: hour})
    .format();
}

const startDate = moment(new Date()).subtract(30, 'd');
const programStartDate = moment(new Date()).subtract(21, 'd');
const midDate = moment(new Date()).subtract(15, 'd');
const endDate = new Date();

class GroupStore {
  routes = [
    {
      method: 'GET',
      route: /orgs\/[A-Z0-9-]*\/groups$/i,
      action: this.getGroups.bind(this),
    },
    {
      method: 'GET',
      route: /orgs\/[A-Z0-9-]*\/groups\/[A-Z0-9-]*$/i,
      action: this.getGroup.bind(this),
    },
    {
      method: 'GET',
      route: /orgs\/[A-Z0-9-]*\/groups\/[A-Z0-9-]*\/members/i,
      action: this.getGroupMembers.bind(this),
    },
    {
      method: 'POST',
      route: /orgs\/[A-Z0-9-]*\/groups$/i,
      action: this.addGroup.bind(this),
    },
    {
      method: 'GET',
      route: /orgs\/[A-Z0-9-]*\/events/i,
      action: this.getEvents.bind(this),
    },
    {
      method: 'POST',
      route: /orgs\/[A-Z0-9-]*\/groups\/[A-Z0-9-]*\/admissions$/i,
      action: this.createGroupAdmission.bind(this),
    },
    {
      method: 'POST',
      route: /orgs\/[A-Z0-9-]*\/groups\/[A-Z0-9-]*\/admissions\/[A-Z0-9-]*/i,
      action: this.editGroupAdmission.bind(this),
    },
  ];

  groupCategories = [
    {
      name: 'Specialty',
      is_public: true,
      type: {name: 'Other', id: generateId()},
      id: 'specialty_id',
    },
    {
      name: 'Resource',
      is_public: false,
      type: {name: 'Resource', id: generateId()},
      id: 'resource_id',
    },
    {
      name: 'Primary',
      is_public: true,
      type: {name: 'Primary', id: generateId()},
      id: 'primary_id',
    },
  ];

  groups = [
    {
      organization_id: '2bf76431-4519-4654-98bd-2dce550d1a86',
      status: 'active',
      city: '',
      state: '',
      street2: '',
      name: 'Primary Group A',
      zip_code: '',
      street1: '',
      id: 'primary-group-a',
      members_count: 3,
      is_public: true,
      category_id: this.groupCategories[2].id,
      category: this.groupCategories[2],
    },
    {
      organization_id: '2bf76431-4519-4654-98bd-2dce550d1a86',
      status: 'active',
      city: '',
      state: '',
      street2: '',
      name: 'Resource Group A',
      zip_code: '',
      street1: '',
      id: 'resource-group-a',
      members_count: 26,
      is_public: false,
      category_id: this.groupCategories[1].id,
      category: this.groupCategories[1],
    },
  ];

  events = [
    {
      data: {
        user_id: '85201c85-a423-43b4-93df-3872122e5d68',
        user_name: 'Geoff',
      },
      created: randomTime(programStartDate, programStartDate, 9),
      category: 'user_added_friend',
      id: '3a82bd8a-34ab-43d8-bcaf-f9b8c4ccf8e1',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        user_id: '85201c85-a423-43b4-93df-3872122e5d68',
        user_name: 'Frank',
      },
      created: randomTime(programStartDate, programStartDate, 9),
      category: 'user_added_friend',
      id: '3a82bd8a-34ab-43d8-bcaf-f9b8c4ccf8e1',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        user_id: '85201c85-a423-43b4-93df-3872122e5d68',
        user_name: 'Ruby',
      },
      created: randomTime(programStartDate, programStartDate, 9),
      category: 'user_added_friend',
      id: '3a82bd8a-34ab-43d8-bcaf-f9b8c4ccf8e1',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        user_id: '71ab7202-b1a0-48da-b5b9-0b0fcfbb5f93',
        user_name: 'John',
      },
      created: randomTime(programStartDate, programStartDate, 9),
      category: 'user_added_friend',
      id: '3859beac-5d20-4f02-a002-c111edf9369c',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        user_id: '85201c85-a423-43b4-93df-3872122e5d68',
        user_name: 'Mary',
      },
      created: randomTime(programStartDate, programStartDate, 9),
      category: 'user_added_friend',
      id: '3a82bd8a-34ab-43d8-bcaf-f9b8c4ccf8e1',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        user_id: '71ab7202-b1a0-48da-b5b9-0b0fcfbb5f93',
        user_name: 'Elizabeth',
      },
      created: randomTime(programStartDate, programStartDate, 9),
      category: 'user_added_friend',
      id: '3859beac-5d20-4f02-a002-c111edf9369c',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        user_id: '85201c85-a423-43b4-93df-3872122e5d68',
        user_name: 'Dave',
      },
      created: randomTime(programStartDate, programStartDate, 9),
      category: 'user_added_friend',
      id: '3a82bd8a-34ab-43d8-bcaf-f9b8c4ccf8e1',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        user_id: '71ab7202-b1a0-48da-b5b9-0b0fcfbb5f93',
        user_name: 'Omar',
      },
      created: randomTime(programStartDate, programStartDate, 9),
      category: 'user_added_friend',
      id: '3859beac-5d20-4f02-a002-c111edf9369c',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        user_id: '85201c85-a423-43b4-93df-3872122e5d68',
        user_name: 'Maria',
      },
      created: randomTime(programStartDate, programStartDate, 9),
      category: 'user_added_friend',
      id: '3a82bd8a-34ab-43d8-bcaf-f9b8c4ccf8e1',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        user_id: '71ab7202-b1a0-48da-b5b9-0b0fcfbb5f93',
        user_name: 'José',
      },
      created: randomTime(programStartDate, programStartDate, 9),
      category: 'user_added_friend',
      id: '3859beac-5d20-4f02-a002-c111edf9369c',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['SO_SO'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate),
      category: 'user_added_checkin',
      id: '4ae3e6bf-0a61-4cc3-9878-f42bf7148441',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['SLEEPLESSNESS'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 17),
      category: 'user_added_checkin',
      id: '6d3107f7-ded4-486b-b909-2d897c14387b',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['ANXIOUS'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 16),
      category: 'user_added_checkin',
      id: 'bbb94ac7-51c6-4312-8412-dc736c9f8c48',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['BODY_ACHE'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 16),
      category: 'user_added_checkin',
      id: 'a70535ae-e65b-4df9-b545-ad5476f1a323',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['NAUSEOUS'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 18),
      category: 'user_added_checkin',
      id: '16b2a321-31db-49e8-a24e-a526ab0c50a0',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['SLEEPLESSNESS', 'ANXIOUS'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 17),
      category: 'user_added_checkin',
      id: '29681ff6-61d4-451d-a625-8e39918b3750',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['NAUSEOUS'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 18),
      category: 'user_added_checkin',
      id: '16b2a321-31db-49e8-a24e-a526ab0c50a0',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['SLEEPLESSNESS', 'ANXIOUS'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 17),
      category: 'user_added_checkin',
      id: '29681ff6-61d4-451d-a625-8e39918b3750',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['NAUSEOUS'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 18),
      category: 'user_added_checkin',
      id: '16b2a321-31db-49e8-a24e-a526ab0c50a0',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['SLEEPLESSNESS', 'ANXIOUS', 'BAD'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 17),
      category: 'user_added_checkin',
      id: '29681ff6-61d4-451d-a625-8e39918b3750',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['GOOD'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 6),
      category: 'user_added_checkin',
      id: 'd2ba4035-760b-4f4a-8b84-39f44b2b2e59',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['BAD', 'TIRED'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 1),
      category: 'user_added_checkin',
      id: 'f48b1203-a542-41c6-93e8-afd069ecac08',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['GOOD'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 4),
      category: 'user_added_checkin',
      id: 'deb5322b-72bb-4d3f-a819-3304b674c823',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['TIRED'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 2),
      category: 'user_added_checkin',
      id: '162ae0d0-a777-42bd-9dbf-e82867c2fe85',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['NAUSEOUS', 'TIRED'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 18),
      category: 'user_added_checkin',
      id: '6df5993b-8bb1-4a25-998b-b9ddeccbe5aa',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['HAPPY'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate),
      category: 'user_added_checkin',
      id: '259154c9-1736-48c4-961d-41f56817e269',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['DEPRESSED', 'BAD'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 16),
      category: 'user_added_checkin',
      id: '0fad6737-5637-422e-8bd2-adf93ec7c219',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['BAD'],
        user_name: 'Xavier',
      },
      created: randomTime(startDate, midDate, 4),
      category: 'user_added_checkin',
      id: 'cfa0ac1a-62c1-457b-a91f-83d5a9fd96ba',
      created_by_id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
      time_zone: 'UTC',
    },
    // MARY
    {
      data: {
        user_id: '85201c85-a423-43b4-93df-3872122e5d68',
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate),
      category: 'user_added_friend',
      id: '3a82bd8a-34ab-43d8-bcaf-f9b8c4ccf8e1',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['SO_SO'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate),
      category: 'user_added_checkin',
      id: '4ae3e6bf-0a61-4cc3-9878-f42bf7148441',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['TIRED'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 7),
      category: 'user_added_checkin',
      id: '6d3107f7-ded4-486b-b909-2d897c14387b',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['ANXIOUS'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 16),
      category: 'user_added_checkin',
      id: 'bbb94ac7-51c6-4312-8412-dc736c9f8c48',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['BODY_ACHE'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 1),
      category: 'user_added_checkin',
      id: 'a70535ae-e65b-4df9-b545-ad5476f1a323',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['ANGRY'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 15),
      category: 'user_added_checkin',
      id: '16b2a321-31db-49e8-a24e-a526ab0c50a0',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['HEADACHE'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 17),
      category: 'user_added_checkin',
      id: '29681ff6-61d4-451d-a625-8e39918b3750',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['NAUSEOUS'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 18),
      category: 'user_added_checkin',
      id: '16b2a321-31db-49e8-a24e-a526ab0c50a0',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['SLEEPLESSNESS', 'ANXIOUS'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 17),
      category: 'user_added_checkin',
      id: '29681ff6-61d4-451d-a625-8e39918b3750',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['TIRED'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 18),
      category: 'user_added_checkin',
      id: '16b2a321-31db-49e8-a24e-a526ab0c50a0',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['SLEEPLESSNESS'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 17),
      category: 'user_added_checkin',
      id: '29681ff6-61d4-451d-a625-8e39918b3750',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['GOOD'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 6),
      category: 'user_added_checkin',
      id: 'd2ba4035-760b-4f4a-8b84-39f44b2b2e59',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['TIRED'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 1),
      category: 'user_added_checkin',
      id: 'f48b1203-a542-41c6-93e8-afd069ecac08',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['GOOD'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 4),
      category: 'user_added_checkin',
      id: 'deb5322b-72bb-4d3f-a819-3304b674c823',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['SCARED'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 9),
      category: 'user_added_checkin',
      id: '162ae0d0-a777-42bd-9dbf-e82867c2fe85',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['HAPPY', 'PROU'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 8),
      category: 'user_added_checkin',
      id: '6df5993b-8bb1-4a25-998b-b9ddeccbe5aa',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['HAPPY'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate),
      category: 'user_added_checkin',
      id: '259154c9-1736-48c4-961d-41f56817e269',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['HEADACHE', 'BAD'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 6),
      category: 'user_added_checkin',
      id: '0fad6737-5637-422e-8bd2-adf93ec7c219',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
    {
      data: {
        states: ['GOOD'],
        user_name: 'Mary',
      },
      created: randomTime(startDate, endDate, 4),
      category: 'user_added_checkin',
      id: 'cfa0ac1a-62c1-457b-a91f-83d5a9fd96ba',
      created_by_id: 'mary_id',
      time_zone: 'UTC',
    },
  ];

  constructor(tutorialStore) {
    this.tutorialStore = tutorialStore;
  }

  getGroup({route, params}) {
    const groupId = /groups\/([A-Z0-9-]*)/i.exec(route)[1];
    return {
      data: this.groups.find((g) => g.id === groupId),
      status: 'ok',
      messages: [],
      messages_type: 'string_array',
    };
  }

  getGroups({route, params}) {
    return {
      data: {
        groups: this.groups,
        limit: 100,
      },
      status: 'ok',
      offset: 0,
      messages: [],
      messages_type: 'string_array',
    };
  }
  // SHAWN: due to the endpoint change, this is no longer being used.
  // I've adapted the call in the memberStore to handle for group members

  getGroupMembers({route, params}) {
    const group = /groups\/([A-Z0-9-]*)\//i.exec(route)[1];

    const {members} = this.tutorialStore.stores.memberStore;
    return {
      data: {
        members: members.filter((member) =>
          member.group_admissions.find((a) => a.group.id === group),
        ),
      },
      status: 'ok',
    };
  }

  addGroup({route, params}) {
    const {name, city, state, street1, street2, zip_code} = params;
    const id = 'tutorial-group-0';
    const organization_id = /orgs\/([A-Z0-9-]*)\//i.exec(route)[1];

    this.groups.unshift({
      organization_id,
      name,
      city,
      state,
      street1,
      street2,
      zip_code,
      id,
      status: 'active',
      members_count: 0,
      category_id: this.groupCategories[0].id,
      category: this.groupCategories[0],
    });

    return {
      data: {
        id,
      },
      status: 'ok',
    };
  }

  getEvents({route, params}) {
    const {member_id = null} = params;
    const events = member_id
      ? this.events.filter((event) => event.created_by_id === member_id)
      : this.events;
    return {
      data: {
        events,
        limit: 99999,
        offset: 0,
      },
      status: 'ok',
      messages: [],
      messages_type: 'string_array',
    };
  }

  createGroupAdmission({route, params}) {
    const groupId = /orgs\/[A-Z0-9-]*\/groups\/([A-Z0-9-]*)/i.exec(route)[1];
    const group = this.groups.find((g) => g.id === groupId);

    const {members} = this.tutorialStore.stores.memberStore;
    const member = members.find((m) => m.id === params.member_id);
    member.group_admissions.push({
      start_date: params.start_date,
      end_date: params.end_date,
      id: generateId(),
      group,
    });

    return {
      status: 'ok',
      messages: [],
      messages_type: 'string_array',
    };
  }

  editGroupAdmission({route, params}) {
    const admissionId = /orgs\/[A-Z0-9-]*\/groups\/[A-Z0-9-]*\/admissions\/([A-Z0-9-]*)/i.exec(
      route,
    )[1];

    const {discharge_date} = params;
    const {members} = this.tutorialStore.stores.memberStore;

    let memberIndex = -1;
    let admissionIndex = -1;

    members.some((m, i) => {
      const aIndex = m.group_admissions?.findIndex((a) => a.id === admissionId);
      if (aIndex !== -1) {
        memberIndex = i;
        admissionIndex = aIndex;
        return true;
      } else {
        return false;
      }
    });

    members[memberIndex].group_admissions[
      admissionIndex
    ].discharge_date = discharge_date;

    return {
      status: 'ok',
    };
  }
}

export default GroupStore;
