// @flow
import React from 'react';
import type {Node} from 'react';
import {Events} from '@wellstone-solutions/common';
import {DataGrid} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {useColumnMap} from './useColumnMap';

type PropsType = {
  rows: Array<Object>,
  readOnlyGroups: Array<string>,
  selectedGroups: Array<string>,
  onSelectionChange: (groupIds: Array<string>) => void,
};

const EVENT_TABLE_NAME = 'SelectableGroupList';

export const SelectableGroupList = ({
  rows,
  readOnlyGroups,
  selectedGroups,
  onSelectionChange,
}: PropsType): Node => {
  const {eventStore} = useStores();

  const columnMap = useColumnMap();
  const columns = [columnMap.name, columnMap.type, columnMap.activeMembers];

  return (
    <DataGrid
      autoHeight
      checkboxSelection
      pageSize={5}
      rowsPerPageOptions={[5]}
      rows={rows}
      isRowSelectable={(row) => !readOnlyGroups.includes(row.id)}
      columns={columns}
      noRowsText="No groups"
      selectionModel={selectedGroups}
      onSelectionModelChange={(newSelection) => {
        onSelectionChange(newSelection);
      }}
      onSortModelChange={(sortedColumns) => {
        let updatedSortField = '';
        let updatedSortOrder = '';

        if (sortedColumns?.length > 0) {
          const [sortedColumn] = sortedColumns;
          updatedSortField = sortedColumn.field;
          updatedSortOrder = sortedColumn.sort;

          eventStore.addEvent(Events.USER_SORTED_TABLE, {
            sort_field: updatedSortField,
            sort_order: updatedSortOrder,
            table: EVENT_TABLE_NAME,
          });
        }
      }}
    />
  );
};
