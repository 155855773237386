// @flow
import React from 'react';
import type {Node} from 'react';
import {ListItem, Skeleton, Stack} from '@wellstone-solutions/web';

export const SkeletonListItem = (): Node => {
  return (
    <ListItem sx={{px: 2, py: 2}} data-testid="skeleton-list-item">
      <Skeleton
        variant="circular"
        width={40}
        height={40}
        sx={{marginRight: 2}}
      />
      <Stack>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={75} height={20} />
      </Stack>
    </ListItem>
  );
};
