// @flow
import React from 'react';
import type {Node} from 'react';
import {SuspendedComponent} from '@wellstone-solutions/web';
import {Permissions} from './Permissions';
import {useDataLoader} from '../../dataloaders/staffPermissionsDataLoader';

export const PermissionsLoader = ({staffId}: {staffId: string}): Node => {
  // `key` attribute forces remount of suspended component on prop change
  // this fixes a bug where the child component rerenders before the dataloader has started fetching the new staff member
  return (
    <SuspendedComponent useDataLoader={useDataLoader} keyProp={String(staffId)}>
      <Permissions />
    </SuspendedComponent>
  );
};
