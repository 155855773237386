// @flow
import React from 'react';
import type {Node} from 'react';
import {ErrorPage} from 'components/ErrorPage';

const MESSAGE = "Sorry, we couldn't find what you were looking for.";

export const NotFound = (): Node => (
  <ErrorPage status={404} message={MESSAGE} />
);
