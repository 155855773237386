// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {BridgeEventSources, Events} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {MemberList} from './MemberList';
import {MemberListV2} from './MemberListV2';
import {TMP_MEMBER_LANDING, hasFlag} from 'constants/FeatureFlags';

export const MemberRoot = (): Node => {
  const {appUIStore, meStore, eventStore} = useStores();

  useEventSource(BridgeEventSources.MEMBERS_PAGE);

  useEffect(() => {
    const crumbs = hasFlag(TMP_MEMBER_LANDING, meStore.features)
      ? []
      : [
          {
            name: 'Members',
            url: '/members',
            active: true,
          },
        ];
    appUIStore.setBreadcrumbs(crumbs);

    eventStore.addEvent(Events.VIEWED_MEMBER_LIST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUIStore]);

  return hasFlag(TMP_MEMBER_LANDING, meStore.features) ? (
    <MemberListV2 />
  ) : (
    <MemberList />
  );
};
