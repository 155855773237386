// @flow
import {useState, useEffect} from 'react';
import type {UIMemberBasicType} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';

type FetchDataProps = {
  searchTerm: string,
  pageNumber: number,
  appendResults?: boolean,
};

export const useShareListDataloader = (): ({
  isLoading: boolean,
  data: {
    members: Array<UIMemberBasicType>,
    hasNextPage: boolean,
  },
  fetchData: (FetchDataProps) => Promise<void>,
}) => {
  const {organizationStore} = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);

  const fetchData = async ({
    searchTerm,
    pageNumber,
    appendResults = false,
  }: FetchDataProps): Promise<void> => {
    // When isLoading is true the entire list is replaced with loaders
    // Keep false when appending results so the current list stays visible
    if (!appendResults) {
      setIsLoading(true);
    }

    const response = await organizationStore.searchMembers({
      search: searchTerm,
      pageNumber: pageNumber,
    });

    if (response.isSuccess) {
      const newMembers = appendResults
        ? [...members, ...response.data]
        : response.data;

      setMembers(newMembers);
      setHasNextPage(newMembers.length < response.totalCount);
    } else {
      setMembers([]);
      setHasNextPage(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData({searchTerm: '', pageNumber: 1});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    data: {
      members,
      hasNextPage,
    },
    fetchData,
  };
};
