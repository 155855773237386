export const icons = {
  DEFAULT: '/images/habits/default.png',
  ASK_FOR_HELP: '/images/habits/ask_for_help.png',
  ATTEND_AN_ONLINE_CLASS: '/images/habits/online_class.png',
  BREATHING_EXERCISES: '/images/habits/breathing_exercises.png',
  BRUSH_AND_FLOSS: '/images/habits/brush_and_floss.png',
  CALL_SOMEONE: '/images/habits/call.png',
  CARRY_YOUR_MEDICATION_LIST: '/images/habits/medication_list.png',
  CHECK_JOB_LISTINGS: '/images/habits/check_classifieds.png',
  CLEAR_CLUTTER_FROM_LIVING_SPACE:
    '/images/habits/clear_clutter_living_space.png',
  DO_THE_LAUNDRY: '/images/habits/do_the_laundry.png',
  DRAW_OR_PAINT: '/images/habits/draw_or_paint.png',
  EAT_1_VEGETARIAN_MEAL: '/images/habits/eat_1_vegetarian_meal.png',
  EAT_A_HEALTHY_BREAKFAST: '/images/habits/eat_a_healthy_breakfast.png',
  EAT_A_HEALTHY_DINNER: '/images/habits/eat_a_healthy_dinner.png',
  EAT_A_HEALTHY_SNACK: '/images/habits/eat_a_healthy_snack.png',
  GO_TO_BED_AT_A_DECENT_HOUR: '/images/habits/go_to_bed_decent_hour.png',
  HAVE_A_SOBER_LIVING_SPACE: '/images/habits/have_a_sober_living_space.png',
  HELP_A_FRIEND_OR_STRANGER: '/images/habits/help_a_friend.png',
  LEARN_SOMETHING_NEW: '/images/habits/learn_something_new.png',
  LISTEN_TO_YOUR_FAVORITE_MUSIC: '/images/habits/listen_favorite_music.png',
  MEDITATE: '/images/habits/meditate.png',
  MEET_UP_WITH_A_FRIEND: '/images/habits/meet_up_with.png',
  ORGANIZE_YOUR_WORKSPACE: '/images/habits/organize_workspace.png',
  'PATHFINDER_CHECK-IN': '/images/habits/pf_check_in.png',
  PLAY_A_CROSSWORD_PUZZLE: '/images/habits/crossword.png',
  PLAY_A_SPORTING_ACTIVITY: '/images/habits/play_a_sport_activity.png',
  READ_A_BOOK: '/images/habits/read_a_book.png',
  READ_LOCAL_NEWS: '/images/habits/read_local_news.png',
  RECYCLE_WHAT_YOU_CAN: '/images/habits/recycle.png',
  REFLECT_ON_YOUR_GRATITUDE: '/images/habits/gratitude.png',
  SET_A_BUDGET_FOR_THE_DAY: '/images/habits/budget_today.png',
  SUBMIT_A_JOB_APPLICATION: '/images/habits/submit_application.png',
  SWEEP_OR_MOP: '/images/habits/sweep_or_mop.png',
  TAKE_A_HIKE: '/images/habits/take_a_hike.png',
  TAKE_A_NAP: '/images/habits/take_a_nap.png',
  TAKE_A_PAUSE: '/images/habits/take_a_pause.png',
  TAKE_A_SHOWER: '/images/habits/take_a_shower.png',
  TAKE_A_WALK_OR_RUN: '/images/habits/take_a_walk_or_run.png',
  TAKE_A_WORK_BREAK: '/images/habits/work_break.png',
  TAKE_YOUR_MEDICATIONS: '/images/habits/medications.png',
  TEXT_OR_EMAIL_A_FRIEND_OR_PEER: '/images/habits/text_or_email.png',
  TOBACCO_FREE_DAY: '/images/habits/tobacco_free.png',
  VIDEO_WITH_A_FRIEND_OR_PEER: '/images/habits/video_chat_with.png',
  WAKE_UP_EARLY: '/images/habits/wake_up_early.png',
  WASH_THE_DISHES: '/images/habits/wash_the_dishes.png',
  WATCH_TV: '/images/habits/watch_tv.png',
  WEAR_YOUR_MEDICAL_ART_JEWELRY: '/images/habits/medical_jewelry.png',
  WRITE_DOWN_TOP_3_TASKS_TODAY: '/images/habits/top_three.png',
  WRITE_IN_A_JOURNAL: '/images/habits/journal.png',
};

export const categoryIcons = {
  EMOTIONAL: '/images/habits/categories/emotional.png',
  ENVIRONMENTAL: '/images/habits/categories/environmental.png',
  FINANCIAL: '/images/habits/categories/financial.png',
  INTELLECTUAL: '/images/habits/categories/intellectual.png',
  OCCUPATIONAL: '/images/habits/categories/occupational.png',
  PHYSICAL: '/images/habits/categories/physical.png',
  SOCIAL: '/images/habits/categories/social.png',
  SPIRITUAL: '/images/habits/categories/spiritual.png',
};
