// @flow
import {Api} from '@wellstone-solutions/common';
import {routes} from 'api';
import type {APIAnswerType, getAllParamsType} from '../../types';

export const getAll = async (
  params: getAllParamsType,
): Promise<APIAnswerType[]> => {
  const response = await Api.Instance.current().get(
    `${routes.resources.answers}`,
    params,
  );
  return response.isSuccess ? response.data.answers : [];
};
