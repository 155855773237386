// @flow
import React, {Component} from 'react';
import type {Node} from 'react';
import * as Sentry from '@sentry/react';
import {ErrorPage} from '../ErrorPage';

interface IError {
  message: string;
}

type PropsType = {
  children: Node,
};

type StateType = {
  hasError: boolean,
};

const IGNORE_ERRORS = [
  'MobX Provider: The set of provided stores has changed.',
];

export class ErrorBoundary extends Component<PropsType, StateType> {
  state: StateType = {
    hasError: false,
  };

  constructor(props: PropsType) {
    super(props);
  }

  static getDerivedStateFromError(error: IError): {hasError: boolean, ...} {
    const hasError = !IGNORE_ERRORS.find((errorMessage) =>
      error.message.startsWith(errorMessage),
    );

    return {hasError};
  }

  componentDidCatch(error: IError, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setExtra('errorInfo', errorInfo);
      Sentry.captureException(error);
    });
  }

  render(): Node {
    const {hasError} = this.state;
    const {children} = this.props;

    return hasError ? <ErrorPage /> : children;
  }
}
