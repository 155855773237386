// @flow
import React from 'react';
import type {Node} from 'react';
import {Link} from 'react-router-dom';
import {Icon, IconNames} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  memberId: string,
  memberName: string,
};

export const BackButton = ({memberId, memberName}: PropsType): Node => (
  <Link to={`/members/${memberId}`} style={styles.goBackLink}>
    <Icon name={IconNames.ArrowLeft} color="primary" size={18} />
    {`Go to ${memberName.split(' ')[0]}'s profile`}
  </Link>
);

const styles = {
  goBackLink: {
    display: 'flex',
    alignItems: 'center',
    marginTop: Theme.spacing.huge,
  },
};
