// @flow
import React, {useRef, useEffect} from 'react';
import type {Node} from 'react';
import type {UIResourceAnswerType} from '@wellstone-solutions/common';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {Theme} from '@wellstone-solutions/common';
import {
  Chip,
  Divider,
  Paper,
  MarkdownViewer,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {clamp} from 'lib/clamp';

type PropsType = {
  resource: UIResourceAnswerType | UIResourceType,
  owner: 'me' | 'other',
};

const MAX_LINES_DESCRIPTION = 2;

export const ResourceMessage = ({resource, owner}: PropsType): Node => {
  const descriptionRef = useRef();

  useEffect(() => {
    if (descriptionRef.current) {
      clamp(descriptionRef.current, {
        clamp: MAX_LINES_DESCRIPTION,
      });
    }
  }, [descriptionRef]);

  return (
    <Paper
      sx={{...styles.wrapper, ...styles[owner]}}
      elevation={2}
      data-testid="resource-message">
      <Stack>
        <Chip
          label="Resource"
          variant="solid"
          color="info"
          sx={{alignSelf: 'flex-end', mb: 1}}
        />
        <Stack>
          <Typography variant="h6" sx={{color: Theme.colorPalette.primary}}>
            {resource.title}
          </Typography>
          {!!resource.subtitle && (
            <Typography
              variant="subtitle1"
              sx={{color: Theme.colorPalette.primary2}}
              data-testid="subtitle">
              {resource.subtitle}
            </Typography>
          )}
          <Divider sx={{my: 1, height: '1px'}} />
          <div ref={descriptionRef}>
            <MarkdownViewer value={resource.description} />
          </div>
        </Stack>
      </Stack>
    </Paper>
  );
};

const styles = {
  wrapper: {
    p: 2,
    maxWidth: '500px',
    textAlign: 'left',
    borderRadius: '20px',
  },
  me: {
    float: 'right',
    marginLeft: 1,
  },
  other: {
    float: 'left',
    marginRight: 1,
  },
};
