import {observable, action, decorate} from 'mobx';
import {Storage} from '@wellstone-solutions/web';
import moment from 'moment';
import {sortBy} from '../utils/Utils';

const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY;

const asyncPrefix = 'message_';
class MessageStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    // this.loadAllFromAsync();
    // TODO: when ready, put this in to get rid of any messages being stored in async
    // this.clear();
  }

  loadAllFromAsync() {
    try {
      const messages = Storage.getItems(asyncPrefix, CRYPTO_KEY);
      this.messages = sortBy(messages, 'timetoken');
    } catch {
      // Could not decrypt, just clear out
      this.clear();
    }
  }

  messages = [];

  total() {
    return this.messages.length;
  }

  removeAllMessagesForChannel(channel) {
    this.messages = this.messages.filter((msg) => {
      if (msg.message.channel === channel) {
        Storage.removeItem(asyncPrefix + msg.timetoken);
        return false;
      }
      return true;
    });
  }

  remove = (index) => {
    if (index || index === 0) {
      let msg = this.messages[index];
      this.messages.splice(index, 1);
      Storage.removeItem(asyncPrefix + msg.timetoken);
    } else {
      this.messages.forEach((message, m) => {
        Storage.removeItem(asyncPrefix + message.timetoken);
      });
      this.messages = [];
    }
  };

  set = async (newMsg, persist) => {
    newMsg = this.buildPreview(newMsg);
    const found = this.messages.find(
      (message) => message.timetoken === newMsg.timetoken,
    );
    if (!found) {
      this.messages.push(newMsg);
      if (persist) {
        Storage.setItem(asyncPrefix + newMsg.timetoken, newMsg, CRYPTO_KEY);
      }
    }
  };

  buildPreview = (msg) => {
    let isResource = false;
    msg.user = msg.message.data.author;
    const tt = Math.floor(parseInt(msg.timetoken, 10) / 10000);
    msg.dateTime = moment(tt).format('MMM Do, hA');
    msg.name = msg.message.data.name;
    msg.copy = '';
    try {
      isResource = JSON.parse(msg.message.data.content);
      if (typeof isResource === 'number') {
        isResource = false;
      }
    } catch {
      isResource = false;
    }

    if (isResource) {
      msg.copy += isResource.title
        ? '(Resource) ' + isResource.title
        : '(Meeting) ' +
          isResource.day +
          ' ' +
          moment(isResource.time).format('hA');
      msg.color = 'info';
      return msg;
    } else {
      msg.copy += '"' + msg.message.data.content + '"';
      msg.color = 'primary';
      return msg;
    }
  };

  clear() {
    this.messages = [];
    Storage.removeItems(asyncPrefix);
  }
}

decorate(MessageStore, {
  messages: observable,
  set: action,
  remove: action,
  total: observable,
});

export default MessageStore;
