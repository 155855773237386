// @flow
import moment from 'moment';

export const getDateRange = (
  numberBeforeToday: number,
  numberAfterToday: number,
  period: string = 'd',
): {start: string, end: string} => {
  const start = moment().subtract(numberBeforeToday, period).toISOString();
  const end = moment().add(numberAfterToday, period).toISOString();

  return {
    start,
    end,
  };
};

export const concatDateTime = (date: string, time: string): string => {
  const [hour, minute] = time.split(':');

  return moment(date)
    .set({
      hour: Number(hour),
      minute: Number(minute),
      second: 0,
      millisecond: 0,
    })
    .toISOString();
};

export const getEventDetailUrl = (
  id: string | void,
  originalStart: string | void,
): string =>
  `/calendar/calendar-event/${String(id)}?original_start=${encodeURIComponent(
    String(originalStart),
  )}`;
