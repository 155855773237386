// @flow
import {Transforms} from '@wellstone-solutions/common';
import {ContactModel} from '../contact';
import type {APIAnswerType, UIAnswerType} from '../../types';
import {Factory} from './factories';

export const toUI = (answer: APIAnswerType): UIAnswerType => {
  const {data, ...rest} = answer;
  const {contacts = []} = data;

  const uiAnswer = {
    ...rest,
    contacts: contacts.map(ContactModel.toUI),
  };

  return Factory(Transforms.toUIObject(uiAnswer));
};

export const toAPI = (answer: UIAnswerType): APIAnswerType => {
  const keysToOmit = ['modified'];
  if (answer.id?.length === 0) {
    keysToOmit.push('id');
  }

  const {contacts, expires, ...rest} = Transforms.omitKeys(answer, keysToOmit);

  const apiAnswer = {
    ...rest,
    expires: new Date(expires).toISOString(),
    data: {contacts: contacts.map(ContactModel.toAPI)},
  };

  return Transforms.toApiObject(apiAnswer);
};
