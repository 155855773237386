// @flow
import React from 'react';
import type {Node} from 'react';
import {OutlinedInput} from '@mui/material';
import {Select} from '@wellstone-solutions/web';

// Somewhat arbitrary, seems like a reasonable max
const MAX_SELECTION = 100;

// Generates a list from 1-100
const items = [...new Array(MAX_SELECTION).keys()].reduce((current, value) => {
  const currentValue = value + 1;
  current.push({label: String(currentValue), value: currentValue});

  return current;
}, []);

type PropsType = {
  onChange: (number) => void,
  value?: number,
};

export const IntervalSelect = ({onChange, value}: PropsType): Node => {
  const augmentedValue = Number(value) <= 0 ? 1 : value;

  return (
    <Select
      data-testid="interval-select"
      canUnselect={false}
      input={<OutlinedInput notched={false} />}
      items={items}
      onChange={(e) => onChange(e.target.value)}
      value={augmentedValue}
    />
  );
};
