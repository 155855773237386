import React from 'react';

const styles = {
  linksWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appleLogo: {
    height: 36,
    marginTop: 20,
    marginRight: 20,
  },
  googleLogo: {
    marginTop: 20,
    height: 36,
  },
};

const links = [
  {
    href: 'https://apps.apple.com/us/app/id1523679587',
    target: '_blank',
    alt: 'Apple Store',
    src:
      'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/apple.svg',
    style: styles.appleLogo,
  },
  {
    href:
      'https://play.google.com/store/apps/details?id=com.pfsbc.pathfinder.aspen',
    target: '_blank',
    alt: 'Google Store',
    src:
      'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/google.svg',
    style: styles.googleLogo,
  },
];

export default (props) => {
  return (
    <div style={styles.linksWrapper}>
      {links.map(({href, target, alt, src, style}) => {
        return (
          <a key={href} href={href} target={target} rel="noopener noreferrer">
            <img alt={alt} src={src} style={style} />
          </a>
        );
      })}
    </div>
  );
};
