// React
import React, {Component} from 'react';

// Third Party
import {inject, observer} from 'mobx-react';
import {Navigate} from 'react-router-dom';

// WS
import {Routes as SharedRoutes} from 'components/Routes';
import {hasFlag, SYSTEM_PAGES} from '../constants/FeatureFlags';
import {EventsPage} from './system/EventsPage';

export const routes = [
  {
    path: 'events',
    Component: EventsPage,
  },
];

class SystemFrame extends Component {
  render() {
    const hasAccess = hasFlag(SYSTEM_PAGES, this.props.meStore.features);

    if (!hasAccess) {
      return <Navigate to="/" />;
    }

    return (
      <div className="contained" style={styles.container}>
        <div style={styles.main}>
          <SharedRoutes routes={routes} />
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    flexDirection: 'row',
    display: 'flex',
  },
  main: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 40,
  },
};

export default inject('meStore')(observer(SystemFrame));
