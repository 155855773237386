// @flow
import React from 'react';
import type {Node} from 'react';
import {Box, MarkdownViewer, Stack, Typography} from '@wellstone-solutions/web';
import {StoryContentWrapper} from '../StoryContentWrapper';
import {ChipBlank} from './ChipBlank';

type PropsType = {
  abstract?: string,
  markdownText?: string,
};

export const Content = ({abstract, markdownText}: PropsType): Node => {
  return (
    <>
      {/* abstract content */}
      {typeof abstract !== 'undefined' && (
        <Box sx={styles.abstractContainer}>
          {abstract?.length > 0 ? (
            <Typography sx={styles.abstract}>{abstract}</Typography>
          ) : (
            <Stack direction="column">
              <ChipBlank />
              <ChipBlank />
              <ChipBlank sx={{width: '10rem'}} />
            </Stack>
          )}
        </Box>
      )}

      {/* Markdown content */}
      {typeof markdownText !== 'undefined' && (
        <Box sx={styles.markdownContainer}>
          {markdownText?.length > 0 ? (
            <StoryContentWrapper>
              <MarkdownViewer value={markdownText} />
            </StoryContentWrapper>
          ) : (
            <Stack direction="column">
              <ChipBlank />
              <ChipBlank />
              <ChipBlank sx={{width: '10rem'}} />
            </Stack>
          )}
        </Box>
      )}
    </>
  );
};

const styles = {
  abstractContainer: {
    overflow: 'hidden',
    px: 2,
    py: 2,
  },
  markdownContainer: {
    overflow: 'auto',
    marginTop: 1,
    px: 2,
  },
  abstract: {
    fontSize: 12,
  },
};
