// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Stack, Icon} from '@wellstone-solutions/web';
import {PfButton} from 'components/shared/PfButton';
import {STATUSES} from 'modules/stories/constants';

type PropsType = {
  formId: string,
  form: any,
  status?: 'pending' | 'approved' | 'deleted' | null,
  onPublish?: (isPublishing: boolean) => Promise<void>,
  confirmRemove?: () => void,
};

export const ActionButtons = ({
  formId,
  form,
  status,
  onPublish,
  confirmRemove,
}: PropsType): Node => {
  const canPublish = !form.dirty && status === STATUSES.draft;
  const isPublished = status === STATUSES.published;
  const publishStyles = canPublish
    ? {...styles.actionButton, ...styles.publishButton}
    : styles.actionButton;
  const showRemove = status && !isPublished;
  const showSave = !isPublished;

  return (
    <Stack direction="row" sx={{flex: 1, mt: 4}}>
      {showSave && (
        <>
          <PfButton
            isDisabled={!form.isValid || !form.dirty || form.isSubmitting}
            form={formId}
            color="primary"
            type="submit"
            onClick={() => {}}
            data-testid="action-button-save"
            loading={form.isSubmitting}
            style={styles.actionButton}>
            SAVE
          </PfButton>
        </>
      )}
      {isPublished ? (
        <PfButton
          isDisabled={false}
          color="primary"
          data-testid="action-button-unpublish"
          style={publishStyles}
          onClick={() => onPublish?.(false)}>
          UNPUBLISH
          <Icon name="ArrowRight" color="light" />
        </PfButton>
      ) : (
        <PfButton
          isDisabled={!canPublish}
          outlined={true}
          inverted={true}
          color={canPublish ? 'primary' : 'light'}
          data-testid="action-button-publish"
          style={publishStyles}
          onClick={() => onPublish?.(true)}>
          PUBLISH TO PATHFINDER
          <Icon name="ArrowRight" color={canPublish ? 'primary' : 'light'} />
        </PfButton>
      )}
      {showRemove && (
        <PfButton
          isDisabled={form.isSubmitting}
          form={formId}
          color="danger"
          type="button"
          data-testid="action-button-remove"
          onClick={() => confirmRemove?.()}>
          REMOVE
        </PfButton>
      )}
    </Stack>
  );
};

const styles = {
  actionButton: {
    // backgroundColor: Theme.colorPalette.medium,
    marginRight: '32px',
  },
  publishButton: {
    borderColor: Theme.colorPalette.primary,
    color: Theme.colorPalette.primary,
  },
};
