// @flow
import React from 'react';
import type {ComponentType} from 'react';
import {styled} from '@mui/material/styles';

export const StoryContentWrapper: ComponentType<{}> = styled((props) => (
  <div {...props} />
))(() => ({
  '& .toastui-editor-contents img': {
    width: '100%',
  },
}));
