// @flow
import {Transforms} from '@wellstone-solutions/common';
import type {PubSubMessageType} from 'types';
import type {NotificationType} from './types';

export const toNotification = (
  message: PubSubMessageType,
): NotificationType<any> => {
  const type = message.message.data.type;

  const data =
    type === 'calendar_event'
      ? {calendar_event: message.message.data?.event}
      : message.message.data?.data;

  return {
    channel: message.channel,
    data: Transforms.toUIObject(data),
    message: message.message.data.content,
    timetoken: message.timetoken,
    type: message.message.data.type,
    action: message.message.data.action,
  };
};
