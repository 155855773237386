// @flow
import React, {useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {alpha} from '@mui/material';
import {
  Icon,
  IconNames,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@wellstone-solutions/web';
import {Events, Theme, Transforms} from '@wellstone-solutions/common';
import type {UICalendarEventType} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';

type PropsType = {
  calendarEvent: UICalendarEventType,
  stackProps?: mixed,
};

export const AreYouAttending: ComponentType<PropsType> = observer(
  ({
    calendarEvent,
    stackProps = {direction: 'row', alignItems: 'center', spacing: 2},
  }: PropsType): Node => {
    const {eventStore, calendarStore, meStore} = useStores();

    const myInvitation = calendarEvent.invitations.find(
      (invite) => invite.user === meStore.me.id,
    );

    const [isAttending, setIsAttending] = useState(myInvitation?.isAttending);

    const updateInvite = async (value) => {
      const inviteData = {
        originalStart: calendarEvent.originalStart,
        // Assuming for now that when accepting, we will
        // be accepting all
        applyToAll: true,
        owner: meStore.me.id,
      };

      await calendarStore.updateCalendarEventInvitation(
        calendarEvent.id,
        {
          ...myInvitation,
          isAttending: value,
        },
        inviteData,
      );

      const analyticEvent = value
        ? Events.ACCEPTED_INVITATION
        : Events.REJECTED_INVITATION;
      eventStore.addEvent(analyticEvent, {
        calendar_event_id: calendarEvent.id,
        ...Transforms.toApiObject(inviteData),
      });

      // clear inbox notifications related to this event
      calendarStore.dismissNotificationsByCalenderEventId(calendarEvent.id);
    };

    return (
      <Stack {...stackProps} mt={2}>
        <Typography variant="h6" sx={{color: Theme.colorPalette.primary}}>
          Are you attending?
        </Typography>

        <ToggleButtonGroup
          value={isAttending}
          sx={styles.buttonGroup}
          onChange={(e, value) => {
            if (value !== null && myInvitation) {
              setIsAttending(value);
              updateInvite(value);
            }
          }}
          exclusive
          size="large">
          <ToggleButton
            value={true}
            data-testid="button-attending-yes"
            aria-label="Yes"
            sx={styles.buttonPositive}>
            <Icon name={IconNames.ThumbsUp} size={28} />
          </ToggleButton>
          <ToggleButton
            value={false}
            id="buttonNegative"
            data-testid="button-attending-no"
            aria-label="No"
            sx={styles.buttonNegative}>
            <Icon name={IconNames.ThumbsDown} size={28} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    );
  },
);

const styles = {
  buttonGroup: {
    '& .MuiToggleButton-root': {
      backgroundColor: Theme.colorPalette.violet,
    },
    '& .MuiToggleButton-root:hover': {
      backgroundColor: alpha(Theme.colorPalette.violet, 0.9),
    },
    '& .MuiToggleButton-root svg': {
      color: Theme.colorPalette.mediumDark,
    },
    '& .MuiToggleButton-root.Mui-selected svg': {
      color: Theme.colorPalette.lightest,
    },
  },

  buttonPositive: {
    '&.MuiToggleButton-root.Mui-selected, &.MuiToggleButton-root.Mui-selected:hover': {
      backgroundColor: Theme.colorPalette.green,
    },
  },

  buttonNegative: {
    '&.MuiToggleButton-root.Mui-selected, &.MuiToggleButton-root.Mui-selected:hover': {
      backgroundColor: Theme.colorPalette.red,
    },
  },
};
