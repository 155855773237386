// @flow
import React from 'react';
import type {Node} from 'react';
import {Stack, Typography} from '@wellstone-solutions/web';

type PropsType = {
  noResultsText: string,
  filterType?: string,
};

export const NoAnalyticsResults = ({
  noResultsText,
  filterType,
}: PropsType): Node => (
  <Stack>
    <Typography variant="h6">{noResultsText}</Typography>
    {!!filterType && (
      <Typography variant="subtitle1">
        Try changing the {filterType} filter.
      </Typography>
    )}
  </Stack>
);
