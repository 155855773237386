import {Selectors} from '@wellstone-solutions/common';

export const fieldStatus = (val, type, required) => {
  let color = 'dark';
  let error = null;
  if (val !== undefined && val !== null && val !== '' && val) {
    switch (type) {
      case 'email':
        error = testEmail(val);
        break;
      case 'zip':
        error = testZip(val);
        break;
      case 'phone':
        error = testPhone(val);
        break;
      default:
        error = null;
        break;
    }

    if (error) {
      color = 'danger';
    } else {
      color = 'success';
    }
  } else if (required) {
    // TODO: handle required on submit
    error = 'Field is Required';
    color = 'warning';
  }

  return {
    color: color,
    error: error,
  };
};

const testEmail = (email) => {
  let patt = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (patt.test(email)) {
    return null;
  } else {
    return 'Invalid Email';
  }
};

const testZip = (zip) => {
  let patt = /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/;
  if (patt.test(zip)) {
    return null;
  } else {
    return 'Invalid Zipcode';
  }
};

const testPhone = (phone) => {
  let patt = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
  if (patt.test(phone)) {
    return null;
  } else {
    return 'Invalid Phone Number';
  }
};

export const processPhone = (phone) => {
  let cleanPhone = phone.replace(/[^0-9]/, '');
  return (
    '(' +
    cleanPhone.slice(0, 3) +
    ') ' +
    cleanPhone.slice(3, 6) +
    '-' +
    cleanPhone.slice(6, 10)
  );
};

export const constructDate = (dateString) => {
  var dateArray = dateString.split('-');
  var year = dateArray[0];
  var month = parseInt(dateArray[1], 10) - 1;
  var date = dateArray[2];
  var entryDate = new Date(year, month, date);
  return entryDate;
};

export const processDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
};

export const fieldChange = (event, field, context) => {
  context.setState({...context.state, [field]: event.target.value});
};

export const adminRoles = [
  {
    name: 'Peer',
    value: 'peer',
    color: 'primary',
  },
  {
    name: 'Counselor',
    value: 'counselor',
    color: 'info',
  },
  {
    name: 'Admin',
    value: 'admin',
    color: 'success',
  },
  {
    name: 'Super Admin',
    value: 'super_admin',
    color: 'link',
  },
];

// export const getAdminRole = (s) => {
//   let roleObj = null;
//   adminRoles.map((role, r) => {
//     if(role.value === s) {
//       roleObj = role;
//     }
//   });
//   return roleObj;
// };

export const getAdminRole = (s) => {
  return adminRoles.find((role) => role.value === s);
};

export const genders = [
  {
    name: 'Male',
    value: 'male',
  },
  {
    name: 'Female',
    value: 'female',
  },
  {
    name: 'Unspecified',
    value: 'unspecified',
  },
];

export const identities = [
  {
    name: 'Not Listed',
    value: 'not_listed',
  },
  {
    name: 'Male',
    value: 'male',
  },
  {
    name: 'Female',
    value: 'female',
  },
  {
    name: 'Transgender Male',
    value: 'transgender_male',
  },
  {
    name: 'Transgender Female',
    value: 'transgender_female',
  },
  {
    name: 'Gender Variant',
    value: 'gender_variant',
  },
];
// export const getGender = (s) => {
//   let genderObj = null;
//   genders.map((gender, g) => {
//     if(gender.value === s) {
//       genderObj = gender;
//     }
//   });
//   return genderObj;
// };

export const getGender = (gender) => {
  const unspecifiedGender = genders[genders.length - 1];
  if (gender) {
    if (gender.value) {
      return gender;
    } else {
      const foundGender = genders.find((g) => g.value === gender.toLowerCase());
      return foundGender || unspecifiedGender;
    }
  } else {
    return unspecifiedGender;
  }
};

export const getIdentity = (identifiesAs) => {
  if (identifiesAs) {
    if (identifiesAs.value) {
      return identifiesAs;
    } else {
      const foundIdentity = identities.find(
        (identity) => identity.value === identifiesAs.toLowerCase(),
      );
      return foundIdentity || identities[0];
    }
  } else {
    return identities[0];
  }
};

export const states = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const memberStructure = {
  email: {
    required: true,
    default: '',
    test: 'email',
  },
  name: {
    required: true,
    default: '',
    test: 'text',
  },
  // birth_date: {
  //   required: true,
  //   default: new Date(new Date().setYear(new Date().getFullYear() - 18)),
  //   test: "text"
  // },
  // street1: {
  //   required: true,
  //   default: "",
  //   test: "text"
  // },
  // street2: {
  //   required: false,
  //   default: "",
  //   test: "text"
  // },
  // city: {
  //   required: true,
  //   default: "",
  //   test: "text"
  // },
  // state: {
  //   required: true,
  //   default: "MN",
  //   test: "text"
  // },
  // zip_code: {
  //   required: true,
  //   default: "",
  //   test: "zip"
  // },
  gender: {
    required: true,
    default: genders[2],
    test: 'text',
  },
  identity: {
    required: false,
    default: identities[0],
    text: 'text',
  },
  // sober_date: {
  //   required: true,
  //   default: new Date(),
  //   test: "text"
  // },
  // phone: {
  //   required: true,
  //   default: "",
  //   test: "phone"
  // }
};

export const adminStructure = {
  ...memberStructure,
  role: {
    required: true,
    default: adminRoles[0],
    test: 'text',
  },
};

export const groupStructure = {
  name: {
    required: true,
    default: '',
    test: 'text',
  },
  category: {
    required: false,
    default: 'primary',
    test: 'text',
  },
  street1: {
    required: false,
    default: '',
    test: 'text',
  },
  street2: {
    required: false,
    default: '',
    test: 'text',
  },
  city: {
    required: false,
    default: '',
    test: 'text',
  },
  state: {
    required: false,
    default: 'MN',
    test: 'text',
  },
  programId: {
    required: true,
    test: 'text',
  },
  zip_code: {
    required: false,
    default: '',
    test: 'zip',
  },
};

// examples of objects you may want to retrieve field value from:
// form.errors, form.touched, form.values
export const getFieldValue = (obj: any, field: string): boolean => {
  const isNested = field.indexOf('.') >= 0;
  const value = isNested
    ? Selectors.getDeepValue(obj, field)
    : // $FlowIgnoreMe
      obj[field];

  return Boolean(value);
};

// if the field has an error and has been touched
export const hasFieldError = (form: any, field: string): boolean =>
  getFieldValue(form.errors, field) && getFieldValue(form.touched, field);
