// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import Tag from 'react-bulma-components/lib/components/tag';
import {
  Box,
  Icon,
  IconNames,
  Stack,
  Tooltip,
  Typography,
} from '@wellstone-solutions/web';
import {Events, Hooks, Models, Theme} from '@wellstone-solutions/common';
import type {UIStoryType} from '@wellstone-solutions/common';
import {usePrompt} from 'hooks/usePrompt';
import {useStores} from 'hooks/useStores';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import type {ApiResponseType} from 'api/types';
import {ActionButtons} from './ActionButtons';
import {RemoveStoryModal} from './RemoveStoryModal';
import {EditFormValidators} from '../../validators';
import {STATUSES, TOOLTIP_FULLSTORY, TOOLTIP_PREVIEW} from '../../constants';
import {Form} from './Form';
import {Abstract, FullStory} from '../StoryPreview';

const FORM_ID = 'edit_form';

type PropsType = {
  data: {story: UIStoryType},
};

export const Edit = ({data}: PropsType): Node => {
  const {story} = data;
  const {appUIStore, eventStore, storyStore} = useStores();
  const {Story} = Models;
  const {useForm} = Hooks;
  const navigate = useNavigate();
  const [isRemoving, setIsRemoving] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const form = useForm({
    initialValues: story,
    schema: {
      ...Story.schema,
      ...EditFormValidators,
    },
    onSubmit: async (values, {resetForm}) => {
      const response: ApiResponseType<UIStoryType> = await storyStore.updateStory(
        values,
      );
      if (response.isSuccess) {
        resetForm({values: response.data});
        eventStore.addEvent(Events.UPDATED_STORY);
        showAlert('Successfully updated the story.');
        return;
      } else {
        showAlert(
          'Failed to save the story. Please try again.',
          ALERT_TYPES.ERROR,
        );
      }
    },
  });

  useEffect(() => {
    eventStore.addEvent(Events.VIEWED_STORY_EDIT);
    appUIStore.setBreadcrumbs([
      {
        name: NAVIGATION_NAMES.stories,
        url: '/stories',
      },
      {
        name: NAVIGATION_NAMES.storyEdit,
        // $FlowFixMe
        url: `/stories/${story.id}`,
        active: true,
      },
    ]);
  }, [eventStore, appUIStore, story]);

  const onPublish = async () => {
    const newStatus =
      form.values.status === STATUSES.published
        ? STATUSES.draft
        : STATUSES.published;
    const res: ApiResponseType<UIStoryType> = await storyStore.updateStoryStatus(
      form.values.id,
      form.values.organization,
      newStatus,
    );
    if (res.isSuccess) {
      form.resetForm({values: res.data});
      if (newStatus === STATUSES.published) {
        eventStore.addEvent(Events.PUBLISHED_STORY);
        showAlert('Story has been published.');
      } else if (newStatus === STATUSES.draft) {
        eventStore.addEvent(Events.UNPUBLISHED_STORY);
        showAlert('Story has been unpublished.');
      }
    } else {
      showAlert('Failed to update story status.', ALERT_TYPES.ERROR);
    }
  };

  const openRemoveModal = () => {
    setShowRemoveModal(true);
  };

  const onRemove = async () => {
    setIsRemoving(true);
    const res: ApiResponseType<null> = await storyStore.removeStory(
      form.values.id,
    );
    if (res.isSuccess) {
      eventStore.addEvent(Events.DELETED_STORY);
      showAlert('Story has been removed.');
      navigate('/stories');
    } else {
      showAlert('Failed to remove story.', ALERT_TYPES.ERROR);
    }
    setIsRemoving(false);
  };

  // prevent unintentionally losing unsaved changes
  usePrompt(
    'Are you sure you want to leave? You have unsaved changes that will be lost if you navigate away from this page.',
    // $FlowIgnoreMe
    !form.isSubmitting && !isRemoving && form.dirty,
  );

  return (
    <Box>
      <Stack direction={{xs: 'column', md: 'row'}}>
        <Stack
          flex={1}
          direction="column"
          justifyContent={{md: 'space-between'}}
          sx={{mb: 4, pr: 8}}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" sx={{color: Theme.colorPalette.primary}}>
              Update Story
            </Typography>
            {form.values.status === STATUSES.published && (
              <Tag color="success" title="Published" style={styles.statusTag}>
                Published
              </Tag>
            )}
          </Stack>
          <Form form={form} formId={FORM_ID} storyStatus={form.values.status} />
          <ActionButtons
            formId={FORM_ID}
            form={form}
            status={form.values.status}
            onPublish={onPublish}
            confirmRemove={openRemoveModal}
          />
        </Stack>
        <Stack
          flex={1}
          direction="column"
          justifyContent={{md: 'start'}}
          sx={{mb: 4, pr: 8}}>
          <Stack direction="row" sx={{mb: 2}}>
            <Typography variant="h6">Story Preview</Typography>
            <Tooltip title={TOOLTIP_PREVIEW} placement="top">
              <div style={styles.tooltip}>
                <Icon name={IconNames.InfoCircle} />
              </div>
            </Tooltip>
          </Stack>
          <Abstract
            abstract={form.values.abstract}
            title={form.values.title}
            host={form.values.host}
            organization={form.values.organization}
            mediaImage={
              EditFormValidators.mediaImage.validate(form.values.mediaImage)
                ? form.values.mediaImage
                : null
            }
            tags={form.values.tags}
            markdownText={form.values.desc}
            posterImageUrl={form.values.mediaImageUrl}
          />
          <Stack direction="row" sx={{mt: 2, mb: 2}}>
            <Typography variant="h6">Full Story</Typography>
            <Tooltip title={TOOLTIP_FULLSTORY} placement="top">
              <div style={styles.tooltip}>
                <Icon name={IconNames.InfoCircle} />
              </div>
            </Tooltip>
          </Stack>
          <FullStory
            abstract={form.values.abstract}
            title={form.values.title}
            host={form.values.host}
            organization={form.values.organization}
            tags={form.values.tags}
            markdownText={form.values.desc}
            mediaImage={
              EditFormValidators.mediaImage.validate(form.values.mediaImage)
                ? form.values.mediaImage
                : null
            }
            posterImageUrl={form.values.mediaImageUrl}
          />
        </Stack>
      </Stack>
      <RemoveStoryModal
        open={showRemoveModal}
        isRemoving={isRemoving}
        setShowModal={setShowRemoveModal}
        onRemove={onRemove}
      />
    </Box>
  );
};

const styles = {
  statusTag: {marginLeft: Theme.spacing.large},
  tooltip: {display: 'flex', alignItems: 'center', marginLeft: 4},
};
