import {Storage} from '@wellstone-solutions/web';

const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY;
const STORED_SELECTED_ORGANIZATION = 'stored_selected_organization';
const pendoApiKey = process.env.REACT_APP_PENDO_API_KEY;

const loadPendo = (features, user) => {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w) {
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push'](
                [m].concat([].slice.call(arguments)),
              );
            };
        })(v[w]);
      }
      y = e.createElement(n);
      y.async = true;
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })(pendoApiKey);
};

const initializePendo = (visitorId, orgId, orgName, role) => {
  window.pendo.initialize({
    visitor: {
      id: visitorId,
      role: role,
    },
    account: {
      id: orgId,
      name: orgName,
    },
  });
};

const resetPendo = () => {
  try {
    window.pendo.initialize({
      visitor: {
        id: 'visitor-logged-out',
      },
      account: {
        id: 'account-logged-out',
      },
    });
  } catch (error) {
    console.warn('Pendo does not exist - no reset needed');
  }
};

const setupPendoForCurrentUser = async (user) => {
  const currentOrg = await Storage.getItem(
    STORED_SELECTED_ORGANIZATION,
    CRYPTO_KEY,
  );
  const membership = user.memberships.find(
    (m) => m.organization.id === currentOrg,
  );
  if (membership) {
    initializePendo(
      user.id,
      membership.organization.name, // using name as both id and name for now
      membership.organization.name,
      membership.role,
    );
  } else {
    console.warn('No matching organization found for initialization');
  }
};

const removePendoScript = () => {
  const pendoScript = document.querySelector('script[src*="pendo"]');
  if (pendoScript) {
    pendoScript.parentNode.removeChild(pendoScript);
  }
  window.pendo = undefined;
};

export {
  loadPendo,
  initializePendo,
  resetPendo,
  removePendoScript,
  setupPendoForCurrentUser,
};
