// @flow
import React from 'react';
import type {Node} from 'react';
import type {UICollectionType} from '@wellstone-solutions/common/models/rest';
import {Box} from '@wellstone-solutions/web';
import {RemoteIcon} from 'components/RemoteIcon';

type PropsType = {
  collection: UICollectionType,
  selectedCollections?: Array<string>,
  setSelectedCollections?: (Array<string>) => void,
};

export const CollectionIcon = ({
  collection,
  selectedCollections = [],
  setSelectedCollections,
}: PropsType): Node => {
  const isSelected = selectedCollections.includes(collection.id);

  const toggleSelected = () => {
    if (!setSelectedCollections) {
      return;
    }
    if (isSelected) {
      setSelectedCollections([]);
    } else {
      setSelectedCollections([collection.id]);
    }
  };

  return (
    <Box
      sx={styles.wrapper}
      onClick={toggleSelected}
      data-testid="collection-icon">
      <RemoteIcon
        name={collection.name}
        label={collection.name}
        path={'/icons/collections/resources'}
        isSelected={isSelected}
        size={50}
      />
    </Box>
  );
};

const styles = {
  wrapper: {
    maxWidth: '80px',
    width: {
      xs: '80px',
      sm: 'auto',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
