// @flow
import React from 'react';
import type {Node} from 'react';
import {Stack, Box, Link, Icon, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  iconName: string,
  text: string,
  href?: string,
  noWrap?: boolean,
  onClick?: () => void,
};

export const ContactMethod = ({
  iconName,
  text,
  href,
  onClick,
  noWrap = false,
}: PropsType): Node => {
  return (
    <Stack direction="row" alignItems="center">
      <Box sx={styles.contactMethodIcon}>
        <Icon
          name={iconName}
          size={20}
          color={Theme.colorPalette.primary}
          style={styles.icon}
        />
      </Box>

      {href ? (
        <Link
          noWrap={noWrap}
          onClick={onClick}
          href={href}
          target="__blank"
          title={text}
          sx={styles.contactMethodText}>
          {text}
        </Link>
      ) : (
        <Typography noWrap={noWrap} sx={styles.contactMethodText}>
          {text}
        </Typography>
      )}
    </Stack>
  );
};

const styles = {
  contactMethodIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 1,
  },
  contactMethodText: {
    fontSize: 14,
    textDecoration: 'none',
    color: Theme.colorPalette.onSurface,
    '&:hover': {
      color: 'inherit',
    },
  },
  icon: {
    strokeWidth: 2,
  },
};
