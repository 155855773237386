// @flow
import React from 'react';
import type {Node} from 'react';
import {Chip} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  count: number,
  sx?: mixed,
  testId?: string,
};

export const ActiveCount = ({
  count,
  sx = {},
  testId = 'active-count',
}: PropsType): Node => {
  if (count === 0) {
    return null;
  }

  return (
    <Chip
      label={String(count)}
      size="small"
      variant="solid"
      sx={{...styles.chip, ...sx}}
      data-testid={testId}
    />
  );
};

const styles = {
  chip: {
    fontWeight: Theme.typography.weight.bold,
    backgroundColor: Theme.colorPalette.darkest,
    color: Theme.colorPalette.lightest,
  },
};
