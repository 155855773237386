// React
import React, {Component} from 'react';

// Third Party
import {inject, observer} from 'mobx-react';

// Bulma
import Columns from 'react-bulma-components/lib/components/columns';
import Level from 'react-bulma-components/lib/components/level';
import {Field, Label} from 'react-bulma-components/lib/components/form';

// WS
import {Api, Theme} from '@wellstone-solutions/common';
import {Switch, Typography} from '@wellstone-solutions/web';
import {EventEmitter} from 'utils/EventEmitter';
import {fieldStatus, states, groupStructure} from 'utils/Forms';
import {Palette} from 'palette';
import {NO_PROGRAM} from 'constants/groups';
import Button from '../../tutorial/button';
import PfDropdown from '../components/PfDropdown';
import PfInput from '../components/PfInput';
import PfLoader from '../../shared/PfLoader';
import {ProgramsDropdown} from './ProgramsDropdown';
import {ENTITY_LEVEL} from 'modules/rbac';

class EditGroupForm extends Component {
  state = {
    ready: false,
    name: groupStructure.name.default,
    category: groupStructure.category.default,
    programId: groupStructure.programId.default,
    street1: groupStructure.street1.default,
    street2: groupStructure.street2.default,
    city: groupStructure.city.default,
    state: groupStructure.state.default,
    zip_code: groupStructure.zip_code.default,
    is_public: false,
    isProcessing: false,
    isProgramReadOnly: false,
  };

  componentDidMount() {
    this.setLoaded();
  }

  setLoaded() {
    const {organizationStore, group} = this.props;
    const category = group.category
      ? organizationStore.getGroupCategoryById(group.category.id)
      : organizationStore.groupCategories[0];
    const programId = group.programs.length > 0 ? group.programs[0].id : '';
    // If program is already set, make it readonly
    const isProgramReadOnly = programId.length > 0;

    this.setState({
      ...group,
      isProgramReadOnly,
      category,
      programId,
      ready: true,
    });
  }

  _selectState = (sel) => {
    this.setState({state: sel});
  };

  _selectCategory = (sel) => {
    this.setState({category: sel, is_public: sel.is_public});
  };

  _checkForErrors = () => {
    let hasErrors = false;
    for (let [key, value] of Object.entries(groupStructure)) {
      if (fieldStatus(this.state[key], value.test, value.required).error) {
        hasErrors = true;
      }
    }
    return hasErrors;
  };

  _updateGroup = async () => {
    this.setState({isProcessing: true});
    const org = this.props.meStore.me.membership.organization.id;
    let params = {
      name: this.state.name,
      category_id:
        this.state.category.id ||
        this.props.organizationStore.groupCategories[0].id,
      street1: this.state.street1,
      street2: this.state.street2,
      city: this.state.city,
      state: this.state.state,
      zip_code: this.state.zip_code,
      is_public: this.state.is_public,
    };

    if (this.state.programId.length > 0) {
      params.programs = [{id: this.state.programId}];
    }

    try {
      await Api.Instance.current().post(
        '/orgs/' + org + '/groups/' + this.props.group.id,
        params,
      );
      this.setState({isProcessing: false});
      this.props.groupStore.loadGroups();
      this.props.nextStep();

      if (this.props.onUpdate) {
        this.props.onUpdate();
      }
    } catch (e) {
      this.setState({isProcessing: false});
    }
  };

  render() {
    const {meStore, organizationStore, RBACStore} = this.props;
    const {groupCategories} = organizationStore;

    if (!this.state.ready) {
      return <PfLoader />;
    }

    return (
      <div style={styles.form}>
        {/*NAME*/}
        <PfInput
          fullWidth
          placeholder="name of group"
          val={this.state.name}
          setval={(newVal) => this.setState({name: newVal})}
          label="Name*"
          field={{
            test: groupStructure.name.test,
            name: 'name',
            required: groupStructure.name.required,
          }}
        />
        {/*TYPE*/}
        <>
          <PfDropdown
            fullWidth
            val={this.state.category}
            label="Type"
            labelPath="name"
            selectFunc={this._selectCategory}
            list={groupCategories}
            classes="right-drop short-drop"
            field={{
              test: groupStructure.category.test,
              name: 'category',
              required: groupStructure.category.required,
            }}
          />
        </>
        <>
          <Field>
            <Columns>
              <Columns.Column size={9}>
                <Label htmlFor="is_public" style={styles.fieldNoGutter}>
                  Community Group
                </Label>
                <Typography sx={{fontSize: 14, color: Theme.colorPalette.blue}}>
                  Community Groups allow members to chat with each other.
                </Typography>
              </Columns.Column>
              <Columns.Column size={3} style={styles.flexColumn}>
                <Switch
                  checked={this.state.is_public}
                  onChange={(event) => {
                    this.setState({is_public: event.target.checked});
                  }}
                />
              </Columns.Column>
            </Columns>
          </Field>
          {RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all) &&
          !this.state.isProgramReadOnly ? (
            <ProgramsDropdown
              value={this.state.programId}
              onChange={(programId) => {
                this.setState({programId});
              }}
              programs={meStore.myPrograms.slice()}
            />
          ) : (
            <Field>
              <Columns>
                <Columns.Column size={9}>
                  <Label>Program</Label>
                </Columns.Column>
                <Columns.Column size={3} style={styles.flexColumn}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    {this.state.programs?.length > 0
                      ? meStore.myProgramsLookup[this.state.programs[0].id]
                          ?.name || NO_PROGRAM
                      : NO_PROGRAM}
                  </Typography>
                </Columns.Column>
              </Columns>
            </Field>
          )}
        </>
        {/*STREET 1*/}
        <PfInput
          fullWidth
          placeholder="Street"
          val={this.state.street1}
          setval={(newVal) => this.setState({street1: newVal})}
          label="Street"
          field={{
            test: groupStructure.street1.test,
            name: 'street1',
            required: groupStructure.street1.required,
          }}
        />
        {/*STREET 2*/}
        <PfInput
          fullWidth
          placeholder="Street 2"
          val={this.state.street2}
          setval={(newVal) => this.setState({street2: newVal})}
          label="Street 2"
          field={{
            test: groupStructure.street2.test,
            name: 'street2',
            required: groupStructure.street2.required,
          }}
        />
        {/*CITY*/}
        <PfInput
          fullWidth
          placeholder="city"
          val={this.state.city}
          setval={(newVal) => this.setState({city: newVal})}
          label="City"
          field={{
            test: groupStructure.city.test,
            name: 'city',
            required: groupStructure.city.required,
          }}
        />
        {/*STATE*/}
        <PfDropdown
          fullWidth
          val={this.state.state}
          label="State"
          selectFunc={this._selectState}
          list={states}
          classes="short-drop thin-drop top-drop"
          field={{
            test: groupStructure.state.test,
            name: 'state',
            required: groupStructure.state.required,
          }}
        />
        {/*ZIP*/}
        <PfInput
          fullWidth
          placeholder="5 digit zipcode"
          val={this.state.zip_code}
          setval={(newVal) => this.setState({zip_code: newVal})}
          label="Zipcode"
          field={{
            test: groupStructure.zip_code.test,
            name: 'zip_code',
            required: groupStructure.zip_code.required,
          }}
        />

        <Level>
          <Level.Side>
            <Level.Item>
              <Button
                color="primary"
                disabled={this._checkForErrors() ? true : false}
                isprocessing={this.state.isProcessing}
                onClick={this._updateGroup}>
                Update Group
              </Button>
            </Level.Item>
          </Level.Side>
          <Level.Side>
            <Level.Item>
              <Button
                color="danger"
                onClick={() => EventEmitter.dispatch('closeModal', null)}>
                Cancel
              </Button>
            </Level.Item>
          </Level.Side>
        </Level>
      </div>
    );
  }
}

const styles = {
  flexColumn: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  fieldLabel: {
    paddingRight: 20,
  },
  fieldNoGutter: {
    marginBottom: 0,
  },
  form: {
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mRight: {
    marginRight: 10,
    transform: 'translateY(25%)',
  },
  communityLabel: {
    color: Palette.INFO,
    textAlign: 'right',
    paddingBottom: 20,
    fontSize: 14,
    fontStyle: 'italic',
  },
};

export default inject(
  'meStore',
  'organizationStore',
  'groupStore',
  'RBACStore',
)(observer(EditGroupForm));
