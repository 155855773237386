// @flow
import * as factories from './factories';
import * as validation from './validation';
import * as transforms from './transforms';
import * as api from './api';

export const SessionDocument = {
  ...factories,
  ...validation,
  ...transforms,
  ...api,
};
