import {generateId} from './TutorialStore';

class MemberStore {
  routes = [
    {
      method: 'GET',
      route: /orgs\/[A-Z0-9-]*\/members\/[A-Z0-9-]*/i,
      action: this.getOrgMember.bind(this),
    },
    {
      method: 'GET',
      route: /orgs\/[A-Z0-9-]*\/members$/i,
      action: this.getOrgMembers.bind(this),
    },
    {
      method: 'POST',
      route: /orgs\/[A-Z0-9-]*\/members$/i,
      action: this.addOrgMember.bind(this),
    },
    {
      method: 'POST',
      route: /orgs\/[A-Z0-9-]*\/members\/[A-Z0-9-]*/i,
      action: this.editOrgMember.bind(this),
    },
    {
      method: 'GET',
      route: /orgs\/[A-Z0-9-]*\/search/i,
      action: this.searchForMembersAndStaff.bind(this),
    },
    {
      method: 'POST',
      route: '/messaging/channels',
      action: this.getMemberChannel.bind(this),
    },
  ];

  members = [];

  constructor(tutorialStore) {
    this.tutorialStore = tutorialStore;
  }

  _init() {
    this.members = [
      {
        role: 'patient',
        user: {
          searchable: true, // Addition to the API spec.
          email: 'xavier@pfsbc.com',
          id: '403c1558-b7cb-4059-bce2-7c7311961fax',
          name: 'Xavier',
          gender: 'male',
          identifies_as: 'male',
        },
        admissions: [
          {
            start_date: '2020-12-1',
            end_date: '2021-3-1',
            id: generateId(),
            program: this.tutorialStore.stores.programStore.programs[0],
          },
        ],
        group_admissions: [
          {
            start_date: '2020-12-1',
            end_date: '2021-3-1',
            id: generateId(),
            group: this.tutorialStore.stores.groupStore.groups[0],
          },
          {
            start_date: '2020-12-1',
            end_date: '2021-3-1',
            id: generateId(),
            group: this.tutorialStore.stores.groupStore.groups[1],
          },
        ],
        name: 'Xavier',
        gender: 'male',
        identifies_as: 'male',
        id: '599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
        is_in_treatment: false,
        status: 'active',
        groups: [
          {
            id: 'dbe73745-4f3c-4cb3-9b85-e27548bafccd',
            category: 'primary',
            name: 'Group A',
            status: 'active',
          },
        ],
      },
      {
        role: 'patient',
        user: {
          searchable: true, // Addition to the API spec.
          email: 'mary@pfsbc.com',
          id: '403c1558-b7cb-4059-bce2-7c7311961fam',
          name: 'Mary',
          gender: 'female',
          identifies_as: 'female',
        },
        admissions: [
          {
            start_date: '2020-12-1',
            end_date: '2021-3-1',
            id: generateId(),
            program: this.tutorialStore.stores.programStore.programs[0],
          },
        ],
        group_admissions: [
          {
            start_date: '2020-12-1',
            end_date: '2021-3-1',
            id: generateId(),
            group: this.tutorialStore.stores.groupStore.groups[0],
          },
          {
            start_date: '2020-12-1',
            end_date: '2021-3-1',
            id: generateId(),
            group: this.tutorialStore.stores.groupStore.groups[1],
          },
        ],
        name: 'Mary',
        gender: 'female',
        identifies_as: 'female',
        id: '599be126-03bc-4ae5-9cb7-87dfe4d64f1b',
        is_in_treatment: false,
        status: 'active',
        groups: [
          {
            id: 'dbe73745-4f3c-4cb3-9b85-e21548bafccd',
            category: 'primary',
            name: 'Group A',
            status: 'active',
          },
        ],
      },
      {
        role: 'patient',
        user: {
          searchable: true, // Addition to the API spec.
          email: 'john@pfsbc.com',
          id: '403c1558-b7cb-4059-bce2-7c7311961faj',
          name: 'John',
          gender: 'male',
          identifies_as: 'male',
        },
        admissions: [
          {
            start_date: '2020-12-1',
            end_date: '2021-3-1',
            id: generateId(),
            program: this.tutorialStore.stores.programStore.programs[0],
          },
        ],
        group_admissions: [
          {
            start_date: '2020-12-1',
            end_date: '2021-3-1',
            id: generateId(),
            group: this.tutorialStore.stores.groupStore.groups[0],
          },
          {
            start_date: '2020-12-1',
            end_date: '2021-3-1',
            id: generateId(),
            group: this.tutorialStore.stores.groupStore.groups[1],
          },
        ],
        name: 'John',
        gender: 'male',
        identifies_as: 'male',
        id: '599be126-03bc-4ae5-9cb7-87dfe4d64f2b',
        is_in_treatment: false,
        status: 'active',
        groups: [
          {
            id: 'dbe73745-4f3c-4cb3-9b85-e21548baf5cd',
            category: 'primary',
            name: 'Group A',
            status: 'active',
          },
        ],
      },
    ];
  }

  getOrgMember({route, params}) {
    const memberId = /orgs\/[A-Z0-9-]*\/members\/([A-Z0-9-]*)/i.exec(route)[1];
    let member = this.members.find((m) => m.id === memberId);
    member.admissions = member.admissions.filter((adm) => !adm.discharge_date);
    member.group_admissions = member.group_admissions.filter(
      (adm) => !adm.discharge_date,
    );
    return {
      data: member,
      status: 'ok',
      messages: [],
      messages_type: 'string_array',
    };
  }

  getOrgMembers({route, params}) {
    let results;
    const {steps, currentStep} = this.tutorialStore;
    const id = steps[currentStep];
    if (id === 'add-member-to-group-1') {
      results = [this.members[this.members.length - 1]];
    } else if (params.group_id) {
      results = this.members.filter((member) =>
        member.group_admissions.find((g) => g.group.id === params.group_id),
      );
    } else {
      results = this.members;
    }

    return {
      data: {members: results, status: 'ok'},
    };
  }

  addOrgMember({route, params}) {
    const newUser = params;
    const memberId = generateId();

    newUser.id = memberId;
    newUser.user.id = generateId();
    newUser.gender = 'female';
    newUser.identifies_as = 'female';
    newUser.user.gender = 'female';
    newUser.user.name = newUser.name;
    newUser.user.identifies_as = 'female';
    newUser.searchable = false; // Important.
    newUser.is_in_treatment = true;
    newUser.status = 'active';
    newUser.group_admissions = [];
    newUser.admissions = [];

    this.members = [...this.members, newUser];
    return {
      data: {id: memberId},
      status: 'ok',
      messages: [],
      messages_type: 'string_array',
    };
  }

  editOrgMember({route, params}) {
    const memberId = /orgs\/[A-Z0-9-]*\/members\/([A-Z0-9-]*)/i.exec(route)[1];
    let memberIndex = this.tutorialStore.stores.memberStore.members.findIndex(
      (m) => m.id === memberId,
    );
    this.members[memberIndex] = {...this.members[memberIndex], ...params};

    return {
      data: {member_id: this.members[memberIndex].id},
      status: 'ok',
      messages: [],
      messages_type: 'string_array',
    };
  }

  searchForMembersAndStaff({route, params}) {
    const users = [
      this.members[0],
      // TODO: for now only returning Xavier so that all story language matches
      // ...this.members,
      //...this.tutorialStore.stores.staffStore.staff, // Not searching staff.
    ];

    const members = users.reduce((filtered, item) => {
      if (
        item.user.searchable &&
        item.name.toLowerCase().includes(params.query.toLowerCase())
      ) {
        filtered.push({
          role: item.role,
          name: item.user.name,
          id: item.id,
        });
      }
      return filtered;
    }, []);

    return {
      data: {members},
      status: 'ok',
      messages: [],
      messages_type: 'string_array',
    };
  }

  getMemberChannel({route, params}) {
    return {
      data: {
        status: 'active',
        members: [],
        id: 'pf-demo-channel',
        name: 'ch-chat.pf-demo-channel',
      },
      status: 'ok',
      messages: [],
      messages_type: 'string_array',
    };
  }
}

export default MemberStore;
