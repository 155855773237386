// @flow
import React from 'react';
import type {Node} from 'react';
import {SuspendedComponent} from '@wellstone-solutions/web';
import {Admissions} from './Admissions';
import {useDataLoader} from '../../dataloaders/memberAdmissionsDataLoader';

export const AdmissionsLoader = ({memberId}: {memberId: string}): Node => {
  return (
    <SuspendedComponent
      useDataLoader={useDataLoader}
      keyProp={String(memberId)}>
      <Admissions />
    </SuspendedComponent>
  );
};
