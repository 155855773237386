// @flow
import React from 'react';
import type {Node} from 'react';
import Dropdown from 'react-bulma-components/lib/components/dropdown/';

type PropsType = {
  children: Node,
  disabled?: boolean,
};

const CoreDropdown = ({children, disabled, ...rest}: PropsType): Node => {
  return (
    <fieldset disabled={disabled || false} style={styles.fieldset}>
      <Dropdown {...rest}>{children}</Dropdown>
    </fieldset>
  );
};

/**
 * Styles for the Dropdown component.
 */
const styles = {
  fieldset: {
    width: '100%',
    height: '100%',
  },
};

export default CoreDropdown;
