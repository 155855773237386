import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';

// Third Party
import moment from 'moment';
import {Icon, IconNames} from '@wellstone-solutions/web';

// Bulma
import Box from 'react-bulma-components/lib/components/box';

// WS
import {useStores} from 'hooks/useStores';
import {Palette} from '../../../palette';

const List = (props) => {
  const {select, selected} = props;
  const [isLoading, setIsLoading] = useState(true);
  const {channelStore} = useStores();
  const scrollBoxRef = useRef(null);

  //On mount get initial set of channels
  useEffect(() => {
    async function getChannels() {
      await channelStore.loadChannels();
      setIsLoading(false);
    }
    getChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle infinite scroll loading of new channels
  useEffect(() => {
    const element = scrollBoxRef.current;

    const handleScroll = () => {
      async function getChannels() {
        await channelStore.loadChannels();
        setIsLoading(false);
      }
      const position = element.scrollTop;
      const height = element.scrollHeight - element.clientHeight;
      // the Math.ceil accounts for the inexact position values when the screen is zoomed
      if (height > 100 && height <= Math.ceil(position)) {
        setIsLoading(true);
        getChannels();
      }
    };

    if (element) {
      element.addEventListener('scroll', handleScroll, {passive: true});

      return () => {
        element.removeEventListener('scroll', handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box style={styles.box}>
      <div ref={scrollBoxRef} style={styles.scrollBox}>
        {channelStore.channels.map((channel) => {
          if (!channel.members.length) {
            return null;
          }
          const activityDate = channel.data?.last_channel_activity
            ? new Date(channel.data.last_channel_activity)
            : null;
          const uc =
            channelStore.unreadChannels[
              channelStore.findUnreadChannel(channel.name)
            ];
          return (
            <div
              style={{
                ...styles.row,
                ...(selected?.id === channel.id && styles.selected),
                ...(uc?.messageCount > 0 && styles.unread),
              }}
              key={channel.id}
              onClick={() => select(channel)}>
              <Icon name={IconNames.ProfileCircle} color="inherit" />
              <span style={styles.name}>{channel.members[0].name}</span>
              {/*This is to deal with channels without messages, or very very old content lacking metadata*/}
              {!!activityDate && activityDate.getFullYear() > 2019 && (
                <span style={styles.timeAgo}>
                  {moment(activityDate).fromNow()}
                </span>
              )}

              {uc?.messageCount > 0 && (
                <span style={styles.counter}>{uc.messageCount}</span>
              )}
            </div>
          );
        })}
        {isLoading && (
          <div style={styles.loadingContainer}>
            <div className="dot-flashing" />
          </div>
        )}
      </div>
    </Box>
  );
};

const styles = {
  box: {},
  scrollBox: {
    maxHeight: '392px',
    overflowY: 'auto',
    paddingBottom: '20px',
  },
  row: {
    padding: '8px 16px',
    color: Palette.PRIMARY,
    backgroundColor: 'white',
    borderRadius: '5px',
    margin: '8px 0px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  name: {
    width: 'auto',
    marginLeft: '10px',
    flex: 1,
  },
  selected: {
    backgroundColor: Palette.EXCITE_0,
    color: 'white',
  },
  unread: {
    fontWeight: 'bold',
  },
  counter: {
    backgroundColor: Palette.ACTIVE_LINK,
    borderRadius: '12px',
    display: 'inline-block',
    padding: '0 7px',
    height: '24px',
    textAlign: 'center',
    lineHeight: '20px',
    fontSize: '12px',
    color: 'white',
    border: '2px solid white',
    boxShadow: '0px 0px 5px rgb(10 10 10 / 20%)',
  },
  loadingContainer: {
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    height: 0,
    marginTop: '5px',
  },
  timeAgo: {
    textAlign: 'right',
    fontWeight: 'bold',
    paddingRight: '10px',
    fontSize: '14px',
    fontVariant: 'small-caps',
    color: Palette.DARK_INFO,
  },
};

export default observer(List);
