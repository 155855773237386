// @flow

import {GCS_BASE_URL, GCS_DEFAULT_BUCKET} from 'constants/CloudStorage';
import {
  ACCEPTED_IMAGE_TYPES,
  INVALID_IMAGE_TYPE_ERROR,
  MAX_INLINE_FILE_SIZE,
  MAX_INLINE_FILE_ERROR,
} from 'constants/Images';

export const imageGCBucketUrl = (path: string): string => {
  if (path.indexOf('/') !== 0) {
    return `${GCS_BASE_URL}/${GCS_DEFAULT_BUCKET}/${path}`;
  }
  return `${GCS_BASE_URL}/${GCS_DEFAULT_BUCKET}${path}`;
};

type OptionsType = {
  baseUrl: string,
  extension: string,
};

export const iconNameToImageUrl = (
  name: string,
  {baseUrl, extension}: OptionsType,
): string => {
  const imageName = name.replace(/\s/g, '_').replace(/&/g, 'and').toLowerCase();

  return `${baseUrl}/${imageName}.${extension}`;
};

export const richTextImageValidators = (image: Blob): string | null => {
  if (!ACCEPTED_IMAGE_TYPES.includes(image.type)) {
    return INVALID_IMAGE_TYPE_ERROR;
  }

  if (image.size >= MAX_INLINE_FILE_SIZE) {
    return MAX_INLINE_FILE_ERROR;
  }

  return null;
};
