import React, {useRef, useEffect, useState} from 'react';
import Button from 'react-bulma-components/lib/components/button';
import {inject, observer} from 'mobx-react';
import PfLoader from '../../shared/PfLoader';

/**
 * Core button that allows it to be used during the tutorial.
 * Abstracts Bulma button.
 * @param {Object} props
 */
const CoreButton = (props) => {
  const {
    tutorialStore,
    id,
    /**
     * This is a bit gnarly, but allows us to run functions
     * within the context of the buttons parent. Handy when state
     * or a nested component needs updating.
     */
    tutorialfunction = null,
    onClick = null,
    /**
     * We pull the children out of the props and rebuild later.
     */
    children,
  } = props;

  const buttonEl = useRef(null);
  const [zIndex, setZIndex] = useState(0);
  const [isTutorialComponent, setTutorialComponent] = useState(false);
  const [extraStyles, setExtraStyles] = useState({});

  useEffect(() => {
    if (!buttonEl || !tutorialStore || !id) {
      return;
    }
    if (tutorialStore.activeComponents.indexOf(id) !== -1) {
      setTutorialComponent(true);
      if (
        id !== 'nextButton' &&
        id !== 'finishButton' &&
        id !== 'tutorialSkipButton' &&
        id !== 'restart-button'
      ) {
        setZIndex(buttonEl.current.style.zIndex);
        let styles;
        if (typeof tutorialfunction !== 'function') {
          styles = {
            zIndex: 501,
            boxShadow: '0 0 10px 10px rgba(255, 255, 255, 1)',
          };
        } else {
          styles = {
            zIndex: 501,
          };
        }
        setExtraStyles(styles);
      }
    } else {
      setExtraStyles({});
    }

    if (tutorialStore.disabledComponents.indexOf(id) !== -1) {
      setExtraStyles({
        pointerEvents: 'none',
        opacity: 0.5,
      });
    }

    return () => setExtraStyles({});
  }, [
    buttonEl,
    id,
    tutorialStore,
    tutorialStore.currentStep,
    tutorialfunction,
  ]);

  const childProps = Object.assign({}, props);
  delete childProps.tutorialStore;
  delete childProps.tutorialfunction;
  delete childProps.isprocessing;

  return (
    <Button
      domRef={buttonEl}
      {...childProps}
      style={{...props.style, ...extraStyles}}
      disabled={props.disabled || props.isprocessing}
      onClick={(element) => {
        // Skip and Restart buttons do not move us forward.
        if (
          isTutorialComponent &&
          id !== 'tutorialSkipButton' &&
          id !== 'restart-button'
        ) {
          tutorialStore.nextStep();
          // Return to previous zIndex, remove shadow.
          setExtraStyles({zIndex: zIndex});
        }
        if (id === 'tutorialSkipButton') {
          tutorialStore.skipToComplete();
        } else if (id === 'restart-button') {
          tutorialStore.resetTutorial();
        } else {
          if (typeof onClick === 'function') {
            onClick(element);
          }
          if (typeof tutorialfunction === 'function') {
            tutorialfunction(element);
          }
        }
      }}>
      {props.isprocessing ? <PfLoader /> : children}
    </Button>
  );
};

export default inject('tutorialStore')(observer(CoreButton));
