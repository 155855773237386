// @flow
import React, {useRef, useEffect} from 'react';
import type {Node} from 'react';
import {InView} from 'react-intersection-observer';
import type {UIMemberBasicType} from '@wellstone-solutions/common';
import {Theme} from '@wellstone-solutions/common';
import {Box, Typography} from '@wellstone-solutions/web';
// TODO replace with import from common libs after adding forwardRef
import {List} from '@mui/material';
import {ShareListItem} from './ShareListItem';
import {SkeletonListItem} from './SkeletonListItem';

type PropsType = {
  members: Array<UIMemberBasicType>,
  hasNextPage: boolean,
  onLoadMore: () => Promise<void>,
  selectedMembers: Array<UIMemberBasicType>,
  setSelectedMembers: (Array<UIMemberBasicType>) => void,
  isLoading: boolean,
};

export const ShareList = ({
  members,
  hasNextPage,
  onLoadMore,
  selectedMembers,
  setSelectedMembers,
  isLoading,
}: PropsType): Node => {
  const listRef = useRef(null);

  const isSelected = (member) => {
    return selectedMembers.some(({id}) => id === member.id);
  };

  const toggleSelected = (member) => {
    if (isSelected(member)) {
      setSelectedMembers(selectedMembers.filter(({id}) => id !== member.id));
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const renderSkeletons = (count: number): Node => {
    return Array.from({length: count}).map((_, index) => (
      <SkeletonListItem key={index} />
    ));
  };

  // Reset scroll position when loading new data
  useEffect(() => {
    if (listRef.current && isLoading) {
      listRef.current.scrollTop = 0;
    }
  }, [listRef, isLoading]);

  if (isLoading) {
    return (
      <List ref={listRef} sx={styles.list}>
        {renderSkeletons(5)}
      </List>
    );
  }

  return (
    <List ref={listRef} sx={styles.list}>
      {members.length === 0 && (
        <Box p={4}>
          <Typography variant="h6" align="center">
            No results found
          </Typography>
        </Box>
      )}

      {members.map((member) => (
        <ShareListItem
          key={member.id}
          member={member}
          isSelected={isSelected(member)}
          onClick={() => toggleSelected(member)}
        />
      ))}

      {hasNextPage && (
        <InView onChange={(inView) => inView && onLoadMore()}>
          {renderSkeletons(2)}
        </InView>
      )}
    </List>
  );
};

const styles = {
  list: {
    height: 275,
    overflowY: 'scroll',
    border: `1px solid ${Theme.colorPalette.mediumLight}`,
    paddingTop: 0,
    '& .MuiListItem-root': {
      borderBottom: `1px solid ${Theme.colorPalette.mediumLight}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
};
