// React
import React, {useCallback, useEffect, useRef, useState} from 'react';

//Third party
import {observer} from 'mobx-react';
import 'emoji-mart/css/emoji-mart.css';

// Bulma
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Notification from 'react-bulma-components/lib/components/notification';

// WS
import {Api} from '@wellstone-solutions/common';
import {Palette} from '../../palette';
import {hiddenIfEmpty} from '../../utils/Styler';
import PfMessage from './PfMessage';
import {getPubnubTimetokenFromTimestamp, scrollTo} from '../../utils/Utils';
import {useStores} from 'hooks/useStores';
import {autorun} from 'mobx';
import ChatModuleInputs from './ChatModuleInputs';
import {LoadingIndicator} from '../shared/LoadingIndicator';

const PfChatModule = (props) => {
  const {
    meStore,
    memberStore,
    pubnubStore,
    channelStore,
    tutorialStore,
  } = useStores();

  const chatStream = useRef();
  const [channel, setChannel] = useState(null);
  const [chatMember, setChatMember] = useState(null);
  const channelRef = useRef(channel);
  const [isHistoryEmpty, setIsHistoryEmpty] = useState(true);
  const [userChannelAccess, setUserChannelAccess] = useState({denied: false});
  const [loading, setLoading] = useState(false);
  const [timetoken, setTimetoken] = useState(null);
  const historyChunk = 5;

  const scrollStream = (direction) => {
    if (chatStream.current) {
      if (direction === 'up') {
        chatStream.current.scrollTop = 0;
      } else {
        scrollTo(chatStream.current, chatStream.current.scrollHeight, 500);
      }
    }
  };

  useEffect(() => {
    if (channel) {
      const [user] = channel.members;

      if (user) {
        memberStore.getMemberForUserId(user.id).then((member) => {
          setChatMember(member);
        });
      }
    }
  }, [channel, memberStore]);

  useEffect(
    () =>
      autorun(() => {
        if (timetoken && pubnubStore.firstMessage) {
          scrollStream('up');
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pubnubStore.firstMessage],
  );

  useEffect(
    () =>
      autorun(() => {
        if (pubnubStore.lastMessage) {
          scrollStream('down');
        }
      }),
    [pubnubStore.lastMessage],
  );

  const closeChannel = useCallback(
    (channelId) => {
      pubnubStore.clearMessages();
      if (channelId) {
        pubnubStore.unsubscribeFromChannelPresence(['ch-chat.' + channelId]);
      }
    },
    [pubnubStore],
  );

  // componentDidMount
  useEffect(() => {
    return () => {
      closeChannel(channelRef.current?.id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //If the props.channel or props.user changes then close the current channel and set the new channel
  //This triggers the next useEffect to load the channel
  useEffect(() => {
    async function fetchChannel() {
      //close the current channel
      closeChannel(channelRef.current?.id);
      //Channels are either loaded by a known channel.id OR a known user.id
      if (props.channel) {
        // Extract the channelId If channel is a Pubnub Channel Name: 'ch-chat.<channelId>'
        let channelId =
          props.channel.indexOf('.') > 0
            ? props.channel.split('.')[1]
            : props.channel;
        setChannel(await channelStore.getChannelById(channelId));
      } else if (props.user) {
        setChannel(await channelStore.getChannelByUser(props.user));
      }
    }
    fetchChannel();
  }, [props.channel, props.user, channelStore, closeChannel]);

  //When the channel changes we need to load the new channel
  useEffect(() => {
    if (channel) {
      channelRef.current = channel;
      setUserChannelAccess({denied: false});
      setLoading(true);
      pubnubStore.isPartnerTyping = false;
      pubnubStore.subscribeToChannelPresence([
        'ch-chat.' + channel.id + '-pnpres',
      ]);

      let historyObj = {
        channels: [channel?.name],
        count: historyChunk,
      };
      pubnubStore.setActive(channel);
      pubnubStore.getChannelHistory(
        historyObj,
        (msgs, {hasError, hasMoreMessages}) => {
          setLoading(false);
          setIsHistoryEmpty(!hasMoreMessages);
          if (hasError) {
            setUserChannelAccess({
              denied: true,
              message:
                'You do not have access to this conversation. Please contact Pathfinder if this is an error.',
            });
          } else {
            const tt = pubnubStore.messages.length
              ? pubnubStore.messages[0].timetoken
              : getPubnubTimetokenFromTimestamp(1);
            setTimetoken(tt);
            const date = new Date();
            channelStore.updateChannelData(channel, {
              last_viewed: {
                [meStore.me.id]: date,
              },
            });
            scrollStream('down');
            channelStore.clearUnreadChannel(channel);
          }
        },
      );
    }
  }, [channel, channelStore, meStore.me.id, pubnubStore]);

  const createChannel = async () => {
    const params = {
      members: [{id: props.user}],
    };

    try {
      const response = await Api.Instance.current().post(
        '/messaging/channels',
        params,
      );
      setChannel(response.data);
      setUserChannelAccess({denied: false});
      setLoading(false);
    } catch (e) {
      setUserChannelAccess({
        denied: true,
        message:
          'You may not have access to chat with this member. Please reach out to an Admin if you need to get in contact.',
      });
      setLoading(false);
    }
  };

  const loadMoreHistory = (notFound, found) => {
    let historyObj = {
      channels: [channel?.name],
      count: historyChunk,
    };

    if (timetoken) {
      historyObj.start = timetoken;
    }

    pubnubStore.getChannelHistory(historyObj, (msgs, {hasMoreMessages}) => {
      const tt = pubnubStore.messages[0]
        ? pubnubStore.messages[0].timetoken
        : getPubnubTimetokenFromTimestamp(1);
      setTimetoken(tt);
      setIsHistoryEmpty(!hasMoreMessages);
    });
  };

  if (loading) {
    return <LoadingIndicator />;
  }
  if (userChannelAccess.denied) {
    return (
      <Notification style={styles.notification} color="info">
        {userChannelAccess.message}
      </Notification>
    );
  } else if (!channel && !tutorialStore.isActive) {
    return (
      <div style={styles.noChatBox}>
        <Button color="primary" onClick={createChannel}>
          Start Conversation
        </Button>
      </div>
    );
  }
  return (
    <div>
      <Box style={styles.chatBox}>
        <div style={styles.chatFrame}>
          <div ref={chatStream} style={styles.chatStream}>
            {!isHistoryEmpty && (
              <div
                onClick={() => loadMoreHistory()}
                style={styles.moreHistoryButton}>
                view more
              </div>
            )}
            {pubnubStore.messages.map((m, index) => {
              let owner =
                m.message.data.author === meStore.me.id ? 'me' : 'other';
              return (
                <PfMessage message={m} owner={owner} key={'message' + index} />
              );
            })}
            <div style={hiddenIfEmpty(pubnubStore.isPartnerTyping)}>
              <div style={styles.typingIndicator}>Typing...</div>
            </div>
          </div>
        </div>
      </Box>
      <ChatModuleInputs channel={channel} chatMember={chatMember} />
    </div>
  );
};

const styles = {
  noChatBox: {
    height: 440,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chatBox: {
    height: 440,
    position: 'relative',
  },
  chatFrame: {
    height: 400,
    overflowY: 'hidden',
  },
  chatStream: {
    height: 400,
    width: '100%',
    overflowY: 'auto',
    paddingRight: 10,
  },
  notification: {
    color: 'white',
  },
  moreHistoryButton: {
    cursor: 'pointer',
    width: '100%',
    textAlign: 'center',
    color: Palette.LINK,
  },
  typingIndicator: {
    color: Palette.INFO,
    borderRadius: 10,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
};

export default observer(PfChatModule);
