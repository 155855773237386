import React from 'react';
import BulmaBreadcrumbs from 'react-bulma-components/lib/components/breadcrumb';
import {observer} from 'mobx-react';

import {useStores} from 'hooks/useStores';

export const Breadcrumbs = observer(() => {
  const {appUIStore} = useStores();

  if (!appUIStore.breadcrumbs || appUIStore.breadcrumbs.length === 0) {
    return null;
  }

  return (
    <>
      <BulmaBreadcrumbs items={Array.from(appUIStore.breadcrumbs)} />
      <hr />
    </>
  );
});
