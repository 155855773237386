// @flow
import {createContext, useMemo, useState, useContext} from 'react';
import {useLocation} from 'react-router-dom';

type ContextValueType = {
  // All Resources
  searchTerm: string,
  setSearchTerm: (searchTerm: string) => void,
  collectionFilter: Array<string>,
  setCollectionFilter: (Array<string>) => void,
  // Saved Resources
  savedSearchTerm: string,
  setSavedSearchTerm: (searchTerm: string) => void,
  savedCollectionFilter: Array<string>,
  setSavedCollectionFilter: (Array<string>) => void,
};

type TabContextValue = {
  searchTerm: string,
  setSearchTerm: (searchTerm: string) => void,
  collectionFilter: Array<string>,
  setCollectionFilter: (Array<string>) => void,
};

export const ResourcesContext: React$Context<ContextValueType | void> = createContext();

export const ResourcesProvider = ({
  children,
}: {
  children: any,
}): React$Element<any> => {
  // All Resources
  const [searchTerm, setSearchTerm] = useState('');
  const [collectionFilter, setCollectionFilter] = useState([]);

  // Saved Resources
  const [savedSearchTerm, setSavedSearchTerm] = useState('');
  const [savedCollectionFilter, setSavedCollectionFilter] = useState([]);

  const contextValue = useMemo(
    () => ({
      // All Resources
      searchTerm,
      setSearchTerm,
      collectionFilter,
      setCollectionFilter,
      // Saved Resources
      savedSearchTerm,
      setSavedSearchTerm,
      savedCollectionFilter,
      setSavedCollectionFilter,
    }),
    [searchTerm, savedSearchTerm, collectionFilter, savedCollectionFilter],
  );

  return (
    <ResourcesContext.Provider value={contextValue}>
      {children}
    </ResourcesContext.Provider>
  );
};

export const useResourcesContext = (): ContextValueType => {
  const context = useContext(ResourcesContext);
  if (context === undefined) {
    throw new Error(
      'use ResourcesContext must be used within a ResourcesProvider',
    );
  }

  return context;
};

export const useResourceTabContext = (): TabContextValue => {
  const location = useLocation();
  const values = useResourcesContext();

  if (location.pathname.includes('/saved')) {
    return {
      searchTerm: values.savedSearchTerm,
      setSearchTerm: values.setSavedSearchTerm,
      collectionFilter: values.savedCollectionFilter,
      setCollectionFilter: values.setSavedCollectionFilter,
    };
  } else {
    return {
      searchTerm: values.searchTerm,
      setSearchTerm: values.setSearchTerm,
      collectionFilter: values.collectionFilter,
      setCollectionFilter: values.setCollectionFilter,
    };
  }
};
