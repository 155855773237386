import React from 'react';
import type {Node} from 'react';
import {Box, Progress, ProgressTypes} from '@wellstone-solutions/web';

export const LoadingIndicator = ({
  color = 'secondary',
  size = 32,
  stylesOverride = {},
}: {
  color?: 'secondary' | 'primary',
  size: number,
  stylesOverride?: {[string]: any},
}): Node => (
  <Box
    data-testid="loading-indicator"
    sx={[
      {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        marginBottom: 4,
      },
      stylesOverride,
    ]}>
    <Progress color={color} progressType={ProgressTypes.circular} size={size} />
  </Box>
);
