// @flow
import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react';
import type {Node} from 'react';
import Heading from 'react-bulma-components/lib/components/heading';
import {useStores} from 'hooks/useStores';
import PfLoader from 'components/shared/PfLoader';
import {GettingStarted} from './GettingStarted';
import {HabitList} from './HabitList';
import styles from './HabitSchedule.module.scss';

// $FlowFixMe
export const HabitSchedule = observer((): Node => {
  const {memberDetailStore} = useStores();
  const [isStarted, setIsStarted] = useState(
    memberDetailStore.currentMemberHabits.length > 0,
  );

  const onGetStarted = useCallback(() => {
    setIsStarted(true);
  }, [setIsStarted]);

  if (memberDetailStore.isLoadingMemberHabits) {
    return <PfLoader />;
  }

  return (
    <div className={styles.container}>
      <Heading size={4}>Healthy Habits</Heading>
      {isStarted ? (
        <HabitList />
      ) : (
        <GettingStarted onGetStarted={onGetStarted} />
      )}
    </div>
  );
});
