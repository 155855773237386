import React from 'react';
import {inject, observer} from 'mobx-react';
import MultiStepModal from '../MultiStepModal';

import EditMemberForm from '../../forms/member/EditMemberForm';

const CreateModal = (props) => {
  const {member} = props;
  const steps = [<EditMemberForm member={member} />];

  const checkMemberUser = () => {
    const params = ['name', 'gender', 'identifies_as'];
    params.every((p) => {
      return member[p] === member.user[p];
    });
  };

  checkMemberUser();
  return (
    <MultiStepModal
      {...props}
      steps={steps}
      button={{
        outlined: true,
        text: 'Edit',
        id: 'edit-member-button',
      }}
    />
  );
};

export default inject('groupStore')(observer(CreateModal));
