// @flow

export const baseUrl: string = process.env.REACT_APP_API_URL || '';

export const routes = {
  featuresAll: '/stoplight/features/all',
  login: '/auth/login',
  logout: '/auth/logout',
  resetPasswordRequest: '/auth/reset-password',
  resetPassword: (owner: string, token: string): string =>
    `/auth/reset-password/${owner}/${token}`,
  me: '/users/me',
  myResources: '/users/me/resource-questions',
  organization: {
    memberDetail: (orgId: string, id: string): string =>
      `/orgs/${orgId}/members/${id}`,
    members: (orgId: string): string => `/orgs/${orgId}/members`,
    search: (orgId: string): string => `/orgs/${orgId}/search`,
    staff: (orgId: string): string => `/orgs/${orgId}/staff`,
  },
  resources: {
    answers: '/resources/answers',
    categories: '/resources/groups',
    questions: '/resources/questions',
    questionsByCategory: (categoryId: string): string =>
      `/resources/groups/${categoryId}/questions`,
    answersByQuestion: (categoryId: string, questionId: string): string =>
      `/resources/groups/${categoryId}/questions/${questionId}/answers`,
    search: '/resources/search',
  },
  calendar: {
    calendarEvents: '/schedule/calendar/events',
    calendarEventInvitationDetail: (eventId: string, id: string): string =>
      `/schedule/calendar/events/${eventId}/invitations/${id}`,
  },
  validateCode: '/auth/validate-code',
  dashboardAnalytics: '/dashboard/analytics',
  integrations: {
    find: (orgId: string): string =>
      `/orgs/${orgId}/integrations/ehr/members/find`,
    connect: (orgId: string, memberId: string): string =>
      `/orgs/${orgId}/integrations/ehr/members/${memberId}/connect`,
  },
};
