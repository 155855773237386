// @flow
import type {ComponentType} from 'react';
import {styled} from '@mui/material/styles';
import {Theme} from '@wellstone-solutions/common';

export const ChipBlank: ComponentType<{}> = styled('div')(() => ({
  marginTop: 10,
  height: '16px',
  backgroundColor: Theme.colorPalette.mediumLight,
  borderRadius: 15,
}));
