// @flow
import React, {useLayoutEffect} from 'react';
import type {Node} from 'react';
import {MobileStepper as Stepper} from '@mui/material';
import {Box} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import type {FormType} from '@wellstone-solutions/common';
import type {WizardStepType} from './';
import {WizardHeader, WizardStep, WizardFooter, useWizard} from './';

type PropsType = {
  isSubmitting?: boolean,
  form: FormType<any>,
  steps: WizardStepType[],
  title: string,
  onCancel: () => void,
  onWizardSubmit: (any) => Promise<void>,
  submitText?: string,
  stepOverride?: number,
};

export const Wizard = ({
  isSubmitting = false,
  form,
  steps,
  title,
  onCancel,
  onWizardSubmit,
  submitText,
  stepOverride,
}: PropsType): Node => {
  const {
    step,
    stepIndex,
    isLastStep,
    stepComplete,
    setStepIndex,
    checkCompleteness,
  } = useWizard({steps, stepOverride});

  useLayoutEffect(() => {
    // when the wizard state changes, check if the current step is complete
    checkCompleteness(form.values);
  }, [form.values, stepIndex, checkCompleteness]);

  const handleNext = async (): Promise<void> => {
    if (stepComplete) {
      if (isLastStep) {
        await onWizardSubmit(form.values);
        onCancel();
        return;
      }
      setStepIndex(Math.min(steps.length - 1, stepIndex + 1));
    }
  };

  const handleBack = () => {
    setStepIndex(Math.max(stepIndex - 1, 0));
  };

  const wizardTitle = step && step.title ? step.title(form) : title;
  const wizardSubtitle = step && step.subtitle ? step.subtitle(form) : '';

  return (
    <Box sx={styles.wizardContainer} data-testid="wizard">
      <WizardHeader
        title={wizardTitle}
        subtitle={wizardSubtitle}
        isSubmitting={isSubmitting}
        onBack={stepIndex !== 0 ? handleBack : undefined}
        onCancel={onCancel}
      />
      <Box
        sx={{
          maxHeight: '70vh',
          overflowY: 'auto',
        }}>
        <WizardStep
          step={step}
          form={form}
          onNext={handleNext}
          isComplete={stepComplete}
        />
      </Box>
      <WizardFooter
        isComplete={stepComplete}
        isFirstStep={stepIndex === 0}
        isLastStep={stepIndex === steps.length - 1}
        isSubmitting={isSubmitting}
        handleBack={handleBack}
        disabled={step.disableFooter}
        onNext={handleNext}
        onCancel={onCancel}
        nextText={
          isLastStep ? 'Save' : stepIndex === 0 ? 'Verify Email' : 'Next'
        }
      />
      <Stepper
        steps={steps.length}
        variant="dots"
        position="static"
        activeStep={stepIndex}
        sx={{
          '& .MuiMobileStepper-dotActive': {
            backgroundColor: Theme.colorPalette.primary,
          },
          mb: 2,
          justifyContent: 'center',
        }}></Stepper>
    </Box>
  );
};

const styles = {
  wizardContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Theme.colorPalette.lightest,
    padding: '34px',
    borderRadius: '22px',
    overflow: 'hidden',
    minWidth: '200px',
    width: {xs: '98%', sm: '85%', md: '60%', lg: '40%'},
    border: `1px solid ${Theme.colorPalette.dark}`,
  },
};
