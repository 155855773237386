// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import Message from 'react-bulma-components/lib/components/message';
import type {UIStaffType} from '@wellstone-solutions/common';
import {Theme} from '@wellstone-solutions/common';
import {Stack} from '@wellstone-solutions/web';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {useStores} from 'hooks/useStores';
import {CardDataList} from 'components/CardDataList';
import {AddRevokeModal} from './AddRevokeModal';
import {AccessControl} from 'modules/rbac';

const RevocationItemDetail = ({item}: {item: any}): Node => {
  return (
    <Stack direction="column">
      <div style={styles.revItemTitle}>{item.revocation.patient.name}</div>
      <div style={styles.revItemNotes}>{item.revocation.notes}</div>
    </Stack>
  );
};

type PropTypes = {
  staff: UIStaffType,
};

export const Revocations = ({staff}: PropTypes): Node => {
  const {meStore, staffStore, RBACStore} = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const revocations = staff.revocations;

  const cardItems = revocations.map((revocation) => ({
    revocation,
    preventDelete: false,
  }));

  const removeRevocation = async (item: any): Promise<void> => {
    const response = await staffStore.removeRevocation({
      staffId: staff.id,
      revocationId: item.revocation.id,
    });

    if (!response.isSuccess) {
      showAlert('Unable to unrevoke this member', ALERT_TYPES.ERROR);
    }
  };

  if (
    !RBACStore.hasAccess(AccessControl.staff.editRevocations) ||
    meStore.me.id === staff.user.id
  ) {
    return (
      <Message>
        <Message.Header>Revocations</Message.Header>
        <Message.Body>
          {meStore.me.id === staff.user.id
            ? 'You may not edit your own Revocations.'
            : 'Revocations can only be applied by a Super Admin.'}
        </Message.Body>
      </Message>
    );
  }

  return (
    <div style={styles.cardContainer} data-testid="revocation-card">
      <CardDataList
        title="Revocations"
        ItemDetailsComponent={RevocationItemDetail}
        data={cardItems}
        removeListItem={removeRevocation}
        emptyListText="Staff member does not have any revoked members."
        footerProps={{
          actionText: 'Add member to list',
          onActionClick: () => setShowModal(true),
        }}
        headerProps={{backgroundColor: Theme.colorPalette.red}}
      />
      <AddRevokeModal
        open={showModal}
        setShowModal={setShowModal}
        staff={staff}
      />
    </div>
  );
};

const styles = {
  cardContainer: {
    marginBottom: '30px',
  },
  revItemTitle: {
    fontWeight: 'bold',
  },
  revItemNotes: {
    fontWeight: '300',
    fontSize: '0.8rem',
    color: Theme.colorPalette.dark,
  },
};
