import React, {Fragment} from 'react';
import Button from '../button';
import {Animated} from 'react-animated-css';
import {Palette} from '../../../palette';
import PfHorizLogo from '../../../assets/images/PathfinderBridge_RGB.svg';

export default (props) => {
  return (
    <Fragment>
      <div style={styles.wrapper}>
        <Animated
          animationIn="fadeInDown"
          animationInDuration={500}
          animationInDelay={50}
          animateOnMount={true}>
          <h2 style={styles.h1}>Welcome to</h2>
        </Animated>
        <Animated
          animationIn="fadeInUp"
          animationInDuration={500}
          animationInDelay={50}
          animateOnMount={true}>
          <img alt="Pathfinder Bridge" style={styles.logo} src={PfHorizLogo} />
        </Animated>
        <Animated
          animationIn="fadeIn"
          animationInDuration={500}
          animationInDelay={1500}
          animateOnMount={true}>
          <p style={styles.body}>
            A digital platform supporting those in substance use disorder
            recovery, and enabling organizations to maintain real-time contact
            with clients, improving recovery outcomes with data driven
            decisions.
          </p>
        </Animated>
        <Animated
          animationIn="fadeIn"
          animationInDuration={500}
          animationInDelay={1500}
          animateOnMount={true}>
          <Button id="nextButton" data-testid="get-started-button">
            Get started
          </Button>
        </Animated>
      </div>
      <Button
        id="tutorialSkipButton"
        className={'tutorialSkipButton'}
        data-testid="skip-tutorial-button">
        Skip Tutorial
      </Button>
    </Fragment>
  );
};

const styles = {
  logo: {
    width: '20vw',
    height: '7.5vw',
    color: Palette.PRIMARY,
  },
  h1: {
    fontSize: 40,
    fontWeight: '700',
    color: '#ffffff',
  },
  body: {
    marginBottom: 20,
    fontWeight: 500,
    fontSize: 18,
  },
  wrapper: {
    width: '30vw',
    display: 'flex',
    height: 'calc(100% - 56px)',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#d6d6d6',
    margin: '0 auto',
    textAlign: 'center',
  },
};
