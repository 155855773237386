// @flow

import React from 'react';
import type {Node} from 'react';
import {Stack, Typography} from '@wellstone-solutions/web';
import {Models, Theme} from '@wellstone-solutions/common';
import type {
  UICalendarEventType,
  UICalendarEventInvitationType,
} from '@wellstone-solutions/common';
import {AreYouAttending} from '../AreYouAttending';
import {Participants} from '../Participants';
import {EventContent} from './EventContent';

type PropsType = {
  calendarEvent: UICalendarEventType,
  invitations: Array<UICalendarEventInvitationType>,
};

const {CalendarEvent} = Models;

export const View = ({calendarEvent, invitations}: PropsType): Node => {
  const isEventCanceled = CalendarEvent.isCanceled(calendarEvent);

  return (
    <Stack>
      <Typography
        variant="h4"
        sx={{color: Theme.colorPalette.dark, fontWeight: 300, mb: 2}}>
        {calendarEvent.title}
      </Typography>
      <EventContent calendarEvent={calendarEvent} />
      {isEventCanceled && (
        <Typography component="em">Event was canceled</Typography>
      )}
      {!isEventCanceled && (
        <Stack>
          <Typography sx={{color: Theme.colorPalette.blue, mb: 1}}>
            Guests
          </Typography>
          <Participants
            calendarEvent={calendarEvent}
            invitations={invitations}
            expandable={true}
          />
          <AreYouAttending
            calendarEvent={calendarEvent}
            stackProps={{direction: 'column', spacing: 1}}
          />
        </Stack>
      )}
    </Stack>
  );
};
