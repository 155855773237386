import React from 'react';
import MultiStepModal from '../MultiStepModal';
import EditGroupForm from '../../forms/group/EditGroupForm';

const EditModal = ({onUpdate = () => null, ...rest}) => {
  const steps = [<EditGroupForm group={rest.group} onUpdate={onUpdate} />];
  return (
    <MultiStepModal
      {...rest}
      steps={steps}
      button={{text: 'Edit Group', outlined: true}}
    />
  );
};

export default EditModal;
