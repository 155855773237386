import React from 'react';
import type {Node} from 'react';
import {Box, Card, CardContent, Divider, Stack} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {Skeleton} from '@mui/material';

export const AnalyticsCardSkeleton = (): Node => (
  <Card sx={styles.card} data-testid="mui-skeleton-overlay">
    <CardContent sx={styles.content}>
      <Stack direction="column">
        <Box sx={styles.box}>
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{marginLeft: 'auto', marginRight: 'auto'}}
          />
        </Box>
        <Divider sx={styles.divider} />
        <Skeleton variant="text" sx={{fontSize: '1rem'}} />
        <Skeleton variant="text" sx={{fontSize: '0.5rem'}} />
      </Stack>
    </CardContent>
  </Card>
);

const styles = {
  box: {
    flexGrow: 1,
    textAlign: 'center',
  },
  card: {
    height: '100%',
  },
  content: {
    p: Theme.spacing.mini,
    '&:lastChild': {
      pb: Theme.spacing.mini,
    },
  },
  divider: {
    mx: -Theme.spacing.mini,
    my: Theme.spacing.micro,
    height: '1px',
    borderColor: Theme.colorPalette.mediumLight,
  },
};
