// @flow
import {Checkin} from '@wellstone-solutions/common/models/rest';
import {useEffect, useState} from 'react';
import type {ApiResponseType} from '@wellstone-solutions/common';
import type {UITagType} from '@wellstone-solutions/common/models/rest';

const getUITags = (): Promise<ApiResponseType<UITagType[]>> =>
  Checkin.getTags().then((response) => {
    return {
      ...response,
      data: response.isSuccess ? response.data.map(Checkin.toUITag) : [],
    };
  });

export const useTags = (): Array<UITagType> => {
  const [tags, setTags] = useState<Array<UITagType>>([]);
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  useEffect(() => {
    if (!hasFetched) {  // Only fetch once
      getUITags()
        .then((response) => setTags(response.data))
        .catch((err) => {
          console.warn(err.message);
        })
        .finally(() => {
          setHasFetched(true);  // Mark as fetched, even if failed
        });
    }
  }, [hasFetched]);

  return tags;
};
