// @flow
import React from 'react';
import type {Node} from 'react';
import {Box, Typography} from '@wellstone-solutions/web';

export const MaintenanceMode = (): Node => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
      }}>
      <Typography
        variant="h1"
        sx={{
          fontSize: 24,
          textAlign: 'center',
        }}>
        We're working hard on some updates. Bridge is not currently available,
        please check back soon.
      </Typography>
    </Box>
  );
};
