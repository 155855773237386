// React
import React from 'react';

// Third party
import {
  CartesianGrid,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// WS
import PfLoader from 'components/shared/PfLoader';

export class PfLineChart extends React.Component {
  state = {
    ready: false,
  };

  componentDidMount() {
    this.setLoaded();
  }

  setLoaded() {
    this.setState({ready: true});
  }

  render() {
    if (!this.state.ready) {
      return <PfLoader />;
    }
    return (
      <ResponsiveContainer width="85%" height={400}>
        <LineChart
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {this.props.selectedValues.map((line, l) => {
            return (
              <Line
                key={l}
                type="monotone"
                dataKey={line.key}
                stroke={line.color}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
