// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import moment from 'moment';
import Box from 'react-bulma-components/lib/components/box';
import Modal from 'react-bulma-components/lib/components/modal';
import Heading from 'react-bulma-components/lib/components/heading';
import {MarkdownViewer, Icon, IconNames} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {PfButton} from 'components/shared/PfButton';

import styles from './PastAnnouncement.module.scss';
import type {UIAnnouncementType} from '../../types';
import {AnnouncementContentWrapper} from '../AnnouncementContentWrapper';

type PropsType = {
  announcement: UIAnnouncementType,
  currentUserId: string,
  onRemove: (id: string) => null,
};

export const PastAnnouncement = (props: PropsType): Node => {
  const [isModal, setIsModal] = useState(false);
  const {announcement, currentUserId, onRemove} = props;
  const canRemove = currentUserId === announcement.createdBy.id;
  const publishedOn = moment(announcement.publishedOn);

  const title =
    !!announcement.title && announcement.title.length > 0
      ? announcement.title
      : '';

  const author =
    !!announcement.author && announcement.author.length > 0
      ? announcement.author
      : announcement.createdBy.name;

  const hideModal = () => setIsModal(false);

  const handleRemove = () => {
    onRemove(announcement.id);
    hideModal();
  };

  return (
    <>
      <Modal
        onClose={hideModal}
        show={isModal}
        closeOnBlur={true}
        closeOnEsc={true}>
        <Modal.Content className={styles.modal}>
          <Heading size={4}>Remove Announcement?</Heading>
          <div className={styles.modalPreview}>
            {title.length > 0 && (
              <div>
                <h1 className={styles.title}>{title}</h1>
              </div>
            )}
            <MarkdownViewer value={announcement.content} />
          </div>
          <div className={styles.modalButtonContainer}>
            <PfButton onClick={handleRemove}>Yes, remove it</PfButton>
            <PfButton color="light" onClick={hideModal}>
              No, keep it
            </PfButton>
          </div>
        </Modal.Content>
      </Modal>
      <Box className={styles.container}>
        <div className={styles.announcementHeading}>
          <div className={styles.dateContainer}>
            <div className={styles.date}>{publishedOn.format('MMM D')}</div>
            <div className={styles.time}>{publishedOn.format('hA')}</div>
          </div>
          <div className={styles.removeContainer}>
            {canRemove ? (
              <button
                className={styles.button}
                data-testid="remove-past-announcement-button"
                onClick={() => setIsModal(true)}>
                <Icon name={IconNames.Minus} size={26} color="#fff" />
              </button>
            ) : null}
          </div>
        </div>
        <div className={styles.content}>
          {title.length > 0 && (
            <div className={styles.titleContainer}>
              <h1 style={styling.title}>{title}</h1>
            </div>
          )}
          <div className={styles.markdownContainer}>
            <AnnouncementContentWrapper>
              <MarkdownViewer value={announcement.content} />
            </AnnouncementContentWrapper>
          </div>
        </div>
        <div style={styling.author}>By {author}</div>
      </Box>
    </>
  );
};

const styling = {
  title: {
    marginTop: '10px',
    marginBottom: '5px',
    color: Theme.colorPalette.primary,
    fontSize: '20px',
    fontWeight: '700',
    borderBottom: `1px solid ${Theme.colorPalette.primary}`,
  },
  author: {
    fontStyle: 'italic',
    color: Theme.colorPalette.onSurface,
    fontSize: Theme.typography.size.s1,
    width: '100%',
    textAlign: 'right',
    padding: '0 32px 0 12px',
  },
};
