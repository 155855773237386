// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {Models} from '@wellstone-solutions/common';
import {Box, Stack} from '@wellstone-solutions/web';
import {PagedDataGrid} from 'modules/datagrid';
import {useStores} from 'hooks/useStores';
import {useMemberColumnMap} from '../../hooks';
import {ListHeader} from './ListHeader';
import {ALL_MEMBERS} from '../../constants';
import {ROLES} from 'utils/Permissions';
import type {MemberFilterType} from './types';

type ParamsType = {
  role: string,
  program_id?: string,
  memberName?: string,
  lastLogin?: string,
  group?: string,
  endDate?: string,
  pavillioId?: string,
};

const {Member} = Models;

const DEFAULT_PARAMS: MemberFilterType = {
  role: ROLES.patient,
};

const buildParams = (filter: string): ParamsType => {
  return filter !== ALL_MEMBERS
    ? {...DEFAULT_PARAMS, program_id: filter}
    : DEFAULT_PARAMS;
};

export const MemberListV2 = (): Node => {
  const {meStore} = useStores();
  const initialFilter = meStore.myPrograms[0]?.id || ALL_MEMBERS;
  const [filter, setFilter] = useState(initialFilter);
  const [params, setParams] = useState(buildParams(initialFilter));
  const navigate = useNavigate();
  const columnMap = useMemberColumnMap({
    programId: filter,
  });

  const columns = [
    columnMap.nameLinkGender,
    columnMap.lastOpened,
    columnMap.groupsList,
    columnMap.programEndDate,
  ];

  if (!!meStore.ehrIntegrationOption) {
    columns.push(columnMap.ehr);
  }
  columns.push(columnMap.actionsList);

  // Used when program select changes
  const handleFilterChange = (updatedFilter) => {
    const updatedParams = buildParams(updatedFilter);
    setParams(updatedParams);
    setFilter(updatedFilter);
  };

  const handleRowClick = (data) => {
    const memberId = data?.row?.id;
    if (memberId) {
      navigate(`/members/${memberId}`);
    }
  };

  return (
    <Stack>
      <Box sx={{mb: 2}}>
        <ListHeader filter={filter} setFilter={handleFilterChange} />
      </Box>
      <Box>
        <PagedDataGrid
          label="Member"
          columns={columns}
          params={params}
          dataTransformer={(data) => ({
            rows: data.members.map(Member.toUI),
            total: data.total,
          })}
          initialPageSize={25}
          initialSortField={columns[0].field}
          initialSortOrder="desc"
          isLimitOffset={true}
          url={Member.routes.index(meStore.me.membership.organization.id)}
          disableSelectionOnClick
          onRowClick={handleRowClick}
        />
      </Box>
    </Stack>
  );
};
