// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {Box} from '@wellstone-solutions/web';
import {imageGCBucketUrl, iconNameToImageUrl} from 'utils/images';
import {useImage} from 'hooks/useImage';

type PropsType = {
  name: string,
  width?: number,
  height?: number,
};

export const CategoryIcon = ({
  name,
  width = 42,
  height = 42,
}: PropsType): Node => {
  const baseUrl = imageGCBucketUrl('/resources/icons');
  const defaultIcon = `${baseUrl}/default.png`;
  const categoryIcon = iconNameToImageUrl(name, {
    baseUrl,
    extension: 'svg',
  });

  const [src, setSrc] = useState(null);
  const {loaded, error} = useImage(categoryIcon);

  useEffect(() => {
    if (loaded) {
      setSrc(categoryIcon);
    } else if (error) {
      setSrc(defaultIcon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, error]);

  return (
    <Box
      width={width}
      height={height}
      sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      {!!src && <img alt={name} src={src} width={width} height={height} />}
    </Box>
  );
};
