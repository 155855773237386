// @flow
import React from 'react';
import type {Node} from 'react';
import Heading from 'react-bulma-components/lib/components/heading';
import {MarkdownViewer} from '@wellstone-solutions/web';
import type {AnnouncementPreviewType} from '../../types';
import styles from './Preview.module.scss';
import {AnnouncementContentWrapper} from '../AnnouncementContentWrapper';

type PropsType = {
  fields: AnnouncementPreviewType,
};

export const Preview = ({fields}: PropsType): Node => (
  <div className={styles.container}>
    <Heading size={5} className={styles.heading}>
      Preview
    </Heading>
    <div className={styles.title}>{fields.title}</div>
    <div className={styles.markdown}>
      <AnnouncementContentWrapper>
        <MarkdownViewer value={fields.content} />
        <div className={styles.createdBy}>
          by
          <strong> {fields.author}</strong>
        </div>
      </AnnouncementContentWrapper>
    </div>
  </div>
);
