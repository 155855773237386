import {Roles} from '@wellstone-solutions/common';

export const ROLES = {
  counselor: Roles.COUNSELOR,
  superAdmin: Roles.SUPER_ADMIN,
  admin: Roles.ADMIN,
  patient: Roles.PATIENT,
  peer: Roles.PEER,
};

export const ROLES_NAMES_MAP = {
  [Roles.COUNSELOR]: 'Counselor',
  [Roles.SUPER_ADMIN]: 'Super Admin',
  [Roles.ADMIN]: 'Admin',
  [Roles.PATIENT]: 'Patient',
  [Roles.PEER]: 'Peer',
};

export const invalidCounselor = (
  type,
  group,
  authorizations,
  revocations,
  member,
) => {
  let access = false;

  // check if authorized for a GROUP, with that TYPE of permission
  authorizations.find((auth) => {
    if (auth.obj_id === group) {
      access = auth.permissions.indexOf(type) > -1;
      return true;
    }
    return false;
  });

  if (member) {
    // check if member has been specifically revoked
    access = revocations.find((revocation) => {
      return revocation.patient.user.id === member;
    });
    // set as false to represent access = false
    access = !access;
  }

  return !access;
};
