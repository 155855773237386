// @flow
import {useCallback, useState, useEffect} from 'react';
import {useStores} from 'hooks/useStores';
import type {UIResourceCategoryType} from '@wellstone-solutions/common';

export const useDataLoader = ({
  initialFetch = true,
  hasFiltersApplied = false,
}: {
  initialFetch?: boolean,
  hasFiltersApplied?: boolean,
} = {}): ({
  isLoading: boolean,
  data?: Array<UIResourceCategoryType>,
  fetchData: () => Promise<void>,
}) => {
  const {meStore, resourceStore} = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState(undefined);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const fetchedCategories = await resourceStore.getCategories({
      states: [meStore.me.membership.organization.state],
    });
    setCategories(fetchedCategories);
    setIsLoading(false);
  }, [meStore, resourceStore]);

  useEffect(() => {
    // skip using the loader when filters are applied
    if (hasFiltersApplied) {
      setIsLoading(false);
      return;
    }
    if (initialFetch) {
      fetchData();
    }
  }, [fetchData, initialFetch, hasFiltersApplied]);

  return {isLoading: isLoading, data: categories, fetchData};
};
