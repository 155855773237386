// @flow
export const INTEGRATION_STATUSES = {
  READY: 'ready',
  SEARCHING: 'searching',
  CONNECTED: 'connected',
  ERROR: 'error',
  CONFIRM: 'confirm',
  TIP: 'tip',
};

export const EHR_INPUT_EXAMPLES = {
  Pavillio: {
    member: 'For example: CLI123456',
    staff: 'For example EMP123456',
  },
  Kipu: {
    member: 'For example: 123',
    staff: 'For example: staff.name@example.com',
  },
};
