// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {
  Button,
  Icon,
  IconNames,
  Menu,
  MenuItem,
  Typography,
} from '@wellstone-solutions/web';
import {Events, Theme} from '@wellstone-solutions/common';
import {showAlert} from 'utils/showAlert';
import {hasFlag, REVERT_DOCUMENT} from 'constants/FeatureFlags';
import {useStores} from 'hooks/useStores';
import type {UISessionDocumentType} from 'modules/documentation/model/types';
import {DOCUMENT_STATUSES} from '../../../constants';
import {ConfirmSubmit} from './ConfirmSubmit';
import {ConfirmRevert} from '../ConfirmRevert';

type PropsType = {
  documentation: UISessionDocumentType,
  loadDocumentation: (documentation: UISessionDocumentType) => void,
};

export const ActionButton = ({
  documentation,
  loadDocumentation,
}: PropsType): Node => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmRevert, setOpenConfirmRevert] = useState(false);
  const {meStore, sessionDocumentStore, eventStore} = useStores();

  const isOwner = meStore.activeMembership.id === documentation.staff.id;

  const canRevert = hasFlag(REVERT_DOCUMENT, meStore.features);

  const open = Boolean(anchorEl);

  const hasIntegrationRelationship =
    documentation.integrationRelationship &&
    Object.keys(documentation.integrationRelationship).length > 0;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePDFDownload = () => {
    sessionDocumentStore.downloadPDF(documentation.id);
    eventStore.addEvent(Events.DOWNLOADED_SESSION_DOC_PDF, {
      session_document_id: documentation.id,
    });
    handleClose();
  };

  const onConfirm = async (submittedAt: string) => {
    const response = await sessionDocumentStore.updateSessionDoc(
      documentation.id,
      {
        ...documentation,
        status: DOCUMENT_STATUSES.submitted,
        submittedAt,
      },
    );

    if (response.isSuccess) {
      eventStore.addEvent(Events.SUBMITTED_SESSION_DOC, {
        session_document_id: documentation.id,
      });
      showAlert('Document marked as submitted.');
      // reload to get updates
      loadDocumentation(response.data);
    } else {
      showAlert('Error marking document as submitted.', 'error');
    }

    return response;
  };

  const onRevert = async () => {
    const response = await sessionDocumentStore.updateSessionDocStatus(
      documentation.id,
      documentation,
      DOCUMENT_STATUSES.draft,
    );

    if (response.isSuccess) {
      eventStore.addEvent(Events.REVERTED_SESSION_DOC, {
        document_id: response.data.id,
        owner_id: response.data.staff.id,
        admin_id: meStore.me.id,
      });

      showAlert('Document reverted to draft.');
      // reload to get updates
      loadDocumentation(response.data);
    } else {
      showAlert('Error reverting document.', 'error');
    }
  };

  return (
    <>
      <ConfirmSubmit
        open={openConfirm}
        setOpen={setOpenConfirm}
        documentation={documentation}
        onConfirm={onConfirm}
      />
      <ConfirmRevert
        open={openConfirmRevert}
        setOpen={setOpenConfirmRevert}
        onConfirm={onRevert}
      />
      {/* Non-EHR: Button menu with options to submit, revert, and download */}
      {!hasIntegrationRelationship && (
        <Button
          style={styles.button}
          onClick={handleClick}
          data-testid={'documentation-view-actions'}>
          <Icon
            name={IconNames.MoreVert}
            color={Theme.colorPalette.light}
            size={32}
            style={styles.icon}
          />
        </Button>
      )}
      {/* EHR: Only allow PDF download */}
      {hasIntegrationRelationship && (
        <Button
          variant="contained"
          style={styles.buttonDownload}
          onClick={handlePDFDownload}>
          <>
            <Typography sx={{px: 1, py: 0.5}}>Download PDF</Typography>
            <Icon
              name={IconNames.Download}
              color={Theme.colorPalette.lightest}
              size={20}
              style={styles.icon}
            />
          </>
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        <MenuItem onClick={handlePDFDownload}>Download PDF</MenuItem>
        <MenuItem
          disabled={
            !isOwner || documentation.status !== DOCUMENT_STATUSES.completed
          }
          onClick={() => {
            handleClose(); // prevents Menu modal from competing with dialog for focus
            setOpenConfirm(true);
          }}>
          Tag as Submitted
        </MenuItem>
        <MenuItem
          disabled={!canRevert}
          onClick={() => {
            handleClose(); // prevents Menu modal from competing with dialog for focus
            setOpenConfirmRevert(true);
          }}>
          Revert Document
        </MenuItem>
      </Menu>
    </>
  );
};

const styles = {
  button: {
    backgroundColor: Theme.colorPalette.primary,
    borderRadius: '50%',
    padding: 0,
    height: 60,
  },
  buttonDownload: {
    height: '100%',
    borderRadius: 20,
    textTransform: 'none',
    color: Theme.colorPalette.lightest,
  },
  icon: {strokeWidth: '2px'},
  pdfAnchor: {
    textDoration: 'none',
    color: 'inherit',
  },
};
