// @flow
import React from 'react';
import type {Node} from 'react';
import BDropdown from 'react-bulma-components/lib/components/dropdown';

import {Stack, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import Dropdown from '../../../../components/tutorial/dropdown';
import CreateGroupModal from '../../../../components/modal/group/CreateModal';
import {AccessControl, ENTITY_LEVEL} from 'modules/rbac';

type PropsType = {
  filter: string,
  setFilter: (string) => void,
  refetch: () => void,
};

export const ListHeader = ({filter, setFilter, refetch}: PropsType): Node => {
  const {organizationStore, RBACStore} = useStores();

  const allGroupsLabel = 'All Available Groups';
  const filters = !RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all)
    ? [
        {
          name: allGroupsLabel,
          value: 'All',
        },
        {
          name: 'My Groups',
          value: 'My',
        },
      ]
    : [
        {
          name: allGroupsLabel,
          value: 'All',
        },
      ];

  return (
    <Stack
      direction={{xs: 'column', sm: 'row'}}
      justifyContent="space-between"
      spacing={{xs: 1, sm: 2}}>
      <Dropdown
        value={filter}
        onChange={(updatedFilter) => {
          setFilter(updatedFilter);
        }}>
        {filters.map(({name, value}) => {
          return (
            <BDropdown.Item key={name} value={value}>
              {name}
            </BDropdown.Item>
          );
        })}
        {organizationStore.groupCategories.map(({name, id}) => (
          <BDropdown.Item key={id} value={id}>
            {name}
          </BDropdown.Item>
        ))}
      </Dropdown>
      {RBACStore.hasAccess(AccessControl.groups.createGroup) &&
      organizationStore.groupCategories?.length > 0 ? (
        <CreateGroupModal onCreate={refetch} />
      ) : (
        <Typography
          variant="body2"
          align="center"
          sx={{width: '200px', color: Theme.colorPalette.red}}>
          Contact a Pathfinder Admin to Add Groups
        </Typography>
      )}
    </Stack>
  );
};
