export const V2 = {
  subtle4: '#777777',
  subtle3: '#B2B2B2',
  subtle2: '#EEEEEE',
  subtle1: '#F6F6F6',
  subtle0: '#FBFBFB',
  shade5: '#281636',
  shade4: '#36665C',
  shade3: '#529A8A',
  shade2: '#B6E6DB',
  shade1: '#DAF2ED',
  shade0: '#ffffff',
  tap0: '#512D6D',
  tap1: '#6CACE4',
  tap1Dark: '#5181AB',
  excite0: '#6DCDB8',
  excite1: '#F7EA48',
  errorText: '#fff',
  errorBackground: '#ff3c00',
  errorBackground2: '#4d140c',
  warningBackground: '#f68e07',
  correct: '#43b017',
  correct2: '#1f4410',
  geo: '#07a5fa',
  eyeBrow: '#6CACE4',
  badgeBG: '#ffffff88',
};

export const Palette = {
  PRIMARY: V2.tap0,
  INFO: V2.shade3,
  LIGHT_INFO: V2.shade1,
  MEDIUM_INFO: V2.shade4,
  DARK_INFO: V2.shade5,
  LINK: V2.tap1,
  ACTIVE_LINK: V2.tap1Dark,
  WARNING: V2.warningBackground,
  DANGER: V2.errorBackground,
  LIGHT_DANGER: V2.errorBackground2,
  SUCCESS: V2.correct,
  LIGHT_SUCCESS: V2.correct2,
  GREY_1: V2.subtle1,
  GREY_2: V2.subtle2,
  GREY_3: V2.subtle3,
  GREY_4: V2.subtle4,
  EXCITE_0: V2.excite0,

  // EVENTS
  // WARNING
  MARKED_MESSAGE: '#f1da93',
  MESSAGE_MARKED: '#d7af24',
  REPORT_ABUSE: '#f3ac86',
  ABUSE_REPORTED: '#d78d19',
  RELAPSE: '#AF2900',
  // SHARE
  MESSAGE: '#0a8688',
  SHARED_RESOURCE: '#aa22c6', // TODO: deprecate
  SHARED_RESOURCE_CHAT: '#c79dec',
  SHARED_RESOURCE_RESOURCES: '#e99ae7',
  SHARED_RESOURCE_SAVED: '#7b1491',
  SHARED_MEETING: '#5e14d7',
  // ACTIVITY
  RESOURCE_SAVED: '#a221be',
  MEETING_SAVED: '#6c11ff',
  RELATIONSHIP: '#2693ef',
  CHECKIN: '#0c9b51',
  // STAFF
  ADDED_ADMIN: '#778992',
  EDITED_USER: '#587781',
  ADDED_GROUP: '#386670',
  EDITED_GROUP: '#35474d',
  REMOVED_MEMBER: '#030e11',
  // PROFILE
  VIEW_ANNOUNCEMENT: '#003dd6',
  VIEW_CHART_INFO: '#fa7e47',
  VIEW_CHART_TIME: '#ec7f95',
  VIEW_CHART_DATE: '#ecd17f',
  VIEW_CHART_DAY: '#eca07f',
  VIEW_CHECKIN: '#b53601',
  VIEW_MY_MEETINGS: '#2610a7',
  VIEW_ADD_MEETINGS: '#605a96',
  VIEW_SAVED_RESOURCES: '#7a468c',
  VIEW_SETTINGS: '#cfcfcf',
  // STORIES
  VIEW_STORIES: '#e1e78b',
  VIEW_STORY: '#6c730a',
  // COMMUNITY
  VIEW_CONVERSATIONS: '#a0e0e0',
  VIEW_CHAT: '#03a0a0',
  VIEW_CONTACTS: '#1cd6d6',
  VIEW_DIRECTORY: '#79f5f5',
  VIEW_CHANNEL_DETAIL: '#c7dddd',
  // RESOURCES
  VIEW_RESOURCES: '#8603a2',
  // BADGES
  VIEW_BADGES: '#a1c463',
  VIEW_BADGE_DETAIL: '#688f1b',
  // ENGAGEMENT
  OPEN_APP: '#d9d9d9',
  CREATE_MEETING: '#4106a5',
  UPDATE_MEETING: '#c7a8f1',
  HID_CHANNEL: '#b36b51',
  SAVED_MEETING_FROM_CHAT: '#6c11ff',
  SAVED_RESOURCE_FROM_CHAT: '#7929ab',
  SAVED_RESOURCE_FROM_RESOURCES: '#a729ab',
  // BADGE
  BADGES: '#6fc100',
  BADGE_PROGRESS: '#648146',

  // BASIC
  BAD: '#e4292a',
  GOOD: '#4cdc98',
  SO_SO: '#888',

  // PHYSICAL
  BODY_ACHE: '#f47e71',
  CHRONIC_PAIN: '#a61109',
  CRAMPING: '#bd5570',
  HEADACHE: '#bf1430',
  NAUSEOUS: '#94b440',
  SLEEPLESSNESS: '#d6c966',
  TIRED: '#0f5164',

  // MENTAL
  ANGRY: '#e4292a',
  ANXIOUS: '#e4a626',
  BORED: '#bda09c',
  HAPPY: '#4cdc98',
  LONELY: '#4a2722',
  PROUD: '#7759dc',
  SCARED: '#000000',
  THANKFUL: '#4cdc98',
  DEPRESSED: '#558aa1',
};
