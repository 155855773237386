// @flow
import React, {useState} from 'react';
import type {Node} from 'react';

import {Theme} from '@wellstone-solutions/common';
import {Chip, TextField, Typography} from '@wellstone-solutions/web';

import {Autocomplete} from '@mui/material';

type PropsType = {
  tags?: Array<string>,
  updateValues: (tags: Array<String>) => void,
  maxTags?: number,
  disabled?: boolean,
  setIsTyping?: (value: boolean) => void,
};

export const TagSelector = ({
  tags,
  updateValues,
  maxTags = 3,
  disabled = false,
  setIsTyping,
}: PropsType): Node => {
  const [tagsError, setTagsError] = useState();

  const onTagsChange = (event, tagsArray) => {
    setIsTyping?.(false);
    // don't add more than 3 tags
    if (tagsArray.length <= 3) {
      updateValues(tagsArray);
      setTagsError(null);
    }
  };

  const onTagsTyping = (e) => {
    if ((tags || []).length >= maxTags) {
      setTagsError(`You may only enter up to ${maxTags} tags.`);
    } else {
      setIsTyping?.(Boolean(e.target.value));
    }
  };

  return (
    <>
      <Autocomplete
        multiple
        id="tags"
        options={[]}
        onChange={onTagsChange}
        onInputChange={onTagsTyping}
        value={tags}
        disabled={disabled}
        freeSolo
        renderTags={(tagsArray: string[], getTagProps) =>
          tagsArray.map((option: string, index: number) => (
            <Chip
              variant="filled"
              label={option}
              color="primary"
              {...getTagProps({index})}
            />
          ))
        }
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
      {tagsError && (
        <Typography variant="body2" style={styles.errorMessage}>
          {tagsError}
        </Typography>
      )}
    </>
  );
};

const styles = {
  errorMessage: {
    color: Theme.colorPalette.negative,
  },
  sectionTitle: {
    color: 'black',
    marginBottom: '4px',
  },
};
