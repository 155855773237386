// @flow
import React from 'react';
import type {Node} from 'react';
import {
  Button,
  Stack,
  Typography,
  Icon,
  IconNames,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {MarkdownViewer} from '@wellstone-solutions/web';

type PropsType = {
  title: string,
  subtitle?: string,
  isSubmitting?: boolean,
  onBack?: () => void,
  onCancel?: () => void,
};

export const WizardHeader = ({
  title,
  subtitle,
  isSubmitting,
  onBack,
  onCancel,
}: PropsType): Node => {
  return (
    <header data-testid="wizardHeader" style={styles.wizardHeader}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h5"
          sx={{
            color: Theme.colorPalette.darkest,
            strong: {
              color: Theme.colorPalette.darkest,
            },
            fontWeight: 'bold',
          }}>
          {title}
        </Typography>
        <Button onClick={onCancel} disabled={isSubmitting}>
          <Icon
            name={IconNames.Xmark}
            color={Theme.colorPalette.onSurface}
            size={24}
          />
        </Button>
      </Stack>
      {subtitle && <MarkdownViewer value={subtitle} />}
    </header>
  );
};

const styles = {
  wizardHeader: {
    paddingBottom: 22,
  },
  backButton: {
    fontWeight: 'normal',
    height: '32px',
  },
  spaceRight: {
    marginRight: '4px',
  },
};
