import React, {useRef, useEffect, useState} from 'react';
import RootStore from '../../../mobx/RootStore';

/**
 * A core Link component that can be used within the tutorial to move
 * to the next tutorial step.
 */
export default (props) => {
  const {id, onClick = null} = props;
  const element = useRef(null);

  const [zIndex, setZIndex] = useState(0);
  const [isTutorialComponent, setIsTutorialComponent] = useState(false);
  const [extraStyles, setExtraStyles] = useState({});

  const rootStore = RootStore.getInstance();
  const {tutorialStore} = rootStore.stores;

  useEffect(() => {
    if (!element || !tutorialStore || !id) {
      return;
    }

    if (tutorialStore.activeComponents.indexOf(id) !== -1) {
      setIsTutorialComponent(true);
      setZIndex(element.current.style.zIndex);
      setExtraStyles({
        zIndex: 501,
        background: '#FFF',
        boxShadow: '0 0 10px 10px rgba(255, 255, 255, 1)',
      });
    } else {
      setExtraStyles({});
    }

    return () => setExtraStyles({zIndex: zIndex});
  }, [id, tutorialStore, tutorialStore.currentStep, zIndex]);

  return (
    <a
      {...props}
      ref={element}
      style={extraStyles}
      onClick={(event) => {
        if (isTutorialComponent) {
          tutorialStore.nextStep();
        }
        if (typeof onClick === 'function') {
          onClick(event);
        }
      }}>
      {props.children}
    </a>
  );
};
