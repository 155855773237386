// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {Select} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {sortBy} from 'utils/Utils';
import {MEMBERSHIPS, ALL_ITEMS} from './constants';

type PropsType = {
  hideAllOption?: boolean,
  type: 'group' | 'program',
  value?: string,
  onChange: (value: Array<string>) => void,
};

const buildSortedItems = (
  items: Array<{id: string, name: string}>,
): Array<{value: string, label: string}> => {
  const result = items.map(({id, name}) => ({
    value: id,
    label: name,
  }));

  return sortBy(result, 'label');
};

export const MembershipSelect = ({
  hideAllOption = false,
  type,
  value,
  onChange = () => {},
}: PropsType): Node => {
  const [selectedItem, setSelectedItem] = useState(value || ALL_ITEMS);
  const {meStore, groupStore} = useStores();

  let items = [];
  let allItemIds = [];

  if (type === MEMBERSHIPS.GROUP) {
    items = [
      // $FlowIgnore
      ...buildSortedItems(Object.values(groupStore.myGroupsLookup)),
    ];

    if (!hideAllOption) {
      items.unshift({value: ALL_ITEMS, label: 'All Groups'});
    }

    allItemIds = (Object.values(groupStore.myGroupsLookup): any).map(
      (group) => group.id,
    );
  } else if (type === MEMBERSHIPS.PROGRAM) {
    items = [...buildSortedItems(meStore.myPrograms)];

    if (!hideAllOption) {
      items.unshift({value: ALL_ITEMS, label: 'All Programs'});
    }
    allItemIds = meStore.myPrograms.map((program) => program.id);
  }

  const handleChange = (event): void => {
    const isAll = event.target.value === ALL_ITEMS;

    const result = isAll ? allItemIds : [event.target.value];

    setSelectedItem(event.target.value);
    onChange(result);
  };

  return (
    <Select
      name="membership-select"
      data-testid="membership-select"
      containerProps={{
        fullWidth: true,
        sx: {
          borderRadius: 1,
          color: Theme.colorPalette.lightest,
        },
      }}
      canUnselect={false}
      items={items}
      onChange={handleChange}
      value={selectedItem}
    />
  );
};
