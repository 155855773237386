// React
import React from 'react';

// Bulma
import Columns from 'react-bulma-components/lib/components/columns';
import BDropdown from 'react-bulma-components/lib/components/dropdown';

import {Field, Help, Label} from 'react-bulma-components/lib/components/form';
import Level from 'react-bulma-components/lib/components/level';

// WS
import Dropdown from '../../tutorial/dropdown';

import {fieldStatus} from '../../../utils/Forms';

const PfDropdown = (props) => {
  const _getPath = (path, target) => {
    var schema = target; // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) {
        schema[elem] = {};
      }
      schema = schema[elem];
    }

    return schema[pList[len - 1]];
  };

  return props.fullWidth ? (
    <Field>
      <Columns>
        {props.label ? (
          <Columns.Column size={6}>
            <Label style={styles.fieldLabel} htmlFor={props.field.name}>
              {props.label}
            </Label>
          </Columns.Column>
        ) : (
          ''
        )}
        <Columns.Column size={6} style={styles.stackedRightItems}>
          <Dropdown
            id={props.field.name}
            value={props.val}
            onChange={props.selectFunc}
            fieldname={props.fieldname}
            className={props.classes || 'right-drop short-drop thin-drop'}>
            {props.list.map((item, i) => {
              let label = props.labelPath
                ? _getPath(props.labelPath, item)
                : item;
              let value = props.valuePath
                ? _getPath(props.valuePath, item)
                : item;

              return (
                <BDropdown.Item
                  key={i}
                  value={value}
                  targetid={props.field.name}>
                  {label}
                </BDropdown.Item>
              );
            })}
          </Dropdown>
          <Help color={fieldStatus(props.val, props.field.test).color}>
            {fieldStatus(props.val, props.field.test).error}
          </Help>
        </Columns.Column>
      </Columns>
    </Field>
  ) : (
    <Level>
      {props.label ? (
        <Level.Side>
          <Field>
            <Label style={styles.fieldLabel} htmlFor={props.field.name}>
              {props.label}
            </Label>
          </Field>
        </Level.Side>
      ) : (
        ''
      )}
      <Level.Side>
        <Level.Item style={styles.stackedRightItems}>
          <Dropdown
            id={props.field.name}
            value={props.val}
            onChange={props.selectFunc}
            fieldname={props.field.name}
            className={props.classes || 'right-drop short-drop thin-drop'}>
            {props.list.map((item, i) => {
              let label = props.labelPath
                ? _getPath(props.labelPath, item)
                : item;
              let value = props.valuePath
                ? _getPath(props.valuePath, item)
                : item;

              return (
                <BDropdown.Item
                  key={i}
                  value={value}
                  targetid={props.field.name}>
                  {label}
                </BDropdown.Item>
              );
            })}
          </Dropdown>
          <Help color={fieldStatus(props.val, props.field.test).color}>
            {fieldStatus(props.val, props.field.test).error}
          </Help>
        </Level.Item>
      </Level.Side>
    </Level>
  );
};
const styles = {
  fieldLabel: {
    paddingRight: 20,
  },
  stackedRightItems: {
    textAlign: 'right',
  },
};
export default PfDropdown;
