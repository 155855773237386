// @flow
import {computed, decorate, observable, runInAction} from 'mobx';
import type {IObservableArray} from 'mobx';
import RootStore from 'mobx/RootStore';
import {Models} from '@wellstone-solutions/common';
import type {UIProgramType} from '@wellstone-solutions/common';

const {Program} = Models;

export class ProgramStore {
  rootStore: RootStore;
  programs: IObservableArray<UIProgramType> = observable.array();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  async getAllPrograms(): Promise<Array<UIProgramType>> {
    // $FlowFixMe
    const orgId = this.rootStore.stores.meStore.me.membership.organization.id;

    const response = await Program.getAll(orgId);

    const rawPrograms = response.isSuccess ? response.data.programs : [];

    const uiPrograms = rawPrograms.map(Program.toUI);

    runInAction(() => {
      this.programs.replace(uiPrograms);
    });

    return uiPrograms;
  }

  get programsMap(): {[string]: UIProgramType} {
    return this.programs.slice().reduce((accum, item) => {
      accum[item.id] = item;

      return accum;
    }, {});
  }
}

decorate(ProgramStore, {
  programs: observable,
  programsMap: computed,
});
