// @flow
import {Validation} from '@wellstone-solutions/common';
import moment from 'moment';
import type {UISessionDocumentType} from 'modules/documentation/model/types';

const {isOptional, isRequired, timeIsAfter} = Validation;

type EndTimeValidationsType = {
  hasValue: boolean,
  isAfterStartTime: boolean,
  isBeforeNow: boolean,
};

export const endTimeValidations = (
  value: string | void,
  values: UISessionDocumentType,
): EndTimeValidationsType => {
  return {
    hasValue: isRequired(value),
    isAfterStartTime: timeIsAfter('startTime')(value, values),
    isBeforeNow: endTimeBeforeNow(value, values),
  };
};

const endTimeBeforeNow = (
  value: string | void,
  values: UISessionDocumentType,
): boolean => {
  if (!value || !values?.dateOfService) {
    return true;
  }

  const [hour, minutes] = value.split(':');
  const datePlusTime = moment(values.dateOfService).set({
    hour: Number(hour),
    minute: Number(minutes),
    second: 0,
    milliseconds: 0,
  });
  return datePlusTime.isBefore(moment());
};

type DateOfServiceValidationsType = {
  hasValue: boolean,
  isNotInFuture: boolean,
};

export const dateOfServiceValidations = (
  value?: string,
): DateOfServiceValidationsType => {
  return {
    hasValue: isRequired(value),
    isNotInFuture:
      Boolean(value) && moment(value).isBefore(moment().endOf('day')),
  };
};

// Fields required to create a new "SessionsDocument" in the api
export const schema = {
  id: isOptional,
  created: isOptional,
  modified: isOptional,
  status: isRequired,
  member: isRequired,
  staff: isRequired,
  dateOfService: {
    validate: (value: string): boolean => {
      const validations = dateOfServiceValidations(value);
      // if all checks pass return true
      return Object.keys(validations).every((k) => Boolean(validations[k]));
    },
    error: (values: UISessionDocumentType): string => {
      const validations = dateOfServiceValidations(values.dateOfService);
      if (!validations.hasValue) {
        return 'Please enter a valid date of service.';
      }
      if (!validations.isNotInFuture) {
        return 'Document can’t be finalized with a date in the future.';
      }
      return '';
    },
  },
  startTime: {
    validate: (value: string, values: UISessionDocumentType): boolean => {
      const endTime = endTimeValidations(values.endTime, values);
      // this will highlight the startTime field if theres this particular
      // error on the endTime field
      return isRequired(value) && endTime.isAfterStartTime;
    },
    error: (values: UISessionDocumentType): string => {
      // if value is not present
      if (!isRequired(values.startTime)) {
        return 'Please enter a start time.';
      }
      return '';
    },
  },
  endTime: {
    validate: (value: string, values: UISessionDocumentType): boolean => {
      const validations = endTimeValidations(value, values);
      // if all checks pass return true
      return Object.keys(validations).every((k) => Boolean(validations[k]));
    },
    error: (values: UISessionDocumentType): string => {
      const validations: EndTimeValidationsType = endTimeValidations(
        values.endTime,
        values,
      );
      if (!validations.hasValue) {
        return 'Please enter an end time.';
      }
      if (!validations.isAfterStartTime) {
        return 'End Time must be after Start Time';
      }
      if (!validations.isBeforeNow) {
        return 'Document can’t be finalized with an end time in the future.';
      }
      return '';
    },
  },
  titleOrReason: {
    validate: isRequired,
    error: 'Please enter the title or reason for the session.',
  },
  sessionType: {
    validate: isRequired,
    error: 'Please select the type of session.',
  },
  didMemberAttend: {
    validate: isRequired,
    error: 'Please select whether the member attended or not.',
  },
  location: {
    validate: isRequired,
    error: 'Please provide the location the session took place.',
  },
  typeOfService: {
    validate: isRequired,
    error: 'Please select the type of service provided to the member.',
  },
  notes: {
    validate: isRequired,
    error: 'Please enter any notes related to the session.',
  },
  nextSteps: isOptional,
};
