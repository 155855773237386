// @flow
import React from 'react';
import type {Node} from 'react';
import {Grid} from '@wellstone-solutions/web';
import type {
  UIResourceQuestionType,
  UIResourceAnswerType,
} from '@wellstone-solutions/common';
import {AnswerCard} from '../AnswerCard';

type PropsType = {
  answers: Array<UIResourceAnswerType>,
  shareResource: (
    resource: UIResourceQuestionType | UIResourceAnswerType,
    action?: string,
  ) => void,
  isModal: boolean,
};

export const AnswersGrid = ({
  answers,
  shareResource,
  isModal,
}: PropsType): Node => (
  <Grid container spacing={2}>
    {answers.map &&
      answers.map((answer, index) => (
        <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3}>
          <AnswerCard
            answer={answer}
            shareResource={shareResource}
            isModal={isModal}
          />
        </Grid>
      ))}
  </Grid>
);
