import React, {useEffect, useRef} from 'react';
import {inject, observer} from 'mobx-react';

const ScrollTo = (props) => {
  const {isActive, currentStep, getStepById, steps} = props.tutorialStore;
  const ref = useRef();

  useEffect(() => {
    if (isActive) {
      const docElement = document.documentElement;
      let step = getStepById(steps[currentStep]),
        to = ref.current.offsetTop - 20,
        start = docElement.scrollTop,
        change = to - start,
        currentTime = 0,
        duration = Math.abs(change),
        increment = 20;

      const easeInOut = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) {
          return (c / 2) * t * t + b;
        }
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        currentTime += increment;
        docElement.scrollTop = easeInOut(currentTime, start, change, duration);
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };

      if (props.stepId && props.stepId === step?.id) {
        animateScroll();
      }
    }
  }, [currentStep, getStepById, isActive, props.stepId, steps]);

  return isActive ? <div ref={ref} /> : null;
};

export default inject('tutorialStore')(observer(ScrollTo));
