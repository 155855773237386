// @flow
import React from 'react';
import type {Node} from 'react';
import type {UICalendarEventType} from '@wellstone-solutions/common';
import {useCalendarEventStyles} from '../../hooks/useCalendarEventStyles';
import {CalendarEvent} from './CalendarEvent';

type PropsType = {
  continuesAfter: boolean,
  continuesPrior: boolean,
  currentView: string,
  event: {
    allDay: boolean,
    end: Date,
    isAttending: boolean,
    resource: UICalendarEventType,
    start: Date,
    title: string,
  },
  isAllDay: boolean,
  localizer: any,
  slotEnd: Date,
  slotStart: Date,
  title: string,
};

export const DailyEvent = ({
  isAllDay,
  event,
  currentView,
  title,
}: PropsType): Node => {
  const {isAttending} = event;
  const styles = useCalendarEventStyles({isAttending});

  return (
    <CalendarEvent
      displayTimeInformation={false}
      styles={styles}
      title={title}
    />
  );
};
