// @flow
import React from 'react';
import type {Node} from 'react';
import type {FormType} from '@wellstone-solutions/common';
import {Alert} from '@wellstone-solutions/web';
import {sortBy} from 'utils/Utils';
import {useStores} from 'hooks/useStores';
import {SelectableGroupList} from './SelectableGroupList';

type PropsType = {
  form: FormType<any>,
};

export const SelectableFormGroups = ({form}: PropsType): Node => {
  const {groupStore, meStore} = useStores();

  const newlyAddedProgramGroups = form.values.programs
    // Only newly added programs
    .filter(({existing}) => !existing)
    .map(({id}) => meStore.myProgramsLookup[id])
    // Filter out any nulls
    .filter(Boolean)
    .flatMap((program) => program.groups);

  // Filter down groups to ones I have access to
  const rows = sortBy(
    newlyAddedProgramGroups
      .map((groupId) => groupStore.myGroupsLookup[groupId])
      // Filter out nulls
      .filter(Boolean)
      .map((group) => {
        const programNames = group.programs
          .map(({id}) => meStore.myProgramsLookup[id]?.name)
          .filter(Boolean)
          .join(', ');

        return {
          id: group.id,
          name: group.name,
          programNames,
          activeMembers: group.members_count,
          type: group.category.type.name,
        };
      }),
    'name',
  );

  if (rows.length === 0) {
    return (
      <Alert severity="info">
        No groups to select, please choose another program
      </Alert>
    );
  }

  const selectedGroups = form.values.groups.map(({id}) => id);
  const readOnlyGroups = form.values.groups
    .filter(({existing}) => existing)
    .map(({id}) => id);

  return (
    <SelectableGroupList
      rows={rows}
      readOnlyGroups={readOnlyGroups}
      selectedGroups={selectedGroups}
      onSelectionChange={(selectedGroupIds) => {
        const updatedGroups = selectedGroupIds.map((id) => ({
          id,
          existing: readOnlyGroups.includes(id),
        }));
        form.setFieldValue('groups', updatedGroups);
      }}
    />
  );
};
