import React, {useState, useMemo} from 'react';
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
} from 'recharts';
import Modal from 'react-bulma-components/lib/components/modal';
import {MemberList} from './MemberList';

const CustomizedDot = ({
  cx,
  cy,
  index,
  fill,
  height,
  yAxis,
  value_userlist,
  showModal,
}) => (
  <circle
    data-testid="scatter-dot"
    cx={cx}
    cy={cy}
    r={index > 0 ? height / 2 : 0}
    stroke="black"
    strokeWidth={0}
    fill={fill}
    style={styles.cursor}
    onClick={() => showModal(yAxis.name, value_userlist)}
  />
);

export const PfScatterChart = (props) => {
  const {scatterData, filterData, size, x, y} = props;
  const [userIds, setUserIds] = useState([]);

  const getFilteredData = useMemo(() => {
    if (!filterData || !filterData.key) return scatterData;

    const filterTag = filterData.key.toUpperCase();
    return scatterData.filter((item) => item.tags.includes(filterTag));
  }, [scatterData, filterData]);

  const parseDomain = useMemo(
    () => [
      0,
      Math.max(
        ...getFilteredData.flatMap((dataset) =>
          dataset.data.map((entry) => entry.value),
        ),
      ),
    ],
    [getFilteredData],
  );

  const showModal = (name, users) => {
    if (!props.showDetailsModal) return;
    setUserIds(users);
  };

  const hideModal = () => setUserIds([]);

  const renderChart = (dataset, domain, range, index) => {
    const width = window.innerWidth;
    const dateFontSize = width / dataset.data.length < 45 ? 0 : 10;

    return (
      <ResponsiveContainer key={index} width={size.width} height={60}>
        <ScatterChart margin={{top: 10, right: 0, bottom: 0, left: 0}}>
          <XAxis
            type={x.type}
            dataKey={x.key}
            interval={0}
            tick={{fontSize: dateFontSize}}
            tickLine={{transform: 'translate(0, -6)'}}
          />
          <YAxis
            type={y.type}
            dataKey={y.key}
            name={dataset.name}
            width={150}
            tick={false}
            tickLine={false}
            axisLine={true}
            label={{
              value: dataset.name.replace('_', ' '),
              position: 'insideRight',
              fill: dataset.color,
              style: styles.axisLabel,
            }}
          />
          <ZAxis type="number" dataKey="value" domain={domain} range={range} />
          <Tooltip
            cursor={{strokeDasharray: '3 3'}}
            wrapperStyle={styles.tooltipWrapper}
            content={<CustomTooltip />}
          />
          <Scatter
            data={dataset.data}
            fill={dataset.color}
            shape={<CustomizedDot showModal={showModal} />}
          />
        </ScatterChart>
      </ResponsiveContainer>
    );
  };

  const range = [16, 225];

  return (
    <div>
      <Modal
        show={!!userIds.length}
        onClose={hideModal}
        className="pfModal"
        closeOnBlur
        closeOnEsc
        data-testid="user-modal">
        <Modal.Content style={styles.modal}>
          <MemberList userIds={userIds} />
        </Modal.Content>
      </Modal>

      {getFilteredData.map((dataset, index) =>
        renderChart(dataset, parseDomain, range, index),
      )}
    </div>
  );
};

const CustomTooltip = ({active, payload, label}) => {
  if (active && payload[0] && payload[1] && payload[2]) {
    return (
      <div style={styles.tooltipBox}>
        <p style={styles.label}>{`${payload[0].value}`}</p>
        <p style={styles.value}>{`${payload[1].name} : ${payload[2].value}`}</p>
      </div>
    );
  }
  return null;
};

const styles = {
  cursor: {cursor: 'pointer'},
  tooltipWrapper: {zIndex: 100},
  tooltipBox: {
    border: '1px solid #ccc',
    backgroundColor: 'white',
    padding: '8px 12px',
  },
  label: {fontWeight: 'normal'},
  value: {fontWeight: 'bold'},
  axisLabel: {fontWeight: 'bold', fontSize: 12, marginRight: 5},
  modal: {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 10,
    width: 'auto',
  },
};
