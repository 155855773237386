// @flow
import React, {useEffect, useRef, useState} from 'react';
import type {Node} from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Icon,
  IconNames,
  MarkdownViewer,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import type {
  UIResourceQuestionType,
  UIResourceAnswerType,
} from '@wellstone-solutions/common';
import {clamp} from 'lib/clamp';
import {hasFlag, TMP_SHARE_ANSWER} from 'constants/FeatureFlags';
import {useStores} from 'hooks/useStores';
import {ContactCard} from '../ContactCard';
import {OtherContactsLabel} from './OtherContactsLabel';
import {Actions} from 'constants/Messages';
import {ResourceModal, RESOURCE_MODAL_VIEWS} from '../../v2';

type PropsType = {
  answer: UIResourceAnswerType,
  shareResource: (
    resource: UIResourceQuestionType | UIResourceAnswerType,
    action?: string,
  ) => void,
  isModal: boolean,
};

const MAX_LINES_DESCRIPTION = 2;

export const AnswerCard = ({
  answer,
  shareResource,
  isModal,
}: PropsType): Node => {
  const descriptionRef = useRef();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const {meStore} = useStores();
  const [showDetailModal, setShowDetailModal] = useState(false);

  useEffect(() => {
    if (!isFullScreen && descriptionRef.current) {
      clamp(descriptionRef.current, {
        clamp: MAX_LINES_DESCRIPTION,
      });
    }
  }, [isFullScreen, descriptionRef]);

  const totalContacts = answer.contacts.length;

  const contacts = isFullScreen
    ? answer.contacts
    : totalContacts > 0
    ? answer.contacts.slice(0, 1)
    : null;

  const containerStyles = isFullScreen
    ? {
        position: 'fixed',
        zIndex: 1001,
        top: '5%',
        right: 0,
        bottom: 0,
        left: '5%',
        maxWidth: '90%',
        maxHeight: '90%',
        overflow: 'scroll',
        padding: '0 1rem',
        borderRadius: 2.5,
      }
    : {
        display: 'flex',
        flex: 1,
        flexFlow: 'column nowrap',
        height: '100%',
        maxWidth: {xs: '100%', sm: 'calc(100% - 10px)'},
      };

  const titleContainerStyles = isFullScreen
    ? {
        borderRight: {
          xs: 'none',
          sm: `1px solid ${Theme.colorPalette.medium}`,
        },
        pt: 2,
        pr: {
          xs: 0,
          sm: 4,
        },
        mr: {
          xs: 0,
          sm: 4,
        },
        mt: 6,
      }
    : {};

  const contactCardStyles = isFullScreen
    ? {}
    : {
        flex: 1,
        overflow: 'hidden',
      };

  return (
    <Card sx={containerStyles}>
      <CardContent sx={{flex: 1, height: '100%'}}>
        {isFullScreen && (
          <Button
            onClick={() => setIsFullScreen(false)}
            data-testid="close-button"
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
            }}>
            <Icon
              color={Theme.colorPalette.primary}
              name={IconNames.Xmark}
              size={28}
            />
          </Button>
        )}
        <Stack
          direction={isFullScreen ? {xs: 'column', sm: 'row'} : 'column'}
          flex={1}
          height={{xs: 'auto', sm: '100%'}}
          justifyContent="flex-end">
          <Stack flex={1} sx={titleContainerStyles}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <Typography
                color={Theme.colorPalette.primary}
                sx={{
                  fontSize: isFullScreen ? 28 : 20,
                  lineHeight: 1.2,
                  mb: 1,
                }}>
                {answer.title}
              </Typography>
              {/* Remove TMP_SHARE_ANSWER feature flag, see PAT-4212 */}
              {!isFullScreen && hasFlag(TMP_SHARE_ANSWER, meStore.features) && (
                <Button
                  aria-label="share resource"
                  color="primary"
                  sx={{
                    mt: -1,
                    mr: -1,
                    textTransform: 'none',
                  }}
                  startIcon={
                    <Icon
                      name={IconNames.ShareIos}
                      color="primary"
                      size={18}
                      strokeWidth={2}
                    />
                  }
                  onClick={() =>
                    shareResource(answer, Actions.SHARED_RESOURCE_ANSWER)
                  }>
                  Share
                </Button>
              )}
            </Stack>
            <Typography
              color={Theme.colorPalette.secondary}
              sx={{
                fontSize: isFullScreen ? 20 : 16,
                lineHeight: 1.2,
                mb: 2,
              }}>
              {answer.subtitle}
            </Typography>
            {isFullScreen ? (
              <MarkdownViewer value={answer.description} />
            ) : (
              <div ref={descriptionRef}>
                <MarkdownViewer value={answer.description} />
              </div>
            )}

            {/* Remove TMP_SHARE_ANSWER feature flag, see PAT-4212 */}
            {isFullScreen && hasFlag(TMP_SHARE_ANSWER, meStore.features) && (
              <Button
                aria-label="share resource"
                color="primary"
                variant="contained"
                sx={{
                  mt: 2,
                  alignSelf: 'flex-start',
                }}
                startIcon={
                  <Icon
                    name={IconNames.ShareIos}
                    color="white"
                    size={18}
                    strokeWidth={2}
                  />
                }
                onClick={() =>
                  shareResource(answer, Actions.SHARED_RESOURCE_ANSWER)
                }>
                Share
              </Button>
            )}
          </Stack>
          <Stack
            flex={isFullScreen ? {xs: 1, md: 3} : 0}
            mt={isFullScreen ? 6 : 2}
            sx={{minWidth: 0}}>
            <Typography
              color={Theme.colorPalette.primary}
              sx={{
                fontSize: isFullScreen ? 20 : 16,
                fontWeight: 'bold',
              }}>
              {answer.organizationTitle}
            </Typography>
            <Stack pt={2}>
              <Grid
                container
                rowSpacing={{xs: 2, lg: 4}}
                spacing={{xs: 2, lg: 4}}>
                {contacts &&
                  contacts.map &&
                  contacts.map((contact, index) => (
                    <Grid
                      key={index}
                      item
                      xs={isFullScreen ? 12 : undefined}
                      md={isFullScreen ? 6 : undefined}
                      xl={isFullScreen ? 4 : undefined}
                      sx={contactCardStyles}>
                      <ContactCard
                        contact={contact}
                        isFullScreen={isFullScreen}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Stack>
            {!isFullScreen && totalContacts > 1 && (
              <OtherContactsLabel totalContacts={totalContacts} />
            )}
          </Stack>
        </Stack>
      </CardContent>
      {!isFullScreen && (
        <CardActions
          sx={{borderTop: `solid 1px ${Theme.colorPalette.mediumLight}`}}>
          <Stack alignItems="center" justifyContent="center" sx={{flex: 1}}>
            <Button
              variant="text"
              sx={{width: '100%', textTransform: 'none'}}
              onClick={() => {
                if (isModal) {
                  setIsFullScreen(true);
                } else {
                  setShowDetailModal(true);
                }
              }}>
              View Details
            </Button>
          </Stack>
        </CardActions>
      )}

      <ResourceModal
        open={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        resource={answer}
        defaultView={RESOURCE_MODAL_VIEWS.detail}
      />
    </Card>
  );
};
