// @flow
import React from 'react';
import type {Node, ComponentType} from 'react';
import {Box, Stack} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {NavTabs} from 'modules/navigation';
import {ResourceSearch} from '../ResourceSearch';
import {CurrentLocation} from '../CurrentLocation';
import {CollectionIconList} from '../CollectionIconList';
import {PageTitle} from './PageTitle';
import {useStores} from 'hooks/useStores';
import {observer} from 'mobx-react';

export const ResourcesHeader: ComponentType<{}> = observer((): Node => {
  const {resourceStoreV2} = useStores();

  const tabs = [
    {label: 'All Resources', value: 'all', to: '/resources'},
    {
      label: 'Saved',
      value: 'saved',
      to: '/resources/saved',
      count: resourceStoreV2.userResources.length,
    },
  ];

  return (
    <Box sx={styles.header}>
      <Stack
        direction="row"
        alignItems="flex-end"
        spacing={1}
        sx={styles.titleStack}>
        <PageTitle />
        <CurrentLocation />
      </Stack>
      <Stack
        direction={{xs: 'column-reverse', sm: 'row'}}
        alignItems={{xs: 'stretch', sm: 'end'}}
        spacing={2}>
        <ResourceSearch />
        <Box sx={styles.tabWrapper}>
          <NavTabs tabs={tabs} />
        </Box>
      </Stack>

      <Box mt={3}>
        <CollectionIconList />
      </Box>
    </Box>
  );
});

const styles = {
  header: {
    marginBottom: 3,
  },
  titleStack: {
    marginBottom: 2,
  },
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: '700',
    color: Theme.colorPalette.primary,
  },
  tabWrapper: {
    '& .MuiTab-root': {
      width: {
        xs: '50%',
        sm: 'auto',
      },
    },
  },
};
