import React, {useEffect, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import {NavLink} from 'react-router-dom';
import Image from 'react-bulma-components/lib/components/image';
import Button from './button';
import {Palette} from '../../palette';
import {Animated} from 'react-animated-css';
import AppStoreLinks from '../shared/AppStoreLinks';

const COLORS = {
  background: Palette.DARK_INFO,
  foregroundBlue: Palette.EXCITE_0,
  backgroundBlue: Palette.PRIMARY,
};

const IMAGES = {
  phoneFrame:
    'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/phone_frame.svg',
};

// The index/step at which the tutorial should no longer be full screen.
const fullScreenStopIndex = 4;

/**
 * Micro component for visualizing progress of tutorial.
 * @param {Object} props
 */
const TutorialProgressIndicator = (props) => {
  const {progress, totalSteps} = props;
  const extra = {
    width: `${(progress / totalSteps) * 100}%`,
  };
  return (
    <div style={styles.progressWrapper}>
      <div style={{...styles.progressBar, ...extra}} />
    </div>
  );
};

/**
 * Main Tutorial component, wrapping all visual elements.
 * @param {Object} props
 */
const Tutorial = (props) => {
  const {
    currentStep,
    steps,
    isActive,
    isComplete,
    coreSteps,
  } = props.tutorialStore;

  useEffect(() => {
    if (isActive && !isComplete) {
      document.body.classList.add('is-tutorial');
    }
    return () => document.body.classList.remove('is-tutorial');
  }, [isActive, isComplete]);

  if (currentStep === -1 || !steps.length) {
    return '';
  }

  const getStepById = (id) => {
    return coreSteps.find((step) => {
      if (step.id === id) {
        return step;
      }
      return false;
    });
  };

  let step_id = steps[currentStep];
  let step = getStepById(step_id);

  const {title = '', content = '', jsx = false} = step;
  const progress =
    currentStep < fullScreenStopIndex
      ? 0
      : currentStep + 1 - fullScreenStopIndex;
  let c = null;

  let renderCount = currentStep;

  if (currentStep === steps.length - 1) {
    document.body.classList.add('stop-scrolling');
  }

  return isActive ? (
    <Fragment>
      {currentStep >= fullScreenStopIndex ? (
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          animationInDuration={300}
          animationOutDuration={300}
          animationInDelay={2000}
          animateOnMount={true}
          key={'wrapper'}>
          <div
            style={{
              ...styles.pageCover,
              ...(currentStep < 4 ||
              progress === steps.length - fullScreenStopIndex
                ? styles.fullPage
                : styles.remainder),
            }}
          />
        </Animated>
      ) : (
        ''
      )}
      <div className="show-on-small-screen" style={styles.smallScreenBlocker}>
        <div style={styles.screenBlockerText}>
          Please make the screen larger to experience the tutorial.
        </div>
      </div>
      <div style={styles.tutorialContentWrapper}>
        <div
          style={{
            ...styles.tutorialContent,
            ...styles.cropped,
            ...(isComplete ? styles.hidden : styles.visible),
            ...(currentStep < 4 ||
            progress === steps.length - fullScreenStopIndex
              ? styles.fullPage
              : ''),
          }}>
          <div style={styles.titleBar}>
            <h1 data-testid="bridge-tutorial-header" style={styles.h1}>
              Bridge Tutorial
            </h1>
          </div>
          <TutorialProgressIndicator
            progress={progress}
            totalSteps={steps.length - fullScreenStopIndex}
          />
          {currentStep < fullScreenStopIndex ||
          currentStep === steps.length - 1 ? (
            ''
          ) : (
            <Fragment>
              <p style={styles.chapterReadout}>
                Chapter {progress} / {steps.length - fullScreenStopIndex}
              </p>
              {(currentStep < 4 ||
                progress === steps.length - fullScreenStopIndex) && (
                <h3 style={styles.h3}>{title}</h3>
              )}
            </Fragment>
          )}
          {jsx ? (
            [content]
          ) : (
            <div style={styles.partialWrapper}>
              {content.map(
                (
                  {type, data, text, phoneFrame = null, urlTo = null},
                  index,
                ) => {
                  switch (type) {
                    default:
                      break;
                    // Basic text element.
                    case 'text':
                      c = (
                        <p
                          key={`${renderCount}-${index}`}
                          style={styles.tutorialTextContent}>
                          {data}
                        </p>
                      );
                      break;
                    // A locally stored image.
                    case 'image':
                      c = (
                        <Image
                          key={`${renderCount}-${index}`}
                          renderAs="p"
                          size={32}
                          src={require(`../../assets/images/${data}`)}
                        />
                      );
                      break;
                    case 'appStoreLinks':
                      c = <AppStoreLinks />;
                      break;
                    // An image loaded from an external src.
                    case 'remoteImage':
                      c = (
                        <img
                          alt={''}
                          key={`${renderCount}-${index}`}
                          style={{...data.size, ...styles.image}}
                          src={data.url}
                        />
                      );
                      break;
                    // An SVG element loaded from an external src.
                    case 'svg':
                      c = (
                        <img
                          alt={data.alt}
                          key={`${renderCount}-${index}`}
                          src={data.url}
                          style={{...data.size, ...styles.svg}}
                        />
                      );
                      break;
                    // Video
                    case 'video':
                      c = (
                        <video
                          loop
                          autoPlay
                          style={data.size}
                          key={`${renderCount}-${index}`}>
                          <source src={data.url} type="video/mp4" />
                        </video>
                      );
                      break;
                    // Generic button to move to the next step of the tutorial.
                    case 'nextButton':
                      const d = text && text.length ? text : 'Next';
                      c = (
                        <Button key={`${renderCount}-${index}`} id="nextButton">
                          {d}
                        </Button>
                      );
                      break;
                    // Internal links to other areas of bridge.
                    case 'linkTo':
                      c = (
                        <Button key={`${renderCount}-${index}`} id="nextButton">
                          <NavLink
                            className={(linkProps) =>
                              linkProps.isActive ? 'is-active' : ''
                            }
                            to={data}>
                            {text}
                          </NavLink>
                        </Button>
                      );
                      break;
                    case 'phoneImage':
                      c = (
                        <div
                          style={styles.phoneFrame}
                          key={`${renderCount}-${index}`}>
                          <img
                            src={IMAGES.phoneFrame}
                            style={styles.phoneFrameImage}
                            alt={''}
                          />
                          <img
                            alt=""
                            style={styles.phoneImage}
                            src={data.url}
                          />
                        </div>
                      );
                      break;

                    case 'phoneVideo':
                      c = (
                        <div
                          style={styles.phoneFrame}
                          key={`${renderCount}-${index}`}>
                          <img
                            src={IMAGES.phoneFrame}
                            style={styles.phoneFrameImage}
                            alt={''}
                          />
                          <video
                            loop
                            autoPlay
                            style={{...data.size, ...styles.phoneVideo}}>
                            <source src={data.url} type="video/mp4" />
                          </video>
                        </div>
                      );
                      break;

                    case 'blueText':
                      c = (
                        <Animated
                          animationIn="fadeIn"
                          animationOut="fadeOut"
                          animationInDuration={500}
                          animationOutDuration={500}
                          animationInDelay={500}
                          animateOnMount={true}
                          key={'blue-text'}>
                          <p
                            key={`${renderCount}-${index}`}
                            style={{
                              ...styles.tutorialTextContent,
                              ...styles.blueText,
                            }}>
                            {data}
                          </p>
                        </Animated>
                      );
                      break;

                    case 'infoBox':
                      c = (
                        <Animated
                          animationIn="fadeIn"
                          animationOut="fadeOut"
                          animationInDuration={500}
                          animationOutDuration={500}
                          animationInDelay={500}
                          animateOnMount={true}
                          key={'blue-text'}>
                          <p
                            key={`${renderCount}-${index}`}
                            style={{
                              ...styles.tutorialTextContent,
                              ...styles.infoBox,
                            }}>
                            {data}
                          </p>
                        </Animated>
                      );
                      break;
                  }

                  // If a URL is provided, wrap our content item in a link.
                  if (urlTo) {
                    c = (
                      <a
                        href={urlTo}
                        key={`${renderCount}-${index}-urlTo`}
                        target={'_blank'}
                        rel="noopener noreferrer">
                        {c}
                      </a>
                    );
                  }

                  return type === 'svg' ? (
                    c
                  ) : (
                    <Animated
                      animationIn="fadeInRight"
                      animationOut="fadeOutRight"
                      animationInDuration={300}
                      animationOutDuration={300}
                      animationInDelay={100 + index * 100}
                      animateOnMount={true}
                      key={`${renderCount}-${index}-animation-wrapper`}>
                      {c}
                    </Animated>
                  );
                },
              )}
              <Button
                id="tutorialSkipButton"
                className={'tutorialSkipButton'}
                data-testid="skip-tutorial-button">
                Skip Tutorial
              </Button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  ) : null;
};

/**
 * Styles for Tutorial.
 */
const styles = {
  fullPage: {
    width: '100vw',
  },
  group: {
    height: '100%',
  },
  cropped: {
    width: '28vw',
  },
  remainder: {
    width: '72vw',
    height: '100%',
  },
  hidden: {
    transform: 'translateX(33vw)',
  },
  visible: {},
  tutorialWrapper: {
    height: '100%',
  },
  partialWrapper: {
    padding: '10px 40px 0 40px',
    overflowY: 'auto',
    height: 'calc(100vh - 150px)',
    overflowX: 'hidden',
  },
  tutorialContent: {
    position: 'sticky',
    height: '100vh',
    overflowX: 'hidden',
    top: 0,
    transition:
      'transform 0.5s cubic-bezier(0.16, 1, 0.3, 1), width 0.5s cubic-bezier(0.16, 1, 0.3, 1)',
  },
  tutorialContentWrapper: {
    height: '100%',
    right: 0,
    background: Palette.DARK_INFO,
    position: 'fixed',
    top: 0,
    zIndex: 505,
    boxShadow: '-4px 0px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  pageCover: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 400,
    height: '100%',
    background: 'rgba(0,0,0,0.1)',
    boxShadow: 'black 0px 0px 50vw 0 inset',
  },
  titleBar: {
    height: '52px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    padding: '0 40px',
  },
  h1: {
    textAlign: 'left',
    color: '#FFFFFF',
    fontSize: '24px',
    marginTop: '8px',
  },
  h2: {
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  h3: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    textAlign: 'left',
    margin: '25px 0 0 40px',
  },
  tutorialTextContent: {
    color: '#FFFFFF',
    fontSize: 16,
    margin: '10px 0 10px 0',
  },
  progressWrapper: {
    height: '4px',
    width: '100%',
    background: COLORS.backgroundBlue,
  },
  progressBar: {
    height: '100%',
    background: COLORS.foregroundBlue,
    transition: 'width 1s linear',
  },
  chapterReadout: {
    color: COLORS.foregroundBlue,
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '15px 0 0 40px',
  },
  phoneFrame: {
    position: 'relative',
    height: '40vh',
    margin: '25px 0',
  },
  phoneFrameImage: {
    height: '40vh',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  phoneImage: {
    position: 'absolute',
    height: '38.5vh',
    zIndex: '-1',
    left: '50%',
    top: '9px',
    transform: 'translateX(-50%)',
    borderRadius: '12px',
  },
  phoneVideo: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '0.9vh',
    zIndex: '-1',
  },
  svg: {
    margin: '40px auto',
    display: 'block',
  },
  image: {
    margin: '40px auto',
    display: 'block',
  },
  blueText: {
    color: 'white',
    fontSize: '15px',
    margin: '20px 0 0 0',
    borderLeftColor: 'white',
    borderLeftWidth: 2,
    borderLeftStyle: 'solid',
    padding: 16,
    backgroundColor: COLORS.backgroundBlue,
  },
  infoBox: {
    color: COLORS.backgroundBlue,
    fontSize: '15px',
    margin: '20px 0',
    padding: 15,
    borderRadius: 10,
    backgroundColor: 'white',
  },
  smallScreenBlocker: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    backgroundColor: COLORS.background,
    zIndex: 9999,
    justifyContent: 'center',
    alignItems: 'center',
  },
  screenBlockerText: {
    color: 'white',
    fontSize: 20,
  },
};

export default inject('tutorialStore')(observer(Tutorial));
