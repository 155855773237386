// @flow
import React from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {styled} from '@mui/material/styles';
import type {FormType, UIMemberType} from '@wellstone-solutions/common';
import {Theme} from '@wellstone-solutions/common';
import {Box, Stack, Typography} from '@wellstone-solutions/web';
import {DEFAULT_DATE_FORMAT} from 'constants/dates';
import {ROLES_NAMES_MAP} from 'utils/Permissions';
import {genders, identities} from 'utils/Forms';
import {useStores} from 'hooks/useStores';
import {EHRWrapper} from 'modules/integration/components/EHRWrapper';
import {INTEGRATION_STATUSES} from 'modules/integration/constants';

type PropsType = {
  form: FormType<any>,
  data?: UIMemberType,
  step: any,
};

const LabelTypography = styled(Typography)(({theme}) => ({
  color: Theme.colorPalette.onSurface,
  fontWeight: 'bold',
}));

const ValueTypography = styled(Typography)(({theme}) => ({
  color: Theme.colorPalette.onSurface,
  maxWidth: '500px',
  paddingLeft: theme.spacing(1),
}));

const NOT_SET = 'Not set';

export const ConfirmationStep = ({form, step}: PropsType): Node => {
  const {groupStore, meStore} = useStores();

  // EHRWrapper related rendering
  const {CONFIRM, TIP} = INTEGRATION_STATUSES;
  const showEHRStatus = step.data?.orgIntegration;
  const ehrStatus =
    form.values.externalRelationshipId?.length > 0 ? CONFIRM : TIP;

  // Gather up program names
  const programs = form.values.programs
    .map(({id, existing}) => {
      return !existing ? meStore.myProgramsLookup[id]?.name : null;
    })
    .filter(Boolean)
    .join(', ');

  // Gather up group names
  const groups = form.values.groups
    .map(({id, existing}) => {
      return !existing ? groupStore.myGroupsLookup[id]?.name : null;
    })
    .filter(Boolean)
    .join(', ');

  const gender = genders.find(({name, value}) => form.values.gender === value)
    ?.name;
  const identifiesAs = identities.find(
    ({name, value}) => form.values.identifiesAs === value,
  )?.name;

  return (
    <Stack>
      <Stack direction="row" data-testid="confirmation-step">
        <Stack direction="column">
          <Stack direction="row">
            <LabelTypography>Name:</LabelTypography>
            <ValueTypography>{form.values.name}</ValueTypography>
          </Stack>
          <Stack direction="row">
            <LabelTypography>Email:</LabelTypography>
            <ValueTypography>{form.values.email}</ValueTypography>
          </Stack>
          <Stack direction="row">
            <LabelTypography>Birthdate:</LabelTypography>
            <ValueTypography>
              {form.values.birthdate
                ? moment(form.values.birthdate).format(DEFAULT_DATE_FORMAT)
                : NOT_SET}
            </ValueTypography>
          </Stack>

          <Stack direction="row">
            <LabelTypography>Sex:</LabelTypography>
            <ValueTypography>{gender || NOT_SET}</ValueTypography>
          </Stack>
          <Stack direction="row">
            <LabelTypography>Identifies as:</LabelTypography>
            <ValueTypography>{identifiesAs || NOT_SET}</ValueTypography>
          </Stack>
        </Stack>
        <Stack direction="column" sx={{ml: 4}}>
          <Stack direction="row">
            <LabelTypography>Program(s):</LabelTypography>
            <ValueTypography>{programs}</ValueTypography>
          </Stack>
          <Stack direction="row">
            <LabelTypography>Group(s):</LabelTypography>
            <ValueTypography>{groups}</ValueTypography>
          </Stack>
          <Stack direction="row">
            <LabelTypography>Role:</LabelTypography>
            <ValueTypography>
              {ROLES_NAMES_MAP[form.values.role]}
            </ValueTypography>
          </Stack>
        </Stack>
      </Stack>
      {showEHRStatus && (
        <Box sx={{my: 2}}>
          <EHRWrapper
            initialStatus={ehrStatus}
            form={form}
            orgIntegration={step.data.orgIntegration}
          />
        </Box>
      )}
    </Stack>
  );
};
