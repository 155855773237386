// @flow
import React from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {Theme} from '@wellstone-solutions/common';
import {Icon, IconNames, Tooltip} from '@wellstone-solutions/web';
import type {UISessionDocumentType} from '../../model/types';
import {DATE_TIME_FORMAT} from 'modules/documentation/constants';
import {DocumentDetailRow} from './DocumentDetailRow';

type PropTypes = {
  documentation: UISessionDocumentType,
  sx?: any,
};

export const RevertedDetailRow = ({documentation, sx}: PropTypes): Node => {
  if (!documentation.reverted) {
    return <></>;
  }

  return (
    <DocumentDetailRow
      label="Reverted"
      labelColor={Theme.colorPalette.red}
      sx={sx}>
      {`${moment(documentation.reverted.changedAt).format(
        DATE_TIME_FORMAT,
      )} by ${documentation.reverted?.changedBy || 'Staff'}`}
      <Tooltip
        title="A reverted document contains items that need fixing"
        placement="top-start"
        arrow>
        <span>
          <Icon
            name={IconNames.InfoCircle}
            style={styles.tooltipIcon}
            color={Theme.colorPalette.red}
            strokeWidth={2}
            size={14}
            aria-label="A reverted document contains items that need fixing"
          />
        </span>
      </Tooltip>
    </DocumentDetailRow>
  );
};

const styles = {
  tooltipIcon: {
    marginLeft: Theme.spacing.mini,
    verticalAlign: 'middle',
  },
};
