// @flow
import React, {useEffect, useState, useCallback} from 'react';
import type {Node} from 'react';
import {Box, Stack, Typography} from '@wellstone-solutions/web';
import {Search} from 'components/Search';
import {useStores} from 'hooks/useStores';
import {Theme, Models} from '@wellstone-solutions/common';
import type {
  FormType,
  UICalendarEventInvitationType,
  UIUserType,
} from '@wellstone-solutions/common';
import {uniqueBy} from 'utils/uniqueBy';
import {ROLES} from 'utils/Permissions';
import {GuestList} from './GuestList';
import {ResultsComponent} from './ResultsComponent';
import type {UIParticipantType} from '../../../../types';

const {CalendarEventInvitation} = Models;
type PropsType = {
  form: FormType<any>,
};

const toUIInvite = ({
  user,
}: {
  user: UIUserType,
}): UICalendarEventInvitationType =>
  CalendarEventInvitation.Factory({user: user.id, reminderTime: '60'});

const toUIParticipant = ({
  name,
  user,
}: {
  name: string,
  user: UIUserType,
  // $FlowFixMe
}): UIParticipantType => ({
  id: user.id,
  name: name,
  email: user.email,
  isAttending: null,
});

export const Guests = ({form}: PropsType): Node => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    calendarStore,
    memberStore,
    organizationStore,
    staffStore,
  } = useStores();
  const [participants, setParticipants] = useState<Array<UIParticipantType>>(
    [],
  );

  const addParticipants = useCallback(
    (newParticipants: Array<UIParticipantType>) => {
      setParticipants(uniqueBy([...participants, ...newParticipants], 'id'));
    },
    [setParticipants, participants],
  );

  useEffect(() => {
    addParticipants(
      calendarStore.getNamedParticipants(
        form.values.invitations,
        form.values.owner,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addUserInvitation = async (id, name, user, role) => {
    // populate the store with the user information
    if (role === ROLES.patient) {
      await memberStore.getMember({id});
    } else {
      await staffStore.getStaffMember({id});
    }

    form.setFieldValue(
      'invitations',
      uniqueBy([...form.values.invitations, toUIInvite({user})], 'user'),
    );

    // participants are the UI info to display for each invitation
    addParticipants([toUIParticipant({name, user})]);
  };

  const addGroupInviteData = async (groupId) => {
    // The API defaults to a limit of 10 in the response, so need to specify
    // a higher number to avoid larger groups from getting truncated.
    const memberResults = await memberStore.getAllMembers({
      params: {group_id: groupId, limit: 999},
    });
    const staffResults = await staffStore.getAllStaffMembers({
      params: {group_id: groupId, limit: 999},
    });

    const membersList = memberResults.isSuccess
      ? memberResults.data.members
      : [];
    const staffList = staffResults.isSuccess ? staffResults : [];

    form.setFieldValue(
      'invitations',
      uniqueBy(
        [
          ...form.values.invitations,
          ...membersList.map(toUIInvite),
          ...staffList.map(toUIInvite),
        ],
        'user',
      ),
    );

    // participants are the UI info for display for each invitation
    addParticipants([
      ...membersList.map(toUIParticipant),
      ...staffList.map(toUIParticipant),
    ]);
  };

  const onSelectItem = async ({id, name, user, role}) => {
    // temporarily disables Create/Update
    // $FlowIgnore
    form.setFieldError('invitations', 'Loading guest info...');
    setIsLoading(true);
    if (user) {
      await addUserInvitation(id, name, user, role);
      setIsLoading(false);
    } else {
      await addGroupInviteData(id);
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      <Typography variant="h5" sx={{color: Theme.colorPalette.blue, mb: 4}}>
        Invite Guests
      </Typography>
      {/* $FlowIgnoreMe */}
      <Search
        onSearch={(searchTerm) => {
          return organizationStore.search({
            searchTerm,
            statusFilters: ['active'],
          });
        }}
        onSelectItem={onSelectItem}
        ResultsComponent={ResultsComponent}
      />
      <Box sx={{mt: 4}}>
        <GuestList
          form={form}
          participants={participants}
          isLoading={isLoading}
        />
      </Box>
    </Stack>
  );
};
