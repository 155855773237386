import React from 'react';
import Button from '../button';
import {Animated} from 'react-animated-css';
import PFHorizLogo from '../../../assets/images/PathfinderBridge_RGB.svg';
import {Palette} from '../../../palette';

const COLORS = {
  background: Palette.DARK_INFO,
  foregroundBlue: Palette.INFO,
  backgroundBlue: Palette.LINK,
};

export default (props) => {
  return (
    <div style={styles.wrapper}>
      <Animated
        animationIn="fadeInDown"
        animationInDuration={500}
        animationInDelay={100}
        animateOnMount={true}>
        <img alt="Pathfinder Bridge" style={styles.logo} src={PFHorizLogo} />
      </Animated>
      <Animated
        animationIn="fadeInDown"
        animationInDuration={500}
        animationInDelay={100}
        animateOnMount={true}>
        <h2 style={styles.h2}>Now let's get started...</h2>
      </Animated>
      <Animated
        animationIn="fadeInDown"
        animationInDuration={500}
        animationInDelay={200}
        animateOnMount={true}>
        <p style={styles.body}>
          Thank you for all the work you do. We are here to help support the
          great efforts put forth by everyone in the recovery community, and to
          help those in recovery find a healthy, and successful path through
          life.
        </p>
      </Animated>
      <div style={styles.buttonRow}>
        <Animated
          animationIn="fadeInLeft"
          animationInDuration={500}
          animationInDelay={300}
          animateOnMount={true}>
          <Button id="restart-button" style={styles.restartButton}>
            <span style={styles.restartText}>Restart the tutorial</span>
          </Button>
        </Animated>
        <Animated
          animationIn="fadeInRight"
          animationInDuration={500}
          animationInDelay={300}
          animateOnMount={true}>
          <Button
            data-testid="get-started-button"
            id="finishButton"
            style={styles.finishButton}>
            <span style={styles.finishText}>Get Started</span>
          </Button>
        </Animated>
      </div>
      <Animated
        animationIn="fadeInDown"
        animationInDuration={500}
        animationInDelay={400}
        animateOnMount={true}>
        <p style={styles.body}>
          If you have any questions or feedback, please don't hesitate to
          contact us at{' '}
          <a style={styles.email} href={'mailto:info@pfsbc.com'}>
            info@pfsbc.com
          </a>
        </p>
      </Animated>
    </div>
  );
};

const styles = {
  logo: {
    width: '20vw',
    height: '7.5vw',
  },
  h2: {
    fontSize: 26,
    color: '#ffffff',
  },
  body: {
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 500,
    fontSize: 18,
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  },
  restartText: {
    color: COLORS.foregroundBlue,
  },
  restartButton: {
    border: '1px solid ' + COLORS.foregroundBlue,
    backgroundColor: COLORS.background,
    color: COLORS.foregroundBlue,
  },
  finishText: {
    color: 'white',
  },
  email: {
    color: COLORS.foregroundBlue,
  },
  finishButton: {
    border: '1px solid ' + COLORS.foregroundBlue,
    backgroundColor: COLORS.foregroundBlue,
    color: 'white',
  },
  wrapper: {
    width: '30vw',
    display: 'flex',
    height: 'calc(100% - 56px)',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#d6d6d6',
    margin: '0 auto',
    textAlign: 'center',
  },
};
