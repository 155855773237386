// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Chip} from '@wellstone-solutions/web';
import {pluralize} from 'utils/Utils';

type PropsType = {
  count: number,
  primary?: boolean,
};

export const CountChip = ({count, primary = true}: PropsType): Node => {
  const label = `${count || '0'} ${pluralize('Answer', count)}`;

  return <Chip label={label} variant="filled" sx={countStyle(primary)} />;
};

const countStyle = (primary?: boolean = true) => ({
  height: 24,
  backgroundColor: primary
    ? Theme.colorPalette.purpleLightest
    : Theme.colorPalette.seafoamLight,
  color: primary ? Theme.colorPalette.purple : Theme.colorPalette.seafoamDarker,
  fontSize: 10,
  fontWeight: 'bold',
  cursor: 'pointer',
});
