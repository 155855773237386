// @flow
import React from 'react';
import type {Node} from 'react';
import type {FormType} from '@wellstone-solutions/common';
import type {WizardStepType} from './';

type PropsType = {
  step: WizardStepType,
  form: FormType<any>,
  onNext?: () => void,
  isComplete: boolean,
};

export const WizardStep = ({
  step,
  form,
  onNext,
  isComplete,
}: PropsType): Node => {
  const StepComponent = step.component;

  return (
    <div style={styles.stepContainer}>
      {/* $FlowFixMe */}
      <StepComponent
        form={form}
        step={step}
        onNext={onNext}
        isComplete={isComplete}
      />
    </div>
  );
};

const styles = {
  stepContainer: {},
};
