// @flow
import type {
  UICalendarEventType,
  UICalendarEventInvitationType,
} from '@wellstone-solutions/common';
import {concatDateTime} from '../utils';
import type {ScheduledEventType} from '../types';

export const toScheduledEvent = ({
  calendarEvent,
  invitations,
  myUserId,
}: {
  calendarEvent: UICalendarEventType,
  invitations: Array<UICalendarEventInvitationType>,
  myUserId: string,
}): ScheduledEventType => {
  const myInvite = (invitations || []).find(
    (invite) => invite.user === myUserId,
  );
  const isAttending = myInvite ? myInvite.isAttending : null;

  return {
    allDay: calendarEvent.isAllDay,
    end: new Date(
      concatDateTime(calendarEvent.startDate, calendarEvent.endTime),
    ),
    isAttending,
    resource: calendarEvent,
    start: new Date(
      concatDateTime(calendarEvent.startDate, calendarEvent.startTime),
    ),
    title: calendarEvent.title,
  };
};
