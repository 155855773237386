// @flow
import React from 'react';
import type {Node} from 'react';
import {groupStructure} from 'utils/Forms';
import {NO_PROGRAM} from 'constants/groups';
import PfDropdown from '../components/PfDropdown';

type PropsType = {
  isDisabled?: boolean,
  value: string,
  onChange: (string) => void,
  programs: Array<{id: string, name: string}>,
};

export const ProgramsDropdown = ({
  isDisabled = false,
  value,
  onChange,
  programs,
}: PropsType): Node => {
  return (
    <PfDropdown
      fullWidth
      disabled
      val={value}
      label="Program*"
      labelPath="name"
      valuePath="id"
      selectFunc={(programId) => {
        onChange(programId);
      }}
      list={[
        {
          id: '',
          name: NO_PROGRAM,
        },
        ...programs,
      ]}
      classes="top-drop short-drop is-right"
      field={{
        name: 'programId',
        required: true,
        test: groupStructure.programId.test,
      }}
    />
  );
};
