// @flow
import React, {useRef, useEffect} from 'react';
import type {Node} from 'react';
import {Events, Hooks, Models} from '@wellstone-solutions/common';
import {DataGrid, usePaging} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {useMemberColumnMap} from 'modules/member';

const {usePaginatedData} = Hooks;
const {Member} = Models;
const EVENT_TABLE_NAME = 'ScatterChartMemberList';

type PropsType = {
  userIds: Array<string>,
};

export const MemberList = ({userIds}: PropsType): Node => {
  const initialLoad = useRef(true);
  const params = useRef({user_id: userIds});
  const {eventStore, meStore} = useStores();

  const initialSortField = 'name';
  const initialSortOrder = 'asc';
  const initialState = {
    sorting: {
      sortModel: [{field: initialSortField, sort: initialSortOrder}],
    },
  };

  const {
    offset,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
  } = usePaging({
    initialSortField,
    initialSortOrder,
  });

  const queryResults = usePaginatedData({
    url: Member.routes.index(meStore.me.membership.organization.id),
    params: params.current,
    dataTransformer: ({members, total}) => ({
      members: members.map((m) => Member.toUI(m)),
      total,
    }),
    pageSize,
    currentPage: offset,
    sortField,
    sortOrder,
  });

  const refetchData = () => {
    if (queryResults.refetch) {
      queryResults.refetch({
        pageSize,
        currentPage: offset,
        params: params.current,
        sortField,
        sortOrder,
      });
    }
  };

  useEffect(() => {
    if (!initialLoad.current) {
      refetchData();
    }
    initialLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, currentPage, pageSize, sortField, sortOrder]);

  const columnMap = useMemberColumnMap({refetch: refetchData});
  const columns = [
    columnMap.nameLink,
    columnMap.hasCommunityAccess,
    columnMap.identifiesAs,
    columnMap.groups,
  ];

  const handleSortChange = (sortedColumns) => {
    let updatedSortField = '';
    let updatedSortOrder = '';

    if (sortedColumns?.length > 0) {
      const [sortedColumn] = sortedColumns;
      updatedSortField = sortedColumn.field;
      updatedSortOrder = sortedColumn.sort;

      eventStore.addEvent(Events.USER_SORTED_TABLE, {
        sort_field: updatedSortField,
        sort_order: updatedSortOrder,
        table: EVENT_TABLE_NAME,
      });
    }

    setSortField(updatedSortField);
    setSortOrder(updatedSortOrder);
  };

  const handlePageChange = (updatedPage) => {
    setCurrentPage(updatedPage);
  };

  const handlePageSizeChange = (updatedPageSize) => {
    setPageSize(updatedPageSize);
  };

  return (
    <DataGrid
      autoHeight
      getRowHeight={() => 'auto'}
      loading={queryResults.isLoading}
      rows={queryResults.data?.members ?? []}
      columns={columns}
      noRowsText="No members"
      page={currentPage}
      pageSize={pageSize}
      paginationMode="server"
      rowCount={queryResults.data?.total ?? 0}
      sortingMode="server"
      initialState={initialState}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      onSortModelChange={handleSortChange}
      sx={{
        minWidth: '60vw',
      }}
    />
  );
};
