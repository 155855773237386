// @flow
import {useRef, useState} from 'react';
import {Validation} from '@wellstone-solutions/common';

type PropsType = {
  data: {id: string, startDate: string, endDate: string},
  setData: (any) => void,
  errors: mixed,
  canSave: () => boolean,
  reset: () => void,
};

export const useStartEndDateAndId = (): PropsType => {
  const initialValues = {id: '', startDate: '', endDate: ''};
  const [data, setData] = useState(initialValues);

  const schema = {
    id: Validation.isRequired,
    startDate: Validation.isRequired,
    endDate: {
      validate: Validation.dateIsAfter('startDate', true),
      error: 'End Date must be after Start Date',
    },
  };

  const validator = useRef(new Validation.ObjectValidator(schema));

  const errors = validator.current.validateWithErrors(data);

  const canSave = () => validator.current.validate(data);

  const reset = () => setData(initialValues);

  return {data, setData, errors, canSave, reset};
};
