// @flow
import React, {useEffect, useRef} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {Box} from '@wellstone-solutions/web';
import {
  Events,
  BridgeEventSources as EventSources,
} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {ResourceGrid} from '../ResourceGrid';
import {useResourceGridDataloader} from '../../dataloaders';
import {useResourcesContext} from '../../contexts';
import compassSvg from 'assets/images/compass.svg';
import {NoResourceMessage} from '../NoResourceMessage';

export const AllResources: ComponentType<{}> = observer((): Node => {
  useEventSource(EventSources.RESOURCES_PAGE);
  const {locationStore, eventStore} = useStores();
  const initialLoad = useRef(true);
  const {searchTerm, collectionFilter} = useResourcesContext();

  const {
    isLoading,
    resources,
    totalCount,
    onFilterChange,
    paginationProps,
  } = useResourceGridDataloader();

  useEffect(() => {
    if (initialLoad.current === false) {
      onFilterChange();
    } else {
      initialLoad.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, collectionFilter, locationStore.currentLocation]);

  useEffect(() => {
    eventStore.addEvent(Events.USER_VIEWED_RESOURCES);
  }, [eventStore]);

  useEffect(() => {
    if (
      initialLoad.current === false &&
      (searchTerm || collectionFilter?.length > 0)
    ) {
      eventStore.addEvent(Events.USER_FILTERED_RESOURCES, {
        query: searchTerm,
        collections: collectionFilter,
        result_count: totalCount,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  if (resources.length === 0 && (collectionFilter.length > 0 || searchTerm)) {
    return (
      <NoResourceMessage
        title="Sorry, no results found."
        imgSrc={compassSvg}
        imgAlt="Not found resources"
      />
    );
  }
  return (
    <Box>
      <ResourceGrid
        isLoading={isLoading}
        resources={resources}
        totalCount={totalCount}
        paginationProps={paginationProps}
      />
    </Box>
  );
});
