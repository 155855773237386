// @flow
import React from 'react';
import type {Node} from 'react';
import {Divider, Stack} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {TAG_CHIP_COLOR} from '../../constants';
import {Header} from './Header';
import {Content} from './Content';
import {ChipBlank} from './ChipBlank';
import {StoryTag} from './StoryTag';

type PropsType = {
  abstract?: string,
  host?: string,
  organization?: string,
  file?: mixed,
  markdownText?: string,
  mediaImage?: Blob | null,
  posterImageUrl?: string,
  tags?: Array<string>,
  title?: string,
};

export const FullStory = ({
  abstract = '',
  posterImageUrl = '',
  file = '',
  host = '',
  organization = '',
  title = '',
  markdownText = '',
  mediaImage,
  tags = [],
}: PropsType): Node => {
  const hasTags = tags.length > 0;

  const tagColor = (label: string): string => {
    const letter = label.charAt(0).toLowerCase();
    return TAG_CHIP_COLOR[letter];
  };

  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      sx={styles.container}>
      <Stack flexDirection="column">
        <Header
          title={title}
          host={host}
          organization={organization}
          mediaImage={mediaImage}
          posterImageUrl={posterImageUrl}
        />
        <Divider sx={styles.divider} />
        <Content markdownText={markdownText} />
      </Stack>

      {/* Tags */}
      <Stack direction="row" sx={styles.tagsContainer}>
        {hasTags
          ? tags.map((tag) => (
              <StoryTag
                key={tag}
                label={tag}
                style={{...styles.tag, backgroundColor: tagColor(tag)}}
              />
            ))
          : [...Array(3).keys()].map((_, id) => (
              <ChipBlank key={`blank-tag-${id}`} sx={styles.tagBlank} />
            ))}
      </Stack>
    </Stack>
  );
};

const styles = {
  container: {
    color: Theme.colorPalette.darkest,
    width: 300,
    borderBottom: `1px solid ${Theme.colorPalette.mediumLight}`,
    margin: 0,
    boxShadow: `0px 3px 5px ${Theme.colorPalette.medium}`,
  },
  divider: {
    marginTop: 2,
  },
  tagsContainer: {
    marginBottom: 2,
    px: 2,
  },
  tagBlank: {
    width: '10rem',
    mx: 1,
  },
  tag: {
    color: Theme.colorPalette.offWhite,
    mx: 0.5,

    borderRadius: 12,
    fontSize: 12,
    fontWeight: '700',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 2,
    paddingBottom: 2,
    marginRight: 4,
    marginBottom: 4,
    overflow: 'hidden',
  },
};
