// @flow
import {Api} from '@wellstone-solutions/common';
import {routes} from 'api';
import type {APIResourceQuestionType, getAllParamsType} from '../../types';

export const getAll = async (
  params: getAllParamsType,
): Promise<APIResourceQuestionType[]> => {
  const response = await Api.Instance.current().get(
    `${routes.resources.questions}`,
    params,
  );
  return response.isSuccess ? response.data.questions : [];
};
