// @flow
import {useState} from 'react';
import type {WizardStepType} from './';

type PropType = {
  steps: WizardStepType[],
  stepOverride?: number,
};

export const useWizard = ({steps, stepOverride}: PropType) => {
  const totalSteps = steps.length;
  const initialStepIndex =
    stepOverride && stepOverride < totalSteps ? stepOverride : 0;
  const [stepIndex, setStepIndex] = useState<number>(initialStepIndex);
  const [stepComplete, setStepComplete] = useState<boolean>(false);
  const isLastStep = stepIndex === totalSteps - 1;
  const progress = ((stepIndex + 1) / totalSteps) * 100;
  const step = steps[stepIndex];
  const checkCompleteness = (values: any) => {
    setStepComplete(step.isComplete(values));
  };

  return {
    step, // the current step object
    stepIndex, // the current step index
    progress, // number between 0 and 100 representing how far in the wizard process you are
    isLastStep,
    stepComplete,
    setStepIndex,
    checkCompleteness, // checks if the current step is complete or not
  };
};
