// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {
  IconButton,
  IconNames,
  Stack,
  Icon,
  ListItemIcon,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@wellstone-solutions/web';

type PropsType = {
  title: string,
  icon: string,
  message: string,
  handleClick: () => void,
  handleDismiss: () => void,
};

export const DefaultTemplate = ({
  title,
  icon,
  message,
  handleClick,
  handleDismiss,
}: PropsType): Node => (
  <ListItem sx={{borderBottom: `1px solid ${Theme.colorPalette.medium}`, p: 0}}>
    <ListItemButton onClick={handleClick} data-testid="notification-button">
      <ListItemIcon sx={{minWidth: '40px'}}>
        <Icon color={Theme.colorPalette.primary} name={icon} size={26} />
      </ListItemIcon>
      <ListItemText primary={title} secondary={message} />
    </ListItemButton>
    <Stack sx={{alignItems: 'center', justifyContent: 'center', ml: 'auto'}}>
      <IconButton
        iconProps={{
          color: Theme.colorPalette.mediumDark,
        }}
        icon={IconNames.Xmark}
        onClick={handleDismiss}
      />
    </Stack>
  </ListItem>
);
