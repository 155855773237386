// @flow
import React from 'react';
import type {Node} from 'react';
import {Stack, Typography} from '@wellstone-solutions/web';

type PropsType = {
  displayTimeInformation?: boolean,
  isAllDay?: boolean,
  startTime?: string,
  styles?: {[string]: string},
  title: string,
};

export const CalendarEvent = ({
  displayTimeInformation = true,
  isAllDay = false,
  startTime = '',
  styles = {},
  title,
}: PropsType): Node => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="space-between"
    sx={{
      ...styles,
      px: 0.75,
      py: 0.25,
      borderRadius: 1,
    }}>
    <Typography
      sx={{
        fontWeight: 'bold',
        fontSize: 12,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}>
      {title}
    </Typography>
    {displayTimeInformation && (
      <Typography
        sx={{
          fontSize: 11,
        }}>
        {isAllDay ? 'All Day' : startTime}
      </Typography>
    )}
  </Stack>
);
