// @flow
import React, {useState, useRef, useEffect} from 'react';
import type {Node} from 'react';
// TODO replace with import from common libs when available
import InputAdornment from '@mui/material/InputAdornment';
import {TextField, Icon, IconButton, IconNames} from '@wellstone-solutions/web';
import {useResourcesContext} from '../../contexts';
import {useLocation} from 'react-router-dom';

export const ResourceSearch = (): Node => {
  const {
    searchTerm,
    savedSearchTerm,
    setSearchTerm,
    setSavedSearchTerm,
  } = useResourcesContext();
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const searchInputRef = useRef(null);

  const location = useLocation();
  const isSavedPage = location.pathname.includes('/saved');

  const label = isSavedPage ? 'Search saved resources' : 'Search all resources';

  // Sync internal state with the correct search term based on the current page
  useEffect(() => {
    if (isSavedPage) {
      setValue(savedSearchTerm);
    } else {
      setValue(searchTerm);
    }
  }, [searchTerm, savedSearchTerm, isSavedPage]);

  const handleSearch = () => {
    if (isSavedPage) {
      setSavedSearchTerm(value);
    } else {
      setSearchTerm(value);
    }
  };

  const showPlaceholder = !isFocused && value.length === 0;

  useEffect(() => {
    setValue(searchTerm);
  }, [searchTerm]);

  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      value={value}
      size="normal"
      sx={styles.textField}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
      InputLabelProps={
        showPlaceholder ? {shrink: false, sx: styles.placeholder} : {}
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon name={IconNames.Search} />
          </InputAdornment>
        ),
        endAdornment: value.length > 0 && (
          <InputAdornment position="end">
            <IconButton
              aria-label="Clear search"
              icon={IconNames.Xmark}
              onClick={() => {
                setValue('');
                if (isSavedPage) {
                  setSavedSearchTerm('');
                } else {
                  setSearchTerm('');
                }
                if (searchInputRef.current) {
                  searchInputRef.current.focus();
                }
              }}
            />
          </InputAdornment>
        ),
      }}
      inputRef={searchInputRef}
    />
  );
};

const styles = {
  textField: {
    maxWidth: {
      sm: '360px',
    },
  },
  placeholder: {
    paddingLeft: '28px',
  },
};
