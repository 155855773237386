// @flow
import React from 'react';
import type {Node} from 'react';
import {Box, Button, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {NO_RESULTS_STRING} from '../../constants';

type PropsType = {
  clearFilters?: () => any,
};
export const NoResults = ({clearFilters}: PropsType): Node => (
  <Box sx={styles.container}>
    <Typography variant="h5" style={styles.message}>
      {NO_RESULTS_STRING}
    </Typography>
    {!!clearFilters && (
      <Button sx={{mt: 2}} onClick={() => clearFilters()} color="secondary">
        Clear Filters
      </Button>
    )}
  </Box>
);

const styles = {
  container: {
    mt: 8,
    backgroundColor: 'green',
    textAlign: 'center',
  },
  button: {
    mt: 2,
  },
  message: {
    color: Theme.colorPalette.dark,
  },
};
