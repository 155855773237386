// @flow
import {useCallback} from 'react';
import {Api} from '@wellstone-solutions/common';
import {richTextImageValidators} from 'utils/images';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';

type PropsType = {
  folder?: string,
  onError: (result: any) => void,
};

const TOAST_UI_OK_BUTTON = '.toastui-editor-ok-button';
const TOAST_UI_ALT_TEXT_INPUT = 'toastuiAltTextInput';

export const useToastUIImageUploadHandler = ({
  folder,
  onError,
}: PropsType): Function => {
  const handleImageUpload = useCallback(
    async (blob, callback) => {
      const formData = new FormData();
      formData.append('file', blob);

      if (folder && folder.length > 0) {
        formData.append('folder', folder);
      }

      const error = richTextImageValidators(blob);
      if (error) {
        showAlert(error, ALERT_TYPES.ERROR);
        return false;
      }

      const okButton = document.querySelector(TOAST_UI_OK_BUTTON);
      if (okButton) {
        okButton.setAttribute('disabled', 'true');
        okButton.style.opacity = '0.5';
      }

      const result = await Api.Instance.current().post(
        'uploads/media',
        formData,
      );

      if (result.isSuccess) {
        const {url} = result.data;
        // Adding check for test, at some point document is not there
        const altText = !document
          ? ''
          : document.getElementById(
              TOAST_UI_ALT_TEXT_INPUT,
              // $FlowIgnoreMe
            )?.value;
        callback(url, altText);
      } else {
        onError(result);
      }

      if (okButton) {
        okButton.removeAttribute('disabled');
        okButton.style.opacity = '1';
      }

      return result.isSuccess;
    },
    [folder, onError],
  );

  return handleImageUpload;
};
