// @flow
import {Transforms} from '@wellstone-solutions/common';
import type {APICategoryType, UICategoryType} from '../../types';
import {Factory} from './factories';

export const toUI = (category: APICategoryType): UICategoryType => {
  const uiCategory = Factory(Transforms.toUIObject(category));

  return uiCategory;
};
