// @flow
import React from 'react';
import type {Node} from 'react';
import type {GridColDef} from '@mui/x-data-grid';
import {Typography} from '@wellstone-solutions/web';
import type {UIStoryType} from '@wellstone-solutions/common';
import moment from 'moment';
import Tag from 'react-bulma-components/lib/components/tag';
import {Link} from 'react-router-dom';
import {STATUSES, STATUS_MAP} from '../../constants';

type ColumnMapType = {
  modified: GridColDef<UIStoryType>,
  titleLink: GridColDef<UIStoryType>,
  status: GridColDef<UIStoryType>,
  host: GridColDef<UIStoryType>,
};

export const useStoryColumnMap = (): ColumnMapType => {
  const TitleLinkCell = ({story}): Node => {
    return (
      <Typography noWrap style={styles.typography}>
        {/* $FlowFixMe */}
        <Link to={'/stories/' + story.id} title={story.title}>
          {story.title}
        </Link>
      </Typography>
    );
  };

  const StatusCell = ({story}): Node => {
    const displayStatus = STATUS_MAP[story.status];

    let color = 'light';
    if (story.status === STATUSES.published) {
      color = 'success';
    } else if (story.status === STATUSES.removed) {
      color = 'danger';
    }

    return (
      <Tag color={color} title={displayStatus}>
        {displayStatus}
      </Tag>
    );
  };

  const HostCell = ({story}): Node => (
    <Typography noWrap style={styles.typography}>
      {story.host}
    </Typography>
  );

  return {
    modified: {
      field: 'modified',
      headerName: 'Last Updated',
      type: 'date',
      flex: 1,
      valueGetter: ({row: story}) =>
        story.modified ? moment(story.modified).toDate() : null,
      valueFormatter: ({value}) =>
        value ? moment(value).format('MM/DD/YYYY') : null,
    },
    titleLink: {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: ({row: story}) => <TitleLinkCell story={story} />,
    },
    status: {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: ({row: story}) => <StatusCell story={story} />,
    },
    host: {
      field: 'host',
      flex: 1,
      headerName: 'Author',
      renderCell: ({row: story}) => <HostCell story={story} />,
    },
  };
};

const styles = {
  typography: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
};
