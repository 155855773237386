//@flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {hasFlag, RESOURCES_V2} from 'constants/FeatureFlags';
import {useStores} from 'hooks/useStores';
import {ResourcesProvider} from '../contexts';
import {ResourcesHeader} from './ResourcesHeader';

export const ResourcesRoot = (): Node => {
  const {meStore, appUIStore, resourceStoreV2} = useStores();

  useEffect(() => {
    appUIStore.setBreadcrumbs([]);
  }, [appUIStore]);

  useEffect(() => {
    resourceStoreV2.getUserResources();
  }, [resourceStoreV2]);

  if (!hasFlag(RESOURCES_V2, meStore.features)) {
    return <Navigate to="/" />;
  }

  return (
    <ResourcesProvider>
      <ResourcesHeader />
      <Outlet />
    </ResourcesProvider>
  );
};
