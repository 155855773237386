// React
import React, {useContext} from 'react';
import 'emoji-mart/css/emoji-mart.css';

// Bulma
import Box from 'react-bulma-components/lib/components/box';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';

import EventStream from 'components/member/EventStream';
import TutorialDiv from 'components/tutorial/tutorialdiv';
import {Palette} from 'palette';
import {MemberDetailTabContext} from '../../contexts/MemberDetailTabContext';

export const GeneralTabContent = () => {
  const {state, member, getChatModule, searchBoxTally} = useContext(
    MemberDetailTabContext,
  );
  const {mainColumn, recentEvents} = state || {};

  return (
    <Columns>
      <Columns.Column size={mainColumn} style={styles.boxrelative}>
        <div style={styles.infoBlock}>
          <Heading style={styles.heading} size={4}>
            Recent Events
          </Heading>
          <TutorialDiv id="recent-events-wrapper" key={searchBoxTally}>
            <Box style={styles.streamContainer}>
              <EventStream
                events={recentEvents}
                memberName={member.name}
                memberID={member.id}
                userID={member.user.id}
              />
            </Box>
          </TutorialDiv>
        </div>
      </Columns.Column>
      <Columns.Column size={12 - mainColumn} style={styles.boxrelative}>
        <Heading style={styles.heading} size={4}>
          Chat
        </Heading>
        {getChatModule()}
      </Columns.Column>
    </Columns>
  );
};

const styles = {
  infoBlock: {
    marginBottom: 20,
  },
  heading: {
    color: Palette.GREY_4,
  },
  boxrelative: {
    position: 'relative',
    backgrouncColor: '#FFF',
  },
  streamContainer: {
    position: 'relative',
  },
};
