// @flow
import {Transforms} from '@wellstone-solutions/common';
import {isSnakeCase} from 'utils/isSnakeCase';
import type {APIContactType, UIContactType} from '../../types';
import {Factory} from './factories';

export const toUI = (contact: APIContactType): UIContactType => {
  const uiContact = {...contact};

  // The contactModel is nested in an answer, which will recursively
  // convert to pascalCasing.  If used outside of an answer
  // we still want to ensure we transform snake_case to camelCase
  return Factory(
    isSnakeCase(uiContact) ? Transforms.toUIObject(uiContact) : uiContact,
  );
};

export const toAPI = (contact: UIContactType): APIContactType => {
  const apiContact = {...contact};

  return isSnakeCase(apiContact)
    ? apiContact
    : Transforms.toApiObject(apiContact);
};
