// @flow
import {useCallback, useState, useEffect} from 'react';
import type {UIStoryType} from '@wellstone-solutions/common';
import {useNavigate} from 'react-router-dom';
import {useStores} from 'hooks/useStores';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';

export const useDataLoader = ({
  initialFetch = true,
  storyId,
}: {
  initialFetch?: boolean,
  storyId: string,
}): ({
  isLoading: boolean,
  data?: {story: ?UIStoryType},
  fetchData: () => Promise<void>,
}) => {
  const navigate = useNavigate();
  const {storyStore} = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [story, setStory] = useState(undefined);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const result = await storyStore.getStory(storyId);
    if (result.data) {
      setStory(result.data);
      setIsLoading(false);
    } else {
      showAlert('Could not load story', ALERT_TYPES.ERROR);
      navigate('/stories');
    }
  }, [storyStore, storyId, navigate]);

  useEffect(() => {
    if (initialFetch) {
      fetchData();
    }
  }, [fetchData, initialFetch]);

  return {isLoading, data: {story}, fetchData};
};
