// @flow
import React from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {Box, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import type {
  UIResourceQuestionType,
  UIResourceAnswerType,
} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {sortBy} from 'utils/Utils';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from '../StyledAccordion';
import {CategoryIcon} from '../CategoryIcon';
import {SuspendedQuestions} from '../SuspendedComponents';
import {CountChip} from '../CountChip';
import {NoResults} from '../NoResults/NoResults';

type PropsType = {
  clearFilters: () => void,
  shareResource: (
    resource: UIResourceQuestionType | UIResourceAnswerType,
    action?: string,
  ) => void,
  isModal: boolean,
};

export const CategoryAccordion: ComponentType<PropsType> = observer(
  ({clearFilters, shareResource, isModal}: PropsType): Node => {
    const {resourceStore} = useStores();
    const categories = resourceStore.categoriesArray;

    return (
      <div
        data-testid="accordion-wrapper"
        style={isModal ? styles.container : {}}>
        {sortBy(categories || [], 'name').map((category, c) => (
          <StyledAccordion TransitionProps={{unmountOnExit: true}} key={c}>
            <StyledAccordionSummary>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <CategoryIcon name={category.name} />
                <Typography variant="subtitle1" sx={styles.categoryName}>
                  {category.name}
                </Typography>
                <CountChip count={category.totalAnswerCount} />
              </Box>
            </StyledAccordionSummary>
            <StyledAccordionDetails sx={{p: 0}}>
              <SuspendedQuestions
                categoryId={category.id}
                shareResource={shareResource}
                isModal={isModal}
              />
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
        {categories.length === 0 && <NoResults clearFilters={clearFilters} />}
      </div>
    );
  },
);

const styles = {
  categoryName: {
    fontWeight: Theme.typography.weight.bold,
    color: 'primary.main',
    display: 'flex',
    alignItems: 'center',
    ml: 2.2,
    mr: 1,
  },
  container: {
    overflowY: 'scroll',
    height: 'calc(100% - 90px)',
  },
};
