// @flow
import React from 'react';
import type {Node} from 'react';
// $FlowFixMe
import {Routes as RouterRoutes, Route} from 'react-router-dom';
import {RouteElement} from 'components/RouteElement';
import type {RouteType} from 'types';

type PropsType = {
  routes: Array<RouteType>,
};

export const Routes = ({routes}: PropsType): Node => {
  const renderRoutes = (routeArray: Array<RouteType>) => {
    return routeArray.map((route, routeIndex) => {
      const {
        path,
        index,
        Component,
        isProtected,
        allowed,
        children,
        isDisabled,
        ...rest
      } = route;

      return (
        <Route
          {...rest}
          path={path ? path : null}
          index={index ? index : null}
          key={routeIndex}
          element={
            <RouteElement
              isProtected={isProtected}
              allowedRoles={allowed}
              isDisabled={isDisabled}>
              {/* $FlowIgnoreMe */}
              <Component />
            </RouteElement>
          }>
          {children && children.length > 0 && renderRoutes(children)}
        </Route>
      );
    });
  };

  return <RouterRoutes>{renderRoutes(routes)}</RouterRoutes>;
};
