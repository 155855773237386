// @flow
import React from 'react';
import type {Node} from 'react';
import {Stack, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  label: string,
  value: string,
  labelSX?: any,
  valueSX?: any,
};

export const CompletedRow = ({
  label,
  value,
  labelSX = {},
  valueSX = {},
}: PropsType): Node => (
  <Stack direction="row">
    <Typography sx={{...styles.label, ...labelSX}}>{`${label}:`}</Typography>
    <Typography sx={{...styles.value, ...valueSX}}>{value}</Typography>
  </Stack>
);

const styles = {
  label: {
    color: Theme.colorPalette.darkest,
    fontWeight: 'bold',
  },
  value: {
    color: Theme.colorPalette.darkest,
    ml: 1,
  },
};
