// MESSAGE TYPES
export const Types = {
  CALENDAR_EVENT: 'calendar_event',
  CHECKIN: 'checkin',
  ANNOUNCEMENT: 'announcement',
  MESSAGE: 'message',
  FRIEND_REQUEST: 'friend_request',
  CONTACT: 'contact',
  BADGE: 'user_badge',
  CHANNEL: 'channel',
  EVENT: 'event',
  SESSION_DOCUMENT: 'session_documentation',
};

// ACTIONS
export const Actions = {
  CANCELED: 'canceled',
  CREATED: 'created',
  PROGRESS_EARNED: 'progress_earned',
  PUBLISHED: 'published',
  SHARED_MEETING: 'shared_meeting',
  SHARED_RESOURCE: 'shared_resource',
  SHARED_RESOURCE_ANSWER: 'shared_resource_answer',
  SHARED_STORY: 'shared_story',
  UPDATED: 'updated',
};
