// @flow
import type {SearchFilterField} from '@wellstone-solutions/web';

// NOTE: You must use underscores for fields as the dot notation does not
// work with the `useQueryString` hook, the resourceStore converts back to
// dot notiation.
export const SEARCH_FILTER_FIELDS: SearchFilterField[] = [
  {field: 'question_title', label: 'Question'},
  {field: 'answer_title', label: 'Title'},
  {field: 'answer_subtitle', label: 'Subtitle'},
  {field: 'answer_description', label: 'Description'},
  {field: 'answer_organization_title', label: 'Organization'},
  {field: 'answer_contact_title', label: 'Contact Name'},
  {field: 'answer_contact_email', label: 'Email'},
  {field: 'answer_contact_address1', label: 'Address'},
  {field: 'answer_contact_city', label: 'City'},
];

export const NO_RESULTS_STRING =
  "Sorry, we couldn't find any results matching your criteria.";
