// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Stack, Typography} from '@wellstone-solutions/web';
import {
  Events,
  Hooks,
  Models,
  Theme,
  Transforms,
} from '@wellstone-solutions/common';
import type {
  ApiResponseType,
  UICalendarEventType,
  UICalendarEventInvitationType,
} from '@wellstone-solutions/common';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import {useStores} from 'hooks/useStores';
import {PfButton} from 'components/shared/PfButton';
import {Form} from '../Form';
import {AdjacentEvents} from '../AdjacentEvents';
import {RecurringUpdateModal} from './RecurringUpdateModal';
import {CancelEventActions} from '../../CancelEventActions';

const {useForm} = Hooks;

type PropsType = {
  calendarEvent: UICalendarEventType,
  invitations: Array<UICalendarEventInvitationType>,
};

const FORM_ID = 'edit_form';

export const Edit = ({calendarEvent, invitations}: PropsType): Node => {
  const [showRecurringModal, setShowRecurringModal] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);

  const navigate = useNavigate();

  const {appUIStore, eventStore, calendarStore} = useStores();

  const eventName = calendarEvent ? calendarEvent.title : 'Invalid Event';

  const form = useForm({
    initialValues: {
      ...calendarEvent,
      invitations,
    },
    schema: Models.CalendarEvent.schema,
    onSubmit: async (values, {resetForm}) => {
      if (calendarEvent.isRecurring && values.isRecurring) {
        setShowRecurringModal(true);
      } else {
        handleUpdate(values.isRecurring);
      }
    },
  });

  const handleUpdate = async (
    applyToAll: boolean,
  ): Promise<ApiResponseType<mixed>> => {
    const response = await calendarStore.updateCalendarEvent(
      form.values,
      applyToAll,
    );

    if (response.isSuccess) {
      // check if we need to send event for new participants
      const newInvites = response.data.invitations.map((invite) => invite.id);
      const oldInvites = form.initialValues.invitations.map(
        (invite) => invite.id,
      );
      const hasNewInvites = !newInvites.every((invite) =>
        oldInvites.includes(invite),
      );

      if (hasNewInvites) {
        const invitationData = {
          originalStart: response.data.original_start,
          applyToAll,
        };

        eventStore.addEvent(Events.INVITED_PARTICIPANTS, {
          calendar_event_id: response.data.id,
          count: form.values.invitations.length,
          ...Transforms.toApiObject(invitationData),
        });
      }

      onSuccess();
    } else {
      showAlert('Could not update event at this time.', ALERT_TYPES.ERROR);
    }

    return response;
  };

  const onSuccess = () => {
    showAlert('Successfully updated event.');

    calendarStore.getAllCalendarEvents();

    navigate('/calendar');
  };

  const onCancel = () => {
    showAlert('Event was canceled');

    calendarStore.getAllCalendarEvents();

    navigate('/calendar');
  };

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: NAVIGATION_NAMES.calendar,
        url: '/calendar',
      },
      {
        name: eventName,
        url: `/calendar/calendar-event/${String(calendarEvent.id)}`,
        active: true,
      },
    ]);
  }, [calendarEvent, eventName, appUIStore]);

  return (
    <>
      <RecurringUpdateModal
        form={form}
        handleRecurringUpdate={handleUpdate}
        setShowRecurringModal={setShowRecurringModal}
        showRecurringModal={showRecurringModal}
      />

      <Box>
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          justifyContent="space-between">
          <Typography
            variant="h5"
            sx={{color: Theme.colorPalette.primary, mb: 2}}>
            Edit Event Details
          </Typography>
        </Stack>
        <Stack
          direction={{xs: 'column', md: 'row'}}
          justifyContent={{md: 'space-between'}}
          sx={{mb: 4}}>
          <Stack
            flex={1}
            direction="column"
            justifyContent={{md: 'space-between'}}
            sx={{mb: 4, pr: {md: 8}}}>
            <Box sx={{flex: 1}}>
              <Form formId={FORM_ID} form={form} />
            </Box>
            <Box
              sx={{
                flex: 1,
                mt: 4,
                justifyContent: 'space-between',
                display: 'flex',
              }}>
              <PfButton
                isDisabled={!form.isValid || !form.dirty}
                form={FORM_ID}
                color="primary"
                type="submit"
                onClick={() => {}}>
                Update Event
              </PfButton>
              <CancelEventActions
                isModalOpen={showCancelConfirm}
                isRecurringEvent={calendarEvent.isRecurring}
                calendarEvent={calendarEvent}
                setShowModal={setShowCancelConfirm}
                onSuccess={onCancel}
              />
            </Box>
          </Stack>

          <AdjacentEvents startDate={form.values.startDate} />
        </Stack>
      </Box>
    </>
  );
};
