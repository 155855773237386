// @flow
import React from 'react';
import type {Node} from 'react';
import {Divider} from '@mui/material';
import Modal from 'react-bulma-components/lib/components/modal';
import type {ApiResponseType} from '@wellstone-solutions/common';
import {Box, Stack, Typography} from '@wellstone-solutions/web';
import {PfButton} from 'components/shared/PfButton';

type PropsType = {
  form: any,
  handleRecurringUpdate: (
    applyToAll: boolean,
  ) => Promise<ApiResponseType<mixed>>,
  setShowRecurringModal: (value: boolean) => void,
  showRecurringModal: boolean,
};

export const RecurringUpdateModal = ({
  form,
  handleRecurringUpdate,
  setShowRecurringModal,
  showRecurringModal,
}: PropsType): Node => (
  <Modal
    onClose={() => {
      setShowRecurringModal(false);
    }}
    show={showRecurringModal}
    closeOnBlur={true}
    closeOnEsc={true}>
    <Modal.Content>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 3,
          p: 2,
        }}>
        <Typography sx={{fontSize: '1.5rem', fontWeight: 300}}>
          Update Event
        </Typography>
        <Divider sx={{mt: 2, mb: 4}} />
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          justifyContent="space-between">
          <PfButton
            color="primary"
            outlined
            style={styles.defaultButton}
            onClick={() => {
              handleRecurringUpdate(false);
            }}>
            This Event Only
          </PfButton>
          <PfButton
            color="primary"
            style={styles.defaultButton}
            onClick={() => {
              handleRecurringUpdate(true);
            }}>
            This and Following Events
          </PfButton>
          <PfButton
            color="light"
            style={styles.defaultButton}
            onClick={() => {
              setShowRecurringModal(false);
            }}>
            Cancel
          </PfButton>
        </Stack>
      </Box>
    </Modal.Content>
  </Modal>
);

const styles = {
  defaultButton: {margin: 8},
};
