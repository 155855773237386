// @flow
import {toUIObject} from 'utils/toUIObject';
import type {
  ApiHabitType,
  ApiMemberHabitType,
  UIHabitType,
  UIMemberHabitType,
} from './types';

export const toUI = (payload: ApiHabitType): UIHabitType => {
  const ui = toUIObject(payload);
  const tags = ui.tags.map((t) => t.name);

  return {
    ...ui,
    tags,
  };
};

export const toMemberUI = (payload: ApiMemberHabitType): UIMemberHabitType => {
  return {
    habitId: payload.habit_id,
    isMorning: payload.is_morning,
    isAfternoon: payload.is_afternoon,
    isEvening: payload.is_evening,
  };
};

export const toApi = (habit: UIMemberHabitType): ApiMemberHabitType => {
  return {
    habit_id: habit.habitId,
    is_morning: habit.isMorning,
    is_afternoon: habit.isAfternoon,
    is_evening: habit.isEvening,
  };
};
