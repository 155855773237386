// React
import React, {useState, useEffect, useCallback} from 'react';
import {inject, observer} from 'mobx-react';

// Bulma
import BDropdown from 'react-bulma-components/lib/components/dropdown';
import Columns from 'react-bulma-components/lib/components/columns';

// WS
import {BridgeEventSources, Events} from '@wellstone-solutions/common';
import {useEventSource} from 'hooks/useEventSource';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import ConversationsList from '../../components/list/conversation/List';
import PfChatModule from '../../components/chat/PfChatModule';
import Dropdown from '../../components/tutorial/dropdown';
import {useStores} from 'hooks/useStores';

const ConversationsPage = (props) => {
  useEventSource(BridgeEventSources.CONVERSATIONS_PAGE);
  const [selected, setSelected] = useState(null);
  const [channelCollection, setChannelCollection] = useState('all');
  const {channelStore, eventStore} = useStores();

  // TODO: replacing url allows for browser navigation, but is buggy
  // const onSelect = useCallback(
  //   async (channel) => {
  //     const url = channel
  //       ? '/conversations/' + channel.id
  //       : '/conversations/';
  //     props.history.replace(url);
  //     setSelected(channel);
  //   },
  //   [props.history]
  // );

  const setCrumbs = useCallback(async () => {
    try {
      props.appUIStore.setBreadcrumbs([
        {
          name: NAVIGATION_NAMES.conversations,
          url: '/conversations',
          active: true,
        },
      ]);
    } catch (e) {}
  }, [props.appUIStore]);

  const chooseCollection = useCallback(
    (val) => {
      setChannelCollection(val);
      // props.history.replace('/conversations/');
      setSelected(null);
      channelStore.loadChannels(true, val === 'unread');
    },
    [channelStore],
  );

  useEffect(() => {
    setCrumbs();

    eventStore.addEvent(Events.VIEWED_CONVERSATIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCrumbs]);

  return (
    <div>
      <Columns>
        <Columns.Column style={styles.list}>
          <div style={styles.dropdownContainer}>
            <Dropdown value={channelCollection} onChange={chooseCollection}>
              <BDropdown.Item key={0} value={'all'}>
                All Conversations
              </BDropdown.Item>
              <BDropdown.Item key={1} value={'unread'}>
                Unread Conversations
              </BDropdown.Item>
            </Dropdown>
          </div>
          <ConversationsList selected={selected} select={setSelected} />
        </Columns.Column>
        <Columns.Column>
          {selected && (
            <div style={styles.topMargin}>
              <PfChatModule channel={selected.id} />
            </div>
          )}
        </Columns.Column>
      </Columns>
    </div>
  );
};

const styles = {
  dropdownContainer: {
    marginBottom: '15px',
  },
  topMargin: {
    marginTop: '55px',
  },
  list: {
    minWidth: '300px',
  },
};

export default inject('appUIStore')(observer(ConversationsPage));
