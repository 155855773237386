// @flow
import React from 'react';
import type {Node} from 'react';
import {SuspendedComponent} from '@wellstone-solutions/web';
import type {
  UIResourceQuestionType,
  UIResourceAnswerType,
} from '@wellstone-solutions/common';
import {CategoryAccordion} from '../CategoryAccordion';
import {LoadingIndicator} from 'components/shared/LoadingIndicator';
import {useDataLoader} from '../../dataloaders/categoriesDataLoader';

export const SuspendedCategories = ({
  clearFilters,
  shareResource,
  hasFiltersApplied,
  isModal,
}: {
  clearFilters: () => void,
  shareResource: (
    resource: UIResourceQuestionType | UIResourceAnswerType,
    action?: string,
  ) => void,
  hasFiltersApplied: boolean,
  isModal: boolean,
}): Node => {
  // `keyProp` is not necessary here so we hardcode it
  return (
    <SuspendedComponent
      loaderComponent={<LoadingIndicator stylesOverride={indicatorStyle} />}
      dataLoaderArgs={{hasFiltersApplied}}
      useDataLoader={useDataLoader}
      keyProp={1}>
      <CategoryAccordion
        clearFilters={clearFilters}
        shareResource={shareResource}
        isModal={isModal}
      />
    </SuspendedComponent>
  );
};

const indicatorStyle = {
  marginTop: -6,
};
