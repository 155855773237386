// @flow
import React from 'react';
import type {Node} from 'react';

export const StoryTag = ({
  label,
  style,
}: {
  label: string,
  style: {[string]: string | number},
}): Node => {
  return <div style={style}>{label}</div>;
};
