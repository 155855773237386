// @flow

import moment from 'moment';
import {Models, Transforms} from '@wellstone-solutions/common';
import {getFullMomentDateTimes} from 'utils/getFullMomentDateTimes';
import type {
  ApiSessionDocumentInputType,
  UISessionDocumentType,
  ApiSessionDocumentType,
  UISessionServiceType,
  ApiSessionServiceType,
} from 'modules/documentation/model/types';
import {TIME_FORMAT_24} from '../constants';
import {Factory} from './factories';
const {MemberBasic} = Models;

const {omitKeys, toUIObject, toApiObject} = Transforms;

// create and update
export const toInputApi = (
  sessionDoc: UISessionDocumentType,
): ApiSessionDocumentInputType => {
  const {
    member,
    staff,
    endTime,
    dateOfService,
    typeOfService,
    startTime,
    ...rest
  } = sessionDoc;

  let end;
  let start;
  if (dateOfService) {
    const momentDates = getFullMomentDateTimes(
      dateOfService,
      startTime,
      endTime,
    );
    end = momentDates.end?.toISOString() || '';
    start = momentDates.start?.toISOString();
  }

  const keysToOmit = ['id', 'created', 'modified', 'completed', 'reverted'];

  return {
    ...toApiObject(omitKeys(rest, keysToOmit)),
    member_id: member.id,
    staff_id: staff.id,
    start_time: start,
    end_time: end,
    date_of_service: dateOfService
      ? moment(dateOfService).format('YYYY-MM-DD')
      : null,
    type_of_service_ids: typeOfService.map(({id}) => id),
  };
};

// transform api response shape to ui response shape
export const toUI = (
  sessionDoc: ApiSessionDocumentType,
): UISessionDocumentType => {
  const {
    member,
    staff,
    start_time,
    end_time,
    type_of_service,
    ...rest
  } = sessionDoc;

  const startTime = moment(start_time).format(TIME_FORMAT_24); // 24 hr is required for form fields
  const endTime = end_time ? moment(end_time).format(TIME_FORMAT_24) : end_time;

  return Factory({
    ...toUIObject({
      ...rest,
      start_time: startTime,
      end_time: endTime,
    }),
    member: MemberBasic.toUI(member),
    staff: MemberBasic.toUI(staff),
    typeOfService: serviceTypesToUIList(type_of_service),
  });
};

export const serviceTypesToUIList = (
  apiServiceTypes: Array<ApiSessionServiceType>,
): Array<UISessionServiceType> =>
  apiServiceTypes.map((serviceType: ApiSessionServiceType) => ({
    id: serviceType.id,
    name: serviceType.service_type.name,
  }));
