// @flow
import React from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {Box, Button, Icon, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import type {
  UIResourceQuestionType,
  UIResourceAnswerType,
} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {hasFlag, TMP_SHARE_ANSWER} from 'constants/FeatureFlags';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from '../StyledAccordion';
import {SuspendedAnswers} from '../SuspendedComponents';
import {CountChip} from '../CountChip';
import {NoResults} from '../NoResults';

type PropsType = {
  data: Array<UIResourceQuestionType>,
  categoryId: string,
  shareResource: (
    resource: UIResourceQuestionType | UIResourceAnswerType,
    action?: string,
  ) => void,
  isModal: boolean,
};

export const QuestionAccordion: ComponentType<PropsType> = observer(
  ({shareResource, categoryId, isModal}: PropsType): Node => {
    const {resourceStore, meStore} = useStores();
    const questions = resourceStore.questions.get(categoryId);

    if (!questions || questions.length <= 0) {
      return <NoResults />;
    }

    return (
      <>
        {questions.map((question) => (
          <StyledAccordion
            TransitionProps={{timeout: 300, unmountOnExit: true}}
            sx={styles.accordion}
            key={question.id}>
            <StyledAccordionSummary sx={styles.summary}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Typography sx={styles.title}>{question.title}</Typography>
                <CountChip count={question.totalAnswerCount} primary={false} />
              </Box>
              {/* Remove TMP_SHARE_ANSWER feature flag, see PAT-4212 */}
              {!hasFlag(TMP_SHARE_ANSWER, meStore.features) && (
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  sx={styles.share}
                  onClick={(e) => {
                    e.stopPropagation();
                    shareResource(question);
                  }}>
                  <Icon
                    name="ShareIos"
                    color="white"
                    size={18}
                    strokeWidth={2}
                  />
                  Share
                </Button>
              )}
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <SuspendedAnswers
                categoryId={categoryId}
                questionId={question.id}
                shareResource={shareResource}
                isModal={isModal}
              />
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </>
    );
  },
);

const styles = {
  accordion: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.05)',
    '&.Mui-expanded': {
      backgroundColor: Theme.colorPalette.light,
    },
  },
  summary: {
    py: 3,
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  title: {
    fontSize: 16,
    color: 'primary.main',
    mr: 1,
  },
  share: {
    mr: 4,
    flexShrink: 0,
    '& svg': {
      mr: 0.5,
    },
  },
};
