// @flow
import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {format} from 'd3-format';
import {Box, Grid, Typography} from '@wellstone-solutions/web';
import type {UIAnalyticsDatasetType} from '@wellstone-solutions/common';
import {AnalyticsCard, AnalyticsCardSkeleton} from '../AnalyticsCard';
import {CARD_TYPES, CARD_MAP} from '../../constants';
import {NoAnalyticsResults} from './NoAnalyticsResults';

type PropsType = {
  isLoading: boolean,
  isAll?: boolean,
  columns: Array<string>,
  currentDataset?: UIAnalyticsDatasetType,
  priorDataset?: UIAnalyticsDatasetType,
  noResultsText?: string,
  filterType?: string,
};

const getAllOrDefault = (dataset: {data: Array<{programId: string}>}, isAll) =>
  isAll
    ? dataset.data.find((d) => d.programId === 'all')
    : dataset.data.find((d) => d.programId !== 'all');

export const AnalyticsGrid = ({
  isLoading,
  isAll = false,
  columns,
  currentDataset,
  priorDataset,
  noResultsText = 'No analytics data available.',
  filterType,
}: PropsType): Node => {
  const [cardData, setCardData] = useState({});
  const [lastUpdated, setLastUpdated] = useState();

  // Build <AnalyticsCard /> props object form current and prior datasets
  useEffect(() => {
    if (currentDataset && currentDataset.data.length) {
      if (currentDataset.data[0].endDate) {
        setLastUpdated(moment(currentDataset.data[0].endDate).format('M/D/Y'));
      }

      // When showing all data, grab the data set for "all"
      const currentValues = getAllOrDefault(currentDataset, isAll);
      const priorValues = getAllOrDefault(priorDataset, isAll) || [];

      const data = {};
      columns.forEach((columnName) => {
        if (
          typeof currentValues === 'undefined' ||
          typeof priorValues === 'undefined' ||
          !currentValues?.hasOwnProperty(columnName) ||
          !CARD_MAP[columnName]
        ) {
          return;
        }

        const currentValue = currentValues[columnName];
        const {title, tooltip, cardType, valueFormat} = CARD_MAP[columnName];
        const isComparison = priorDataset && cardType === CARD_TYPES.comparison;
        const priorValue = isComparison ? priorValues[columnName] : undefined;
        const interval = isComparison ? priorDataset?.interval : undefined;
        const formatter = valueFormat ? format(valueFormat) : undefined;

        data[columnName] = {
          cardType,
          title,
          tooltip,
          currentValue,
          priorValue,
          interval,
          formatter,
        };
      });

      setCardData(data);
    }
  }, [columns, currentDataset, isAll, priorDataset]);

  if (!isLoading && currentDataset && currentDataset.data.length === 0) {
    return (
      <NoAnalyticsResults
        noResultsText={noResultsText}
        filterType={filterType}
      />
    );
  }

  return (
    <Box>
      <Grid
        container
        spacing={{
          xs: 2,
          xl: 4,
        }}
        data-testid="analytics-grid">
        <Grid item xs={12} sx={{textAlign: 'end'}}>
          <Typography>
            {`Last Updated ${
              !isLoading && !!lastUpdated ? lastUpdated : '...'
            }`}
          </Typography>
        </Grid>
        {columns.map((column, key) => (
          <Grid item key={key} xs={12} sm={6} md={4} lg={3}>
            {isLoading || !cardData[column] ? (
              <AnalyticsCardSkeleton />
            ) : (
              <AnalyticsCard {...cardData[column]} />
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
