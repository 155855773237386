// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import {
  Box,
  Button,
  DatePicker,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {GCS_BASE_URL, GCS_DEFAULT_BUCKET} from 'constants/CloudStorage';
import type {UISessionDocumentType} from '../../../model/types';

type PropsType = {
  open: boolean,
  setOpen: (state: boolean) => void,
  onConfirm: (submittedAt: string) => Promise<UISessionDocumentType | void>,
  documentation?: UISessionDocumentType,
};

export const ConfirmSubmit = ({
  open,
  setOpen,
  onConfirm,
  documentation,
}: PropsType): Node => {
  const [submittedDate, setSubmittedDate] = useState('');

  const onDialogClose = () => {
    setSubmittedDate('');
    setOpen(false);
  };

  return (
    <Dialog
      onClose={onDialogClose}
      open={open}
      data-testid="document-submit-confirm"
      PaperProps={{
        sx: styles.paper,
      }}>
      <DialogTitle>
        <div style={styles.headerBar} />
        <Typography sx={styles.title} variant="h5">
          Document submitted to billing
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          maxWidth: 500,
          px: {
            sm: 8,
          },
        }}>
        Tagging this document means that you've uploaded the PDF to Procentive
        to process for billing.
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', paddingBottom: 4, px: 4}}>
        <Stack>
          <Box sx={styles.actionItem}>
            <DatePicker
              minDate={documentation?.completed}
              maxDate={new Date()}
              fieldName=""
              label="Date submitted to billing"
              value={submittedDate}
              onChange={(date) => {
                setSubmittedDate(date?.isValid() ? date.toISOString() : '');
              }}
            />
          </Box>
          <Button
            disabled={submittedDate.length === 0}
            onClick={() => {
              onDialogClose();
              onConfirm(submittedDate);
            }}
            color="primary"
            variant="contained"
            style={styles.button}>
            Tag As Submitted
          </Button>
          <Button onClick={onDialogClose} color="primary" style={styles.button}>
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  headerBar: {
    position: 'absolute',
    height: 12,
    top: 0,
    left: 0,
    backgroundColor: Theme.colorPalette.primary,
    width: '100%',
  },
  title: {
    textAlign: 'center',
    marginTop: 4,
  },
  actionItem: {
    marginBottom: 2,
  },
  datePicker: {
    marginBottom: 4,
  },
  button: {
    fontSize: 16,
    textTransform: 'none',
    borderRadius: '20px',
    marginTop: 12,
  },
  paper: {
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundImage: `url(${GCS_BASE_URL}/${GCS_DEFAULT_BUCKET}/organizations/logos/PathfinderLeaf_RGB.png)`,
      backgroundSize: 140,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right -15px bottom -45px',
      opacity: 0.3,
      display: {
        xs: 'none',
        sm: 'block',
      },
    },
  },
};
