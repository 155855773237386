// @flow
import {useCallback, useState, useEffect} from 'react';
import {useStores} from 'hooks/useStores';
import type {IObservable} from 'mobx';
import type {
  UICalendarEventType,
  UICalendarEventInvitationType,
} from '@wellstone-solutions/common';

export const useDataLoader = ({
  calendarEventId,
  originalStart,
  initialFetch = true,
}: {
  calendarEventId: string,
  originalStart: string,
  initialFetch: boolean,
}): ({
  isLoading: boolean,
  data: {
    calendarEvent: IObservable<UICalendarEventType> | null,
    calendarEventInvitations: Array<UICalendarEventInvitationType> | null,
  },
  fetchData: () => Promise<void>,
}) => {
  const {calendarStore, memberStore, staffStore} = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [calendarEvent, setCalendarEvent] = useState(null);
  const [invitations, setInvitations] = useState([]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const eventResponse = await calendarStore.getCalendarEvent({
      id: calendarEventId,
      originalStart,
      forceFetch: true,
    });

    if (!eventResponse.isSuccess) {
      setIsLoading(false);
      return;
    }

    const event = eventResponse.data;

    if (event.invitations?.length > 0) {
      const userIds = event.invitations.map((invite) => invite.user);

      await Promise.all([
        memberStore.getMembersByIds(userIds),
        staffStore.getStaffMembersByIds(userIds),
      ]);
    }

    setCalendarEvent(event);
    setInvitations(event.invitations);
    setIsLoading(false);
  }, [calendarEventId, originalStart, memberStore, staffStore, calendarStore]);

  useEffect(() => {
    if (initialFetch) {
      fetchData();
    }
  }, [fetchData, initialFetch]);

  return {
    isLoading: isLoading,
    data: {calendarEvent, calendarEventInvitations: invitations},
    fetchData,
  };
};
