// @flow
import {useCallback, useState, useEffect} from 'react';
import type {UIStaffType} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';

export const useDataLoader = ({
  initialFetch = true,
  email,
}: {
  initialFetch?: boolean,
  email: string,
} = {}): ({
  isLoading: boolean,
  data: UIStaffType | void,
  fetchData: () => Promise<void>,
}) => {
  const {memberStore} = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({member: undefined, canAccess: false});

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const {member, canAccess} = await memberStore.getMemberByEmail({email});
    setData({member, canAccess});
    setIsLoading(false);
  }, [email, memberStore]);

  useEffect(() => {
    if (initialFetch) {
      fetchData();
    }
  }, [fetchData, initialFetch]);

  return {isLoading, data, fetchData};
};
