// @flow
import React from 'react';
import type {Node} from 'react';
import {SuspendedComponent} from '@wellstone-solutions/web';
import {useDataLoader} from '../../../dataloaders/eventDataLoader';

type PropsType = {
  calendarEventId: string,
  originalStart: string,
  children: Node | (() => any),
};
export const CalendarEventLoader = ({
  calendarEventId,
  originalStart,
  children,
}: PropsType): Node => {
  const dataLoaderArgs = {
    calendarEventId,
    originalStart,
    initialFetch: true,
  };

  return (
    <SuspendedComponent
      dataLoaderArgs={dataLoaderArgs}
      // $FlowFixMe
      useDataLoader={useDataLoader}
      keyProp={`${calendarEventId}>${originalStart}`}>
      {children}
    </SuspendedComponent>
  );
};
