// React
import React, {useState} from 'react';
import {Api} from '@wellstone-solutions/common';
import {Palette} from '../../../palette';
import {inject, observer} from 'mobx-react';
import Button from '../../tutorial/button';
import TutorialDiv from '../../tutorial/tutorialdiv';

const CommunitySwitch = ({meStore, member, withLabel}) => {
  const [isOn, setIsOn] = useState(member.has_community_access);

  const toggleValue = () => {
    const updatedValue = !isOn;
    const org = meStore.me.membership.organization.id;
    const member_id = member.id;
    const params = {
      has_community_access: updatedValue,
    };

    // Optimistic update
    setIsOn(updatedValue);

    // Persist update
    Api.Instance.current()
      .post('/orgs/' + org + '/members/' + member_id, params)
      .catch(() =>
        // Revert update
        setIsOn(!updatedValue),
      );
  };

  return (
    <TutorialDiv id="community-switch-block">
      <div style={styles.switchRow}>
        <div style={styles.switch}>
          <div
            style={{
              ...styles.track,
              ...{
                backgroundColor: isOn ? Palette.LIGHT_SUCCESS : Palette.GREY_3,
              },
            }}
          />
          <Button
            id="community-switch-button"
            onClick={toggleValue}
            style={{
              ...styles.button,
              ...{left: isOn ? 23 : 3},
              ...{
                backgroundColor: isOn ? Palette.SUCCESS : Palette.GREY_1,
              },
            }}
          />
        </div>
        {withLabel ? (
          <div style={styles.switchLabel} className={'has-tooltip'}>
            Peer-to-peer Access
            <span className="tooltip-text">
              {isOn
                ? 'Switch OFF to remove them from the group member listing on the Companion App.'
                : 'Switch ON so that the they can find and be found by their group peers.'}
            </span>
          </div>
        ) : null}
      </div>
    </TutorialDiv>
  );
};

const styles = {
  switchRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  switch: {
    height: 30,
    width: 50,
    position: 'relative',
  },
  switchLabel: {
    fontWeight: 'bold',
    marginLeft: 10,
  },
  track: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    borderRadius: 15,
    backgroundColor: Palette.LIGHT_INFO,
  },
  button: {
    position: 'absolute',
    height: 24,
    width: 24,
    borderWidth: 0,
    borderRadius: 14,
    top: 3,
    padding: 0,
    backgroundColor: Palette.INFO,
    transition: '.25s all',
    cursor: 'pointer',
  },
};

export default inject('meStore')(observer(CommunitySwitch));
