// @flow
import {useCallback, useState, useEffect} from 'react';
import {useStores} from 'hooks/useStores';
import type {UIResourceQuestionType} from '@wellstone-solutions/common';

export const useDataLoader = ({
  initialFetch = true,
  categoryId,
}: {
  initialFetch?: boolean,
  categoryId: string,
} = {}): ({
  isLoading: boolean,
  data?: Array<UIResourceQuestionType>,
  fetchData: () => Promise<void>,
}) => {
  const {resourceStore, meStore} = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState(undefined);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const fetchedQuestions = await resourceStore.getQuestionsByCategory({
      categoryId,
      states: [meStore.me.membership.organization.state],
    });
    setQuestions(fetchedQuestions);
    setIsLoading(false);
  }, [resourceStore, categoryId, meStore]);

  useEffect(() => {
    if (initialFetch) {
      fetchData();
    }
  }, [fetchData, initialFetch]);

  return {isLoading: isLoading, data: questions, fetchData};
};
