// @flow
import React, {useEffect, useRef, useState} from 'react';
import type {Node} from 'react';
import {Events, Hooks, Models} from '@wellstone-solutions/common';
import {Box, DataGrid, usePaging} from '@wellstone-solutions/web';
import {ListHeader} from './ListHeader';
import {AccessControl} from 'modules/rbac';
import {useStores} from 'hooks/useStores';
import {useGroupColumnMap} from '../../hooks/';

type ParamsType = {
  id?: Array<string>,
  category_id?: string,
};

const {usePaginatedData} = Hooks;
const {Group} = Models;

const MY_FILTER = 'My';
const ALL_FILTER = 'All';
const EVENT_TABLE_NAME = 'GroupList';

const buildParams = (
  filter: string,
  myGroups: Array<{id: string}>,
): ParamsType => {
  const params = {};
  if (filter === MY_FILTER) {
    params.id = myGroups.map(({id}) => id);
  } else if (filter !== ALL_FILTER) {
    params.category_id = filter;
  }

  return params;
};

export const GroupList = (): Node => {
  const initialLoad = useRef(true);
  const {eventStore, groupStore, meStore, RBACStore} = useStores();

  const initialFilter = RBACStore.hasAccess(
    AccessControl.groups.defaultFilterAll,
  )
    ? ALL_FILTER
    : MY_FILTER;

  const initialParams = buildParams(initialFilter, groupStore.myGroups);

  const [params, setParams] = useState(initialParams);
  const [filter, setFilter] = useState(initialFilter);

  const initialSortField = 'name';
  const initialSortOrder = 'asc';
  const initialState = {
    sorting: {
      sortModel: [{field: initialSortField, sort: initialSortOrder}],
    },
  };

  const {
    offset,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
  } = usePaging({
    initialSortField,
    initialSortOrder,
  });

  const queryResults = usePaginatedData({
    url: Group.routes.index(meStore.me.membership.organization.id),
    params,
    dataTransformer: ({groups, total}) => ({
      groups: groups.map(Group.toUI),
      total,
    }),
    pageSize,
    currentPage: offset,
    sortField,
    sortOrder,
  });

  const refetchData = () => {
    if (queryResults.refetch) {
      queryResults.refetch({
        pageSize,
        currentPage: offset,
        params,
        sortField,
        sortOrder,
      });
    }
  };

  useEffect(() => {
    if (!initialLoad.current) {
      refetchData();
    }
    initialLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, currentPage, pageSize, sortField, sortOrder]);

  const columnMap = useGroupColumnMap(refetchData);
  const columns = [
    columnMap.name,
    columnMap.program,
    columnMap.category,
    columnMap.membersCount,
  ];

  // Only admins/super admins can do actions right now
  if (RBACStore.hasAccess(AccessControl.groups.viewListActions)) {
    columns.push(columnMap.actions);
  }

  const handleFilterChange = (updatedFilter) => {
    setParams(buildParams(updatedFilter, groupStore.myGroups));
    setCurrentPage(0);
    setFilter(updatedFilter);
  };

  const handleSortChange = (sortedColumns) => {
    let updatedSortField = '';
    let updatedSortOrder = '';

    if (sortedColumns?.length > 0) {
      const [sortedColumn] = sortedColumns;
      updatedSortField = sortedColumn.field;
      updatedSortOrder = sortedColumn.sort;

      eventStore.addEvent(Events.USER_SORTED_TABLE, {
        sort_field: updatedSortField,
        sort_order: updatedSortOrder,
        table: EVENT_TABLE_NAME,
      });
    }

    setSortField(updatedSortField);
    setSortOrder(updatedSortOrder);
  };

  const handlePageChange = (updatedPage) => {
    setCurrentPage(updatedPage);
  };

  const handlePageSizeChange = (updatedPageSize) => {
    setPageSize(updatedPageSize);
  };

  return (
    <>
      <Box sx={{mb: 2}}>
        <ListHeader
          filter={filter}
          setFilter={handleFilterChange}
          refetch={refetchData}
        />
      </Box>
      <DataGrid
        autoHeight
        loading={queryResults.isLoading}
        rows={queryResults.data?.groups ?? []}
        columns={columns}
        noRowsText="No groups"
        page={currentPage}
        pageSize={pageSize}
        paginationMode="server"
        sortingMode="server"
        initialState={initialState}
        rowCount={queryResults.data?.total ?? 0}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSortModelChange={handleSortChange}
      />
    </>
  );
};
