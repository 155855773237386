// @flow
import React from 'react';
import type {Node} from 'react';
import {Select, Stack, Typography} from '@wellstone-solutions/web';

type PropTypes = {
  staffId: string,
  selectOptions: Array<{label: string, value: any}>,
  selectedItem?: string,
  setSelectedItem: (id: string) => void,
  label: string,
};

export const ItemSelection = ({
  setSelectedItem,
  selectedItem,
  selectOptions,
  staffId,
  label,
}: PropTypes): Node => {
  const handleChange = (evt) => {
    setSelectedItem(evt.target.value);
  };

  return (
    <div style={styles.selectContainer}>
      <Stack direction="column">
        {selectOptions.length > 0 ? (
          <Select
            label={label}
            items={selectOptions}
            value={selectedItem}
            onChange={handleChange}
            data-testid="select-item"
          />
        ) : (
          <Typography>{`No additional ${label.toLowerCase()} to add.`}</Typography>
        )}
      </Stack>
    </div>
  );
};

const styles = {
  selectContainer: {
    marginBottom: '30px',
  },
};
