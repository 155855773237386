// @flow
import React from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStores} from 'hooks/useStores';
import {documentStatusMap} from '../../constants';
import {DATE_FORMAT_LONG} from 'modules/documentation';
import type {
  NotificationType,
  SessionDocumentNotificationDataType,
} from '../../types';
import {getDocumentUrl} from 'modules/documentation';
import {unix17DigitTimeToMoment, toSemiRelativeDate} from 'utils/dateHelpers';
import {DefaultTemplate} from '../NotificationTemplates';

type PropsType = {
  item: NotificationType<SessionDocumentNotificationDataType>,
  closeMenu: () => void,
};

export const SessionDocumentNotification = ({
  item,
  closeMenu,
}: PropsType): Node => {
  const navigate = useNavigate();
  const {appNotificationStore} = useStores();
  const {action, timetoken, data} = item;
  const {icon, title} = documentStatusMap[action];

  const handleClick = (event) => {
    handleDismiss();
    if (item.data) {
      navigate(getDocumentUrl(item.data.member, item.data.id));
      closeMenu();
    }
  };

  const handleDismiss = () => {
    appNotificationStore.dismissNotification(item);
  };

  const humanTime = toSemiRelativeDate(
    unix17DigitTimeToMoment(Number(timetoken)).toISOString(),
    DATE_FORMAT_LONG,
  );

  const message = `${humanTime} by ${data?.reverted?.changedBy || 'Staff'}`;

  return (
    <DefaultTemplate
      title={title}
      icon={icon}
      message={message}
      handleClick={handleClick}
      handleDismiss={handleDismiss}
    />
  );
};
