// @flow
import {action, observable, decorate} from 'mobx';
import type {IObservableArray} from 'mobx';
import RootStore from 'mobx/RootStore';
import type {BreadcrumbType} from './types';

export class AppUIStore {
  rootStore: RootStore;
  breadcrumbs: IObservableArray<BreadcrumbType> = observable.array();
  redirectPath: string = '';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  setBreadcrumbs(crumbs: Array<BreadcrumbType>) {
    this.breadcrumbs.replace(crumbs);
  }

  setRedirectPath(path: string) {
    this.redirectPath = path;
  }

  clearRedirectPath() {
    this.redirectPath = '';
  }
}

decorate(AppUIStore, {
  breadcrumbs: observable,
  setBreadcrumbs: action,
  redirectPath: observable,
  setRedirectPath: action,
  clearRedirectPath: action,
});
