import {generateId} from './TutorialStore';

class StaffStore {
  routes = [
    {
      method: 'GET',
      route: /orgs\/[A-Z0-9-]*\/staff$/i,
      action: this.getStaffList.bind(this),
    },
    {
      method: 'GET',
      route: /orgs\/[A-Z0-9-]*\/staff\/[A-Z0-9-]*/i,
      action: this.getStaffMember.bind(this),
    },
    {
      method: 'POST',
      route: /orgs\/[A-Z0-9-]*\/staff/i,
      action: this.addStaffMember.bind(this),
    },
    {
      method: 'POST',
      route: /orgs\/[A-Z0-9-]*\/permissions/i,
      action: this.addStaffToGroup.bind(this),
    },
  ];

  staff = [];

  constructor(tutorialStore) {
    this.tutorialStore = tutorialStore;
  }

  _init() {
    this.staff.push({
      authorizations: [
        {
          permissions: ['has_read_access'],
          obj_type: 'Group',
          id: generateId(),
          obj_id: this.tutorialStore.stores.groupStore.groups[0].id,
        },
      ],
      is_in_treatment: false,
      revocations: [],
      name: 'Michael Brown',
      user: {
        name: 'Michael Brown',
        gender: 'male',
        identifies_as: 'male',
        id: generateId(),
        email: 'michael@pfsbc.com',
      },
      groups: [],
      role: 'counselor',
      status: 'active',
      gender: 'male',
      identifies_as: 'male',
      id: generateId(),
    });
  }

  getStaffList() {
    return {
      data: {
        members: this.staff,
        offset: 0,
        limit: 10,
      },
      status: 'ok',
      messages: [],
      messages_type: 'string_array',
    };
  }

  getStaffMember({route, params}) {
    const memberId = /orgs\/[A-Z0-9-]*\/staff\/([A-Z0-9-]*)/i.exec(route)[1];
    return {
      data: this.staff.find((s) => s.id === memberId),
    };
  }

  addStaffMember({route, params}) {
    const id = 'tutorial-user-0';
    const staff = {
      ...params,
      id,
      revocations: [],
      is_in_treatment: false,
      status: 'active',
      groups: [],
      authorizations: [],
      user: {
        name: params.name,
        gender: params.gender,
        identifies_as: params.identifies_as,
        id: 'tutorial-user-0',
      },
      gender: 'female',
      name: params.name,
      identifies_as: 'female',
    };
    this.staff.push(staff);
    return {
      data: {id},
      status: 'ok',
    };
  }

  addStaffToGroup({route, params}) {
    const id = Math.floor(Math.random() * 1000);
    const staff = this.staff.find((s) => s.id === params.staff_id);
    staff.authorizations.push({
      ...params,
      id,
    });
    return {
      data: {id},
      status: 'ok',
    };
  }
}

export default StaffStore;
