// @flow
import React from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {Stack} from '@wellstone-solutions/web';
import {PfButton} from 'components/shared/PfButton';

export const ListHeader = (): Node => {
  const navigate = useNavigate();

  return (
    <Stack direction="row" justifyContent="flex-end">
      <PfButton
        color="primary"
        outlined
        onClick={() => navigate('/stories/new')}>
        Write New Story
      </PfButton>
    </Stack>
  );
};
