// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {DatePicker, Select, Stack, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {SaveCancelModal} from 'components/modal/saveCancelModal';
import {DEFAULT_DATE_FORMAT, SLASH_DATE_FORMAT} from 'constants/dates';
import {DISCHARGE_STATUSES} from 'constants/Categories';

type PropTypes = {
  open: boolean,
  setShowModal: (show: boolean) => void,
  discharge: (dischargeDate: string, status?: string) => Promise<void>,
  memberName: string,
  item: {
    name?: string,
    startDate?: string,
  },
  selectStatus?: boolean,
};

export const DischargeModal = ({
  open,
  setShowModal,
  discharge,
  memberName,
  item,
  selectStatus = false,
}: PropTypes): Node => {
  const [dischargeDate, setDischargeDate] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState();

  const statusSelectOptions = DISCHARGE_STATUSES.map(({name, value}) => ({
    label: name,
    value,
  }));

  const onSave = async () => {
    if (!dischargeDate || moment(dischargeDate).isAfter(moment())) {
      setError(
        `Discharge date must be before ${moment()
          .add(1, 'd')
          .format(SLASH_DATE_FORMAT)}`,
      );
      return;
    }

    const formattedDischargeDate = moment(dischargeDate).format(
      DEFAULT_DATE_FORMAT,
    );
    if (selectStatus) {
      await discharge(formattedDischargeDate, status);
    } else {
      await discharge(formattedDischargeDate);
    }
    clear();
  };

  const clear = () => {
    setShowModal(false);
    setDischargeDate('');
    setStatus('');
    setError(null);
  };

  return (
    <SaveCancelModal
      open={open}
      setShowModal={clear}
      title={`Discharge <strong>${memberName}</strong> ${
        item?.name ? `from <strong>${item.name}</strong>` : ''
      }`}
      onSave={onSave}
      disableSave={selectStatus ? !dischargeDate || !status : !dischargeDate}>
      <Stack sx={{py: 2}} direction="column" justifyContent="flex-start">
        <DatePicker
          label="Discharge Date"
          fieldName="dischargeDate"
          onChange={(date) => {
            setDischargeDate(date?.isValid() ? date.toISOString() : '');
          }}
          value={dischargeDate}
          minDate={item?.startDate ? item.startDate : null}
          maxDate={moment().format(DEFAULT_DATE_FORMAT)}
        />
        {error && (
          <Typography sx={{color: Theme.colorPalette.negative, mt: 1}}>
            {error}
          </Typography>
        )}
        {selectStatus && (
          <Stack sx={{mt: 2}}>
            <Select
              label="Discharge Status"
              items={statusSelectOptions}
              value={status}
              onChange={(evt) => setStatus(evt.target.value)}
              data-testid="select-discharge-status"
            />
          </Stack>
        )}
      </Stack>
    </SaveCancelModal>
  );
};
