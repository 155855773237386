// @flow
import React from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import type {
  UIResourceQuestionType,
  UIResourceAnswerType,
} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {AnswersGrid} from './AnswersGrid';
import {NoResults} from '../NoResults';

type PropsType = {
  questionId: string,
  shareResource: (
    resource: UIResourceQuestionType | UIResourceAnswerType,
    action?: string,
  ) => void,
  isModal: boolean,
};

export const AnswersGridWrapper: ComponentType<PropsType> = observer(
  ({questionId, shareResource, isModal}: PropsType): Node => {
    const {resourceStore} = useStores();
    const answers = resourceStore.answers.get(questionId);

    return answers?.length > 0 ? (
      <AnswersGrid
        answers={answers}
        shareResource={shareResource}
        isModal={isModal}
      />
    ) : (
      <NoResults />
    );
  },
);
