// @flow
import React from 'react';
import type {Node} from 'react';
import {Icon, IconNames} from '@wellstone-solutions/web';
import {icons} from '../../../../constants/Icons';
import type {UIHabitType, UIMemberHabitType} from '../../types';
import {HABIT_TYPES} from '../../constants';
import styles from './HabitListItem.module.scss';

type PropsType = {
  habit: UIHabitType,
  mergedHabit: UIMemberHabitType,
  addMemberHabit: (args: UIMemberHabitType) => any,
  deleteMemberHabit: (habitId: string) => any,
  updateMemberHabit: (habitId: string, updates: UIMemberHabitType) => any,
};

// TODO: Refactor out to selectors?
const getReminderSchedule = (habit) => {
  const reminders = [
    habit.isMorning ? 'Morning' : null,
    habit.isAfternoon ? 'Afternoon' : null,
    habit.isEvening ? 'Evening' : null,
  ].filter(Boolean);

  return reminders.length > 0 ? reminders.join(', ') : 'Daily';
};

const handleHabitImgError = (event) => {
  event.target.src = '/images/habits/default.png';
};

export const HabitListItem = ({
  habit,
  mergedHabit,
  addMemberHabit,
  deleteMemberHabit,
  updateMemberHabit,
}: PropsType): Node => {
  const updateSchedule = (updates) => {
    // $FlowFixMe
    updateMemberHabit(mergedHabit.id, {
      ...mergedHabit,
      ...updates,
    });
  };

  return (
    <div className={styles.container}>
      <div>
        <input
          className={styles.checkbox}
          type="checkbox"
          checked={(mergedHabit && mergedHabit.type === 'member') || false}
          onChange={(event) => {
            if (event.target.checked) {
              // initialize new member habit with user habit settings if applicable
              const userHabit =
                mergedHabit?.type === HABIT_TYPES.user ? mergedHabit : null;
              addMemberHabit({
                habitId: userHabit ? userHabit.habitId : habit.id,
                isMorning: userHabit ? userHabit.isMorning : habit.isMorning,
                isAfternoon: userHabit
                  ? userHabit.isAfternoon
                  : habit.isAfternoon,
                isEvening: userHabit ? userHabit.isEvening : habit.isEvening,
              });
            } else {
              deleteMemberHabit(habit.id);
            }
          }}
        />
      </div>
      <div
        className={
          mergedHabit
            ? styles.habitDetails
            : `${styles.habitDetails} ${styles.inactive}`
        }>
        <div className={styles.iconContainer}>
          <img
            className={styles.icon}
            alt={habit.name}
            // $FlowFixMe
            src={`${process.env.PUBLIC_URL}${icons[habit.key]}`}
            onError={handleHabitImgError}
          />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>{habit.name}</div>
          <div className={styles.tags}>{habit.tags.join(', ')}</div>
        </div>
        <div className={styles.schedule}>
          {getReminderSchedule(mergedHabit || habit)}
        </div>
        {mergedHabit && mergedHabit.type === 'user' ? (
          <div className={styles.personal}>
            <div>
              <Icon name={IconNames.User} size={16} />
            </div>
            <div>Personal habit</div>
          </div>
        ) : (
          <div className={styles.reminders}>
            <div>
              <input
                disabled={!mergedHabit}
                checked={mergedHabit ? mergedHabit.isMorning : habit.isMorning}
                onChange={(e) =>
                  updateSchedule({isMorning: !mergedHabit.isMorning})
                }
                className={styles.checkbox}
                type="checkbox"
              />
            </div>
            <div>
              <input
                disabled={!mergedHabit}
                checked={
                  mergedHabit ? mergedHabit.isAfternoon : habit.isAfternoon
                }
                onChange={() =>
                  updateSchedule({isAfternoon: !mergedHabit.isAfternoon})
                }
                className={styles.checkbox}
                type="checkbox"
              />
            </div>
            <div>
              <input
                disabled={!mergedHabit}
                checked={mergedHabit ? mergedHabit.isEvening : habit.isEvening}
                onChange={() =>
                  updateSchedule({isEvening: !mergedHabit.isEvening})
                }
                className={styles.checkbox}
                type="checkbox"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
