// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Box, Paper, Typography} from '@wellstone-solutions/web';
type PropsType = {
  title?: string,
  description?: string,
  children: Node,
};

export const DataGridContainer = ({
  title = '',
  description = '',
  children,
}: PropsType): Node => {
  const showHeader = title.length > 0 || description.length > 0;
  return (
    <Box sx={styles.container}>
      {showHeader && (
        <Paper elevation={1} sx={styles.header}>
          {title.length > 0 && (
            <Typography variant="h5" color="darkest.main">
              {title}
            </Typography>
          )}

          {description.length > 0 && (
            <Typography variant="body2" color="darkest.main">
              {description}
            </Typography>
          )}
        </Paper>
      )}

      <Paper elevation={1} sx={showHeader ? styles.squareTop : {}}>
        {children}
      </Paper>
    </Box>
  );
};

const styles = {
  container: {
    backgroundColor: Theme.colorPalette.lightest,
    my: 2,
  },
  header: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    mb: 0.25,
    p: 2,
  },
  squareTop: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
};
