// @flow
import moment from 'moment';
import type Moment from 'moment';

export const getFullMomentDateTimes = (
  date?: string,
  startTime?: string,
  endTime?: string,
): {start: Moment | null, end: Moment | null} => {
  let start = null;
  let end = null;
  if (startTime && date) {
    const [startHour, startMinutes] = startTime.split(':');
    start = moment(date).set({
      hour: Number(startHour),
      minute: Number(startMinutes),
      second: 0,
      milliseconds: 0,
    });
  }

  if (endTime && date) {
    const [endHour, endMinutes] = endTime.split(':');
    end = moment(date).set({
      hour: Number(endHour),
      minute: Number(endMinutes),
      second: 0,
      milliseconds: 0,
    });
  }

  return {start, end};
};
