// @flow
import React, {useCallback, useEffect, useState} from 'react';
import type {Node} from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import {Box, IconButton, IconNames} from '@wellstone-solutions/web';

type PropsType = {
  children: Node,
};

export const Carousel = ({children}: PropsType): Node => {
  const [prevButtonDisabled, setPrevButtonDisabled] = useState<boolean>(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    dragFree: true,
    slidesToScroll: 'auto',
  });

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi) => {
    setPrevButtonDisabled(!emblaApi.canScrollPrev());
    setNextButtonDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.buttonWrapper}>
        <IconButton
          data-testid="prev-button"
          onClick={onPrevButtonClick}
          icon={IconNames.NavArrowLeft}
          disabled={prevButtonDisabled}
        />
      </Box>

      <div style={styles.embla} ref={emblaRef}>
        <Box sx={styles.emblaContainer}>
          {React.Children.map(children, (child: Node, index) => (
            <Box sx={styles.emblaSlide} key={index}>
              {child}
            </Box>
          ))}
        </Box>
      </div>
      <Box sx={styles.buttonWrapper}>
        <IconButton
          data-testid="next-button"
          onClick={onNextButtonClick}
          icon={IconNames.NavArrowRight}
          disabled={nextButtonDisabled}
        />
      </Box>
    </Box>
  );
};

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    '&::before, &::after': {
      content: {
        xs: '""',
        sm: 'none',
      },
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '20px',
      zIndex: 1,
    },
    '&::before': {
      left: 0,
      background:
        'linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
    },
    '&::after': {
      right: 0,
      background:
        'linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
    },
  },
  embla: {
    overflow: 'hidden',
    flexGrow: 1,
    position: 'relative',
  },
  emblaContainer: {
    display: 'flex',
    justifyContent: {
      xs: 'flex-start',
      md: 'space-around',
    },
  },
  emblaSlide: {
    flex: '0 0 auto',
    paddingLeft: {
      xs: 0.25,
      sm: 1,
    },
    paddingRight: {
      xs: 0.25,
      sm: 1,
    },
    minWidth: 0,
  },
  buttonWrapper: {
    display: {
      xs: 'none',
      sm: 'flex',
    },
    alignItems: 'center',
  },
};
