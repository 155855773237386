// @flow
import React from 'react';
import type {Node, ComponentType} from 'react';
import {styled} from '@mui/material/styles';
import {Theme} from '@wellstone-solutions/common';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
} from '@wellstone-solutions/web';

type AccordionType = {
  square?: boolean,
  disableGutters?: boolean,
  elevation?: number,
  ...
};

type AccordionSummaryType = {
  expandIcon?: Node,
  ...
};

export const StyledAccordion: ComponentType<AccordionType> = styled((props) => (
  <Accordion square disableGutters elevation={0} {...props} />
))(({theme}) => ({
  borderColor: theme.palette.grey[200],
  borderStyle: 'solid',
  borderWidth: 2,
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  '&:last-child': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const StyledAccordionSummary: ComponentType<AccordionSummaryType> = styled(
  (props) => (
    <AccordionSummary
      expandIcon={
        <Icon
          name="NavArrowDown"
          color={Theme.colorPalette.primary}
          strokeWidth={2}
          size={24}
        />
      }
      {...props}
    />
  ),
)(({theme}) => ({
  padding: theme.spacing(2, 4),
  margin: 0,
  '& .MuiAccordionSummary-content': {margin: 0},
}));

export const StyledAccordionDetails: ComponentType<{}> = styled(
  AccordionDetails,
)(({theme}) => ({
  padding: theme.spacing(2, 4),
}));
