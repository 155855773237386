// @flow
import React from 'react';
import type {Node} from 'react';

import {OrganizationSearchResults} from 'components/SearchResults';
import type {PropsType} from 'components/SearchResults';

export const ResultsComponent = (props: PropsType): Node => (
  <OrganizationSearchResults includeMe={false} {...props} />
);
