// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import type {PropsType as IconPropsType} from '@wellstone-solutions/web';
import {IconButton, IconNames, Progress} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {Tooltip} from '@mui/material';
import {PfConfirmInline} from 'components/shared/PfConfirmInline';

type PropsType = {
  onActionClick?: () => void | Promise<void>,
  actionIcon?: string,
  actionIconProps?: IconPropsType,
  actionOverride?: () => void,
};

export const CardDataListHeaderAction = ({
  onActionClick,
  actionIcon,
  actionIconProps,
  actionOverride,
}: PropsType): Node => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  if (!onActionClick && !actionOverride) {
    return <></>;
  }

  const onRemoveClick = () => {
    if (actionOverride) {
      actionOverride();
      return;
    }

    setShowConfirm(true);
  };

  const cancelRemove = () => {
    setShowConfirm(false);
  };

  const actionClickWrapper = async () => {
    setIsLoading(true);
    setShowConfirm(false);
    await onActionClick?.();
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div style={styles.loading}>
        <Progress
          size="small"
          color="inherit"
          style={styles.progress}
          data-testid="loading-spinner"
        />
      </div>
    );
  }

  if (showConfirm) {
    return (
      <div style={styles.confirm}>
        <PfConfirmInline
          showConfirm={showConfirm}
          confirm={actionClickWrapper}
          cancelConfirm={cancelRemove}
          color="light"
        />
      </div>
    );
  }

  return (
    <Tooltip title="Remove List" placement="left">
      <div>
        <IconButton
          onClick={onRemoveClick}
          icon={actionIcon || IconNames.MinusCircle}
          iconProps={
            actionIconProps || {
              color: Theme.colorPalette.lightest,
              size: 22,
              style: {strokeWidth: 2},
              name: 'remove list',
            }
          }
          style={styles.iconButton}
          aria-label="remove list"
          data-testid="headerActionButton"
        />
      </div>
    </Tooltip>
  );
};

const styles = {
  iconButton: {
    marginRight: '11px',
    marginTop: '4px',
  },
  loading: {
    position: 'relative',
    right: '19px',
    top: '12px',
    height: '42px',
    width: '20px',
  },
  progress: {
    display: 'block',
  },
  confirm: {
    position: 'relative',
    right: '11px',
    top: '9px',
    height: '42px',
  },
};
