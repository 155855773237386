// @flow
import {useCallback, useEffect, useState} from 'react';
import type {
  UIAnalyticsDatasetType,
  UIAnalyticsDatasetQueryType,
} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';

export const useAnalyticsDataloader = ({
  datasetQueries,
  initialFetch = true,
}: {
  datasetQueries: Array<UIAnalyticsDatasetQueryType>,
  initialFetch?: boolean,
}): ({
  isLoading: boolean,
  datasets: {[string]: UIAnalyticsDatasetType},
  fetchData: () => Promise<void>,
}) => {
  const {dashboardStore} = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    if (!datasetQueries.length) {
      return;
    }
    setIsLoading(true);
    const result = await dashboardStore.getAnalytics({
      datasetQueries,
    });

    // If the result is false, it means that the request was aborted
    if (result === false) {
      return;
    } else if (result.length === 0) {
      showAlert('Could not load dashboard data', ALERT_TYPES.ERROR);
      return;
    }

    const datasetMap = {};
    result.forEach((dataset) => {
      datasetMap[dataset.title] = dataset;
    });

    setData(datasetMap);
    setIsLoading(false);
  }, [datasetQueries, dashboardStore]);

  useEffect(() => {
    if (initialFetch) {
      fetchData();
    }
  }, [fetchData, initialFetch]);

  return {isLoading, datasets: data, fetchData};
};
