// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {useParams} from 'react-router-dom';
import {Models} from '@wellstone-solutions/common';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {useStores} from 'hooks/useStores';
import {buildIdLookup} from 'utils/buildIdLookup';
import {ItemSelection} from './ItemSelection';
import {SaveCancelModal} from 'components/modal/saveCancelModal';

import {STAFF_PERMISSIONS} from 'constants/Permissions';

const {Authorization} = Models;

type PropTypes = {
  open: boolean,
  setShowModal: (show: boolean) => void,
};

export const AddProgramModal = ({open, setShowModal}: PropTypes): Node => {
  const {meStore, staffStore} = useStores();
  const [selectedProgram, setSelectedProgram] = useState<string | void>();
  const {admin: staffId} = useParams();

  const availablePrograms = meStore.myPrograms;
  const programLookup = buildIdLookup(availablePrograms);
  const staffPrograms = staffStore.authorizedPrograms({staffId});
  const staffProgramLookup = buildIdLookup(staffPrograms);

  // remove programs that the staff member is already authorized for
  const selectItems = availablePrograms.reduce((accum, prog) => {
    if (!staffProgramLookup[prog.id]) {
      accum.push({
        label: prog.name,
        value: prog.id,
      });
    }
    return accum;
  }, []);

  const onSave = async () => {
    if (!selectedProgram) {
      return;
    }
    const program = programLookup[selectedProgram];

    const authorizationInput = Authorization.fromProgramToApiInput(program, [
      STAFF_PERMISSIONS.read,
      STAFF_PERMISSIONS.write,
    ]);

    const response = await staffStore.createAuthorization({
      staffId,
      authorizationInput,
    });

    if (response.isSuccess) {
      setShowModal(false);
    } else {
      showAlert(
        'Could not add selected program to staff member',
        ALERT_TYPES.ERROR,
      );
    }
  };

  return (
    <SaveCancelModal
      open={open}
      setShowModal={setShowModal}
      title="Add a program"
      onSave={onSave}
      disableSave={Boolean(selectItems.length <= 0 || !selectedProgram)}>
      <ItemSelection
        staffId={staffId}
        setSelectedItem={setSelectedProgram}
        selectedItem={selectedProgram}
        selectOptions={selectItems}
        label="Programs"
      />
    </SaveCancelModal>
  );
};
