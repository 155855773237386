export const defaultPageSize = 10;

export const getCurrentPage = (state, pageSize) => {
  let cp = state.offset ? Math.round(state.offset / pageSize) + 1 : 1;
  return cp;
};

export const getTotalPages = (state, pageSize) => {
  let totalPages = 5;
  if (state.isLastPage) {
    totalPages = getCurrentPage(state, pageSize);
  } else {
    totalPages = getCurrentPage(state, pageSize) + 1;
  }
  return totalPages;
};

export const setPageValues = (
  context,
  page,
  pageSize = defaultPageSize,
  cb = () => null,
) => {
  let offset = (page - 1) * pageSize;
  let limit = pageSize;

  context.setState(
    {
      limit: limit,
      offset: offset,
    },
    cb,
  );
};
