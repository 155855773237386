// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Stack} from '@wellstone-solutions/web';
import {ROLES_NAMES_MAP} from 'utils/Permissions';
import {SearchResult} from './SearchResult';

type MembersSearchResults = {
  members?: Array<{
    name: string,
    role: string,
    id: string,
    user: {
      email: string,
    },
  }>,
};

type PropsType = {
  onSelectItem: (any) => void,
  results: MembersSearchResults,
};

export const PatientSearchResults = ({
  onSelectItem,
  results,
}: PropsType): Node => {
  const members =
    results.members?.map((m) => ({
      name: m.name,
      id: m.id,
      email: m.user.email,
      role: m.role,
    })) || [];

  const patients = members.filter(
    ({role}) => role.toLowerCase() === ROLES_NAMES_MAP.patient.toLowerCase(),
  );

  const hasResults = patients.length > 0;

  if (!hasResults) {
    return null;
  }

  return (
    <Stack
      sx={{
        backgroundColor: Theme.colorPalette.lightest,
        borderRadius: '4px',
        boxShadow: 1,
        maxHeight: '210px',
        overflowY: 'auto',
        width: '100%',
        mt: '2px',
      }}
      data-testid="patient-search-results">
      {patients.map((patient) => (
        <SearchResult
          title={patient.name}
          subtitle={patient.email}
          tag={ROLES_NAMES_MAP[patient.role]}
          item={patient}
          onClick={onSelectItem}
          key={patient.id}
        />
      ))}
    </Stack>
  );
};
