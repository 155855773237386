// @flow

export const EMAIL_PLACEHOLDER = 'Enter your email';
export const PASSWORD_PLACEHOLDER = 'Enter your password';

export const RESET_STAGES = {
  request: 'request',
  wait: 'wait',
  password: 'password',
  success: 'success',
};

export type ResetStageType = $Values<typeof RESET_STAGES>;
