// @flow
import React from 'react';
import type {Node} from 'react';
import type {PropsType as IconPropsType} from '@wellstone-solutions/web';
import {
  Card,
  CardActions,
  CardContent,
  Icon,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {CardHeader} from '@mui/material';
import {PfButton} from 'components/shared/PfButton';
import {CardDataListHeaderAction, CardDataListItem} from './';

type PropsType = {
  footerProps?: {
    actionColor?: string,
    actionText?: string,
    actionIcon?: string,
    onActionClick: () => void,
  } | null,
  headerProps?: {
    onActionClick?: () => Promise<void>,
    actionIcon?: string,
    actionIconProps?: IconPropsType,
    backgroundColor?: string,
    color?: string,
    actionOverride?: () => void,
  },
  data?: any[],
  ItemDetailsComponent: (props: {item: any}) => Node,
  removeListItem?: (item: any) => Promise<void>,
  removeOverride?: (item: any) => void,
  title: string,
  subheader?: string,
  emptyListText?: string,
  specialMessage?: string,
};

export const CardDataList = ({
  footerProps = null,
  headerProps = {},
  title,
  subheader,
  emptyListText,
  specialMessage,
  data = [],
  ItemDetailsComponent,
  removeListItem,
  removeOverride,
}: PropsType): Node => {
  return (
    <Card>
      <CardHeader
        title={title}
        titleTypographyProps={{
          display: 'inline-block',
        }}
        subheader={subheader}
        subheaderTypographyProps={{
          display: 'inline-block',
          ml: 1,
          color: Theme.colorPalette.lightest,
          fontWeight: 100,
        }}
        sx={{
          color: headerProps.color || Theme.colorPalette.lightest,
          backgroundColor:
            headerProps.backgroundColor || Theme.colorPalette.secondary,
        }}
        action={
          <CardDataListHeaderAction
            onActionClick={headerProps.onActionClick}
            actionIcon={headerProps.actionIcon}
            actionIconProps={headerProps.actionIconProps}
            actionOverride={headerProps.actionOverride}
          />
        }
      />
      <CardContent style={styles.content}>
        {specialMessage && (
          <Stack
            justifyContent="center"
            direction="row"
            style={styles.specialMessage}>
            {specialMessage}
          </Stack>
        )}
        {data.length <= 0 ? (
          <Stack justifyContent="center" direction="row" style={styles.noItems}>
            {emptyListText || 'There are no entries in this list'}
          </Stack>
        ) : (
          <ul>
            {data.map((item, index) => (
              <CardDataListItem
                item={item}
                removeListItem={removeListItem}
                removeOverride={removeOverride}
                ItemDetailsComponent={ItemDetailsComponent}
                key={index}
              />
            ))}
          </ul>
        )}
      </CardContent>
      {footerProps?.onActionClick && (
        <CardActions
          sx={{
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <PfButton
            color={footerProps.actionColor || 'primary'}
            onClick={footerProps.onActionClick}
            style={styles.button}
            aria-label="add list item"
            inverted>
            {footerProps.actionText && (
              <Typography sx={{fontWeight: 'bold', mr: 1}}>
                {footerProps.actionText}
              </Typography>
            )}
            <Icon
              color={footerProps.actionColor || Theme.colorPalette.primary}
              name={footerProps.actionIcon || IconNames.PlusCircle}
              strokeWidth={2}
            />
          </PfButton>
        </CardActions>
      )}
    </Card>
  );
};

const styles = {
  button: {
    borderSize: 0,
    paddingRight: '15px',
  },
  content: {
    padding: 0,
  },
  noItems: {
    padding: '12px 18px',
    borderBottom: `1px solid ${Theme.colorPalette.mediumLight}`,
    color: Theme.colorPalette.dark,
  },
  specialMessage: {
    padding: '12px 18px',
    color: Theme.colorPalette.darkest,
    fontSize: '0.75rem',
    fontStyle: 'italic',
  },
};
