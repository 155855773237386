// @flow
import React, {useRef, useState} from 'react';
import type {ComponentType, Node} from 'react';
import {SearchFilters} from '@wellstone-solutions/web';
import type {SearchFilterModel} from '@wellstone-solutions/web';
import type {
  UIResourceQuestionType,
  UIResourceAnswerType,
} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {SEARCH_FILTER_FIELDS} from '../../constants';
import {SuspendedCategories} from '../SuspendedComponents';
import {LoadingIndicator} from 'components/shared/LoadingIndicator';

type PropsType = {
  shareResource: (
    resource: UIResourceQuestionType | UIResourceAnswerType,
    actions?: string,
  ) => void,
  isLoading: boolean,
  isModal: boolean,
};

export const ResourceAccordion: ComponentType<PropsType> = ({
  shareResource,
  isModal = true,
}: PropsType): Node => {
  const {resourceStore, meStore} = useStores();
  const [hasFilters, setHasFilters] = useState(true);
  const [isSearching, setIsSearching] = useState(true);

  const searchFiltersRef = useRef();

  const clearFilters = () => {
    searchFiltersRef?.current?.clearFilters();
  };

  const onFilterUpdate = async (filterModel: SearchFilterModel) => {
    setHasFilters(filterModel.items.length > 0);
    setIsSearching(true);
    await resourceStore.search({
      states: [meStore.me.membership.organization.state],
      filterModel,
    });
    setIsSearching(false);
  };

  return (
    <>
      <SearchFilters
        apiRef={searchFiltersRef}
        fields={SEARCH_FILTER_FIELDS}
        initialField={SEARCH_FILTER_FIELDS[0]}
        onFilterUpdate={onFilterUpdate}
        operatorOptions={[
          {label: 'Exactly', value: 'equals'},
          {label: 'Contains', value: 'contains'},
          {label: 'Does not contain', value: 'does_not_contain'},
        ]}
        allowOperatorToggle={true}
        isPersisted={true}
        isUrlDriven={false}
        storageKeyOverride="ConversationsResources"
      />
      {isSearching ? (
        <LoadingIndicator stylesOverride={styles.loading} />
      ) : (
        <SuspendedCategories
          clearFilters={clearFilters}
          shareResource={shareResource}
          hasFiltersApplied={hasFilters}
          isModal={isModal}
        />
      )}
    </>
  );
};

const styles = {
  title: {
    color: 'primary.main',
    pb: 3,
    position: 'absolute',
  },
  bump: {
    pt: 5,
  },
  loading: {
    marginTop: -6,
  },
};
