// React
import React from 'react';

import {Routes as SharedRoutes} from 'components/Routes';
import LoginPage from './LoginPage';

export const routes = [
  {
    path: 'login',
    Component: LoginPage,
    isProtected: false,
  },
  {
    path: 'logout',
    Component: () => null,
    isProtected: false,
  },
];

const PublicFrame = () => <SharedRoutes routes={routes} />;

export default PublicFrame;
