// @flow
import React from 'react';
import type {Node, ComponentType} from 'react';
import {observer} from 'mobx-react';
import {Box, Button, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useResourceTabContext} from '../../contexts';
import {useStores} from 'hooks/useStores';

type PropsType = {
  currentCount: number,
  totalCount: number,
};

export const ResourceCount: ComponentType<PropsType> = observer(
  ({currentCount, totalCount}: PropsType): Node => {
    const {
      searchTerm,
      setSearchTerm,
      collectionFilter,
    } = useResourceTabContext();
    const {resourceStoreV2} = useStores();
    const displayTotalCount = totalCount.toLocaleString('en-US');

    const currentCollection = resourceStoreV2.collections.find(
      (collection) => collection.id === collectionFilter[0],
    );

    const getDisplayText = (): string => {
      if ((searchTerm || currentCollection) && currentCount === 0) {
        return 'Showing 0 results';
      }
      if (searchTerm || currentCollection) {
        return `Showing ${currentCount} of ${displayTotalCount} results`;
      }
      return `Showing ${currentCount} of ${displayTotalCount} Resources`;
    };

    return (
      <Box sx={styles.resourcesCount}>
        <Typography sx={styles.text}>
          {getDisplayText()}
          {!!searchTerm && (
            <span data-testid="search-term-text">
              {' for '}
              <span className="strong">"{searchTerm}"</span>
            </span>
          )}
          {!!currentCollection?.name && (
            <span data-testid="collection-filter-text">{` in ${currentCollection.name}`}</span>
          )}
        </Typography>

        {searchTerm && (
          <Button
            onClick={() => setSearchTerm('')}
            variant="text"
            sx={styles.clearButton}>
            Clear search
          </Button>
        )}
      </Box>
    );
  },
);

const styles = {
  resourcesCount: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 2,
    color: Theme.colorPalette.onSurface,
  },
  text: {
    fontSize: 12,
    '& .strong': {
      fontWeight: 700,
    },
  },
  clearButton: {
    textTransform: 'none',
    fontSize: 12,
    lineHeight: 1,
    marginLeft: 0.5,
  },
};
