// @flow
import React, {useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {Box, Stack, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import type {
  UICalendarEventType,
  UICalendarEventInvitationType,
} from '@wellstone-solutions/common';
import {PfButton} from 'components/shared/PfButton';
import {useStores} from 'hooks/useStores';
import {AttendingStatusIcon} from './AttendingStatusIcon';
import type {UIParticipantType} from '../../types';

type PropsType = {
  calendarEvent: UICalendarEventType,
  isOwner?: boolean,
  invitations: Array<UICalendarEventInvitationType>,
  expandable?: boolean,
};

// Only show up to 4 invitations initially
const DEFAULT_LIMIT = 4;

export const Participants: ComponentType<PropsType> = observer(
  ({
    calendarEvent,
    isOwner = false,
    invitations,
    expandable = false,
  }: PropsType): Node => {
    const [showAll, setShowAll] = useState(false);
    const {calendarStore, meStore} = useStores();

    const removeSelf = (collection): Array<any> =>
      collection.filter((item) => item.user !== meStore.me.id);

    const ownerFirst = (a: UIParticipantType, b: UIParticipantType) =>
      a.isOwner ? -1 : 1;

    // get count of known users
    const participants = calendarStore.getNamedParticipants(
      invitations,
      calendarEvent.owner,
    );

    const filteredInvitations = removeSelf(invitations);
    const sortedParticipants = participants.sort(ownerFirst);

    // Limit participants shown to 4
    const listItems = showAll
      ? sortedParticipants
      : sortedParticipants.slice(0, DEFAULT_LIMIT);

    const totalHiddenCount = filteredInvitations.length - listItems.length;
    const unknownCount = filteredInvitations.length - participants.length;
    const revealableCount = totalHiddenCount - unknownCount;

    return (
      <>
        <Stack direction="row" sx={{flexWrap: 'wrap'}} spacing={0} mb={1}>
          {listItems.map((participant) => (
            <Stack direction="row" key={participant.id} sx={styles.participant}>
              <AttendingStatusIcon isAttending={participant.isAttending} />
              <Typography sx={{fontWeight: 'bold'}} variant="body2">
                {String(participant.name)}
              </Typography>
              {participant.isOwner && (
                <Typography sx={styles.isOwnerText} variant="body2">
                  Organizer
                </Typography>
              )}
            </Stack>
          ))}
          {totalHiddenCount > 0 && (
            <Box p={1}>
              <Typography sx={{fontWeight: 'bold'}} variant="body2">
                +{totalHiddenCount} more
              </Typography>
            </Box>
          )}
        </Stack>
        {(showAll || revealableCount > 0) && expandable && (
          <PfButton
            color="light"
            size="small"
            onClick={() => setShowAll(!showAll)}
            style={styles.button}>
            {!showAll ? 'View All Guests' : 'Collapse Guests'}
          </PfButton>
        )}
      </>
    );
  },
);

const styles = {
  button: {
    alignSelf: 'flex-start',
    color: Theme.colorPalette.purple,
  },
  participant: {
    marginRight: 1,
    marginBottom: 1,
    padding: 1,
    paddingRight: 2,
    alignItems: 'center',
    backgroundColor: Theme.colorPalette.mediumLight,
    borderRadius: 1,
  },
  isOwnerText: {
    fontWeight: 'bold',
    ml: 1,
    color: Theme.colorPalette.blue,
  },
};
