// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {Box} from '@wellstone-solutions/web';
import {SessionDocumentList} from 'modules/documentation';
//import {BridgeEventSources, Events} from '@wellstone-solutions/common';
//import {useEventSource} from 'hooks/useEventSource';
import {useStores} from 'hooks/useStores';
import {AccessControl} from 'modules/rbac';

export const DocumentationLanding = (): Node => {
  const {meStore, RBACStore, appUIStore} = useStores();
  //useEventSource(BridgeEventSources.DOCUMENTATION_PAGE);

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: 'Session Documentation',
        url: '/documentation',
        active: true,
      },
    ]);
    // Commented out until VIEWED_DOCUMENTATION is available in API
    //eventStore.addEvent(Events.VIEWED_DOCUMENTATION);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUIStore]);

  const showMyDocuments = RBACStore.hasAccess(
    AccessControl.documentation.viewMySessionDocs,
  );

  const showProgramFilter = RBACStore.hasAccess(
    AccessControl.documentation.viewProgramFilter,
  );

  const defaultParams = showMyDocuments
    ? {staff: meStore.activeMembership.id}
    : {};

  const allColumns = [
    'dateOfService',
    'member',
    'staff',
    'sessionType',
    'typeOfService',
    'status',
    'actions',
  ];

  // Include staff when showing all documents
  const columns = showMyDocuments
    ? allColumns.filter((c) => c !== 'staff')
    : allColumns;

  return (
    <Box mb={1}>
      <SessionDocumentList
        title="Session Documentation"
        defaultParams={defaultParams}
        columnNames={columns}
        showProgramFilter={showProgramFilter}
        showMemberFilter={true}
        showStaffFilter={!showMyDocuments}
      />
    </Box>
  );
};
