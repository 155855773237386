// @flow
import React from 'react';
import type {Node} from 'react';
import {SuspendedComponent} from '@wellstone-solutions/web';
import {useDocumentationDataLoader} from '../dataloaders/documentationDataLoader';

type PropsType = {
  documentId: string,
  children: Node | (() => any),
};
export const DocumentationLoader = ({
  documentId,
  children,
}: PropsType): Node => {
  const dataLoaderArgs = {
    documentId,
    initialFetch: true,
  };

  return (
    <SuspendedComponent
      dataLoaderArgs={dataLoaderArgs}
      // $FlowFixMe
      useDataLoader={useDocumentationDataLoader}
      keyProp={`Document-${documentId}`}>
      {children}
    </SuspendedComponent>
  );
};
