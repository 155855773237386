// @flow
import React from 'react';
import type {Node} from 'react';
import {useParams} from 'react-router-dom';
import {useQueryString} from '@wellstone-solutions/web';

import {CalendarEventLoader} from './CalendarEventLoader';
import {Detail} from './Detail';

export const CalendarDetailPage = (): Node => {
  const query = useQueryString();
  const params = useParams();

  return (
    <CalendarEventLoader
      calendarEventId={params.id}
      originalStart={String(query.get('original_start'))}>
      {/* $FlowFixMe */}
      <Detail />
    </CalendarEventLoader>
  );
};
